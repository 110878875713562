import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Button, Dialog, IconButton } from "@material-ui/core";
import DialogModal from "../../../Extras/DialogModal";
import styles from "./MyAllGroup.module.css";
import CloseIcon from "../../../../Icons/CloseIcon";
import { ShareSocial } from "../../Chats/Extra/ShareURL/Share";
import SidebarContextMenu from "../../Chats/Sidebar/SidebarContextMenu";
import SearchIcon from "@material-ui/icons/Search";
import ConvListSkeleton from "../../../Skeleton/ConvListSkeleton/ConvListSkeleton";
import LoveFace from "../../../../Icons/Emogi/LoveFace";
import {  getMyGroup } from "../../../../Utils/Constants";
import axiosConfig from "../../../../Utils/axiosConfig";
import useAlert from "../../../Extras/Alert/useAlert";
import InfiniteScroll from "react-infinite-scroll-component";
import { getMyGroups } from "../../../../Redux/Actions/myGroupsAction";

const MyAllGroup = (props) => {
    const [myGroups, setMygroups] = useState([]);
	const [isOpen, setIsOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [groupsCount, setGroupsCount] = useState(0);
	const [skip, setSkip] = useState(10);
	const [pgLoading, setPgLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
    const [clickedGroupId, setClickedGroupId] = useState(null);
	const theme = useSelector((state) => state.theme.theme);
	const grpID = useSelector((state) => state.groups.groupData.id);
	const { showAlert } = useAlert();
    const isMobile = window.screen.width < 724;
    const dispatch = useDispatch();
    let myGroupsData = useSelector((state) => state.myGroups.myGroups);
 


    useEffect(() => {
        console.log('myGroupsData', myGroupsData);
        if (myGroupsData) {
            setMygroups(myGroupsData)
        }
    }, [myGroupsData])
    
    
	useEffect(() => {
		getAllGroups();
    }, []);


	const getAllGroups = (searchText) => {
		setIsLoading(true);
		axiosConfig
			.get(
				getMyGroup +
					`?limit=10&offset=0&search_phrase=${
						searchText ? searchText : ""
					}`
			)
            .then((res) => {
				setGroupsCount(res.data.count || 0);
                setIsLoading(false);
                dispatch(getMyGroups(res.data.result || res.data.body))
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
			});
	};

	const pagination = async (searchText) => {
		setPgLoading(true);

		let data = await axiosConfig
			.get(
				getMyGroup +
					`?limit=10&offset=${skip}&search_phrase=${
						searchText ? searchText : ""
					}`
			)
			.then((res) => {
				if (res.status !== 200) return;
				return res.data;
			})
			.catch((err) => {
				setPgLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
				return "error";
			});
		return data;
	};

	const openShareModel = (id) => {
		if (id) {
			setClickedGroupId(id);
			setIsOpen(true);
		}
	};

	const fetchData = () => {
		pagination()
			.then((data) => {                
                dispatch(getMyGroups([...myGroups, ...data.result]))
				setSkip(skip + 10);
				if (skip >= data?.count) {
					setHasMore(false);
				}
				setPgLoading(false);
			})
			.catch((err) => {
				setPgLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
				setPgLoading(false);
			});
	};

	const searchHandler = (e) => {
		if (e.target.value === "") {
			getAllGroups();
			return null;
		}
		getAllGroups(e.target.value);
	};

	const DisplaySkeleton = () => {
		return (
			<>
				<ConvListSkeleton />
				<ConvListSkeleton />
				<ConvListSkeleton />
			</>
		);
	};

	return (
		<DialogModal
			{...props}
			width={isMobile ? "lg" : "sm"}
			maxWidth="true"
			fullWidth={isMobile ? "true" : "false"}
			className={`${styles.root} ${
				theme === "dark" ? styles.rootDark : styles.rootLg
                }`}
		>
			<div className={styles.section}>
				<div
					className={`${styles.sticky} ${
						theme === "dark" ? styles.rootDark : styles.rootLg
					}`}
				>
					<div className={styles.groupHeading}>
						<h6
							className={`${styles.heading} ${
								theme === "dark"
									? styles.headingdark
									: styles.headinglg
							}`}
						>
							My Groups ({groupsCount})
						</h6>
						<span
							className={styles.crossIcon}
							onClick={props.onHide}
						>
							<CloseIcon />
						</span>
					</div>

					<div className={styles.searchBox}>
						<SearchIcon
							style={{ margin: "0 .25rem", color: "#7F89BE" }}
						/>
						<input
							type="text"
							className={styles.searchField}
							placeholder="Search groups"
							onChange={(e) => searchHandler(e)}
						/>
					</div>
				</div>

				{isLoading ? (
					<DisplaySkeleton />
				) : (
					<>
						<InfiniteScroll
							dataLength={myGroups.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={pgLoading && <DisplaySkeleton />}
							scrollableTarget="allGroups"
							height={500}
							className={styles.groupCont}
						>
							{myGroups?.map((grp) => {
								return (
									<div
										className={
											grpID === grp.id
												? styles.myGrp
												: styles.item
										}
										onClick={() =>
											props.handleChangeGroup(grp)
										}
									>
										<div className={styles.cont}>
											<div className={styles.cont}>
												<Avatar
													className="mr-2"
													src={grp.avatar}
													style={{
														background:
															theme === "dark"
																? "#d9def4"
																: "#0d0f22",
														color:
															theme === "dark"
																? "#0d0f22"
																: "#d9def4",
														border: "3px solid #fff",
													}}
												>
													{grp.name[0]?.toUpperCase()}
												</Avatar>

												<span
													className={`${
														styles.grpname
													} ${
														theme === "dark"
															? styles.headingdark
															: styles.headinglg
													}`}
												>
													{grp.name}
												</span>

												{grp.type === "private" && (
													<span
														className={
															styles.editPhoto
														}
													>
                                                        <img
                                                            src="/Home/Lock.svg"
                                                            alt="true"
                                                        />
													</span>
												)}
											</div>
										</div>

										<span className={styles.moreIcon}>
											<span
												onClick={() =>
													openShareModel(grp.id)
												}
											>
												<img
                                                    src="/Home/share.svg"
                                                    alt="true"
													style={{
														width: "4.5rem",
														margin: "3px 0",
														cursor: "pointer",
													}}
													{...props}
												/>
											</span>
											<SidebarContextMenu
												className={styles.hoverBtn1}
												transformOrigin={{
													vertical: "top",
													horizontal: "center",
												}}
												icon={(props) => (
													<img
														src="/Home/options.svg"
														style={{
															width: "4.5rem",
															margin: "0",
															cursor: "pointer",
														}}
                                                        {...props}
                                                        alt="true"
													/>
													// <MoreVertOutlined style={{color:'grey'}} className={styles.hoverBtn} {...props}/>
												)}
											>
                                                <Button
                                                    disableRipple
													className={`${
														styles.deleteBtn
													} ${
														theme === "dark"
															? styles.headingdark
															: styles.headinglg
													}`}
													style={{
														fontSize: "0.9rem",
                                                        margin: -15,
                                                        padding:10
													}}
													onClick={() =>
														props.openInfoModal(
															grp.id
														)
													}
												>
													More Info
												</Button>
											</SidebarContextMenu>
										</span>
									</div>
								);
							})}
						</InfiniteScroll>
						{pgLoading && <DisplaySkeleton />}
						{/* <div className={styles.groupCont}>
						</div> */}
					</>
				)}
			</div>

			<Dialog
				open={isOpen}
				aria-labelledby="share-dialog"
				onClose={() => setIsOpen(!isOpen)}
				classes={{
					paper: `${styles.dialog} ${
						theme === "dark" ? styles.rootDark : styles.rootLg
					}`,
				}}
			>
				<div className={styles.header}>
					<h2
						className={`${
							theme === "dark" ? styles.textdark : styles.textlg
						}`}
					>
						Share Bditto
					</h2>
					<IconButton
						onClick={() => setIsOpen(!isOpen)}
						size="small"
						className={styles.shareClose}
					>
						<CloseIcon />
					</IconButton>
				</div>

				<div>
					<div className={styles.textBox}>
						<LoveFace />
						<p
							className={`${
								theme === "dark"
									? styles.textdark
									: styles.textlg
							}`}
						>
							Recommendation: <br />
							We are competing with leading companies & to stand
							out, we need to focus on fantastic design with an
							engaging copy.
						</p>
					</div>

					<div className={styles.textBox}>
						<LoveFace />
						<p
							className={`${
								theme === "dark"
									? styles.textdark
									: styles.textlg
							}`}
						>
							You can invite anyone to participate in Bditto and
							have fun sharing thoughts & ideas.
						</p>
					</div>

					<div className={styles.textBox}>
						<LoveFace />
						<p
							className={`${
								theme === "dark"
									? styles.textdark
									: styles.textlg
							}`}
						>
							Finding people who you know or finding people with
							similar interests is a lot easier on bditto.
						</p>
					</div>
				</div>

				<div>
					<ShareSocial
						className={styles.ShareSocial}
						url={`${window.location.href}group-info/${clickedGroupId}`}
						iconSize={40}
					/>
				</div>
			</Dialog>
		</DialogModal>
	);
};

export default MyAllGroup;
