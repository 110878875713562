import config from "../config";

const baseURL = config.DJANGO_API_BASE_URL;

const apiURL = config.DJANGO_API_RESOURCE_URL;

export const imageURL = config.DJANGO_API_BASE_URL;

export const endpoint = `${baseURL}${apiURL}`;

export const socialKeys = {
	GOOGLE: config.GOOGLE_CLIENT_ID,
	FACEBOOK: config.FACEBOOK_APP_ID,
	twitter: {
		key: config.TWITTER.KEY,
		secret: config.TWITTER.SECRET,
	},
};

export const GiffyKey = "p2Ozm6NUpRWwgK6CfPAIQPf1jarOhiBX";
export const socialLoginURLs = {
	google: "/social_auth/google/",
	twitter: {
		LOGIN: "/social_auth/twitter/",
		GET_TOKEN: "/social_auth/request-token/",
	},
	facebook: "/social_auth/facebook/",
	createProfile: "/social_auth/create_social_profile/",
};
export const verifyOtpURL = "/auth/verify-otp/";
export const loginURL = "/auth/login/";
export const registerURL = "/auth/signup/";
export const forgetPasswordURL = "/auth/request-password-reset/";
export const verifyAccountURL = "/auth/activate/";

export const toggleOnlineURL = "/auth/toggle-online/";
export const toggleNotificationsURL = "/notifications/toggle-notification/";

export const getSuggestionsURL = "/status/get-suggestions/";
export const getSuggestionsURLV2 = "/status/get-suggestions/v2/";

export const getAllStatusURL = "/status/get-my-status/";
export const getUserStatusURL = "/friends/get-user-status/";
export const getRecentUploadsByFriendsURL = "/friends/recent-count/";
export const changeStatusURL = "/status/change-status/";
export const deleteStatusURL = "/status/delete-status/";
export const createStatusURL = "/status/create-status/";
export const editStatusURL = "/status/edit-status/";

export const getTopHashtagsURL = "/status/get-topn-hashtags/";
export const getTopHashtagsURLV2 = "/status/get-topn-hashtags/v2/";
export const getTagsSuggestionURL = "/status/get-status-by-hashtags/";
export const getTagsSuggestionURLV2 = "/status/get-status-by-hashtags/v2/";
export const searchHashtagsURL = "/status/search-hashtags/";
export const createHashtagsURL = "/status/create-update-count-hashtag/";
export const getSentRequestURL = "/friends/get-sent-requests/";

export const getUserProfileURL = "/auth/get-user-profile/";
export const getMyProfileURL = "/auth/get-my-profile/";
export const editMyProfileURL = "/auth/edit-profile/";
export const deleteMyProfileURL = "/auth/delete-account/";
export const changeMyPasswordURL = "/auth/change-password/";
export const PasswordResetTokenURL = "/auth/password-reset/";
export const PasswordResetURL = "/auth/password-reset-complete/";
export const activateAccountURL = "/auth/activate/";
export const reportIssueURL = "/miscellaneous/report-issue/";
export const addWebUrl = "/auth/AddWebUrl/";
export const deleteWebUrl = "/auth/DeleteWebUrl/";
export const getAllIndustry = "auth/get-all-industries/?search_phrase=";

export const searchKeywordURL = "/auth/searchkeyword/";
export const createKeyWordURL = "/auth/createkeyword/";
export const getInterests = "/auth/getInterests/";
export const addUpdateInterests = "/auth/updateInterests/";
export const deleteInterestsURL = "/auth/deleteInterests/";
export const countsInContactUrl = "/auth/get-friends-count/";

export const RequestDeleteaccountLink =
	"/social_auth/request-delete-account-link/";
export const ValidateDeleteaccountLink =
	"/social_auth/validate-delete-account-link/";
export const Deleteaccount = baseURL + "/api/social_auth/delete-account";

export const getTrendingStatusURL = "/status/get-trending-status/";
export const getTrendingStatusURLV2 = "/status/get-trending-status/v2/";
export const getBuzzingStatusURL = "/status/get-buzzing-status/";
export const getBuzzingStatusURLV2 = "/status/get-buzzing-status/v2/";
export const getRightNowStatusURL = "/status/right-now-status/";
export const getRightNowStatusURLV2 = "/status/right-now-status/v2/";
export const getFavouriteStatusURL = "/status/get-my-favourites/";
export const getHistoryURL = "/status/view-history/";
export const getFriendStatus = "/status/getfriendstatus/";
export const getFriendSuggestion = "/friends/get-friends-suggestions/";

export const getShortUserProfileUrl = "/auth/shortuserprofile/";

export const searchStatusURL = "/miscellaneous/search-status/";
export const searchStatusURLV2 = "/status/search-status/v2/";
export const searchUserURL = "/miscellaneous/search-users/";
export const searchUserURLV2 = "/status/search-user/v2/";
export const searchMessagesURLV2 = "/user/exploreMessage";

export const getGroupInfoURL = "/friends/get-group-info/";

// Chats API Endpoints
export const chatEndpoint = config.CHAT_API;
// export const chatEndpoint = ;

export const getFriendsRecentCount = "/friends/recent-count/";
export const getFriendsList = "/auth/get-friends-list/";
export const getBlockedUsers = "/friends/get-blocked-users/";
export const getPendingRequests = "/friends/get-pending-requests/";
export const unFriend = "/friends/unfriend/";
export const blockUnblock = "/friends/toggle-block/";
export const userReport = "/friends/report-user/";
export const getConversationListURL = {
	group: "/user/conversations/group",
	private: "/user/conversations/private",
};
export const searchFriendURL = "/auth/search-friend/";

export const friendRequestURL = "/friends/send-request/";
export const actionRequest = "/friends/action-request/";
export const allLikedStatus = "/status/get-my-liked-status/";

export const getGroupDetails = "/groups/get_group_details/";
export const editGroupURL = "/groups/edit_group/";
export const createGroupURL = "/groups/create_group/";
export const getGroups = "/groups/getgroups/";
export const joinGroupsURL = "/groups/join_group/";
export const leaveTheGroupsURL = "/groups/leave_group/";
export const getMyGroup = "/groups/mygroups/";
export const removeUserGroupURL = "/groups/remove_user/";
export const actionGroupRequest = "/groups/action_group_request/";
export const makeUserAdminURL = "/groups/make_admin/";
export const deleteGroupURL = "/groups/delete/";

export const getChatsURL = "/group/chats";
export const getPersonalChatsURL = "/user/chats";
export const getMediaByID = "/user/media";
export const getConversationID = "/user/conversationID";

export const joinGroupURL = "/group/joinGroup";
export const likeMessageURL = "/group/likeMessage";
export const uploadMediaURL = "/user/uploadMedia";
export const reportMessageURL = "/user/reportMessage";
export const leaveGroupURL = "/group/leaveGroup";
export const setStatusAsFavURL = "/status/set-favourite/";
export const getNotificationURL = "/notifications/get-notifications/";
export const starMessageURL = "/user/star";
export const unstarMessageURL = "/user/unstar";
export const setNotificationSeenURL = "/notifications/notification-seen/";

export const getLikedMessages = "/user/likes";
export const getStarredMessages = "/user/stars";
export const likeStatus = "/status/like-status/";
export const getStatusLikers = "/status/liked-users/";
export const getMsgLikers = "/group/likes/";
export const findMessageURL = "/user/findMessage";
export const removeUserURL = "/group/removeMember";
export const getMessageDetails = "/group/messageById";
export const getMessageReads = "/group/getMessageDetails";
export const forwardMessage = "/group/forward";
export const getMessageLikes = (messageID) => `/group/likes/${messageID}`;

export const foldersAPI = {
	GET_FOLDERS: "/status/getfolders/",
	ADD_UPDATE_FOLDER: "/status/addupdatefolder/",
	DELETE_FOLDER: "/status/deletefolder/",
	ADD_STATUS_TO_FOLDER: "/status/addremovefavstatus/",
};

export const TurnOffURL = "/auth/turn-off-user/";
export const turnedOffUsers = "/auth/turned-off-users-get/";
export const checkUsername = "/auth/check-username-availability";

export const CHAT_META_SERVICES = { OPEN_GRAPH: "/meta/opengraph" };

export const googlePlacesEndpoint =
	"https://maps.googleapis.com/maps/api/place/autocomplete/json";

export const MESSAGE_STATUS = {
	PENDING: 0,
	SENT: 1,
	DELIVERED: 2,
	sending: -1,
};

export const MESSAGE_MAX_LENGTH = Math.pow(2, 16);
