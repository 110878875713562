import React, { useState } from "react";

import { findMessageURL } from "../../../../Utils/Constants";
import axiosChatConfig from "../../../../Utils/axiosChatConfig";

import styles from "./SearchInConv.module.css";
import LikedMessage from "../Extra/LikedMessage/LikedMessageBox";
import { CircularProgress, IconButton, Slide } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Close, Search } from "@material-ui/icons";

const SearchInConv = (props) => {
	const [result, setResult] = useState([]);
	const [loading, setLoading] = useState(false);
	const container = props.show ? styles.contShow : styles.contHide;
	const userID = useSelector((state) => state.auth?.user_data?.userID);
	const theme = useSelector((state) => state.theme.theme);
	const isMobile = window.screen.width < 768;
	const closeDrawer = () => {
		document.querySelector("#text").value = "";
		setResult([]);
		props.handleSearchConv();
	};

	const closeDrawer1 = () => {
		closeDrawer();
	};

	const searchHandler = (e) => {
		// if (e.key === "Enter") {
		if (e.target.value.length > 0) {
			let key = e.target.value;

			const data = {
				keyword: key,
				conversationID: props.ID,
				isGroup: props.type === "group" ? true : false,
			};
			setLoading(true);

			axiosChatConfig
				.post(findMessageURL, data)
				.then((res) => {
					if (res.status !== 200) throw new Error();
					setResult(res.data.chats);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
				});
		}
	};
	return (
		<div
			className={`${container} ${
				theme === "dark" ? styles.darkbg : styles.lgbg
			}`}
		>
			{isMobile ? (
				<>
					<div
						className={styles.topBar}
						style={{ display: "flex", marginTop: "0.5rem" }}
					>
						<IconButton onClick={closeDrawer}>
							<Close
								htmlColor={`${
									theme === "dark" ? "#D9DEF4" : "#0d0f22"
								}`}
							/>
						</IconButton>
						<div
							className={styles.searchCont}
							style={{ margin: "auto 0.5rem", width: "100%" }}
						>
							<Search />
							<input
								id="text"
								className={styles.input}
								type="text"
								onKeyDown={searchHandler}
								placeholder="Search messages"
							/>
						</div>
					</div>
				</>
			) : (
				<>
					<div className={styles.topBar}>
						<IconButton onClick={closeDrawer}>
							<Close
								htmlColor={`${
									theme === "dark" ? "#D9DEF4" : "#0d0f22"
								}`}
							/>
						</IconButton>
					</div>
					<div className={styles.searchCont}>
						<Search />
						<input
							id="text"
							className={styles.input}
							type="text"
							onKeyDown={searchHandler}
							placeholder="Search messages"
						/>
					</div>
				</>
			)}

			<div className={styles.searchResult}>
				{result.length !== 0 ? (
					result.map((message, i) => {
						return (
							<LikedMessage
								isGroup={props.type === "group" ? true : false}
								conversationID={
									message.from
									// userID === Number(message.from)
									// 	? message.from
									// 	: message.to
								}
								data={message}
								key={i}
								closeSearchConv={closeDrawer1}
							/>
						);
					})
				) : (
					<div className={styles.no_result}>No Result Found</div>
				)}
			</div>
			<Slide direction="down" in={loading} mountOnEnter unmountOnExit>
				<div className={styles.loaderContainer}>
					<CircularProgress
						size="2.5rem"
						thickness="5"
						style={{
							color: theme === "dark" ? "#d9def4" : "#0d0f22",
						}}
						className={styles.loader}
					/>
				</div>
			</Slide>
		</div>
	);
};

export default React.memo(SearchInConv);
