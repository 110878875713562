import {
	Avatar,
	Button,
	CircularProgress,
	useMediaQuery,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosConfig from "../../../../Utils/axiosConfig";
import { actionRequest } from "../../../../Utils/Constants";
import styles from "../Contacts.module.css";

import useAlert from "./../../../Extras/Alert/useAlert";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { getCountInContact } from "../../../../Redux/Actions/contactAction";
import Username from "../../../Extras/Username";
import BlockDialog from "../../../Extras/BlockDialogBox/BlockDialog";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import { useSelector } from "react-redux";

export const RequestedCard = ({ friend, setRequests, Requests, requestId }) => {
	const { showAlert } = useAlert();
	const [isAccSending, setIsAccSending] = useState(false);
	const [isRejSending, setIsRejSending] = useState(false);
	const dispatch = useDispatch();

	const theme = useSelector((state) => state.theme.theme);

	const [closeDialog, setCloseDialog] = useState();
	const isMobile = window.screen.width < 768;

	const requestAction = (id, action) => {
		setIsAccSending(true);

		const request = new FormData();
		request.append("requestId", requestId);
		request.append("action", action);

		axiosConfig
			.post(actionRequest, request)
			.then((res) => {
				if (res.data.status_code !== 200) throw new Error();
				setIsAccSending(false);
				setRequests(
					Requests.filter(
						(request) => request.requestId !== requestId
					)
				);
				dispatch(getCountInContact());
				showAlert("", {
					header: "Request deleted Successfully",
					buttonText: "Go Back",
				});
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert(err.response.data.message, {
							header: err.response.data.error,
							buttonText: "Try Again",
						});
					}
				} else {
					showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
				setIsAccSending(false);
			});
	};
	return (
		<div className={styles.pendingCard}>
			<Link
				to={`/profile/${friend.pk}`}
				className={styles.pendingRequestProfileLink}
			>
				<Avatar
					src={friend.avatar}
					classname={styles.avatarSent}
					style={{
						marginRight: ".55rem",
						background: theme === "dark" ? "#d9def4" : "#0d0f22",
						color: theme === "dark" ? "#0d0f22" : "#d9def4",
						border: theme === "dark" ? "2px solid #d9def4" : "",
						"@media screen and (min-width: 768px) ": {
							marginTop: "0.2rem",
						},
					}}
				>
					{friend.full_name && friend.full_name[0].toUpperCase()}
				</Avatar>

				<p
					className={
						friend.profession ? styles.cardText1 : styles.cardText
					}
				>
					<span
						style={{
							fontSize: "1rem",
							color: theme === "dark" ? "#d9def4" : "#0D0F22",
						}}
					>
						<Username value={friend.full_name || friend.username} />
					</span>
					{friend.profession && (
						<p
							s
							style={{
								color: theme === "dark" ? "#7F89BE" : "#4A517E",
								padding: "0rem 0rem 0rem 0.2rem",
								fontSize: "0.85rem",
								margin: "0.5rem 0rem 0rem 0",
							}}
						>
							{friend.profession && friend.profession}
						</p>
					)}
				</p>
			</Link>

			<div className={styles.btnCont}>
				{isAccSending ? (
					<CircularProgress
						size="20px"
						style={{ color: "#00C6FF" }}
					/>
				) : (
					<>
						<div onClick={() => requestAction(friend.pk, "Delete")}>
							<Button1 padding={"5px 18px"}>
								<FormattedMessage
									id="withdraw"
									defaultMessage={`Withdraw`}
								/>
							</Button1>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
