import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axiosConfig from "../../../Utils/axiosConfig";
import { getHistoryURL } from "../../../Utils/Constants";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import CardSkeleton from "../../Skeleton/CardSkeleton/CardSkeleton";
import styles from "./History.module.css";
import EmptyState from "../../Extras/EmptyState";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import formatDate from "date-fns/format";
import useQuery from "./../../../Utils/useQuery";
import { IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import Arrows from "../../../Icons/Arrows";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import CancelIcon from "@material-ui/icons/Cancel";

const FETCH_LIMIT = 6;
const History = () => {
	const [search, setSearch] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [paginationLoading, setPaginationLoading] = useState(false);
	const [historyData, setHistory] = useState([]);
	const [searchedData, setSearched] = useState([]);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	let reset = useRef(false);
	const history = useHistory();
	const { searchQuery } = useQuery();
	let offset = useRef(0);
	let end = useRef(false);
	const searchInputRef = useRef();
	const theme = useSelector((reduxState) => reduxState.theme.theme);

	useEffect(() => {
		setSearchText("");
	}, []);

	const setLoading = (state) => {
		if (offset.current !== 0) {
			setPaginationLoading(state);
		} else {
			setIsLoading(state);
		}
	};

	const getFavourites = (text = "") => {
		if (isLoading || end.current) return;

		const activeForm = new FormData();

		setLoading(true);

		let URL = `${getHistoryURL}?limit=${FETCH_LIMIT}&offset=${offset.current}&search_phrase=${text}&groupId=${groupDataFromStore.id}`;
		axiosConfig
			.get(URL, activeForm)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setLoading(false);
				reset.current = false;
				if (text) {
					setSearched(res.data.result || res.data.body);
				} else {
					setSearched([]);
					setHistory((prev) => [
						...prev,
						...(res.data.body || res.data.result),
					]);
					offset.current += FETCH_LIMIT;
					if (!res.data.next) end.current = true;
				}
				setSearchText("");
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const keyPress = (e) => {
		const text = e.target.value.trim();

		if (e.keyCode === 13 && text.length >= 1) {
			history.push({
				pathname: "/myprofile/history",
				search: `?searchQuery=${text}`,
			});
		} else if (e.keyCode === 8 && searchText.length <= 1) {
			history.push({
				pathname: "/myprofile",
			});
		}
	};
	useEffect(() => {
		setHistory([]);
		setSearchText(searchQuery);
		reset.current = true;
		offset.current = 0;
		end.current = false;

		if (searchQuery) {
			setSearch(true);
			getFavourites(searchQuery);
		} else {
			getFavourites();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const handleHistoryScroll = (event) => {
		const bottom =
			event.target.scrollHeight - event.target.scrollTop <=
			event.target.clientHeight;
		if (
			bottom &&
			!isLoading &&
			!paginationLoading &&
			historyData.length != 0
		) {
			getFavourites();
		}
	};
	useEffect(() => {
		if (search) {
			searchInputRef.current.focus();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
	const isMobile = window.screen.width < 768;

	const resetSearch = (e) => {
		setSearchText("");
		history.push("/myprofile");
	};

	return (
		<>
			{!isMobile ? (
				<div className={styles.wrapper} onScroll={handleHistoryScroll}>
					<div className={styles.container}>
						<div className={styles.header}>
							<h2
								style={{
									color:
										theme === "dark"
											? "#d9def4"
											: "#0D0F22",
								}}
							>
								{" "}
								<FormattedMessage
									id="activity"
									defaultMessage={`Activity`}
								/>
							</h2>
						</div>

						<div className={styles.searchContainer}>
							<img
								src="/Search.svg"
								className="ml-2 mr-1"
								alt=""
							/>

							<input
								type="text"
								value={searchText}
								ref={searchInputRef}
								className={styles.input}
								onChange={(e) => setSearchText(e.target.value)}
								onKeyDown={keyPress}
								placeholder={`Search History`}
							/>
							{searchText !== "" && (
								<CancelIcon
									onClick={() => resetSearch()}
									style={{
										margin: "auto 0",
										color:
											theme === "dark"
												? "#7F89BE"
												: "#4A517E",
									}}
								/>
							)}
						</div>

						<div className={styles.cards}>
							<Content
								theme={theme}
								items={historyData}
								isLoading={isLoading}
								searchQuery={searchQuery}
							/>

							{searchedData?.length > 0 ? (
								<SearchedStatus
									theme={theme}
									searchedData={searchedData}
								/>
							) : searchQuery ? (
								<EmptyState heading="No Results for your search" />
							) : null}

							{paginationLoading && (
								<>
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
									<CardSkeleton width={"35%"} />
								</>
							)}
						</div>
					</div>
				</div>
			) : (
				<div style={{ height: "100%", overflow: "auto" }}>
					<div className={styles.cards}>
						<Content
							theme={theme}
							items={historyData}
							isLoading={isLoading}
							searchQuery={searchQuery}
						/>

						{searchedData?.length > 0 ? (
							<SearchedStatus
								theme={theme}
								searchedData={searchedData}
							/>
						) : searchQuery ? (
							<EmptyState heading="No Results for your search" />
						) : null}

						{paginationLoading && (
							<>
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

const SearchedStatus = ({ theme, searchedData }) => {
	const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
	return (
		<>
			<div className={styles.section}>
				<h1
					className={styles.time}
					style={{ color: theme === "dark" ? "#d9def4" : "#0d0f22" }}
				>
					Current
				</h1>
			</div>
			<div className={styles.cards}>
				{searchedData &&
					searchedData?.map((status, idx) => (
						<StatusCard
							status={status.status}
							key={status.pk}
							idx={idx % FETCH_LIMIT}
							width={isDesktop ? "30.7%" : "100%"}
							height="13rem"
						/>
					))}
			</div>
		</>
	);
};

const Content = ({ theme, items, isLoading, searchQuery }) => {
	const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });

	if (isLoading)
		return (
			<>
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
				<CardSkeleton width={isDesktop ? "30.7%" : "100%"} />
			</>
		);
	else {
		const groups = items.reduce((acc, item) => {
			const createdDate = new Date(item.updated_at || item.created_on);

			let tag = "";
			if (isToday(createdDate)) {
				tag = "Today";
			} else if (isYesterday(createdDate)) {
				tag = "Yesterday";
			} else {
				tag = formatDate(createdDate, "do MMMM");
			}

			if (acc[tag]) {
				return { ...acc, [tag]: [...acc[tag], item.status] };
			} else {
				return { ...acc, [tag]: [item.status] };
			}
		}, {});
		const tags = Object.keys(groups);
		return (
			<>
				{tags.length !== 0
					? tags.map((tag, i) => (
							<React.Fragment key={i}>
								<div className={styles.section}>
									<h1
										className={styles.time}
										style={{
											color:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
										}}
									>
										{tag}
									</h1>
								</div>
								<div className={styles.cards}>
									{groups[tag]
										.reverse()
										.map((status, idx) => (
											<StatusCard
												status={status}
												key={status.pk}
												idx={
													idx %
													(groups[tag].length <
													FETCH_LIMIT
														? groups[tag].length
														: FETCH_LIMIT)
												}
												width={
													isDesktop ? "22rem" : "100%"
												}
												height="13rem"
											/>
										))}
								</div>
							</React.Fragment>
					  ))
					: !searchQuery?.length && (
							<EmptyState
								heading="Go back in time!"
								imageUrl="/vectors/empty-states/emptyHistory.svg"
								subHeading="You haven’t posted anything yet but when you do, you can view all your past statutes here!"
							/>
					  )}
			</>
		);
	}
};

export default History;
