import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import ChatSection from "./ChatSection/GroupChatSection/ChatSection";
import PrivateChat from "./ChatSection/PrivateChatSection/PrivateChat";
import { ReactComponent as ChatImg } from "../../../assets/undrawChat.svg";
import * as Socket from "../../../Utils/socket";
import styles from "./Chat.module.css";
import EmptyState from "../../Extras/EmptyState";

class Chat extends Component {
	constructor(props) {
		super(props);

		this.state = {
			chatType: "",
			chatID: "",
			newMessage: null,
			onlineMessage: null,
			chatsPresent: true,
			likedBox: { category: "liked", open: false },
			searchConvOpen: false,
			chatInfoOpen: false,
			showChat: false,
		};
	}

	setChatsPresent = (present) => {
		this.setState({ chatsPresent: present });
	};
	connectSocket = () => {
		Socket.connect(this.setNewMessages, this.setOnlineMessage);
	};

	handleRightBarOpen = ({ searchConv, chatInfo }) =>
		this.setState({
			searchConvOpen: searchConv,
			chatInfoOpen: chatInfo,
		});

	componentDidMount = () => {
		this.connectSocket();
		if (Object.keys(this.props.rootParams.params).length) {
			const { chatType, chatID } = this.props.rootParams.params;

			this.setState({ chatType: chatType.toLowerCase(), chatID });
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { chatType, chatID } = this.props.rootParams.params;
		const { params } = prevProps.rootParams;
		if (
			params.chatType !== chatType &&
			!this.props.rootParams.params.chatType
		) {
			this.setState({ chatType: undefined, chatID: undefined });
		}
		if (Object.keys(this.props.rootParams.params).length) {
			if (!chatType) {
				this.setState({ chatType: undefined, chatID: undefined });
				return;
			}

			if (
				chatType.toLowerCase() !== params.chatType ||
				chatID !== params.chatID
			) {
				this.setState({ chatType: chatType.toLowerCase(), chatID });
			}
		} else {
			const isMobile = window.screen.width < 768;
			if (isMobile && chatType !== params.chatType) {
				this.setState({ chatType: "", chatID: "" });
			}
		}
	};

	setNewMessages = (message) => {
		if (this.state.newMessage) {
			this.setState({
				newMessage: [message, ...this.state.newMessage],
			});
		} else
			this.setState({
				newMessage: [message],
			});
	};

	setOnlineMessage = (message) => {
		this.setState({ onlineMessage: message });
	};

	displayDefault = () => {
		return !this.state.chatsPresent ? (
			<EmptyState
				id="suggestions-box"
				heading="No Messages Yet!"
				imageUrl="/vectors/empty-states/emptyChats.svg"
				subHeading="Looks like you haven’t initiated a conversation yet. What are you waiting for, send your first message!"
			/>
		) : (
			<div className={styles.imgCont}>
				<ChatImg className={styles.img} />
			</div>
		);
	};
	SetLikedBoxState = (category, open) => {
		const state = {};
		if (category) state.category = category;
		if (open !== undefined) {
			state.open = open;
		}
		this.setState((prev) => ({
			likedBox: { ...prev.likedBox, ...state },
		}));
	};

	closeLikedBox = () => {
		this.setState((prev) => {
			return { likedBox: { ...prev.likedBox, open: false } };
		});
	};
	render() {
		const isMobile = window.screen.width < 768;

		return (
			<>
				{!isMobile ? (
					<div className={styles.homeContainer}>
						<div
							className={`${styles.center} ${
								this.state.chatType &&
								this.state.chatID &&
								styles.centerExpanded
							}`}
						>
							<Sidebar
								chatID={this.state.chatID}
								isLoading={this.state.isConversationLoading}
								conversationList={this.state.conversationList}
								newMessage={this.state.newMessage}
								setChatsPresent={this.setChatsPresent}
								chatsPresent={this.state.chatsPresent}
								handleLikedState={this.SetLikedBoxState}
								likedBox={this.state.likedBox}
								closeLikedBox={this.closeLikedBox}
							/>

							{this.state.chatType ? (
								this.state.chatType === "private" ? (
									<PrivateChat
										chatID={this.state.chatID}
										handleScroll={this.handleScroll}
										onlineMessage={this.state.onlineMessage}
										handleRightBar={this.handleRightBarOpen}
										searchOpen={this.state.searchConvOpen}
										chatInfoOpen={this.state.chatInfoOpen}
									/>
								) : (
									<ChatSection
										chatType={this.state.chatType}
										chatID={this.state.chatID}
										handleScroll={this.handleScroll}
										connectSocket={this.connectSocket}
										onlineMessage={this.state.onlineMessage}
										handleRightBar={this.handleRightBarOpen}
										searchOpen={this.state.searchConvOpen}
										chatInfoOpen={this.state.chatInfoOpen}
									/>
								)
							) : (
								this.displayDefault()
							)}
						</div>
					</div>
				) : (
					<>
						<div
							style={{
								height: "80vh",
							}}
						>
							<Sidebar
								chatID={this.state.chatID}
								isLoading={this.state.isConversationLoading}
								conversationList={this.state.conversationList}
								newMessage={this.state.newMessage}
								setChatsPresent={this.setChatsPresent}
								chatsPresent={this.state.chatsPresent}
								handleLikedState={this.SetLikedBoxState}
								likedBox={this.state.likedBox}
								closeLikedBox={this.closeLikedBox}
								showChat={this.state.showChat}
							/>

							{this.state.chatType ? (
								this.state.chatType === "private" ? (
									<PrivateChat
										chatID={this.state.chatID}
										handleScroll={this.handleScroll}
										onlineMessage={this.state.onlineMessage}
										handleRightBar={this.handleRightBarOpen}
										searchOpen={this.state.searchConvOpen}
										chatInfoOpen={this.state.chatInfoOpen}
									/>
								) : (
									<ChatSection
										chatType={this.state.chatType}
										chatID={this.state.chatID}
										handleScroll={this.handleScroll}
										connectSocket={this.connectSocket}
										onlineMessage={this.state.onlineMessage}
										handleRightBar={this.handleRightBarOpen}
										searchOpen={this.state.searchConvOpen}
										chatInfoOpen={this.state.chatInfoOpen}
									/>
								)
							) : (
								this.displayDefault()
							)}
						</div>
					</>
				)}
			</>
		);
	}
}

export default withRouter(Chat);
