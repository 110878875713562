import {
	// Badge,
	Button,
	// Chip,
	CircularProgress,
	debounce,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
// import { IconButton, Slide } from "@material-ui/core";
// import SearchIcon from "@material-ui/icons/Search";

import Close from "../../../Icons/CloseIcon";
// import SidebarContextMenu from "../../Home/Chats/Sidebar/SidebarContextMenu";
import {
	addUpdateInterests,
	// getTopHashtagsURLV2,
	// searchHashtagsURL,
	deleteInterestsURL,
	getInterests,
	createKeyWordURL,
} from "../../../Utils/Constants";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import { OverlayTrigger, Popover } from "react-bootstrap";
// import BlockDialog from "../../Extras/BlockDialogBox/BlockDialog";
import { getParsedLocations } from "../../../Redux/selectors/locationSelector";
import "./InterestsSidebar.scss";
import styles from "./Interests.module.css";
import axiosConfig, { bdittoAPIv2 } from "../../../Utils/axiosConfig";
import { createRef } from "react";

import withAlert from "../../Extras/Alert/withAlert";
import { searchKeywordURL } from "../../../Utils/Constants";
import { FormattedMessage } from "react-intl";
import AddIcon from "../../../Icons/AddIcon";
import Star from "../../../Icons/Star";
// import Search from "../../../Icons/SearchIcon";
// import Minus from '../../../Icons/Minus'
import MinusIcon from "../../../Icons/MinusIcon";

class Interests extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tags: [],
			isLoading: true,
			intrests: [],
			allInterests: [],
			error: "",
			searchTerm: "",
			addLoading: false,
			tags: [],
			suggestedTags: [],
			isTagsLoading: false,
			tagsToAdd: null,
			tagsSelected: [],
			editMode: false,
			createMode: false,
		};
		this.searchInputRef = createRef();
	}

	componentDidMount = () => {
		this.setState({ isLoading: true });
		this.getInterests();
		this.getSuggestedTags();
	};

	componentDidUpdate(prevProps) {
		if (this.props.countries !== prevProps.countries) {
			this.setState({ isLoading: true });

			this.getInterests();
		}
		if (this.props.groupDataFromStore !== prevProps.groupDataFromStore) {
			this.setState({ isLoading: true });

			this.getInterests();
		}
		if (
			this.props.groupDataFromStore.id !== prevProps.groupDataFromStore.id
		) {
			this.setState({ isLoading: true });

			this.getInterests();
		}
	}
	handleSearchInput = (e) => {
		let regex = new RegExp("/[^w]|_/g");

		if (!regex.test(e.target.value)) {
			this.setState({
				error: "",
			});
		}

		this.setState({ searchTerm: e.target.value, editMode: false });
		this.searchStatus(e.target.value);

		if (e.target.value === "") {
			this.setState({
				intrests: this.state.allInterests,
			});
			return;
		}

		let arr = this.state.intrests.filter((item) =>
			item.name.includes(e.target.value)
		);
		this.setState({
			intrests: arr,
		});
	};

	searchStatus = debounce((searchTerm) => {
		this.searchTags(searchTerm);
	}, 400);

	searchTags = () => {
		if (this.state.searchTerm.length <= 1) return;

		this.setState({ isTagsLoading: true });
		const form = new FormData();
		form.append("keyword", this.state.searchTerm);

		axiosConfig
			.post(searchKeywordURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.body?.length == 0) {
					this.setState({
						isTagsLoading: false,
						createMode: true,
						tags: [],
					});
				} else {
					this.setState({
						tags: res.data.body,
						isTagsLoading: false,
						createMode: false,
					});
				}
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.data.message ===
						"Keyword can't have special symbol"
					) {
						this.setState({
							error: "Keyword can't have special symbol",
						});
						return;
					}
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						this.props.showAlert(err.message, {
							header: "Request failed",
							buttonText: "Try Again",
						});
					}
				} else {
					this.props.showAlert("Request Falied", {
						buttonText: "Try Again",
					});
				}
				this.setState({ isLoading: false });
			});
	};
	getSuggestedTags = () => {
		this.setState({ isTagsLoading: true });
		const form = new FormData();
		form.append("keyword", "");

		axiosConfig
			.post(searchKeywordURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.body?.length == 0) {
					this.setState({
						isTagsLoading: false,
						createMode: true,
						suggestedTags: [],
					});
				} else {
					this.setState({
						suggestedTags: res.data.body,
						isTagsLoading: false,
						createMode: false,
					});
				}
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.data.message ===
						"Keyword can't have special symbol"
					) {
						this.setState({
							error: "Keyword can't have special symbol",
						});
						return;
					}
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						this.props.showAlert(err.message, {
							header: "Request failed",
							buttonText: "Try Again",
						});
					}
				} else {
					this.props.showAlert("Request Falied", {
						buttonText: "Try Again",
					});
				}
				this.setState({ isLoading: false });
			});
	};

	addUpdateIntrests = (id) => {
		const form = new FormData();
		form.append("keywords", id);
		this.setState({ addLoading: true });
		axiosConfig
			.post(addUpdateInterests, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.updateState(res.data.body);
			})
			.catch((err) => {
				this.setState({ addLoading: false });
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	createKeyword = () => {
		if (this.state.searchTerm === null) return;
		this.setState({ createLoading: true });
		const form = new FormData();
		form.append("name", this.state.searchTerm);
		axiosConfig
			.post(createKeyWordURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.updateStateAfterCreatekeyword(res.data.body);
			})
			.catch((err) => {
				this.setState({ createLoading: false });
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	updateStateAfterCreatekeyword = (res) => {
		setTimeout(() => {
			this.addUpdateIntrests(res.Id);
		}, 200);
	};

	updateState = (res) => {
		setTimeout(() => {
			this.getInterests();
		}, 200);

		this.setState({
			tagsSelected: [],
			tagsToAdd: null,
			tags: [],
			searchTerm: "",
			addLoading: false,
		});
	};

	deleteInterests = (id) => {
		const form = new FormData();
		form.append("keywords", id);

		axiosConfig
			.post(deleteInterestsURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setTimeout(() => {
					this.getInterests();
				}, 200);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	getInterests = () => {
		if (!this.props.groupDataFromStore.id) return;
		const { countries } = this.props;
		const data = {
			location: countries,
			groupId: this.props.groupDataFromStore.id,
		};

		bdittoAPIv2
			.post(getInterests, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					intrests: res.data.body,
					allInterests: res.data.body,
					isLoading: false,
				});
			})
			.catch((err) => {
				this.setState({ isLoading: false });
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	handleEdit = () => {
		if (this.state.searchTerm?.length === 0) {
			this.setState({ editMode: !this.state.editMode });
		}
	};

	displayInterests() {
		const activeInterest = this.props.location?.pathname.replace(
			"/interests/",
			""
		);

		if (this.state.intrests && this.state.intrests?.length < 1) {
			return (
				<h6 className={styles.noIntrests}>No Interests Added Yet</h6>
			);
		} else {
			return (
				<>
					<div
						className={
							window.screen.width < 768 && styles.mobInterests
						}
					>
						{this.state.intrests &&
							this.state.intrests.map((interest, index) => (
								<div
									className={
										activeInterest === interest.name
											? styles.activetagContainer
											: styles.tagContainer
									}
								>
									<Link
										className={styles.link}
										to={`/interests/${interest.name}`}
										key={interest.pk}
									>
										<h4
											className={
												activeInterest === interest.name
													? `${styles.activetag} activetag`
													: `${styles.tag} ${
															this.props.theme ===
															"dark"
																? styles.darkl
																: styles.lgl
													  } tag`
											}
										>
											{interest.name}&nbsp;
											{interest.count > 0 && (
												<span
													className={`${
														styles.count
													} ${
														this.props.theme ===
														"dark"
															? styles.darkl
															: styles.lgl
													}`}
												>
													&nbsp;&nbsp;(
													{interest.count})
												</span>
											)}
										</h4>
									</Link>
									{/* <SidebarContextMenu
                            className={styles.hoverBtn1}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center",
                                }}
                                icon={(props) => (
                                    <MoreVertIcon
                                        className={styles.hoverBtn}
                                        {...props}
                                    />
                                )}

                            >
                                <Button
                                    className={styles.deleteBtn}
                                    onClick={() => {
                                        this.deleteInterests(interest.id)
                                    }}
                                >
                                    <FormattedMessage
                                        id="delete"
                                        defaultMessage={`Remove`}
                                    />
                                </Button>
                            </SidebarContextMenu> */}
									<div
										className={styles.removeTag}
										onClick={() => {
											this.deleteInterests(interest.id);
										}}
									>
										{/* <Minus.Outline /> */}
										<MinusIcon
											color={
												this.props.theme === "dark"
													? "#d9def4"
													: "#0d0f22"
											}
										/>
									</div>
								</div>
							))}
						{this.state.suggestedTags.length > 0 &&
							this.state.suggestedTags.map((tag, index) => (
								<>
									{this.state.intrests &&
									this.state.intrests.find(
										(ele) => ele.id == tag.pk
									) ? (
										<></>
									) : (
										<>
											<div
												className={styles.chipContainer}
											>
												<span className={styles.link}>
													<h4
														className={`${styles.tag} newTag`}
													>
														{tag.name}
													</h4>
												</span>
												<span
													onClick={() =>
														this.addUpdateIntrests(
															tag.pk
														)
													}
													className={styles.addBtn}
												>
													<AddIcon
														color={
															this.props.theme ===
															"dark"
																? "#7F89BE"
																: "#4A517E"
														}
													/>
												</span>
											</div>
										</>
									)}
								</>
							))}
						{this.state.editMode ? (
							<Button
								variant="contained"
								className={styles.post}
								onClick={this.handleEdit}
							>
								Cancel
							</Button>
						) : (
							<></>
						)}
					</div>
				</>
			);
		}
	}

	displayTags() {
		if (this.state.tags?.length > 0) {
			return (
				<>
					<div>
						{this.state.tags.find(
							(ele) => ele.name === this.state.searchTerm
						) ? (
							""
						) : (
							<>
								{this.state.searchTerm?.length > 0 && (
									<div className={styles.chipContainer}>
										<span className={styles.link}>
											<h4
												className={`${styles.tag} newTag`}
											>
												{this.state.searchTerm}
											</h4>
										</span>
										<span
											onClick={() => this.createKeyword()}
											className={styles.addBtn}
										>
											<AddIcon
												color={
													this.props.theme === "dark"
														? "#7F89BE"
														: "#4A517E"
												}
											/>
											<AddIcon />
										</span>
									</div>
								)}
							</>
						)}
						{this.state.searchTerm?.length > 0 &&
							this.state.tags.map((tag, index) => (
								<>
									{this.state.intrests &&
									this.state.intrests.find(
										(ele) => ele.id == tag.pk
									) ? (
										<></>
									) : (
										<>
											<div
												className={styles.chipContainer}
											>
												<span className={styles.link}>
													<h4
														className={`${styles.tag} newTag`}
													>
														{tag.name}
													</h4>
												</span>
												<span
													onClick={() =>
														this.addUpdateIntrests(
															tag.pk
														)
													}
													className={styles.addBtn}
												>
													<AddIcon
														color={
															this.props.theme ===
															"dark"
																? "#7F89BE"
																: "#4A517E"
														}
													/>
												</span>
											</div>
										</>
									)}
								</>
							))}
					</div>
				</>
			);
		} else if (this.state.createMode) {
			return (
				<>
					{this.state.searchTerm?.length > 0 && (
						<div className={styles.chipContainer}>
							<span className={styles.link}>
								<h4 className={`${styles.tag} newTag`}>
									{this.state.searchTerm}
								</h4>
							</span>
							<span
								onClick={() => this.createKeyword()}
								className={styles.addBtn}
							>
								<AddIcon
									color={
										this.props.theme === "dark"
											? "#7f89be"
											: "#4A517E"
									}
								/>
							</span>
						</div>
					)}
				</>
			);
		}
	}
	render() {
		const isMobile = window.screen.width < 768;

		return (
			<>
				{!isMobile && (
					<div className={styles.tagsContainer} id="Tags">
						<div className={styles.backgroundText}>
							<div className={styles.header}>
								{isMobile && (
									<span
										style={{ color: "#ff5d5d" }}
										onClick={this.props.history.goBack}
									>
										<Close />
									</span>
								)}

								{this.props.theme === "dark" ? (
									<Star.WhiteOutline />
								) : (
									<img src="/Home/star-lg.svg" alt="" />
								)}

								<h2 className={`heading`}>
									<FormattedMessage
										id="interests"
										defaultMessage={`My Interests`}
									/>
								</h2>
							</div>
						</div>

						<div className={`${styles.searchBox} searchBox`}>
							<img src="/Search.svg" className="mx-1" alt="" />
							<input
								type="text"
								className={`${styles.searchField} searchField px-2`}
								placeholder="Add/Search Interest"
								onChange={(e) => this.handleSearchInput(e)}
							/>
						</div>
						{this.state.error && (
							<p
								style={{
									margin: "1rem 1.5rem",
									color:
										this.props.theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
							>
								{this.state.error}
							</p>
						)}

						<div className={styles.mainContent}>
							{this.state.tags && this.displayTags()}

							{this.state.isLoading ? (
								<div className={styles.loader}>
									<CircularProgress
										className={`${styles.loaderSpinner} ${
											this.props.theme === "dark"
												? styles.darkl
												: styles.lgl
										}`}
									/>
								</div>
							) : (
								this.displayInterests()
							)}
						</div>
					</div>
				)}
				{isMobile && (
					<div className={styles.tagsContainer} id="Tags">
						<div className={styles.backgroundText}>
							<div className={styles.header}>
								<img src="/Mobile/interests.svg" alt="" />
								<h2 className={`heading`}>
									<FormattedMessage
										id="interests"
										defaultMessage={`My Interests`}
									/>
								</h2>
							</div>

							<div className={`${styles.searchBox} searchBox`}>
								<img
									src="/Search.svg"
									className="mx-1"
									alt=""
								/>
								<input
									type="text"
									className={`${styles.searchField} searchField px-2`}
									placeholder="Add/Search Interest"
									onChange={(e) => this.handleSearchInput(e)}
								/>
							</div>

							<div>
								{this.state.tags && this.displayTags()}

								{this.state.isLoading ? (
									<div className={styles.loader}>
										<CircularProgress
											className={`${
												styles.loaderSpinner
											} ${
												this.props.theme === "dark"
													? styles.darkl
													: styles.lgl
											}`}
										/>
									</div>
								) : (
									this.displayInterests()
								)}
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => ({
	countries: getParsedLocations(state),
	groupDataFromStore: state.groups.groupData,
	theme: state.theme.theme,
});
export default connect(mapStateToProps)(withRouter(withAlert(Interests)));
