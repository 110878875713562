import { CLOSE_INCOMMING_CALL, INCOMMING_CALL, TOGGLE_AUDIO, TOGGLE_VIDEO } from "../../Actions/videoCalls/types";
import createReducer from "../utils/createReducer";

const INITIAL_STATE = {
   video: true,
   audio: true ,
   incomingCall: false,
   incommingCallData:[]
   };
const handleVideoToggle = (state, { payload }) => ({ ...state, video: !state.video });
const handleAudioToggle = (state, { payload }) => ({ ...state, audio: !state.audio });
const incommingCall = (state, { payload }) => ({
                  ...state,
                  incomingCall : true,
                  incommingCallData: payload
               });
const incommingCallClose = (state, { payload }) => ({
                 ...state,
                 incomingCall : false,
                 incommingCallData: []
              });

const videoCallsReducer = createReducer(INITIAL_STATE, {
  [TOGGLE_VIDEO]: handleVideoToggle,
  [TOGGLE_AUDIO]: handleAudioToggle,
  [INCOMMING_CALL] :incommingCall,
  [CLOSE_INCOMMING_CALL]: incommingCallClose
});
export default videoCallsReducer;
