export default [
  {
    pk: 2339,
    author: {
      full_name: "nicole willis",
      username: "nicoleWillis",
      pk: 49,
      avatar: "/media/Profiles/49",
    },
    content: "Plenty season beyond by hardly giving of",
    background_color: "#FAE1E3 0%, #EEA8C5 100%",
    background_image: "",
    last_updated_at: "2021-01-14T21:41:52.185121Z",
    current_status: "active",
    userCount: 2,
    profiles: [{ profileURL: "/media/Profiles/49" }],
  },
  {
    pk: 2273,
    author: {
      full_name: "Charles Ortega",
      username: "charlesOrtega",
      pk: 49,
      avatar: "/media/Profiles/49",
    },
    content: "Not rapturous resolving continued Household",
    background_color: "#E0FBFC 0%, #BFF9FA 100%",
    background_image: "",
    last_updated_at: "2020-12-31T17:49:08.500723Z",
    current_status: "active",
    userCount: 1,
    profiles: [{ profileURL: "/media/Profiles/49" }],
  },
  {
    pk: 2339,
    author: {
      full_name: "patricia Palmer",
      username: "patriciaPalmer",
      pk: 49,
      avatar: "/media/Profiles/49",
    },
    content: "Are projection put celebrated particular thinking",
    background_color: "#FAE1E3 0%, #EEA8C5 100%",
    background_image: "",
    last_updated_at: "2021-01-14T21:41:52.185121Z",
    current_status: "active",
    userCount: 2,
    profiles: [{ profileURL: "/media/Profiles/49" }],
  },
  {
    pk: 2273,
    author: {
      full_name: "Samuel Delgado",
      username: "samuelDelgado",
      pk: 49,
      avatar: "/media/Profiles/49",
    },
    content: "Incommode depending do frankness...",
    background_color: "#E0FBFC 0%, #BFF9FA 100%",
    background_image: "",
    last_updated_at: "2020-12-31T17:49:08.500723Z",
    current_status: "active",
    userCount: 1,
    profiles: [{ profileURL: "/media/Profiles/49" }],
  },
  {
    pk: 2339,
    author: {
      full_name: "Jonathan Black",
      username: "jonathanBlack",
      pk: 49,
      avatar: "/media/Profiles/49",
    },
    content: "Blind there if every Olson..",
    background_color: "#FAE1E3 0%, #EEA8C5 100%",
    background_image: "",
    last_updated_at: "2021-01-14T21:41:52.185121Z",
    current_status: "active",
    userCount: 2,
    profiles: [{ profileURL: "/media/Profiles/49" }],
  },
  {
    pk: 2273,
    author: {
      full_name: "Rick Thomas",
      username: "rickThomas",
      pk: 49,
      avatar: "/media/Profiles/49",
    },
    content: "I have no idea whatsoever... #RickThomas",
    background_color: "#E0FBFC 0%, #BFF9FA 100%",
    background_image: "",
    last_updated_at: "2020-12-31T17:49:08.500723Z",
    current_status: "active",
    userCount: 1,
    profiles: [{ profileURL: "/media/Profiles/49" }],
  },
];
