import React from "react";

const MinusIcon = ({ color }) => {
	return (
		<div>
			<svg
				width="14"
				height="2"
				viewBox="0 0 14 2"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 1L13 1"
					stroke={color}
					strokeWidth="1.5"
					strokeLinecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	);
};

export default MinusIcon;
