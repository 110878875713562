/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Avatar, Button, IconButton, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import {
	getShortUserProfileUrl,
	removeUserURL,
} from "../../../../../Utils/Constants";
import axiosConfig from "../../../../../Utils/axiosConfig";
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import styles from "./UserCard.module.css";
import { connect, useSelector } from "react-redux";
import { emitRemoveUser } from "../../../../../Utils/socket";
import OnlineBadge from "../../../../Extras/StyledBadge";
import Username from "../../../../Extras/Username";
import useAlert from "../../../../Extras/Alert/useAlert";
import ChatUserReview from "../../../../Extras/ChatUserReview/ChatUserReview";
import SidebarContextMenu from "../../Sidebar/SidebarContextMenu";

const useStyles = makeStyles({
	contextButton: {
		justifyContent: "flex-start",
		textAlign: "left",
		textTransform: "none",
		fontWeight: "400",
		color: "#fff",
	},
});

function UserCard(props) {
	const { showAlert } = useAlert();
	const classes = useStyles();
	const [user, setUser] = useState({});
	const [userLoading, setUserLoading] = useState(false);
	const loggedinUserID = useSelector((state) => state.auth.user_data?.userID);
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const isUserAdmin =
		Number(props.author.userID) === Number(props.data.userID);

	const removeUser = () => {
		if (!props.hasRemovePrivileges) {
			showAlert("Only admin can remove from the group", {
				header: "Not Allowed",
				buttonText: "Go Back",
			});
			return;
		}

		const data = {
			userID: props.data.userID,
			statusID: props.pk,
		};

		axiosChatConfig
			.post(removeUserURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert(`@${props.data.username} is removed from the group`, {
					header: "User removed",
					buttonText: `Close`,
				});
				emitRemoveUser(props.pk, props.data.userID);
			})
			.catch((err) => {
				showAlert(err.response.data.message, {
					header: "Something went wrong",
					buttonText: "Go Back",
				});
			});
	};

    const UserAvatar = ({ style, ...remProps }) => {
        const [profileUrl, setProfileUrl] = useState("")
        React.useEffect(() => {
            setProfileUrl(props?.data?.profileURL)
        }, [props.data.profileURL])
        
		return (<Avatar
			src={profileUrl}
			{...remProps}
			style={{
				background: theme === "dark" ? "#d9def4" : "#0d0f22",
				color: theme === "dark" ? "#0d0f22" : "#d9def4",
			}}
		>
			{props.data.username && props.data.username[0].toUpperCase()}
		</Avatar>)
    } 

	const getShortUSerProfile = () => {
		if (Object.keys(user).length > 0 || userLoading) {
			return;
		}
		const request = new FormData();
		request.append("userId", props.data.userID);
		setUserLoading(true);
		axiosConfig
			.post(getShortUserProfileUrl, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUserLoading(false);
				setUser(res.data.body);
			})
			.catch((err) => {
				setUserLoading(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						setUser({ error: true });
					}
				}
			});
	};

	return (
		<UserCardContainer theme={theme} className={styles.memberCont}>
			<ChatUserReview
				id={props.data.userID}
				data={{ ...props.data, from: props.data.userID }}
				anchorOrigin={{ horizontal: "left", vertical: "center" }}
				transformOrigin={{ horizontal: "right", vertical: "center" }}
				user={false}
				userProfile={user}
				userLoading={userLoading}
			>
				{props.data.onlineStatus ? (
					<OnlineBadge color="#44b700" ripple="true">
						<UserAvatar
							onMouseOver={
								Number(props.data.userID) !==
									Number(loggedinUserID) &&
								getShortUSerProfile
							}
						/>
					</OnlineBadge>
				) : (
					<UserAvatar
						onMouseOver={
							Number(props.data.userID) !==
								Number(loggedinUserID) && getShortUSerProfile
						}
					/>
				)}
			</ChatUserReview>

			<h1
				className={styles.username}
				style={{ color: theme === "dark" ? "#d9def4" : "#0d0f22" }}
			>
				<Username value={props.data.username} />
			</h1>
			{isUserAdmin && <span className={styles.adminTag}>ADMIN</span>}
			{props.active && props.hasRemovePrivileges && !isUserAdmin && (
				<SidebarContextMenu
					listStyles={{ padding: 0 }}
					trigger={
						<IconButton size="small">
							{theme === "dark" ? (
								<img src="/cards-img/more-dark.png" alt="" />
							) : (
								<img src="/cards-img/more-lg.png" alt="" />
							)}
						</IconButton>
					}
				>
					<Button
						className={classes.contextButton}
						onClick={removeUser}
					>
						Remove
					</Button>
				</SidebarContextMenu>
			)}
		</UserCardContainer>
	);
}

const UserCardContainer = styled.div`
	&:hover {
		background-color: ${function ({ theme }) {
			return theme === "dark" ? "#4a517e" : "#e6e6e6";
		}};
	}
`;

const mapStateToProps = ({ auth }, ownProps) => ({
	hasRemovePrivileges: ownProps.author.userID === auth?.user_data.userID,
});
export default connect(mapStateToProps)(UserCard);
