import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axiosConfig from "../../../../Utils/axiosConfig";
import { getSentRequestURL } from "../../../../Utils/Constants";
import EmptyState from "../../../Extras/EmptyState";
import SmallCardSkeleton from "../../../Skeleton/SmallCardSkeleton/SmallCardSkeleton";
import styles from "../Contacts.module.css";
// import { PendingRequestCard } from "./PendingRequestCard";
import { useDispatch } from "react-redux";
import { getCountInContact } from "../../../../Redux/Actions/contactAction";
import { RequestedCard } from "./RequestedCard";

export default function Requested(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [Requests, setRequests] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		setIsLoading(true);
		axiosConfig
			.post(getSentRequestURL)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.message === "No requests ") {
				} else {
					setRequests(res.data.result || res.data.body);
					dispatch(getCountInContact());
				}
				setIsLoading(false);
			})
			.catch((err) => {});
	}, []);

	const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });

	const filterBySearchTerm = (searchText) =>
		Requests.filter((friend) =>
			friend.friend.username
				.toLowerCase()
				.includes(searchText.toLowerCase())
		);

	if (isLoading) {
		return (
			<div className={styles.cards}>
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
				<SmallCardSkeleton width={isDesktop ? "80%" : "80%"} />
			</div>
		);
	} else {
		const filteredRequests = filterBySearchTerm(props.searchText);
		return (
			<div className={styles.cards}>
				{filteredRequests.length > 0 ? (
					filteredRequests.map((friend, i) => (
						<RequestedCard
							key={i}
							friend={friend.friend}
							setRequests={setRequests}
							Requests={Requests}
							requestId={friend.requestId}
						/>
					))
				) : (
					<EmptyState
						heading="No Pending Requests"
						className={"users"}
						imageUrl="/vectors/empty-states/emptyPendingUsers.svg"
						subHeading="Looks like you don’t have any pending friends requests. When you do, this is where you’ll find them."
					/>
				)}
			</div>
		);
	}
}
