import { INITGROUP, LOGOUT_REQUEST, SETGROUP } from "../Actions/actionTypes";

const initialState = {
	groupData: {},
};
const init = {
	groupData: {
		id: 1,
		name: "Global",
		avatar: "",
	},
};
export default function (state = initialState, action) {
	switch (action.type) {
		case SETGROUP:
			return {
				...state,
				groupData: action.payload,
			};
		case LOGOUT_REQUEST:
			return initialState;
		case INITGROUP:
			return init;
		default:
			return state;
	}
}
