import { Grid, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import styles from "./NotificationSkeleton.module.css";
const useStyles = makeStyles({
	avatar: { margin: "0 0.5em" },
});
export default function NotificationSkeleton() {
	const classes = useStyles();
	return (
		<div className={styles.subCont}>
			<div className={styles.contentContainer}>
				<Grid container spacing={3}>
					<div className={styles.avatarContainer}>
						<Skeleton
							variant="circle"
							width="3rem"
							height="3rem"
							className={classes.avatar}
						/>
					</div>
					<Grid item xs className={styles.right}>
						<div className={styles.username}>
							<Skeleton
								variant="text"
								width="50%"
								className={classes.body}
							/>
						</div>

						<div className={styles.content}>
							<Skeleton
								variant="text"
								width="80%"
								className={classes.body}
							/>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}
