export default function Trash({ theme }) {
	const mainColor = theme === "dark" ? "#D9DEF4" : "#0D0F22";

	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 27 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 5H17M7 9V15M11 9V15M2 5L3 17C3 17.5304 3.21071 18.0391 3.58579 18.4142C3.96086 18.7893 4.46957 19 5 19H13C13.5304 19 14.0391 18.7893 14.4142 18.4142C14.7893 18.0391 15 17.5304 15 17L16 5M6 5V2C6 1.73478 6.10536 1.48043 6.29289 1.29289C6.48043 1.10536 6.73478 1 7 1H11C11.2652 1 11.5196 1.10536 11.7071 1.29289C11.8946 1.48043 12 1.73478 12 2V5"
				stroke={mainColor}
				strokeWidth="1.2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
