import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Encrypt from "../../../Utils/Encryption";
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Box,
	DialogTitle,
} from "@material-ui/core";
import axiosConfig from "../../../Utils/axiosConfig";
import styles from "./DeleteAccountRedirect.module.css";
import { makeStyles } from "@material-ui/core/styles";
import { ValidateDeleteaccountLink } from "../../../Utils/Constants";
import { Deleteaccount } from "./../../../Utils/Constants";
import Alert from "../../Extras/Alert/Alert";
import { useDispatch } from "react-redux";
import { logout } from "../../../Redux/Actions/actionAuth";
import useAlert from "./../../Extras/Alert/useAlert";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import Button2 from "../../Extras/Buttons/Button2/Button2";
const useStyles = makeStyles((theme) => ({
	save: {
		fontFamily: "Roboto",
		textTransform: "none",
		height: "2.5rem",
		width: "45%",
		fontWeight: "400",
		margin: "1rem 0.8rem",
	},
	save1: {
		borderRadius: "6px",
		fontFamily: "Roboto",
		textTransform: "none",
		height: "2.5rem",
		width: "45%",
		fontWeight: "400",
		margin: "1rem 0.8rem",
	},
	dialog: {
		padding: 0,
		width: "100%",
		maxHeight: "70vh",

		"&::-webkit-scrollbar": {
			display: "none",
		},
		[theme.breakpoints.up("md")]: {
			width: "400px",
		},
	},
	loader: {
		marginLeft: "49%",
		marginTop: "20%",
	},
}));

const DeleteAccountRedirect = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { showAlert } = useAlert();
	const dispatch = useDispatch();
	const [tag, setTag] = useState("");
	const [token, setToken] = useState("");
	const [isOpen, setIsOpen] = useState(false);
	const [alert, setAlert] = useState(false);
	const [loading, setLoading] = useState(true);
	const [alertMessage, setAlertMessage] = useState("");
	const [callback, setCallback] = useState(null);
	const [authToken, setAuthToken] = useState(null);

	const onClose = () => {
		setIsOpen(false);
		history.push("/");
	};

	useEffect(() => {
		validateUrl();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props]);

	const validateUrl = async () => {
		const tag = props.rootParams.params.tag;
		const token = props.rootParams.params.token;
		const Token1 = await Encrypt.getDecryptedToken();
		setAuthToken(Token1);
		axiosConfig
			.get(ValidateDeleteaccountLink + tag + "/" + token)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setLoading(false);
				setIsOpen(true);
				setTag(tag);
				setToken(token);
			})
			.catch((err) => {
				setAlert(true);
				setCallback(null);
				setLoading(false);
				if (err.response) {
					setAlertMessage("Error : Try Again");
					history.push("/");
				} else {
					setAlertMessage("Network Problem");
				}
			});
	};

	const handleDelete = () => {
		const Token = "Token " + authToken;
		var data = {
			token: token,
			uidb64: tag,
		};
		var config = {
			method: "delete",
			url: Deleteaccount,
			headers: {
				Authorization: Token,
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios(config)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert("", {
					header: "Account Deleted Successfully",
					buttonText: "Close",
				});
				setTimeout(() => {
					dispatch(logout());
					history.push("/");
				}, 400);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};
	return (
		<div>
			<div className={styles.container}>
				<div className={classes.loader}>
					{loading && <CircularProgress />}
				</div>
				<Dialog
					open={isOpen}
					onClose={onClose}
					maxWidth="md"
					classes={{ paper: classes.dialog }}
				>
					<Box sx={{ p: 2 }}>
						<DialogTitle>Delete Account?</DialogTitle>
						<DialogContent>
							Are you sure you want to delete your account? Your
							data will be permanently deleted.
						</DialogContent>
						<DialogActions>
							<Button1
								variant="contained"
								color="primary"
								className={classes.save1}
								onClick={onClose}
							>
								No
							</Button1>
							<Button2
								variant="contained"
								color="danger"
								className={classes.save}
								onClick={handleDelete}
							>
								Yes , Delete
							</Button2>
						</DialogActions>
					</Box>
				</Dialog>
			</div>
			<Alert
				show={alert}
				message={alertMessage}
				hideAlert={() => setAlert(false)}
				callback={callback}
			/>
		</div>
	);
};
export default DeleteAccountRedirect;
