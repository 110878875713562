export default function Smiley({ theme }) {
	const mainColor = theme === "dark" ? "#D9DEF4" : "#0D0F22";

	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
				stroke={mainColor}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M21.1973 19C20.6701 19.9118 19.9124 20.6688 19.0001 21.1952C18.0879 21.7215 17.0532 21.9986 16 21.9986C14.9468 21.9986 13.9122 21.7215 12.9999 21.1952C12.0876 20.6689 11.3299 19.9119 10.8027 19.0001"
				stroke={mainColor}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M11.5 15C12.3284 15 13 14.3284 13 13.5C13 12.6716 12.3284 12 11.5 12C10.6716 12 10 12.6716 10 13.5C10 14.3284 10.6716 15 11.5 15Z"
				fill={mainColor}
			/>
			<path
				d="M20.5 15C21.3284 15 22 14.3284 22 13.5C22 12.6716 21.3284 12 20.5 12C19.6716 12 19 12.6716 19 13.5C19 14.3284 19.6716 15 20.5 15Z"
				fill={mainColor}
			/>
		</svg>
	);
}
