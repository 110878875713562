import MyProfile from "../MyProfile/MyProfile";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./ProfileSidebar.module.css";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import LikedThoughts from "../../LikedStatus/LikedStatus";
import MyThoughts from "../../MyThoughts/MyThoughts";
import Contacts from "../Contacts/Contacts";
import History from "../../Home/History/History";
import { Dialog, Avatar,Box,DialogTitle} from "@material-ui/core";
import {
	Close,
	AccountCircle,
	ChevronRight,
	Search,
    ArrowBackIos,
} from "@material-ui/icons";
import Dropdown, {
	StyledDropdownItem,
	StyledIcon,
} from "../../Extras/Dropdown/Dropdown";
import DotIcon from "../../../Icons/Dot";
import DeleteIcon from "../../../Icons/Delete";
import Bookmark from "../../Home/Bookmark/Bookmark";
import {
	selectFolders,
} from "../../../Redux/selectors/Bookmarks/folders";
import { useDispatch, useSelector } from "react-redux";
import { fetchFolders, removeFolder } from "../../../Redux/Actions/bookmarks/folderActions";
import NewFolderDialog from "../../Home/Bookmark/CreateNewFolder/NewFolderDialog";
import { StyledSidebarItem } from "../../Info/Info";
import axiosConfig from "../../../Utils/axiosConfig";
import Preferences from "../../Settings/SettingsSidebar";
import Policies from "../../Info/Policies";
import LoveFace from "../../../Icons/Emogi/LoveFace";
import {
	ShareSocial,
} from "../../Home/Chats/Extra/ShareURL/Share.jsx";
import { logout } from "../../../Redux/Actions/actionAuth";
import Info from "../../Info/Info";
import Faq from "../../Info/pages/Faqs";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import Button2 from "../../Extras/Buttons/Button2/Button2";

const useStyles = (theme) => ({
	userMenuButton: {
		color: "var(--heading-color2)",
		textTransform: "none",
		fontStyle: "normal",
		fontWeight: "normal",
		fontFamily: "Roboto",
		display: "flex",
		justifyContent: "flex-start",
		"&:hover": {
			color: "var(--text-primary)",
		},
	},
});
const ProfileSidebar = (props) => {
	const [active, setActive] = useState(1);
	const [mobActive, setMobActive] = useState(0);
	const [mobActiveHelp, setmobActiveHelp] = useState(0);
	const [showSearch, setShowSearch] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({});
	const history = useHistory();
    const dispatch = useDispatch();
    const [folders, setFolders] = useState([])
	const theme = useSelector((state) => state.theme.theme);
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
    const allFolders = useSelector(selectFolders);
    const [show, setShow] = useState(false);
    const [folderId, setFolderId] = useState("")

	useEffect(() => {
        myProfile();
        setFolders(allFolders);
	}, [allFolders]);

	
	function SideBar({ isMobile }) {
		useEffect(() => {
			dispatch(fetchFolders());

			if (history.location.search) {
				let activeTab = history.location.search
					.split("?")[1]
					.split("=")[1];
				if (activeTab === "requests") {
					setActive(7);
				}
			}

			// if (history.location.state === "notifications") setActive(7);
		}, []);

		const clickHandler = (key) => {
			setActive(key);
			if (key === 13) history.push("/myprofile/all");
			else history.push("/myprofile");
		};

        const deleteFolder =async () => {
            const form = new FormData();
            form.append("ID", folderId);
            try {
                const res = await axiosConfig.post("/status/deletefolder/", form)
                if (res.status === 200) {
                    setShow(false);
                    dispatch(removeFolder(folderId));
                   }
                } catch (error) {
                    setFolderId('')
                    setShow(false);
                }
        }
		return (
			<div>
				<ul className={styles.sidebar}>
					<StyledSidebarItem
						className={active === 1 ? styles.selected : styles.link}
						onClick={() => clickHandler(1)}
						isActive={active === 1}
						theme={theme}
					>
						{theme === "dark" ? (
							active === 1 ? (
								<img
									className={styles.imgIcon}
									src="/Home/dark-active-proile.svg"
								/>
							) : (
								<img
									className={styles.imgIcon}
									src="/Home/dark-profile.svg"
								/>
							)
						) : active === 1 ? (
							<img
								className={styles.imgIcon}
								src="/Home/dark-active-proile.svg"
							/>
						) : (
							<img
								className={styles.imgIcon}
								src="/Home/dark-profile.svg"
							/>
						)}
						<FormattedMessage
							id="editProfile"
							defaultMessage={`Edit Profile`}
						/>
					</StyledSidebarItem>
					<StyledSidebarItem
						className={
							active === 2 || active === 3 || active === 4
								? styles.selected
								: styles.link
						}
						onClick={() => clickHandler(2)}
						isActive={active === 2 || active === 3 || active === 4}
						theme={theme}
					>
						{theme === "dark" ? (
							active === 2 || active === 3 || active === 4 ? (
								<img
									className={styles.imgIcon}
									src="/Home/dark-active-document.svg"
								/>
							) : (
								<img
									className={styles.imgIcon}
									src="/Home/dark-document.svg"
								/>
							)
						) : active === 2 || active === 3 || active === 4 ? (
							<img
								className={styles.imgIcon}
								src="/Home/dark-active-document.svg"
                                alt=""
							/>
						) : (
							<img
								className={styles.imgIcon}
								src="/Home/dark-document.svg"
							/>
						)}
						<FormattedMessage
							id="editProfile"
							defaultMessage={`My Thoughts`}
						/>

						<StyledIcon
							className={`fa fa-chevron-right ${styles.icon} ${
								(active === 2 ||
									active === 3 ||
									active === 4) &&
								styles.open
							}`}
							theme={theme}
							hasBackground={
								active === 2 || active === 3 || active === 4
							}
						/>
					</StyledSidebarItem>

					<ul
						className={
							active === 2 || active === 3 || active === 4
								? styles.dropdownActive
								: styles.dropdownNone
						}
					>
						<StyledDropdownItem
							className={`${
								active === 2
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(2)}
							isActive={active === 2}
							theme={theme}
						>
							<DotIcon />
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Active`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 3
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(3)}
							isActive={active === 3}
							theme={theme}
						>
							<DotIcon color="#8C123A" />
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Deactive`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 4
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(4)}
							isActive={active === 4}
							theme={theme}
						>
							<DeleteIcon />
							<FormattedMessage
								className="ml-2"
								id="editProfile"
								defaultMessage={`Deleted`}
							/>
						</StyledDropdownItem>
					</ul>

					<StyledSidebarItem
						className={active > 11 ? styles.selected : styles.link}
						onClick={() => clickHandler(12)}
						isActive={active > 11}
						theme={theme}
					>
						{theme === "dark" ? (
							active > 11 ? (
								<img
									className={styles.imgIcon}
									src="/Home/dark-active-bookmark.svg"
                                    alt=""

								/>
							) : (
								<img
									className={styles.imgIcon}
                                    src="/Home/dark-bookmark.svg"
                                    alt=""

								/>
							)
						) : active > 11 ? (
							<img
								className={styles.imgIcon}
								src="/Home/dark-active-bookmark.svg"
                                alt=""

							/>
						) : (
							<img
								className={styles.imgIcon}
								src="/Home/dark-bookmark.svg"
                                alt=""
							/>
						)}
						<FormattedMessage
							id="editProfile"
							defaultMessage={`Saved Thoughts`}
						/>

						<StyledIcon
							className={`fa fa-chevron-right ${styles.icon} ${
								active > 11 && styles.open
							}`}
							theme={theme}
							hasBackground={active > 11}
						/>
					</StyledSidebarItem>

					<ul
						className={
							active > 11
								? styles.dropdownActive
								: styles.dropdownNone
						}
					>
						<StyledDropdownItem
							className={`${
								active === 12
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(12)}
							isActive={active === 12}
							theme={theme}
						>
							<img
								src={
									theme === "dark"
										? "/default-dark.png"
										: "/default-lg.png"
								}
								className="ml-2 mr-3"
								alt=""
							/>

							<FormattedMessage
								id="editProfile"
								defaultMessage={`Default`}
							/>
						</StyledDropdownItem>

                        {folders.map((folder, i) => {
                            return (
                            <Box key={i} sx={{display: "flex", alignItems:'center', justifyContent: 'space-between'}}>
								<StyledDropdownItem
									className={`${
										active === 13 + 1 + i
											? styles.selectedSub
											: styles.linkSub
									} ${
										theme === "dark"
											? styles.darkText
											: styles.lgText
									}`}
									onClick={() => clickHandler(13 + 1 + i)}
									isActive={active === 13 + 1 + i}
									theme={theme}
								>
                                    <FormattedMessage
                                        id="editProfile"
                                        defaultMessage={`${folder?.foldername.toUpperCase()}`}
                                    />
                                    </StyledDropdownItem>
                                    
                                    <Box onClick={() => {
                                        setShow(true)
                                        setFolderId(folder.id)
                                    }}
                                    >
                                        <DeleteIcon  />
                                    </Box>
                            </Box>
							);
                        })}

                        <Dialog open={show} onClose={()=>setShow(false)} classes={{
                            paper:
                                theme === "dark" ? styles.rootDark : styles.rootLg,
                        }} >
                            <Box sx={{ padding: 14 }}>
                                <DialogTitle className={`${
								active === 2
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`} id="simple-dialog-title">Are you sure you want to Delete this folder?</DialogTitle>
                                <Box sx={{display:'flex', alignItems:'center', justifyContent: 'space-evenlly', padding:10}}>
                                    <Button1
                                       
                                        onClick={() => {
                                        setShow(false)
                                        setFolderId("")
                                        }}>Cancel</Button1> 
                                    <div style={{marginLeft:5}}></div>
                                    <Button2 style={{marginLeft:10}} onClick={()=>deleteFolder()}>yes, Delete</Button2>
                                </Box>
                            </Box>

                        </Dialog>
                    
						<StyledDropdownItem
							className={`${
								active === 13
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(13)}
							isActive={active === 13}
							theme={theme}
						>
							<img
								src={
									theme === "dark"
										? "/all-dark.png"
										: "/all-lg.png"
								}
								className="ml-2 mr-3"
								alt=""
							/>
							<FormattedMessage
								id="editProfile"
								defaultMessage={`All Collections`}
							/>
						</StyledDropdownItem>
					</ul>

					<StyledSidebarItem
						className={active === 5 ? styles.selected : styles.link}
						onClick={() => clickHandler(5)}
						isActive={active === 5}
						theme={theme}
					>
						{theme === "dark" ? (
							active === 5 ? (
								<img
									className={styles.imgIcon}
                                    src="/Home/dark-active-heart.svg"
                                                                    alt=""

								/>
							) : (
								<img
									className={styles.imgIcon}
                                        src="/Home/dark-heart.svg"
                                                                        alt=""

								/>
							)
						) : active === 5 ? (
							<img
								className={styles.imgIcon}
                                    src="/Home/dark-active-heart.svg"
                                                                    alt=""

							/>
						) : (
							<img
								className={styles.imgIcon}
                                        src="/Home/dark-heart.svg"
                                                                        alt=""

							/>
						)}
						<FormattedMessage
							id="editProfile"
							defaultMessage={`Liked Thoughts`}
						/>
					</StyledSidebarItem>

					<StyledSidebarItem
						className={
							active === 6 ||
							active === 7 ||
							active === 8 ||
							active === 9 ||
							active === 10
								? styles.selected
								: styles.link
						}
						onClick={() => clickHandler(6)}
						isActive={
							active === 6 ||
							active === 7 ||
							active === 8 ||
							active === 9 ||
							active === 10
						}
						theme={theme}
					>
						{theme === "dark" ? (
							active === 6 ||
							active === 7 ||
							active === 8 ||
							active === 9 ||
							active === 10 ? (
								<img
									className={styles.imgIcon}
                                        src="/Home/dark-active-network.svg"
                                                                        alt=""

								/>
							) : (
								<img
									className={styles.imgIcon}
                                        src="/Home/dark-network.svg"
                                                                        alt=""

								/>
							)
						) : active === 6 ||
						  active === 7 ||
						  active === 8 ||
						  active === 9 ||
						  active === 10 ? (
							<img
								className={styles.imgIcon}
                                        src="/Home/dark-active-network.svg"
                                                                        alt=""

							/>
						) : (
							<img
								className={styles.imgIcon}
                                        src="/Home/dark-network.svg"
                                                                        alt=""

							/>
						)}
						<FormattedMessage
							id="editProfile"
							defaultMessage={`Network`}
						/>

						<StyledIcon
							className={`fa fa-chevron-right ${styles.icon} ${
								(active === 6 ||
									active === 7 ||
									active === 8 ||
									active === 9 ||
									active === 10) &&
								styles.open
							}`}
							theme={theme}
							hasBackground={
								active === 6 ||
								active === 7 ||
								active === 8 ||
								active === 9 ||
								active === 10
							}
						/>
					</StyledSidebarItem>

					<ul
						className={
							active === 6 ||
							active === 7 ||
							active === 8 ||
							active === 9 ||
							active === 10
								? styles.dropdownActive
								: styles.dropdownNone
						}
					>
						<StyledDropdownItem
							className={`${
								active === 6
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(6)}
							isActive={active === 6}
							theme={theme}
						>
							{active === 6 ? (
								<img
									className={styles.imgSmallI}
									src="/images/connectionA.png"
									alt=""
								/>
							) : (
								<img
									className={styles.imgSmallI}
									src="/images/connection.png"
									alt=""
								/>
							)}
							&nbsp;
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Connections`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 7
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(7)}
							isActive={active === 7}
							theme={theme}
						>
							{active === 7 ? (
								<img
									className={styles.imgSmallI}
									src="/images/TUserA.svg"
									alt=""
								/>
							) : (
								<img
									className={styles.imgSmallI}
									src="/images/TUser.png"
									alt=""
								/>
							)}
							&nbsp;
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Requests`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 8
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(8)}
							isActive={active === 8}
							theme={theme}
						>
							{active === 8 ? (
								<img
									className={styles.imgSmallI}
									src="/images/AddA.svg"
									alt=""
								/>
							) : (
								<img
									className={styles.imgSmallI}
									src="/images/Add.png"
									alt=""
								/>
							)}
							&nbsp;
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Invitations Sent`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 9
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(9)}
							isActive={active === 9}
							theme={theme}
						>
							{active === 9 ? (
								<img
									className={styles.imgSmallI}
									src="/images/blockA.svg"
									alt=""
								/>
							) : (
								<img
									className={styles.imgSmallI}
									src="/images/block.png"
									alt=""
								/>
							)}
							&nbsp;
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Blocked Users`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 10
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => clickHandler(10)}
							isActive={active === 10}
							theme={theme}
						>
							{active === 10 ? (
								<img
									className={styles.imgSmallI}
									src="/images/blockA.svg"
									alt=""
								/>
							) : (
								<img
									className={styles.imgSmallI}
									src="/images/block.png"
									alt=""
								/>
							)}
							&nbsp;
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Turned Off Users`}
							/>
						</StyledDropdownItem>
					</ul>

					<StyledSidebarItem
						className={
							active === 11 ? styles.selected : styles.link
						}
						onClick={() => clickHandler(11)}
						isActive={active === 11}
						theme={theme}
					>
						{theme === "dark" ? (
							active === 11 ? (
								<img
									className={styles.imgIcon}
                                    src="/Home/dark-active-clock.svg"
                                                                    alt=""

								/>
							) : (
								<img
									className={styles.imgIcon}
                                        src="/Home/dark-clock.svg"
                                                                        alt=""

								/>
							)
						) : active === 11 ? (
							<img
								className={styles.imgIcon}
                                    src="/Home/dark-active-clock.svg"
                                                                    alt=""

							/>
						) : (
							<img
								className={styles.imgIcon}
                                        src="/Home/dark-clock.svg"
                                                                        alt=""

							/>
						)}
						<FormattedMessage
							id="editProfile"
							defaultMessage={`Activity`}
						/>
					</StyledSidebarItem>
				</ul>
			</div>
		);
	}
	const keyPress = (e) => {
		const text = e.target.value.trim();

		if (e.keyCode === 13 && text.length >= 1) {
			history.push({
				pathname: "/myprofile/history",
				search: `?searchQuery=${text}`,
			});
		} else if (e.keyCode === 8 && searchText.length <= 1) {
			history.push({
				pathname: "/myprofile",
			});
		}
	};
	const myProfile = () => {
		setLoading(true);
		axiosConfig
			.get("/auth/get-my-profile/")
			.then((res) => {
				if (res.status !== 200) throw Error;
				setUser(res.data.body);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
			});
	};

	const tab = [
		{
			id: 1,
			label: "Edit Profile",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/editProfile.svg"
					: "/Mobile/MyProfile/light-editProfile.svg",
		},
		{
			id: 2,
			label: "My Thoughts",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/myThoughts-dark.svg"
					: "/Mobile/MyProfile/myThoughts.svg",
		},
		{
			id: 3,
			label: "Saved Thoughts",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/savedThoughts.svg"
					: "/Mobile/MyProfile/light-savedThoughts.svg",
		},
		{
			id: 4,
			label: "Liked Thoughts",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/likedThoughts.svg"
					: "/Mobile/MyProfile/light-likedThoughts.svg",
		},
		{
			id: 5,
			label: "Network",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/Network.svg"
					: "/Mobile/MyProfile/light-network.svg",
		},
		{
			id: 6,
			label: "Activity",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/activity.svg"
					: "/Mobile/MyProfile/light-activity.svg",
		},
	];

	const tab2 = [
		{
			id: 7,
			label: "Preferences",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/preferences.svg"
					: "/Mobile/MyProfile/light-preferences.svg",
		},
		{
			id: 8,
			label: "Share Bditto",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/share.svg"
					: "/Mobile/MyProfile/light-share.svg",
		},
		{
			id: 9,
			label: "Help Center",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/helpCenter.svg"
					: "/Mobile/MyProfile/light-help.svg",
		},
		{
			id: 10,
			label: "Terms & Policies",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/t&p.svg"
					: "/Mobile/MyProfile/light-tp.svg",
		},
		{
			id: 11,
			label: "Log Out",
			ico: "/Mobile/MyProfile/logout.svg",
		},
	];

	const HelpCenter = [
		{
			id: 1,
			label: "Send Feedback",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/HelpCenter/Edit.svg"
					: "/Mobile/MyProfile/HelpCenter/light-Edit.svg",
		},
		{
			id: 2,
			label: "About Us",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/HelpCenter/About.svg"
					: "/Mobile/MyProfile/HelpCenter/light-About.svg",
		},
		{
			id: 3,
			label: "Tutorial",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/HelpCenter/Tutorial.svg"
					: "/Mobile/MyProfile/HelpCenter/light-Tutorial.svg",
		},
	];

	const HelpCenterFaq = [
		{
			id: 4,
			label: "General",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/HelpCenter/General.svg"
					: "/Mobile/MyProfile/HelpCenter/light-General.svg",
		},
		{
			id: 5,
			label: "Subscription Plans",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/HelpCenter/Sub.svg"
					: "/Mobile/MyProfile/HelpCenter/light-Sub.svg",
		},
		{
			id: 6,
			label: "Free Credits",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/HelpCenter/Free.svg"
					: "/Mobile/MyProfile/HelpCenter/light-Free.svg",
		},
		{
			id: 7,
			label: "Privacy",
			ico:
				theme === "dark"
					? "/Mobile/MyProfile/HelpCenter/Privacy.svg"
					: "/Mobile/MyProfile/HelpCenter/light-Privacy.svg",
		},
	];

	return (
		<>
			{!isMobile && (
				<Dialog
					open={true}
					fullScreen={true}
					maxWidth={"100vw"}
					fullWidth={"100vw"}
					classes={{
						paper:
							theme === "dark" ? styles.rootDark : styles.rootLg,
					}}
				>
					<div className={styles.wrapper}>
						<div className={styles.container}>
							<div
								className={`${styles.sidebarHeader} ${
									theme === "dark"
										? styles.darkText
										: styles.lgText
								}`}
							>
								{active === 1 ? (
									<h1>My Account</h1>
								) : active === 2 ||
								  active === 3 ||
								  active === 4 ? (
									<h1>My Thoughts</h1>
								) : active === 5 ? (
									<h1>Liked Thoughts</h1>
								) : active === 6 ||
								  active === 7 ||
								  active === 8 ||
								  active === 9 ||
								  active === 10 ? (
									<h1>Network</h1>
								) : active === 11 ? (
									<h1>Activity</h1>
								) : (
									active > 11 && <h1>Saved Thoughts</h1>
								)}
								<img
									onClick={() => {
										history.push("/");
									}}
									style={{ cursor: "pointer" }}
									src="/icons/squaredClose.svg"
									alt=""
								/>
							</div>

							<div className={styles.mainContainer}>
								{!isMobile && (
									<>
										<div className={styles.left}>
											<SideBar
												active={active}
												setActive={setActive}
											/>
										</div>

										<div className={styles.right}>
											{active === 1 ? (
                                                <MyProfile
                                                    user={user}
													isMobile={isMobile}
												/>
											) : active === 2 ? (
												<MyThoughts
													isMobile={isMobile}
													com={"Active"}
												/>
											) : active === 3 ? (
												<MyThoughts
													isMobile={isMobile}
													com={"Deactive"}
												/>
											) : active === 4 ? (
												<MyThoughts
													isMobile={isMobile}
													com={"Deleted"}
												/>
											) : active === 5 ? (
												<LikedThoughts
													isMobile={isMobile}
												/>
											) : active === 6 ? (
												<Contacts
													path={active}
													isMobile={isMobile}
												/>
											) : active === 7 ? (
												<Contacts
													path={active}
													isMobile={isMobile}
												/>
											) : active === 8 ? (
												<Contacts
													path={active}
													isMobile={isMobile}
												/>
											) : active === 9 ? (
												<Contacts
													path={active}
													isMobile={isMobile}
												/>
											) : active === 10 ? (
												<Contacts
													path={active}
													isMobile={isMobile}
												/>
											) : active === 11 ? (
												<History isMobile={isMobile} />
											) : active > 11 ? (
												<>
													<NewFolderDialog />
													<Bookmark folder={active} />
												</>
											) : (
												active === 13 && <h1>all</h1>
											)}
										</div>
									</>
								)}
							</div>
						</div>
					</div>
				</Dialog>
			)}
			{isMobile && (
				<>
					<div style={{ height: "100%", overflow: "auto" }}>
						<div className={styles.avatarStyle}>
							{loading && !user ? (
								<AccountCircle
									style={{
										borderRadius: "50%",
										fontSize: "8.1rem",
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
								/>
							) : (
								<Avatar
									// alt={user.user.username}
									style={{
										width: "3.5rem",
										height: "3.5rem",
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
									src={user.avatar}
								/>
							)}
							<p className={styles.username + " mb-0 mx-3"}>
								{user.full_name}
							</p>
						</div>
						<div>
							<p
								className="mx-3"
								style={{
									color:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
							>
								My Account
							</p>
							<div>
								{tab.map((tab) => (
									<div
										className={styles.myprofileTab}
										key={tab.id}
										onClick={() => setMobActive(tab.id)}
										style={{
											background:
												theme === "dark"
													? "#121528"
													: "#fff",
										}}
									>
										<p
											className="mb-0"
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
											}}
										>
											<span className="mr-3">
												<img src={tab.ico} alt="" />
											</span>
											{tab.label}
										</p>
										<span>
											<ChevronRight
												style={{ color: "#7F89BE" }}
											/>
										</span>
									</div>
								))}
							</div>
						</div>

						<div className="mt-5">
							{tab2.map((tab) => (
								<div
									className={styles.myprofileTab}
									key={tab.id}
									onClick={() => {
										tab.label !== "Log Out"
											? setMobActive(tab.id)
											: dispatch(logout());
									}}
									style={{
										background:
											theme === "dark"
												? "#121528"
												: "#fff",
									}}
								>
									<p
										className="mb-0"
										style={{
											color:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
										}}
									>
										<span className="mr-3">
											<img src={tab.ico} alt="" />
										</span>
										{tab.label}
									</p>
									{tab.label !== "Log Out" && (
										<span>
											<ChevronRight
												style={{ color: "#7F89BE" }}
											/>
										</span>
									)}
								</div>
							))}
						</div>

						{mobActive === 1 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div
									className="d-flex align-items-center "
									style={{
										background: "#030413",
										padding: "0.7rem 1rem",
									}}
								>
									<span className="btn p-1">
										<ArrowBackIos
											onClick={() => setMobActive(0)}
											style={{ color: "#D9DEF4" }}
										/>
									</span>
									<div
										className={`${styles.headerText} ${
											theme === "dark"
												? styles.darkPText
												: styles.lgPText
										} w-75`}
									>
										<h2 className="mb-0 text-center">
											<FormattedMessage
												id="editProfile"
												defaultMessage={`Edit Profile`}
											/>
										</h2>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<MyProfile />
								</div>
							</Dialog>
						)}
						{mobActive === 2 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<span className="btn p-1">
										{showSearch ? (
											<Close
												onClick={() =>
													setShowSearch(false)
												}
												style={{ color: "#D9DEF4" }}
											/>
										) : (
											<ArrowBackIos
												onClick={() => setMobActive(0)}
												style={{ color: "#D9DEF4" }}
											/>
										)}
									</span>
									{!showSearch && (
										<div
											className={`${styles.headerText} ${
												theme === "dark"
													? styles.darkPText
													: styles.lgPText
											}`}
										>
											<h2 className="mb-0">
												<FormattedMessage
													id="editProfile"
													defaultMessage={`My Thoughts`}
												/>
											</h2>
										</div>
									)}
									{!showSearch && (
										<div>
											<span
												onClick={() =>
													setShowSearch(true)
												}
												className="btn p-1"
											>
												<Search
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
									)}
									{showSearch && (
										<div className={styles.searchContainer}>
											<img
												src="/Search.svg"
												className="ml-2 mr-1"
												alt=""
											/>
											<input
												type="text"
												// value={searchText}
												className={styles.input}
												// onChange={(e) =>
												// 	setSearchText(
												// 		e.target.value.trim()
												// 	)
												// }
												placeholder={`Search thoughts`}
											/>
											{/* {searchText !== "" && (
												<Close
													onClick={() =>
												setSearchText("")
													}
													style={{
												margin: "auto 0",
												color:
												theme === "dark"
												? "#7F89BE"
												: "#4A517E",
													}}
												/>
											)} */}
										</div>
									)}
								</div>
								<div style={{ height: "80vh" }}>
									<MyThoughts searchText={searchText} />
								</div>
							</Dialog>
						)}
						{mobActive === 3 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<span className="btn p-1">
										{showSearch ? (
											<Close
												onClick={() =>
													setShowSearch(false)
												}
												style={{ color: "#D9DEF4" }}
											/>
										) : (
											<ArrowBackIos
												onClick={() => {
													setMobActive(0);
													history.push("/myprofile");
												}}
												style={{ color: "#D9DEF4" }}
											/>
										)}
									</span>
									{!showSearch && (
										<div
											className={`${styles.headerText} ${
												theme === "dark"
													? styles.darkPText
													: styles.lgPText
											}`}
										>
											<h2 className="mb-0">
												<FormattedMessage
													id="editProfile"
													defaultMessage={`Saved Thoughts`}
												/>
											</h2>
										</div>
									)}
									{!showSearch && (
										<div>
											<span
												onClick={() =>
													setShowSearch(true)
												}
												className="btn p-1"
											>
												<Search
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
									)}
									{showSearch && (
										<div className={styles.searchContainer}>
											<img
												src="/Search.svg"
												className="ml-2 mr-1"
												alt=""
											/>
											<input
												type="text"
												// value={searchText}
												className={styles.input}
												// onChange={(e) =>
												// 	setSearchText(
												// 		e.target.value.trim()
												// 	)
												// }
												placeholder={`Search thoughts`}
											/>
											{/* {searchText !== "" && (
												<Close
													onClick={() =>
												setSearchText("")
													}
													style={{
												margin: "auto 0",
												color:
												theme === "dark"
												? "#7F89BE"
												: "#4A517E",
													}}
												/>
											)} */}
										</div>
									)}
								</div>
								<div style={{ height: "90vh" }}>
									<NewFolderDialog />
									<Bookmark />
								</div>
							</Dialog>
						)}
						{mobActive === 4 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<span className="btn p-1">
										{showSearch ? (
											<Close
												onClick={() => {
													setSearchText("");
													setShowSearch(false);
												}}
												style={{ color: "#D9DEF4" }}
											/>
										) : (
											<ArrowBackIos
												onClick={() => setMobActive(0)}
												style={{ color: "#D9DEF4" }}
											/>
										)}
									</span>
									{!showSearch && (
										<div
											className={`${styles.headerText} ${
												theme === "dark"
													? styles.darkPText
													: styles.lgPText
											}`}
										>
											<h2 className="mb-0">
												<FormattedMessage
													id="editProfile"
													defaultMessage={`Liked Thoughts`}
												/>
											</h2>
										</div>
									)}
									{!showSearch && (
										<div>
											<span
												onClick={() => {
													setShowSearch(true);
												}}
												className="btn p-1"
											>
												<Search
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
									)}
									{showSearch && (
										<div className={styles.searchContainer}>
											<img
												src="/Search.svg"
												className="ml-2 mr-1"
												alt=""
											/>
											<input
												type="text"
												value={searchText}
												className={styles.input}
												onChange={(e) =>
													setSearchText(
														e.target.value
													)
												}
												placeholder={`Search thoughts`}
											/>
											{/* {searchText !== "" && (
												<Close
													onClick={() =>
												setSearchText("")
													}
													style={{
												margin: "auto 0",
												color:
												theme === "dark"
												? "#7F89BE"
												: "#4A517E",
													}}
												/>
											)} */}
										</div>
									)}
								</div>
								<div style={{ height: "90vh" }}>
									<LikedThoughts searchText={searchText} />
								</div>
							</Dialog>
						)}
						{mobActive === 5 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<span className="btn p-1">
										{showSearch ? (
											<Close
												onClick={() =>
													setShowSearch(false)
												}
												style={{ color: "#D9DEF4" }}
											/>
										) : (
											<ArrowBackIos
												onClick={() => setMobActive(0)}
												style={{ color: "#D9DEF4" }}
											/>
										)}
									</span>
									{!showSearch && (
										<div
											className={`${styles.headerText} ${
												theme === "dark"
													? styles.darkPText
													: styles.lgPText
											}`}
										>
											<h2 className="mb-0">
												<FormattedMessage
													id="editProfile"
													defaultMessage={`Network`}
												/>
											</h2>
										</div>
									)}
									<div>
										<span
											onClick={() => setShowSearch(true)}
											className="btn p-1"
										></span>
									</div>
									{/* {!showSearch && (
										<div>
											<span
												onClick={() =>
													setShowSearch(true)
												}
												className="btn p-1"
											>
												<Search
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
									)} */}
									{showSearch && (
										<div className={styles.searchContainer}>
											<img
												src="/Search.svg"
												className="ml-2 mr-1"
												alt=""
											/>
											<input
												type="text"
												// value={searchText}
												className={styles.input}
												// onChange={(e) =>
												// 	setSearchText(
												// 		e.target.value.trim()
												// 	)
												// }
												placeholder={`Search thoughts`}
											/>
											{/* {searchText !== "" && (
												<Close
													onClick={() =>
												setSearchText("")
													}
													style={{
												margin: "auto 0",
												color:
												theme === "dark"
												? "#7F89BE"
												: "#4A517E",
													}}
												/>
											)} */}
										</div>
									)}
								</div>
								<div style={{ height: "90vh" }}>
									<Contacts />
								</div>
							</Dialog>
						)}
						{mobActive === 6 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<span className="btn p-1">
										{showSearch ? (
											<Close
												onClick={() =>
													setShowSearch(false)
												}
												style={{ color: "#D9DEF4" }}
											/>
										) : (
											<ArrowBackIos
												onClick={() => setMobActive(0)}
												style={{ color: "#D9DEF4" }}
											/>
										)}
									</span>
									{!showSearch && (
										<div
											className={`${styles.headerText} ${
												theme === "dark"
													? styles.darkPText
													: styles.lgPText
											}`}
										>
											<h2 className="mb-0">
												<FormattedMessage
													id="editProfile"
													defaultMessage={`Activity`}
												/>
											</h2>
										</div>
									)}
									{!showSearch && (
										<div>
											<span
												onClick={() =>
													setShowSearch(true)
												}
												className="btn p-1"
											>
												<Search
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
									)}
									{showSearch && (
										<div className={styles.searchContainer}>
											<img
												src="/Search.svg"
												className="ml-2 mr-1"
												alt=""
											/>
											<input
												type="text"
												value={searchText}
												className={styles.input}
												onChange={(e) =>
													setSearchText(
														e.target.value.trim()
													)
												}
												onKeyDown={keyPress}
												placeholder={`Search History`}
											/>
											{/* {searchText !== "" && (
												<Close
													onClick={() =>
												setSearchText("")
													}
													style={{
												margin: "auto 0",
												color:
												theme === "dark"
												? "#7F89BE"
												: "#4A517E",
													}}
												/>
											)} */}
										</div>
									)}
								</div>
								<div style={{ height: "90vh" }}>
									<History isMobile={isMobile} />
								</div>
							</Dialog>
						)}
						{mobActive === 7 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setMobActive(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Preferences`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Preferences />
								</div>
							</Dialog>
						)}

						{mobActive === 10 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setMobActive(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Terms & Policies`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Policies />
								</div>
							</Dialog>
						)}

						{mobActive === 9 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setMobActive(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Help Center`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<div className="mt-4">
										{HelpCenter.map((tab) => (
											<div
												className={styles.myprofileTab}
												key={tab.id}
												onClick={() =>
													setmobActiveHelp(tab.id)
												}
												style={{
													background:
														theme === "dark"
															? "#121528"
															: "#fff",
												}}
											>
												<p
													className="mb-0"
													style={{
														color:
															theme === "dark"
																? "#d9def4"
																: "#odof22",
													}}
												>
													<span className="mr-3">
														<img
															src={tab.ico}
															alt=""
														/>
													</span>
													{tab.label}
												</p>
												{tab.label !== "Tutorial" && (
													<span>
														<ChevronRight
															style={{
																color: "#7F89BE",
															}}
														/>
													</span>
												)}
											</div>
										))}
									</div>

									<div className="mt-4	">
										<p
											style={{
												color: "#7F89BE",
												margin: "10px 20px",
												fontSize: "12px",
											}}
										>
											FAQS
										</p>
										{HelpCenterFaq.map((tab) => (
											<div
												className={styles.myprofileTab}
												key={tab.id}
												onClick={() =>
													setmobActiveHelp(tab.id)
												}
												style={{
													background:
														theme === "dark"
															? "#121528"
															: "#fff",
												}}
											>
												<p
													className="mb-0"
													style={{
														color:
															theme === "dark"
																? "#d9def4"
																: "#odof22",
													}}
												>
													<span className="mr-3">
														<img
															src={tab.ico}
															alt=""
														/>
													</span>
													{tab.label}
												</p>
												<span>
													<ChevronRight
														style={{
															color: "#7F89BE",
														}}
													/>
												</span>
											</div>
										))}
									</div>
								</div>
							</Dialog>
						)}

						{mobActive === 8 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setMobActive(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Terms & Policies`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div
									style={{
										height: "90vh",
										padding: "0 15px",
									}}
								>
									<div style={{ marginTop: "1rem" }}>
										<div className={styles.textBoxShare}>
											<LoveFace />
											<p>
												We are competing with leading
												companies & to stand out, we
												need to focus on fantastic
												design with an engaging copy.
											</p>
										</div>

										<div className={styles.textBoxShare}>
											<LoveFace />
											<p>
												You can invite anyone to
												participate in Bditto and have
												fun sharing thoughts & ideas.
											</p>
										</div>

										<div className={styles.textBoxShare}>
											<LoveFace />
											<p>
												Finding people who you know or
												finding people with similar
												interests is a lot easier on
												bditto.
											</p>
										</div>
									</div>
									<div className={styles.shareSocialIcons}>
										<ShareSocial
											url="https://www.bditto.com/"
											className={styles.ShareSocial}
											iconSize={40}
										/>
									</div>
								</div>
							</Dialog>
						)}

						{mobActiveHelp === 1 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<Close
													onClick={() =>
														setmobActiveHelp(0)
													}
													style={{
														color: "#D9DEF4",
														fontSize: "30px",
													}}
												/>
											</span>
										</div>
										<div className="col">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Send Feedback`}
													/>
												</h2>
											</div>
										</div>

										{/* <div className="col-2">
											<div
												className={`${styles.headerText} ${
											theme === "dark"
											? styles.darkPText
											: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
											<p style={{ color: "#6E88FC", fontSize:"14px"}}>Submit</p>
												</h2>
											</div>
										</div> */}
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Info active={mobActiveHelp} />
								</div>
							</Dialog>
						)}

						{mobActiveHelp === 2 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setmobActiveHelp(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`About Us`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Info active={mobActiveHelp} />
								</div>
							</Dialog>
						)}
						{mobActiveHelp === 3 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setmobActiveHelp(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Tutorial`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Info active={mobActiveHelp} />
								</div>
							</Dialog>
						)}
						{mobActiveHelp === 4 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setmobActiveHelp(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`General`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Faq />
								</div>
							</Dialog>
						)}
						{mobActiveHelp === 5 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setmobActiveHelp(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Subscription Plans`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Faq />
								</div>
							</Dialog>
						)}
						{mobActiveHelp === 6 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setmobActiveHelp(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													<FormattedMessage
														id="editProfile"
														defaultMessage={`Free Credits`}
													/>
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Faq />
								</div>
							</Dialog>
						)}
						{mobActiveHelp === 7 && (
							<Dialog
								open={true}
								fullScreen={true}
								maxWidth={"100vw"}
								fullWidth={"100vw"}
								classes={{
									paper:
										theme === "dark"
											? styles.rootDark
											: styles.rootLg,
								}}
							>
								<div className={styles.mobNav}>
									<div className="row w-100">
										<div className="col-4">
											<span className="btn p-1">
												<ArrowBackIos
													onClick={() =>
														setmobActiveHelp(0)
													}
													style={{ color: "#D9DEF4" }}
												/>
											</span>
										</div>
										<div className="col-8">
											<div
												className={`${
													styles.headerText
												} ${
													theme === "dark"
														? styles.darkPText
														: styles.lgPText
												}`}
											>
												<h2 className="mt-2">
													{/* <FormattedMessage
														id="editProfile"
														defaultMessage={`Privacy`}
													/> */}
                                                    Privacy
												</h2>
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: "90vh" }}>
									<Faq />
								</div>
							</Dialog>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default ProfileSidebar;
