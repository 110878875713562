import React from "react";
import "./BackToLandingBtn.scss";

import { Link } from "react-router-dom";

function BackToLandingBtn() {
  return (
    <a href="https://bditto.com">
      <div className="logo">
        <img src="/icons/bditto_logo.png" alt="logo" />
      </div>
    </a>
  );
}

export default BackToLandingBtn;
