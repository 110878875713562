import React, { useState, useRef, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import SearchCard from "../Extras/SearchCard/SearchCard";
import UserPreview from "../Extras/UserPreview/UserPreview";
import CardSkeleton from "../Skeleton/CardSkeleton/CardSkeleton";
import UserSkeleton from "../Skeleton/UserSkeleton/UserSkeleton";

import { Close } from "@material-ui/icons";
import useQuery from "../../Utils/useQuery";
import Mobile from "./MoblieDisplay/Mobile";
import { FormattedMessage } from "react-intl";
import EmptyState from "../Extras/EmptyState";
import { bdittoAPIv2 } from "../../Utils/axiosConfig";
import { Avatar, Dialog, Typography } from "@material-ui/core";
import Tags from "../Home/Tags/TagsSidebar/TagsSidebar";
import GroupsSidebar from "../Home/Groups/GroupsSidebar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
	searchUserURLV2,
	searchStatusURLV2,
	searchMessagesURLV2,
} from "../../Utils/Constants";
import { getParsedLocations } from "../../Redux/selectors/locationSelector";

import "./Search.scss";
import styles from "./Search.module.css";
import StatusCard from "../Extras/StatusCard/StatusCard";
import axiosChatConfig from "../../Utils/axiosChatConfig";
import LikedMessageBox from "../Home/Chats/Extra/LikedMessage/LikedMessageBox";

import Button1 from "../Extras/Buttons/Button1/Button1";
import Button2 from "../Extras/Buttons/Button2/Button2";
import { setRecentSearch } from "../../Redux/Actions/recentSearchAction";

const Search = (props) => {
	const [searchText, setSearchText] = useState("");
	const [statusResult, setStatusResult] = useState([]);
	const [messageResult, setMessageResult] = useState([]);
	const [recentSearches, setRecentSearches] = useState([]);
	const [userResult, setUserResult] = useState([]);
	const [thought, setThought] = useState(1);
	const [isStatusLoading, setStatusLoading] = useState(false);
	const [isUserLoading, setUserLoading] = useState(false);
	const [mouseOver, setMouseOver] = useState(-1);
	const [isEmpty, setIsEmpty] = useState({
		status: false,
		users: false,
		message: false,
	});

	const [groupInfo, setGroupInfo] = useState(false);
	const [groupDisplay, setGroupDisplay] = useState(false);
	const [isSugg, setIsSugg] = useState(false);
	const [thoughtsSugg, setThoughtsSugg] = useState([]);
	const [usersSugg, setUsersSugg] = useState([]);
	const [msgsSugg, setMsgsSugg] = useState([]);
	const [noSugg, setNoSugg] = useState(false);
	// const [activeTab, setActiveTab] = useState(1);

	let userOffset = useRef(0);
	let statusOffset = useRef(0);
	const history = useHistory();
	let userReset = useRef(false);
	const location = useLocation();
	let userLoaded = useRef(false);
	let statusReset = useRef(false);
	let statusLoaded = useRef(false);
	const { searchQuery } = useQuery();

	const isMobile = useMediaQuery({
		query: "(max-device-width: 1224px)",
	});

	const searchInputRef = useRef();

	const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });
	const locations = useSelector(getParsedLocations);
	const user_data = useSelector((state) => state.auth.user_data);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const theme = useSelector((state) => state.theme.theme);
	const recentSearch = useSelector(
		(state) => state.recentSearch.recentSearches
	);

	useEffect(() => {
		setRecentSearches(recentSearch);
	}, []);

	useEffect(() => {
		if (recentSearches.length === 0) return;
		props.setRecentSearch(recentSearches);
	}, [recentSearches]);

	useEffect(() => {
		setThoughtUser(1);
	}, []);

	const keyPress = (e) => {
		const text = e.target.value.trim();
		if (e.keyCode === 13) {
			history.push({
				pathname:
					thought === 1
						? "/search/thoughts"
						: thought === 2
						? "/search/users"
						: "/search/messages",
				search: `?searchQuery=${searchText}`,
			});
			if (searchText != "") {
				if (
					searchText === recentSearches &&
					recentSearches[0].searchText
				)
					return;
				setRecentSearches((prev) => [
					{
						thought: thought,
						text: searchText,
					},
					...prev,
				]);
			}
		}
	};

	const handleBtnSearch = (e) => {
		e.preventDefault();
		history.push({
			pathname:
				thought === 1
					? "/search/thoughts"
					: thought === 2
					? "/search/users"
					: "/search/messages",
			search: `?searchQuery=${searchText}`,
		});
		if (searchText !== "") {
			if (searchText === recentSearches && recentSearches[0].searchText)
				return;
			setRecentSearches((prev) => [
				{
					thought: thought,
					text: searchText,
				},
				...prev,
			]);
		}
	};

	const perSearch = (text) => {
		history.push({
			pathname:
				thought === 1
					? "/search/thoughts"
					: thought === 2
					? "/search/users"
					: "/search/messages",
			search: `?searchQuery=${text}`,
		});
	};

	useEffect(() => {
		setUserResult([]);
		setStatusResult([]);
		setSearchText(searchQuery);

		userReset.current = true;
		statusReset.current = true;

		userOffset.current = 0;
		statusOffset.current = 0;

		userLoaded.current = false;
		statusLoaded.current = false;

		if (searchQuery) {
			fetchStatusResult(searchQuery);
			fetchUserResult(searchQuery);
			fetchMessageResult(searchQuery);
		}

		if (location.pathname === "/search/users") {
			setThought(false);
		}
	}, [searchQuery, groupDataFromStore]);

	const fetchStatusResult = (text) => {
		if (isStatusLoading || statusLoaded.current) return;
		setStatusLoading(true);
		const data = {
			search_phrase: text,
			location: locations,
			groupId: groupDataFromStore.id,
		};
		const URL =
			searchStatusURLV2 + "?limit=16&offset=" + statusOffset.current;
		bdittoAPIv2
			.post(URL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.body === "No search result") {
					setIsEmpty((prev) => ({ ...prev, status: true }));
				} else {
					setIsEmpty((prev) => ({ ...prev, status: false }));

					if (statusReset.current) {
						setStatusResult(res.data.result || res.data.body);
						statusReset.current = false;
					} else
						setStatusResult([
							...statusResult,
							...(res.data.result || res.data.body),
						]);
				}

				setStatusLoading(false);

				statusOffset.current += 8;
				if (!res.data.links.next) statusLoaded.current = true;
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;
					alert(catchError.message);
					setStatusLoading(false);
				}
			});
	};

	const fetchUserResult = (text) => {
		if (isUserLoading || userLoaded.current) return;

		setUserLoading(true);
		const data = { search_phrase: text, location: locations };
		// form.append("country", `props.country);
		// form.append("city", `props.city);

		const URL = searchUserURLV2 + "?limit=16&offset=" + userOffset.current;

		bdittoAPIv2
			.post(URL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.body === "No search result") {
					setIsEmpty((prev) => ({ ...prev, users: true }));
				} else {
					setIsEmpty((prev) => ({ ...prev, users: false }));
					if (userReset.current) {
						setUserResult(res.data.result || res.data.body);
						userReset.current = false;
					} else
						setUserResult([
							...userResult,
							...(res.data.result || res.data.body),
						]);
				}
				setUserLoading(false);

				userOffset.current += 10;
				if (!res.data.links.next) userLoaded.current = true;
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;
					alert(catchError.message);
					setUserLoading(false);
				}
			});
	};

	const handleStatusScroll = (event) => {
		const bottom =
			event.target.scrollHeight - event.target.scrollTop ===
			event.target.clientHeight;

		if (bottom) fetchStatusResult(searchText);
	};

	const handleUserScroll = (event) => {
		const bottom =
			event.target.scrollHeight - event.target.scrollTop ===
			event.target.clientHeight;

		if (bottom) fetchUserResult(searchText);
	};
	const cancelSearch = () => {
		setSearchText("");
		setIsEmpty({ user: true, status: true });
		history.push("/search");
	};

	const setThoughtUser = (value) => {
		if (value === 1) {
			setThought(1);
			history.push({
				pathname: "/search/thoughts",
				search: searchQuery && `?searchQuery=${searchQuery}`,
			});
		} else if (value === 2) {
			setThought(2);
			history.push({
				pathname: "/search/users",
				search: searchQuery && `?searchQuery=${searchQuery}`,
			});
		} else if (value === 3) {
			setThought(3);
			history.push({
				pathname: "/search/messages",
				search: searchQuery && `?searchQuery=${searchQuery}`,
			});
		}
	};

	const removeRecent = (data) => {
		const arr = recentSearches.filter((item) => item.text !== data.text);
		setRecentSearches(arr);
	};

	const fetchMessageResult = (text) => {
		setUserLoading(true);

		const data = {
			keyword: text,
			groupID: `${groupDataFromStore.id}`,
			conversationID: "",
			skip: 0,
			limit: 30,
		};

		axiosChatConfig
			.post(searchMessagesURLV2, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.count === 0) {
					setIsEmpty((prev) => ({ ...prev, message: true }));
				} else {
					setIsEmpty((prev) => ({ ...prev, message: false }));
					setMessageResult(res.data.chats);
					// if (userReset.current) {
					// 	setUserResult(res.data.body);
					// 	userReset.current = false;
					// } else setUserResult([...userResult, ...res.data.body]);
				}
				setUserLoading(false);

				// userOffset.current += 10;
				// if (!res.data.links.next) userLoaded.current = true;
			})
			.catch((err) => {});
	};

	const handleCancel = () => {
		setSearchText("");
		setStatusResult([]);
		setUserResult([]);
		setMessageResult([]);
		setThoughtsSugg([]);
		setUsersSugg([]);
		setMsgsSugg([]);
		setIsSugg(false);
		setIsEmpty({ user: true, status: true });
		history.push("/search");
	};

	const handleSuggestions = () => {
		if (searchText.length < 2) return;

		searchText ? setIsSugg(true) : setIsSugg(false);

		if (searchText === "") {
			setThoughtsSugg([]);
			setUsersSugg([]);
			setMsgsSugg([]);
			setIsSugg(false);
		}

		//thoughts Suggestion
		const data1 = {
			search_phrase: searchText,
			location: locations,
			groupId: groupDataFromStore.id,
		};
		const URL1 =
			searchStatusURLV2 + "?limit=16&offset=" + statusOffset.current;
		bdittoAPIv2
			.post(URL1, data1)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				// if (res.data.body === "No search result") {
				if (res.data.body.length < 1) {
					setNoSugg(true);
					return <div>No suggestions</div>;
				} else {
					setNoSugg(false);
					const thoughtsRes = res.data.result || res.data.body;
					const thoughts = thoughtsRes.filter((value) => {
						return value.content
							.toLowerCase()
							.includes(searchText.toLowerCase());
					});
					setThoughtsSugg(thoughts);
				}
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;
					alert(catchError.message);
				}
			});

		//users suggestion
		const data2 = { search_phrase: searchText, location: locations };

		const URL2 = searchUserURLV2 + "?limit=16&offset=" + userOffset.current;

		bdittoAPIv2
			.post(URL2, data2)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.body === "No search result") {
					return <div>No suggestions</div>;
				} else {
					const userRes = res.data.result || res.data.body;
					const users = userRes.filter((value) => {
						return value.username
							.toLowerCase()
							.includes(searchText.toLowerCase());
					});
					setUsersSugg(users);
				}
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;
					alert(catchError.message);
				}
			});

		//messages Suggestion
		const data3 = {
			keyword: searchText,
			groupID: `${groupDataFromStore.id}`,
			conversationID: "",
			skip: 0,
			limit: 30,
		};

		axiosChatConfig
			.post(searchMessagesURLV2, data3)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.count === 0) {
					return <div>No suggestions</div>;
				} else {
					const msgsRes = res.data.chats;
					const msgs = msgsRes.filter((value) => {
						return value.message
							.toLowerCase()
							.includes(searchText.toLowerCase());
					});
					setMsgsSugg(msgs);
				}
			})
			.catch((err) => {});
	};

	const tabs = [
		{
			id: 1,
			label: "Thoughts",
		},
		{
			id: 2,
			label: "Users",
		},
		{
			id: 3,
			label: "Messages",
		},
	];

	const mobile = window.screen.width < 768;

	return (
		<Dialog
			open={true}
			fullScreen={true}
			maxWidth={"100vw"}
			fullWidth={"100vw"}
			classes={{
				paper: theme === "dark" ? styles.rootDark : styles.rootLg,
			}}
		>
			<div className={styles.coverContainer}>
				<div className={styles.mainContainer}>
					{!mobile && (
						<>
							<div className={styles.header}>
								<div className={styles.grpContainer}>
									<div
										className={styles.groupName}
										onClick={() => setGroupInfo(!groupInfo)}
									>
										<Avatar
											style={{
												width: "1.7em",
												height: "1.7em",
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
												border:
													theme === "dark"
														? "2px solid #d9def4"
														: "",
											}}
											className="ml-2"
											src={groupDataFromStore.avatar}
										>
											{groupDataFromStore.name &&
												groupDataFromStore.name[0].toUpperCase()}
										</Avatar>
										<span
											className={`${styles.grpname} ${
												theme === "dark"
													? styles.darkrow
													: styles.lgrow
											}`}
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "#0D0F22",
											}}
										>
											{groupDataFromStore.name}
										</span>
									</div>
									<div
										onClick={() =>
											setGroupDisplay(!groupDisplay)
										}
									>
										{groupDisplay ? (
											<ExpandMoreIcon
												style={{
													fontSize: "1.5rem",
													color:
														theme === "dark"
															? "#D9DEF4"
															: "#0d0f22",
													transform: "rotate(180deg)",
												}}
											/>
										) : (
											<ExpandMoreIcon
												style={{
													fontSize: "1.5rem",
													color:
														theme === "dark"
															? "#D9DEF4"
															: "#0d0f22",
												}}
											/>
										)}
									</div>
								</div>

								<div className={styles.policyHeader}>
									<img
										onClick={() => {
											history.push("/");
										}}
										src="/icons/squaredClose.svg"
										alt=""
									/>
								</div>
							</div>

							{groupDisplay && (
								<div
									className={`${styles.group} ${
										theme === "dark"
											? styles.grpdark
											: styles.grplg
									}`}
								>
									<GroupsSidebar
										myGrp={groupDataFromStore?.name}
										setGroupsDisplay={setGroupDisplay}
                                        top="true"
                                        groupId={groupDataFromStore?.id}
                                        
									/>
								</div>
							)}

							<div
								className={
									theme === "dark"
										? styles.contentDk
										: styles.contentLg
								}
							>
								<>
									<div className={styles.heading}>
										<h2
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "#0D0F22",
											}}
										>
											{/* <FormattedMessage
												id="search"
												defaultMessage={`Search`}
											/> */}
                                            search
										</h2>
									</div>
									<div className={styles.searchContainer}>
										<img src="/Search.svg" alt="" />
										<input
											type="text"
											value={searchText ? searchText : ""}
											ref={searchInputRef}
											className={styles.input}
											onChange={(e) =>
												setSearchText(e.target.value)
											}
											onKeyDown={keyPress}
											onKeyUp={handleSuggestions}
											placeholder="Search thoughts and users"
											style={{
												color:
													theme === "dark"
														? "#D8DEF3"
														: "#4A517E",
											}}
										/>
										{searchText && (
											<Close
												onClick={handleCancel}
												style={{
													margin: ".25rem .5rem",
													color:
														theme === "dark"
															? "#d9def4"
															: "#0d0f22",
												}}
											/>
										)}
										<div>
											<Button2 onClick={handleBtnSearch}>
												Search
											</Button2>
										</div>
									</div>
									<div
										className={styles.mainSetCont}
										style={{
											boxShadow: `0px 0px 10px ${
												theme === "dark"
													? "#0d0f22"
													: "#d9def4"
											}`,
										}}
									>
										<div>
											<Typography
												variant="body1"
												style={{
													margin: "1.5rem 1rem",
													color:
														theme === "light"
															? "#4A517E"
															: "#7F89BE",
													fontSize: "1rem",
												}}
											>
												Search in
											</Typography>
											<div className={styles.links}>
												<div className={styles.btn}>
													{thought === 1 ? (
														<Button2
															onClick={() =>
																setThoughtUser(
																	1
																)
															}
														>
															<span className="mr-2">
																<img
																	src="/search/thoughtIcon.svg"
																	alt=""
																/>
															</span>
															Thoughts
														</Button2>
													) : (
														<Button1
															onClick={() =>
																setThoughtUser(
																	1
																)
															}
														>
															<span className="mr-2">
																<img
																	src="/search/thoughtIcon.png"
																	alt=""
																/>
															</span>
															Thoughts
														</Button1>
													)}
												</div>
												<div className={styles.btn}>
													{thought === 2 ? (
														<Button2
															onClick={() =>
																setThoughtUser(
																	2
																)
															}
														>
															<span className="mr-2">
																<img
																	src="/search/Profile.svg"
																	alt=""
																/>
															</span>
															Users
														</Button2>
													) : (
														<Button1
															onClick={() =>
																setThoughtUser(
																	2
																)
															}
														>
															<span className="mr-2">
																<img
																	src="/search/Profile.png"
																	alt=""
																/>
															</span>
															Users
														</Button1>
													)}
												</div>
												<div className={styles.btn}>
													{thought === 3 ? (
														<Button2
															onClick={() =>
																setThoughtUser(
																	3
																)
															}
														>
															<span className="mr-2">
																<img
																	src="/search/msgIcon.svg"
																	alt=""
																/>
															</span>
															Messages
														</Button2>
													) : (
														<Button1
															onClick={() =>
																setThoughtUser(
																	3
																)
															}
														>
															<span className="mr-2">
																<img
																	src="/search/msgIcon.png"
																	alt=""
																/>
															</span>
															Messages
														</Button1>
													)}
												</div>
											</div>
										</div>

										{isSugg ? (
											<>
												<Typography
													variant="body1"
													style={{
														margin: "1.5rem 1rem",
														color:
															theme === "light"
																? "#4A517E"
																: "#7F89BE",
														fontSize: "1rem",
													}}
												>
													Suggestions
												</Typography>
												{noSugg && (
													<p
														style={{
															margin: "1.5rem 1rem",
															color:
																theme ===
																"light"
																	? "#4A517E"
																	: "#7F89BE",
															fontSize: "1rem",
														}}
													>
														No suggestions
													</p>
												)}

												{thoughtsSugg &&
													thought === 1 &&
													thoughtsSugg
														.slice(0, 5)
														.map((thought, i) => (
															<div
																key={i}
																className={
																	styles.recentSearch
																}
															>
																{theme ===
																"dark" ? (
																	<img
																		src="/search/thoughtIcon.svg"
																		alt=""
																	/>
																) : (
																	<img
																		src="/thoughtsLg.svg"
																		alt=""
																	/>
																)}
																<p
																	style={{
																		margin: "0 1rem",
																		color:
																			theme ===
																			"dark"
																				? "#d9def4"
																				: "#0d0f22",
																		cursor: "pointer",
																	}}
																	onClick={() => {
																		perSearch(
																			thought.content
																		);
																	}}
																>
																	{
																		thought.content
																	}
																</p>
															</div>
														))}
												{usersSugg &&
													thought === 2 &&
													usersSugg
														.slice(0, 5)
														.map((user, i) => (
															<div
																className={
																	styles.recentSearch
																}
																key={i}
															>
																{theme ===
																"dark" ? (
																	<img
																		src="/search/Profile.svg"
																		alt=""
																	/>
																) : (
																	<img
																		src="/profileLg.svg"
																		alt=""
																	/>
																)}
																<p
																	style={{
																		margin: "0 1rem",
																		color:
																			theme ===
																			"dark"
																				? "#d9def4"
																				: "#0d0f22",
																		cursor: "pointer",
																	}}
																	onClick={() => {
																		perSearch(
																			user.username
																		);
																	}}
																>
																	{
																		user.username
																	}
																</p>
															</div>
														))}
												{msgsSugg &&
													thought === 3 &&
													msgsSugg
														.slice(0, 5)
														.map((msg, i) => (
															<div
																className={
																	styles.recentSearch
																}
																key={i}
															>
																{theme ===
																"dark" ? (
																	<img
																		src="/search/msgIcon.svg"
																		alt=""
																	/>
																) : (
																	<img
																		src="/msgLg.svg"
																		alt=""
																	/>
																)}
																<p
																	style={{
																		margin: "0 1rem",
																		color:
																			theme ===
																			"dark"
																				? "#d9def4"
																				: "#0d0f22",
																		cursor: "pointer",
																	}}
																	onClick={() => {
																		perSearch(
																			msg.message
																		);
																	}}
																>
																	{
																		msg.message
																	}
																</p>
															</div>
														))}
											</>
										) : (
											recentSearches &&
											recentSearches.length > 0 && (
												<div
													className={
														styles.recentSearchContainer
													}
												>
													<Typography
														variant="body1"
														style={{
															margin: "1.5rem 1rem",
															color:
																theme ===
																"light"
																	? "#4A517E"
																	: "#7F89BE",
															fontSize: "1rem",
														}}
													>
														Recent Searches
													</Typography>
													{recentSearches &&
														recentSearches.length >
															0 &&
														recentSearches
															.slice(0, 5)
															.map(
																(search, i) => (
																	<div
																		key={i}
																		className={
																			styles.recentSearch
																		}
																		onMouseOver={() =>
																			setMouseOver(
																				i
																			)
																		}
																	>
																		{search.thought ===
																		1 ? (
																			<img
																				src="/search/thoughtIcon.svg"
																				alt=""
																			/>
																		) : search.thought ===
																		  2 ? (
																			<img
																				src="/search/Profile.svg"
																				alt=""
																			/>
																		) : (
																			<img
																				src="/search/msgIcon.svg"
																				alt=""
																			/>
																		)}
																		<p
																			onClick={() => {
																				perSearch(
																					search.text
																				);
																			}}
																			style={{
																				margin: "0 1rem",
																				color:
																					theme ===
																					"dark"
																						? "#d9def4"
																						: "#0d0f22",
																				cursor: "pointer",
																			}}
																		>
																			{
																				search.text
																			}
																		</p>
																		{mouseOver ===
																			i && (
																			<Close
																				onClick={() =>
																					removeRecent(
																						search
																					)
																				}
																				style={{
																					marginLeft:
																						"auto",
																					color:
																						theme ===
																						"dark"
																							? "#7F89BE"
																							: "#4A517E",
																					cursor: "pointer",
																				}}
																			/>
																		)}
																	</div>
																)
															)}
												</div>
											)
										)}
									</div>
								</>

								<div className={styles.resultBox}>
									{!isEmpty.status ||
									!isEmpty.users ||
									!isEmpty.message ? (
										<>
											{thought === 1 ? (
												<div
													className={styles.status}
													onScroll={
														handleStatusScroll
													}
												>
													<div
														className={styles.cards}
													>
														{typeof statusResult !==
															"string" &&
															statusResult.map(
																(
																	item,
																	index
																) => (
																	<>
																		<StatusCard
																			status={
																				item
																			}
																			key={
																				index
																			}
																			width={
																				isDesktop
																					? "20.5rem"
																					: "80%"
																			}
																			height="13.5rem"
																			className={
																				styles.card
																			}
																		/>
																	</>
																)
															)}

														{isStatusLoading && (
															<>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
																<CardSkeleton
																	width={
																		isDesktop
																			? "20.5rem"
																			: "80%"
																	}
																/>
															</>
														)}
													</div>
												</div>
											) : thought === 2 ? (
												<div
													className={
														userResult.length > 0
															? styles.user
															: null
													}
													onScroll={handleUserScroll}
												>
													{userResult.length > 0 &&
														userResult.map(
															(item, index) => {
																return (
																	<div
																		className={
																			userResult.length ==
																			1
																				? styles.resultuserDiv1
																				: styles.resultuserDiv
																		}
																	>
																		<UserPreview
																			{...props}
																			user={
																				item
																			}
																			key={
																				index
																			}
																			online={
																				user_data.online
																			}
																			theme={
																				theme
																			}
																		/>
																	</div>
																);
															}
														)}

													{isUserLoading && (
														<>
															<UserSkeleton
																radius={60}
															/>
															<UserSkeleton
																radius={60}
															/>
															<UserSkeleton
																radius={60}
															/>
															<UserSkeleton
																radius={60}
															/>
														</>
													)}

													{/* {userResult.length == 0 &&
															recentSearches.length > 0 &&
															!isStatusLoading && (
															<div
																className={
															styles.recentSearchContainer
																}
															>
																<div
															className={
															styles.recentSearchHeader
																	}
																>
																	<p>
																		<FormattedMessage
																			id="recentSearch"
																			defaultMessage={`Recent Search`}
																		/>
																	</p>
																</div>
																{recentSearches.map(
																	(item) => (
																		<div
																			className={
																				styles.resultuserDiv
																			}
																			onClick={() =>
																				history.push(
																					{
																						pathname:
																							thought
																								? "/search/thoughts"
																								: "/search/users",
																						search: `?searchQuery=${item.text}`,
																					}
																				)
																			}
																		>
																			<div
																				className={
																					styles.recentSearch
																				}
																			>
																				<p>{item.text}</p>
																			</div>
																		</div>
																	)
																)}
														</div>
													)} */}
												</div>
											) : (
												<div
													className={styles.msgResult}
												>
													{messageResult &&
														messageResult.map(
															(message) => (
																<div
																	className={
																		styles.msg
																	}
																>
																	<LikedMessageBox
																		data={
																			message
																		}
																		isGroup={
																			message.groupID
																		}
																		isOwnMessage={
																			false
																		}
																		conversationID={
																			message._id
																		}
																	/>
																</div>
															)
														)}
												</div>
											)}
										</>
									) : (
										<EmptyState
											heading="No Results!"
											imageUrl="/vectors/empty-states/emptySearch.svg"
											subHeading="We cannot find what you’re looking for, maybe a little spelling mistake?"
										/>
									)}
								</div>
							</div>
						</>
					)}

					{mobile && (
						<>
							<div className={styles.mobContent}>
								<div style={{ height: "22vh" }}>
									<div className="d-flex align-items-center justify-content-start container-fluid">
										<div className="mr-3">
											<ArrowBackIosIcon
												onClick={() => {
													history.push("/");
												}}
												style={{
													color:
														theme === "dark"
															? "#d9def4"
															: "#0d0f22",
												}}
											/>
										</div>
										<div
											className={
												styles.searchContainer +
												" w-100"
											}
										>
											<img src="/Search.svg" alt="" />
											<input
												type="text"
												value={
													searchText ? searchText : ""
												}
												ref={searchInputRef}
												className={styles.input}
												onChange={(e) =>
													setSearchText(
														e.target.value
													)
												}
												onKeyDown={keyPress}
												onKeyUp={handleSuggestions}
												placeholder="Search thoughts and users"
												style={{
													color:
														theme === "dark"
															? "#D8DEF3"
															: "#4A517E",
												}}
											/>
											{searchText && (
												<Close
													onClick={handleCancel}
													style={{
														color:
															theme === "dark"
																? "#d9def4"
																: "#0d0f22",
													}}
												/>
											)}
										</div>
									</div>
									<div>
										<div className={styles.thoughtsTab}>
											{tabs.map((tab, i) =>
												tab.id === thought ? (
													<div
														className={
															styles.activeTab
														}
													>
														{tab.label}
													</div>
												) : (
													<div
														onClick={() =>
															setThoughtUser(
																tab.id
															)
														}
														className={styles.tab}
													>
														{tab.label}
													</div>
												)
											)}
										</div>
									</div>
								</div>
								<div
									style={{
										height: "78vh",
										overflow: "auto",
										// background: "#121528",
									}}
								>
									<Mobile
										userResult={userResult}
										statusResult={statusResult}
										isUserLoading={isUserLoading}
										isStatusLoading={isStatusLoading}
										messageResult={messageResult}
										// handleStatusScroll={handleStatusScroll}
										// handleUserScroll={handleUserScroll}
										isEmpty={isEmpty}
										// searchQuery={searchQuery}
										perSearch={perSearch}
										isSugg={isSugg}
										recentSearches={recentSearches}
										thoughtsSugg={thoughtsSugg}
										usersSugg={usersSugg}
										msgsSugg={msgsSugg}
										thought={thought}
										setMouseOver={setMouseOver}
										mouseOver={mouseOver}
										removeRecent={removeRecent}
										noSugg={noSugg}
									/>
								</div>
							</div>
						</>
					)}
					{/* {!isMobile  && <Tags />} */}
				</div>
			</div>
		</Dialog>
	);
};

export default connect(null, { setRecentSearch })(Search);
