import { CircularProgress, Fade } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
const InputField = ({
	label,
	placeholder,
	className,
	type,
	tooltip,
	options,
	onChange,
	onSearchQueryChange,
	value,
	errorText,
	optionsLoading,
	...props
}) => {
	const [passwordToggle, setToggle] = useState(false);
	const [dropdownOpen, setDropDownOpen] = useState(false);
	const toggle = () => setToggle(!passwordToggle);
	const toggleList = () => setDropDownOpen(!dropdownOpen);
	const [searchTerm, setSearchTerm] = useState("");
	const selectItem = (item) => () => {
		setSearchTerm(item);
		onChange({ target: { name: props.name, value: item } });
		setDropDownOpen(false);
	};

	const theme = useSelector((state) => state.theme.theme);

	useEffect(() => {
		if (type === "select") {
			setSearchTerm(value);
		}
	}, [type, value]);

	const onInputChange = (e) => {
		if (props.name === "country") {
			if (e.target.value.length === 0) {
				props.removecountry();
			}
		}
		if (type === "select") {
			setSearchTerm(e.target.value);
			onSearchQueryChange(e.target.value);
		} else {
			onChange({
				target: { name: e.target.name, value: e.target.value },
			});
		}
	};
	return (
		<div className={`${styles.inputfieldcontainer} ${className}`}>
			<div className={styles.pre}>
				<p className={styles.label}>{label}</p>
				<p className={styles.tip}>{tooltip}</p>
			</div>

			<div className={theme === "dark" ? styles.dark : styles.lg}>
				<div className={styles.inputfield}>
					{type === "password" && (
						<img
							className={`${styles.showpassword} ${
								passwordToggle ? styles.clicked : ""
							} ${errorText ? styles.showpassworderror : ""}`}
							src="/icons/viewPassword.svg"
							onClick={toggle}
							alt="showPass"
						/>
					)}
					{/* {type === "select" && (
						<ExpandMore
							className={`${styles.showpassword} ${
								passwordToggle ? styles.clicked : ""
							} ${errorText ? styles.showpassworderror : ""}`}
							src="/icons/viewPassword.svg"
							onClick={toggleList}
							alt="showPass"
						/>
					)} */}
					<input
						onFocus={() => setDropDownOpen(true)}
						onBlur={() => setDropDownOpen(false)}
						className={`${errorText ? styles.invalid : ""}`}
						type={
							passwordToggle
								? "text"
								: type !== "select"
								? type
								: "search"
						}
						placeholder={placeholder}
						value={type === "select" ? searchTerm : value}
						onChange={onInputChange}
						{...props}
					/>
					{type === "select" && (
						<Fade in={dropdownOpen}>
							<div className={styles.dropdown}>
								{!optionsLoading ? (
									options.map((item) => (
										<div
											onClick={selectItem(item)}
											className={styles.item}
										>
											{item}
										</div>
									))
								) : (
									<div className={styles.loader}>
										<CircularProgress size={10} />
									</div>
								)}
							</div>
						</Fade>
					)}
					<p className={styles.errorText}>{errorText || " "}</p>
				</div>
			</div>
		</div>
	);
};

export default InputField;
