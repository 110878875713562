import { ReactComponent as Message } from "../assets/MessageIcon.svg";
import genIcons from "./genIcons";
import React from "react";

const MessageIcon = ({ width, height }) => {
	return (
		<svg
			width={width ? width : "14"}
			height={height ? height : "14"}
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="14"
				height="14"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0.582031 0.582031H13.1196V13.1201H0.582031V0.582031Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.56195 11.4758C3.90029 11.4758 4.21937 11.6041 4.55712 11.74C6.6262 12.6967 9.07329 12.2615 10.6675 10.6679C12.771 8.5632 12.771 5.13962 10.6675 3.03611C9.64904 2.01761 8.29454 1.45703 6.85254 1.45703C5.40995 1.45703 4.05487 2.0182 3.03695 3.0367C1.44212 4.63036 1.00812 7.07745 1.95604 9.1267C2.09254 9.46445 2.22437 9.79345 2.22437 10.1353C2.22437 10.4765 2.10712 10.8201 2.00387 11.1234C1.9187 11.3731 1.78979 11.7499 1.87262 11.8328C1.9537 11.9168 2.33287 11.7844 2.58312 11.6986C2.88354 11.5959 3.2242 11.4781 3.56195 11.4758ZM6.83795 13.1202C5.94662 13.1202 5.04945 12.9318 4.21004 12.5433C3.9627 12.4441 3.73112 12.3508 3.56487 12.3508C3.37354 12.3519 3.11629 12.4406 2.86779 12.5264C2.35795 12.7014 1.72329 12.9195 1.2537 12.4517C0.78587 11.9833 1.0017 11.3504 1.17554 10.8411C1.26129 10.5903 1.34937 10.3313 1.34937 10.1353C1.34937 9.97428 1.27179 9.76895 1.15279 9.4732C0.060203 7.11361 0.56537 4.26986 2.41862 2.41778C3.60162 1.2342 5.17604 0.582031 6.85195 0.582031C8.52787 0.582031 10.1029 1.23361 11.2859 2.4172C13.7312 4.86253 13.7312 8.84087 11.2859 11.2862C10.0871 12.4855 8.47303 13.1202 6.83795 13.1202Z"
					fill="#D9DEF4"
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.15598 7.67839C8.83398 7.67839 8.57031 7.41764 8.57031 7.09505C8.57031 6.77247 8.82873 6.51172 9.15073 6.51172H9.15598C9.47798 6.51172 9.73931 6.77247 9.73931 7.09505C9.73931 7.41764 9.47798 7.67839 9.15598 7.67839Z"
				fill="#D9DEF4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.81613 7.67839C6.49414 7.67839 6.23047 7.41764 6.23047 7.09505C6.23047 6.77247 6.4883 6.51172 6.81089 6.51172H6.81613C7.13813 6.51172 7.39947 6.77247 7.39947 7.09505C7.39947 7.41764 7.13813 7.67839 6.81613 7.67839Z"
				fill="#D9DEF4"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.47629 7.67839C4.15429 7.67839 3.89062 7.41764 3.89062 7.09505C3.89062 6.77247 4.14904 6.51172 4.47104 6.51172H4.47629C4.79829 6.51172 5.05962 6.77247 5.05962 7.09505C5.05962 7.41764 4.79829 7.67839 4.47629 7.67839Z"
				fill="#D9DEF4"
			/>
		</svg>
	);
};

export default MessageIcon;
