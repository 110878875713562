import { CLOSE_INCOMMING_CALL, INCOMMING_CALL, TOGGLE_AUDIO, TOGGLE_VIDEO } from "./types";

export const toggleVideo = () => ({ type: TOGGLE_VIDEO });
export const toggleAudio = () => ({ type: TOGGLE_AUDIO });

export const incomingCallNotifier = (payload) =>{
    return {
        type: INCOMMING_CALL,
        payload
      };
}
export const closeCallNotification = () =>{
  return {
        type: CLOSE_INCOMMING_CALL
      };
}