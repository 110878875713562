import React, { Component } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
export default class SmallStatus extends Component {
	render() {
		return (
			<div
				style={{
					borderTop: "1px #e9e9e9 solid",
					padding: "1rem 0",
					width: "90%",
					margin: "auto",
				}}
			>
				<Skeleton variant="text" width="90%" height={15} />
				<Skeleton variant="text" width="90%" height={15} />
				<Skeleton variant="text" width="60%" height={15} />
				<Skeleton variant="text" width="40%" height={15} />
			</div>
		);
	}
}
