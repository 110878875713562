import { Avatar, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axiosConfig from "../../../../Utils/axiosConfig";
import { actionRequest } from "../../../../Utils/Constants";
import styles from "../Contacts.module.css";

import useAlert from "./../../../Extras/Alert/useAlert";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { getCountInContact } from "../../../../Redux/Actions/contactAction";
import Username from "../../../Extras/Username";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import Button2 from "../../../Extras/Buttons/Button2/Button2";
import { useSelector } from "react-redux";

export const PendingRequestCard = ({
	friend,
	setPendindRequests,
	pendingRequests,
	isMobile,
}) => {
	const { showAlert } = useAlert();
	const [isAccSending, setIsAccSending] = useState(false);
	const [isRejSending, setIsRejSending] = useState(false);

	const theme = useSelector((state) => state.theme.theme);

	const dispatch = useDispatch();
	const requestAction = (id, action) => {
		if (action === "Accept") {
			setIsAccSending(true);
		} else {
			setIsRejSending(true);
		}
		const request = new FormData();
		request.append("requestId", id);
		request.append("action", action);

		axiosConfig
			.post(actionRequest, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setIsAccSending(false);
				setIsRejSending(false);
				setPendindRequests(
					pendingRequests.filter(
						(request) => request.requestId !== id
					)
				);
				dispatch(getCountInContact());
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
				setIsAccSending(false);
				setIsRejSending(false);
			});
	};

	return (
		<>
			{!isMobile ? (
				<div className={styles.pendingCard}>
					<Link
						to={`/profile/${friend.user.userID}`}
						className={styles.pendingRequestProfileLink}
					>
						<Avatar
							src={friend.user.avatar}
							style={{
								marginRight: ".55rem",
								marginTop: "0.2rem",
								background:
									theme === "dark" ? "#d9def4" : "#0d0f22",
								color: theme === "dark" ? "#0d0f22" : "#d9def4",
								border:
									theme === "dark" ? "2px solid #d9def4" : "",
							}}
						>
							{friend.user.full_name &&
								friend.user.full_name[0].toUpperCase()}
						</Avatar>

						<p
							className={
								friend.user.profession
									? styles.cardText1
									: styles.cardText
							}
							style={{
								fontSize: "1rem",
								color: theme === "dark" ? "#d9def4" : "#0D0F22",
							}}
						>
							<span
								style={{
									fontSize: "1rem",
									color:
										theme === "dark"
											? "#d9def4"
											: "#0D0F22",
								}}
							>
								<Username
									value={
										friend.user.full_name ||
										friend.user.username
									}
								/>{" "}
							</span>
							{friend.user.profession && (
								<p
									style={{
										color:
											theme === "dark"
												? "#7F89BE"
												: "#4A517E",
										padding: "0rem 0rem 0rem 0.2rem",
										fontSize: "0.85rem",
										margin: "0.15rem 0rem 0rem 0",
									}}
								>
									{friend.user.profession &&
										friend.user.profession}
								</p>
							)}{" "}
						</p>
					</Link>
					<div className={styles.btnCont}>
						<div
							onClick={() => {
								let action = "Accept";
								requestAction(friend.requestId, action);
							}}
							className={styles.acceptButton}
						>
							<Button2>
								<FormattedMessage
									id="accept"
									defaultMessage={`Accept`}
								/>
							</Button2>
						</div>
						<div
							onClick={() => {
								let action = "Reject";
								requestAction(friend.requestId, action);
							}}
						>
							<p className={styles.declineText}>
								<FormattedMessage
									id="decline"
									defaultMessage={`Decline`}
								/>
							</p>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
