import { Button, IconButton, makeStyles, useTheme } from "@material-ui/core";
import {
	CallEnd,
	ErrorOutlineOutlined,
	FullscreenOutlined,
	MicNoneOutlined,
	MicOffOutlined,
	MoreVertOutlined,
	Settings,
	SettingsOutlined,
	VideocamOffOutlined,
	VideocamOutlined,
} from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	toggleAudio,
	toggleVideo,
} from "../../../../Redux/Actions/videoCalls/actions";
import { audioVideoStateSelector } from "../../../../Redux/selectors/videoCalls/configuration";
import SidebarContextMenu from "../../../Home/Chats/Sidebar/SidebarContextMenu";
import styles from "./BottomBar.module.scss";
import SettingsModal from "./SettingsModal";
const useStyles = makeStyles((theme) => ({
	sideButtonsOff: {
		border: "2px solid black",
		padding: "0.5rem",
		color: "black",
	},

	sideButtonsOn: {
		backgroundColor: "#42DA93",
		border: "2px solid #42DA93",
		padding: "0.5rem",
		"&:hover": { backgroundColor: "#42DA93" },

		color: "white",
	},
	iconStyle: { fontSize: "1.7rem" },
	callEndButton: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		margin: " 0 1.5rem",
		"&:hover": { backgroundColor: theme.palette.primary.main },
	},
}));
const BottomBar = ({ onDisconnect, setFullScreen, fullScreen }) => {
	const dispatch = useDispatch();
	const [popSettingsModal, setpopSettingsModal] = useState(false);
	const theme = useTheme();
	const classes = useStyles(theme);
	const closeSettingsModal = () => {
		setpopSettingsModal(false);
	};
	const { video: videoOn, audio: audioOn } = useSelector(
		audioVideoStateSelector
	);

	return (
		<div className={styles.callContainer}>
			<div className={styles.container}>
				<div className={styles.callOptionsContainer}>
					<IconButton
						className={
							audioOn
								? classes.sideButtonsOn
								: classes.sideButtonsOff
						}
						onClick={() => dispatch(toggleAudio())}
					>
						{audioOn ? (
							<MicNoneOutlined className={classes.iconStyle} />
						) : (
							<MicOffOutlined className={classes.iconStyle} />
						)}
					</IconButton>
					<IconButton
						onClick={onDisconnect}
						classes={{ colorPrimary: classes.callEndButton }}
						color="primary"
					>
						<CallEnd
							fontSize="large"
							className={classes.iconStyle}
						/>
					</IconButton>
					<IconButton
						className={
							videoOn
								? classes.sideButtonsOn
								: classes.sideButtonsOff
						}
						onClick={() => dispatch(toggleVideo())}
					>
						{videoOn ? (
							<VideocamOutlined className={classes.iconStyle} />
						) : (
							<VideocamOffOutlined
								className={classes.iconStyle}
							/>
						)}
					</IconButton>
				</div>
			</div>
			<div className={styles.moreIcon}>
				<SidebarContextMenu
					listItemStyle={{ padding: " 0 10px" }}
					anchorOrigin={{ vertical: "top", horizontal: "left" }}
					transformOrigin={{ horizontal: "left", vertical: "top" }}
					// className={classes.userData}
					trigger={<MoreVertOutlined className={classes.iconStyle} />}
				>
					<Button
						style={{ textTransform: "none", fontWeight: "400" }}
						onClick={() => setFullScreen(!fullScreen)}
					>
						<img
							src="/icons/FullScreen.svg"
							style={{ marginRight: "0.4em" }}
						/>{" "}
						{fullScreen ? "Go Side By Side " : "Go Full Screen "}
					</Button>

					<Button
						style={{ textTransform: "none", fontWeight: "400" }}
						onClick={() => {
							setpopSettingsModal(true);
						}}
					>
						<SettingsOutlined style={{ marginRight: "0.4em" }} />{" "}
						Call Settings{" "}
					</Button>

					<Button
						style={{ textTransform: "none", fontWeight: "400" }}
					>
						<ErrorOutlineOutlined
							style={{ marginRight: "0.4em" }}
						/>
						Report Problem{" "}
					</Button>
				</SidebarContextMenu>
			</div>
			<SettingsModal
				show={popSettingsModal}
				onHide={closeSettingsModal}
			/>
		</div>
	);
};

export default BottomBar;
