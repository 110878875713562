import Log from "../Utils/logger";
import Timer from "./Timer";

class CustomSocket {
	// Constructor which takes socket URL as parameter
	constructor(url) {
		this.customSocket = {};
		this.socketUrl = url;
		this.reconnectTimer = new Timer(() => {
			this.disconnect();
			this.connect();
		}, this.reconnectAfterMs);
	}

	reconnectAfterMs(tries) {
		return 2 ** Math.min(tries, 10) * 20;
	}

	connect(authToken, messageListener, groupD, options = {}) {
		if (authToken) {
			this.authToken = authToken;
		}
		if (messageListener) {
			this.messageListener = messageListener;
		}
		if (options.onConnect) {
			this.onConnect = options.onConnect;
		}
		if (options.onClose) {
			this.onClose = options.onClose;
		}
		if (options.onError) {
			this.onError = options.onError;
		}

		Log.debug("trying");
		this.customSocket = new WebSocket(
			`${this.socketUrl}?token=${this.authToken}&group_id=${
				groupD ? groupD.id : 1
			}`
		);

		this.customSocket.onopen = (event) => {
			this.reconnectTimer.reset();

			this.customSocket.onmessage = (event) => {
				this.messageListener(event);
			};
			if (this.onConnect) {
				this.onConnect(event);
			}
		};

		this.customSocket.onclose = (event) => {
			Log.debug("connection lost");

			this.reconnectTimer.scheduleTimeout();
			if (this.onClose) {
				this.onClose(event);
			}
		};

		this.customSocket.onerror = (event) => {
			if (this.onError) {
				this.onError(event);
			}
		};
	}

	setListener(eventName, callback) {
		if (
			this.customSocket &&
			this.customSocket.readyState === WebSocket.OPEN
		) {
			this.customSocket.addEventListener(eventName, callback);
		}
	}

	disconnect() {
		this.customSocket.onclose = function () {
			console.log("disconnected");
			return null;
		};
	}
}

export default CustomSocket;
