import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function ConvListSkeleton() {
	return (
		<div style={{ width: "90%", margin: "1rem auto" }}>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					marginBottom: "0.7rem",
				}}
			>
				<Skeleton variant="circle" width={30} height={30} />
				<Skeleton variant="text" width="70%" height={14} />
			</div>
			<Skeleton variant="text" width="100%" height={14} />
			<Skeleton variant="text" width="75%" height={12} />
		</div>
	);
}
