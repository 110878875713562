import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import formatDistanceToNow from "date-fns/formatDistanceToNowStrict";
import styles from "./NotificationDropdown.module.scss";
import { Avatar, Button, CircularProgress } from "@material-ui/core";
import {
	getTop5Notifications,
	getUnreadCount,
	isNotificationLoading,
} from "../../../../Redux/selectors/notificationSelectors";
import {
	fetchNotifications,
	setUnreadCount,
} from "../../../../Redux/Actions/notifications";
import {
	fetchContactRequests,
	removeContactRequest,
} from "../../../../Redux/Actions/notifications/contactRequests";
import {
	getRequestsCount,
	getTop5ContactRequests,
	isContactRequestsLoading,
} from "../../../../Redux/selectors/ContactRequests";

//Utils
import { timeConverter } from "../../../../Utils/timeConversion";
import axiosConfig from "../../../../Utils/axiosConfig";
import {
	actionRequest,
	setNotificationSeenURL,
} from "../../../../Utils/Constants";

//Components
import ClickPopover from "../../../Extras/ClickPopover";
import { generateNotificationProps } from "../Notification";
import Username from "../../../Extras/Username";
import useAlert from "../../../Extras/Alert/useAlert";
import BlueLine from "../../../../Icons/Blueline";
import Button1 from "../../../Extras/Buttons/Button1/Button1";

const NotificationDropdown = ({ children, ...props }) => {
	const [activeTab, setActiveTab] = useState(0);
	const dispatch = useDispatch();
	const notifications = useSelector(getTop5Notifications);
	const theme = useSelector((state) => state.theme.theme);
	const isLoading = useSelector(isNotificationLoading);
	const notificationCount = useSelector(getUnreadCount);
	const friendRequests = useSelector(getTop5ContactRequests);
	const isFriendRequestsLoading = useSelector(isContactRequestsLoading);
	const friendRequestsCount = useSelector(getRequestsCount);


	useEffect(() => {
		dispatch(fetchNotifications());
		dispatch(fetchContactRequests());
	}, [dispatch]);

	const openNotifications = () => {
		setActiveTab(0);
	};
	const openContactRequests = () => {
		setActiveTab(1);
		// dispatch(fetchContactRequests());
	};

	const onPopoverOpen = () => {
		if (notificationCount > 0) {
			dispatch(setUnreadCount(0));
		}
	};

	const readNotification = (notificationID) => {
		const data = new FormData();
		data.append("notifIds", notificationID);
		axiosConfig
			.post(setNotificationSeenURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUnreadCount(notificationCount - 1);
			})
			.catch((err) => {});
	};

	const displayNotifications = (close) => {
		if (notifications.length === 0) {
			return <p className={styles.empty}>No Notifications</p>;
		}
		return notifications.map((notification) => {
            if (notification.created_at || !notification.group_id) {
				const date = formatDistanceToNow(
					new Date(notification.created_at),
					{ addSuffix: false }
				);
				const formattedDate = timeConverter(date);
				const { notificationLink } =
					generateNotificationProps(notification);
				const onNotificationClick = () => {
					if (!notification.seen) readNotification(notification.pk);
					close();
				};

				return (
					<Link
						onClick={onNotificationClick}
						to={notificationLink}
						className={styles.notificationContainer}
						key={notification.pk}
					>
						<div style={{ display: "flex" }}>
							<div>
								<Avatar
									src={
										notification.metas.user &&
										notification.metas.user.avatar
									}
									style={{
										width: "3rem",
										height: "3rem",
										marginRight: "1rem",
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
								>
									{(notification.metas.user &&
										notification.metas.user.username[0].toUpperCase()) ||
										(notification.metas.sender &&
											notification.metas.sender.username[0].toUpperCase())}
								</Avatar>
							</div>
							<div className="d-flex ">
								<div
									className={`${styles.notificationContent} `}
								>
									{notification.content}
								</div>
								<div className={styles.date}>
									<img
										src="/Home/timeSquare.png"
										style={{
											marginRight: ".4rem",
											marginBottom: "5px",
										}}
										alt=""
									/>
									<div>{formattedDate}</div>
								</div>
							</div>
						</div>
					</Link>
				);
			} else {
				return null;
			}
		});
	};

	const displayRequests = () => {
		if (friendRequests?.length === 0) {
			return (
				<p
					className={`${styles.empty} ${
						theme === "dark" ? styles.darkbg : styles.lgbg
					}`}
				>
					No Pending Friend Requests
				</p>
			);
		}
		return friendRequests?.map((request) => {
			return <FriendRequestCard request={request} />;
		});
	};

	const displayLoading = () => {
		return (
			<div className={styles.loader}>
				<CircularProgress
					className={styles.spinner}
					size="0.9rem"
					color="primary"
				/>
				Loading
			</div>
		);
	};
	return (
		<ClickPopover
			// onOpen={onPopoverOpen}
			className={styles.popover}
			trigger={children}
		>
			{({ onClose }) => (
				<div
					className={`${styles.container} ${
						theme === "dark" ? styles.darkbg : styles.lgbg
					}`}
				>
					<div
						className={`${styles.header} ${
							theme === "dark" ? styles.darktext : styles.lgtext
						}`}
					>
						<div>
							<h2
								className={activeTab === 0 ? styles.active : ""}
								onClick={openNotifications}
							>
								Notifications
							</h2>
							{activeTab === 0 && <BlueLine />}
						</div>
						<div>
							<h2
								className={activeTab === 1 ? styles.active : ""}
								onClick={openContactRequests}
							>
								Requests(
								{friendRequestsCount <= 5
									? friendRequestsCount
									: "5+"}
								)
							</h2>
							{activeTab === 1 && <BlueLine />}
						</div>
					</div>
					<div className={styles.content}>
						{isFriendRequestsLoading || isLoading
							? displayLoading(onClose)
							: activeTab === 0
							? displayNotifications(onClose)
							: displayRequests()}
					</div>
					<Link
						onClick={onClose}
						to={`/notification`}
						className={styles.footer}
					>
						<Button
							color="primary"
							className={styles.linkToNotificationPage}
							onClick={onPopoverOpen}
						>
							View All (
							{activeTab === 0
								? notificationCount
								: friendRequestsCount}
							)
						</Button>
					</Link>
				</div>
			)}
		</ClickPopover>
	);
};

export const FriendRequestCard = ({ request }) => {
	const { showAlert } = useAlert();
	const dispatch = useDispatch();
	const theme = useSelector((state) => state.theme.theme);

	const onAccept = () => {
		requestAction(request.requestId, "Accept", () => {
			dispatch(removeContactRequest(request.requestId));
		});
	};
	const onReject = () => {
		requestAction(request.requestId, "Reject", () => {
			dispatch(removeContactRequest(request.requestId));
		});
	};

	const requestAction = (id, action, onComplete) => {
		const request = new FormData();
		request.append("requestId", id);
		request.append("action", action);

		axiosConfig
			.post(actionRequest, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				onComplete();
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	return (
		<div className={styles.friendRequestCard}>
			<Avatar
				src={request.user.avatar}
				style={{
					height: "2rem",
					width: "2rem",
					background: theme === "dark" ? "#d9def4" : "#0d0f22",
					color: theme === "dark" ? "#0d0f22" : "#d9def4",
				}}
			>
				{request.user.username &&
					request.user.username[0].toUpperCase()}
			</Avatar>
			<div className={styles.requestCont}>
				<b>
					<Username value={request.user.username} />
				</b>{" "}
				has sent you a friend Request
			</div>
			<div className={styles.actions}>
				<Button1 onClick={onAccept} variant="contained" color="primary">
					{/* <Check /> */}
					Accept
				</Button1>
				<Button onClick={onReject} variant="outlined" color="primary">
					{/* <Close /> */}
					Delete
				</Button>
			</div>
		</div>
	);
};

export default NotificationDropdown;
