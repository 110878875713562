import React from "react";
import Username from "../../../../Extras/Username";
import { Avatar, makeStyles } from "@material-ui/core";
import styles from "./tagMember.module.scss";

const useStyles = makeStyles({
	avatarColor: {
		backgroundColor: "#ff5b5b",
	},
	avatarRoot: {
		width: "2rem",
		height: "2rem",
		fontSize: "1rem",
	},
});

const TagMember = ({ members, open, search = "", mentionUser }) => {
	const classes = useStyles();
	return (
		open && (
			<div className={styles.placeHolder}>
				<div className={styles.container}>
					{members &&
						members.map((user) =>
							search.length === 0 ||
							user.username.startsWith(search) ? (
								<div
									className={styles.userContainer}
									onClick={() =>
										mentionUser(user.username, user.userID)
									}
								>
									<div className={styles.avatar}>
										<Avatar
											classes={{
												root: classes.avatarRoot,
												colorDefault:
													classes.avatarColor,
											}}
											src={user.profileURL}
										>
											{user.username &&
												user.username[0].toUpperCase()}
										</Avatar>
									</div>
									<p>
										<Username value={user.username} />
									</p>
								</div>
							) : null
						)}
				</div>
			</div>
		)
	);
};

export default TagMember;
