export default function Paperclip({ theme }) {
	const mainColor = theme === "dark" ? "#D9DEF4" : "#0D0F22";

	return (
		<svg
			style={{ marginLeft: "10px" }}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.9993 10L9.58579 20.5864C9.21071 20.9615 9 21.4702 9 22.0006C9 22.531 9.21071 23.0398 9.58579 23.4148C9.96086 23.7899 10.4696 24.0006 11 24.0006C11.5304 24.0006 12.0391 23.7899 12.4142 23.4148L24.8277 10.8284C25.1991 10.457 25.4938 10.016 25.6948 9.53073C25.8958 9.04543 25.9993 8.52529 25.9993 8C25.9993 7.47471 25.8958 6.95457 25.6948 6.46927C25.4938 5.98396 25.1991 5.54301 24.8277 5.17157C24.4563 4.80014 24.0153 4.5055 23.53 4.30448C23.0447 4.10346 22.5246 4 21.9993 4C21.474 4 20.9538 4.10346 20.4685 4.30448C19.9832 4.5055 19.5423 4.80014 19.1708 5.17157L6.75736 17.758C5.63214 18.8832 5 20.4093 5 22.0006C5 23.5919 5.63214 25.118 6.75736 26.2433C7.88258 27.3685 9.4087 28.0006 11 28.0006C12.5913 28.0006 14.1174 27.3685 15.2426 26.2433L25.4993 16"
				stroke={mainColor}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
