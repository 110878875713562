import { CircularProgress } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { countriesSelector } from "../../../../Redux/selectors/locationSelector";
import { bdittoAPIv2 } from "../../../../Utils/axiosConfig";
import { getTopHashtagsURLV2 } from "../../../../Utils/Constants";
import styles from "./TagsSidebar.module.css";
import { FormattedMessage } from "react-intl";
import TrendingIcon from "../../../../Icons/Trending";

class Tags extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tags: [],
			isLoading: true,
		};
	}
	componentDidMount = () => {
		this.fetchTags();
	};

	componentDidUpdate(prevProps) {
		if (this.props.countries !== prevProps.countries) {
			this.setState({ isLoading: true });
			this.fetchTags();
		}
	}
	fetchTags() {
		const data = {
			location: this.props.countries,
			limit: 15,
			groupId: this.props.groupDataFromStore.id,
		};
		bdittoAPIv2
			.post(getTopHashtagsURLV2, data)
			.then((res) => {
				this.setState({ tags: res.data.body, isLoading: false });
			})
			.catch((err) => {
				this.setState({ isLoading: false });
			});
	}

	displayTags() {
		return this.state.tags.map((tag, index) => {
			const isActive = this.props.location.pathname.endsWith(
				`/${tag.name}/${tag.pk}`
			);
			return (
				<Link to={`/explore/tags/${tag.name}/${tag.pk}`} key={tag.pk}>
					<h3
						className={`${styles.tag} ${
							isActive ? styles.activeTag : ""
						}`}
						style={{
							color:
								this.props.theme === "dark"
									? "#d9def4"
									: "#0D0F22",
						}}
					>
						{tag.name}
						<span className={styles.tagCount}>
							{tag.count > 0 && `(${tag.count})`}
						</span>
					</h3>
				</Link>
			);
		});
	}

	render() {
		return (
			<div className={styles.tagsContainer} id="Tags">
				<div className={styles.backgroundText}>
					<div
						className={`${styles.header} ${
							this.props.theme === "dark"
								? styles.darktext
								: styles.lgtext
						}`}
					>
						<div className={styles.trendingIcon}>
							<TrendingIcon
								color={
									this.props.theme === "dark"
										? "#D9DEF4"
										: "#0D0F22"
								}
							/>
						</div>
						<FormattedMessage
							id="trending"
							defaultMessage={`Trending`}
						/>
					</div>
				</div>
				<div className={styles.mainContent}>
					{this.state.isLoading ? (
						<div className={styles.loader}>
							<CircularProgress
								className={`${styles.loaderSpinner} ${
									this.props.theme === "dark"
										? styles.darktext
										: styles.lgtext
								}`}
								color={
									this.props.theme === "dark"
										? "#d9def4"
										: "black"
								}
							/>
						</div>
					) : (
						this.displayTags()
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	countries: countriesSelector(state),
	groupDataFromStore: state.groups.groupData,
	theme: state.theme.theme,
});

export default connect(mapStateToProps)(withRouter(Tags));
