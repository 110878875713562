import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useState, useEffect } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNowStrict";
import { useHistory, useLocation } from "react-router-dom";
import {
	likeStatus,
	setStatusAsFavURL,
	TurnOffURL,
	turnedOffUsers,
} from "../../../Utils/Constants";
import Styles from "./StatusCard.module.css";
import "./StatusCard.scss";
import { formatNumber } from "../../../Utils/numbers";
import { Grow, IconButton, Dialog } from "@material-ui/core";
import axiosConfig from "../../../Utils/axiosConfig";
import ChatUserReview from "../ChatUserReview/ChatUserReview";
import { useSelector, useDispatch } from "react-redux";
import Username from "../Username";
import useAlert from "./../Alert/useAlert";
import OnlineBadge from "../StyledBadge";
import { getShortUserProfileUrl } from "./../../../Utils/Constants";
import ImagePopover from "../ImagePopover/ImagePopover";
import { timeConverter } from "../../../Utils/timeConversion";
import FavFilledIcon from "../../../Icons/FavFilledIcon";
// import FavIcon from "../../../Icons/FavIcon";
import { gradients } from "../../../Utils/gradients";
// import UsersIcon from "../../../Icons/users";
import SidebarContextMenu from "../../Home/Chats/Sidebar/SidebarContextMenu";
import { FormattedMessage } from "react-intl";
import BookmarksDropdown, {
	AddToBookmarksContent,
} from "../../Home/Bookmark/BookmarksDropdown/BookmarksDropdown";
// import DialogModal from "../../Extras/DialogModal";
import { fetchFolders } from "../../../Redux/Actions/bookmarks/folderActions";
// import UserProfile from "../../Profile/UserProfile/UserProfile";
import Alert from "../Alert/Alert";
// import { selectFolders } from "../../../Redux/selectors/Bookmarks/folders";
// import Button1 from "../Buttons/Button1/Button1";
import LikersDialog from "../../MyThoughts/LikersDialog";
import JoinedDialog from "../../MyThoughts/JoinedDialog";
const useStyles = (theme, props) => ({
	card: {
		width: (props) => props.width,
		height: (props) => props.height,
		margin: "0 1% 2% 1%",
		padding: "1rem",
		boxShadow: "0px 4px 10px rgba(0, 178, 255, 0.04)",
		color: "var(--heading-color2)",
		borderRadius: "1rem",
		display: "flex",
		flexDirection: "column",
		position: "relative",
		"@media (max-width:780px)": {
			width: "100% !important",
		},
	},
	content: {
		fontSize: "1rem",
		overflow: "hidden",
		flex: 1,
		paddingBottom: "2.5rem",
		cursor: "pointer",
		display: "flex",
		position: "relative",
		overflowWrap: "break-word",
		alignItems: "left",
		textOverflow: "ellipsis !important",
	},
	footer: {
		padding: "0 !important",
		paddingTop: "1rem !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		fontSize: ".8rem",
		backgroundColor: "var(--dark-background)",
	},
	avatar: {
		width: theme.spacing(2),
		height: theme.spacing(2),
	},
	view: {
		color: "#626262",
		textTransform: "none",
	},
});

const StatusCard = ({ status, classes, ...props }) => {
	const { showAlert } = useAlert();
	const userID = useSelector((state) => state.auth?.user_data?.userID);
	const theme = useSelector((state) => state.theme.theme);
	const path = useLocation().pathname;
	const history = useHistory();
	const dispatch = useDispatch();
	const [turnedOffUsersList, setTurnedOffUsersList] = useState([]);
	const [user, setUser] = useState({});
	const [paths, setpath] = useState(0);
	const [mounted, setMounted] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [alertHeader, setAlertHeader] = useState("");
	const [alertMsg, setAlertMsg] = useState("");
	const [liked, setLiked] = useState(status.is_liked);
	const [userLoading, setUserLoading] = useState(false);
	const [showBookmark, setShowBookmark] = useState(false);
	const [likesStatusID, setLikesStatusID] = useState(null);
    const [joinedStatusID, setJoinedStatusID] = useState(null);

    console.log("propsuser", props.handleMobileSuggestions)


	const viewLikers = (statusID) => () => {
		setLikesStatusID(statusID);
	};
	const viewJoined = (statusID) => () => {
		setJoinedStatusID(statusID);
	};

	const closeLikesDialog = () => {
		setLikesStatusID(null);
	};
	const closeJoinedDialog = () => {
		setJoinedStatusID(null);
	};
	// const [open, setOpen] = useState(false);

	useEffect(() => {
		if (path === "/myThoughts") {
			setpath(1);
		}
		if (path === "/") {
			setpath(2);
		} else {
			setpath(0);
		}
	}, [path]);

	useEffect(() => {
		setMounted(true);
		getTurnedOffUsers();
	}, []);

	const getTurnedOffUsers = () => {
		axiosConfig
			.get(turnedOffUsers)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				let data = res.data.result || res.data.body;

				setTurnedOffUsersList(data);
			})
			.catch((err) => {});
	};

	useEffect(() => {
		if (showBookmark) {
			dispatch(fetchFolders());
		}
	}, [showBookmark, dispatch]);

	const background = {
		padding: "0",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "var(--dark-background)",
	};

	const cardStyles = {
		background: "var(--dark-background)",
		border: `1.5px solid ${
			props.suggestionId
				? props.suggestionId === status.pk
					? gradients["SELECTED"]
					: "var(--dark-background)"
				: gradients[status.background_color]
		}`,
	};

	let lastUpdatedAt = formatDistanceToNow(
		new Date(status.last_activity || status.last_updated_at),
		{
			addSuffix: false,
		}
	);

	const setStatusLike = () => {
		const form = new FormData();
		form.append("statusId", status.pk);

		axiosConfig
			.post(likeStatus, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (props.onDislike && liked) {
					props.onDislike();
				}
				setLiked((prev) => !prev);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const getShortUSerProfile = () => {
		let newUserid = status.author
			? status.author.pk
			: status.pk || status.author
			? status.author.userID
			: status.userID;
		if (newUserid === user.userID) {
			return;
		}
		if (Object.keys(user).length > 0) {
			return;
		}
		const request = new FormData();

		request.append(
			"userId",
			status.author
				? status.author.pk
				: status.pk || status.author
				? status.author.userID
				: status.userID
		);
		setUserLoading(true);
		axiosConfig
			.post(getShortUserProfileUrl, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUserLoading(false);
				setUser(res.data.body);
			})
			.catch((err) => {
				setUserLoading(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						setUser({ error: true });
					}
				}
			});
	};

	const viewChat = () => {
		if (props.suggestionId) {
			if (props.suggestionId === status.pk) {
				history.push(`/chat/group/${status.pk}`);
			} else {
				if (props.setThoughtContent) {
					props.setThoughtContent(status.content);
				}
				// props.handleMobileSuggestions(true)
				props.setSuggestionId(status.pk);
			}
		} else {
			history.push(`/chat/group/${status.pk}`);
		}
	};

	const viewProfile = (e) => {
		e.stopPropagation();

		let id;
		if (status.author ? status.author.pk : status.pk) {
			id = status.author ? status.author.pk : status.pk;
		} else {
			id = status.author ? status.author.userID : status.userID;
		}

		if (userID === id) {
			history.push("/myprofile");
		} else if (paths === 1) {
			history.push("/myprofile");
		} else {
			history.push(`/profile/${id}`);
		}
	};

	const additionalUsers = status.userCount;

	let profileLink = status.author ? status.author.pk : status.pk;
	let mythought = false;
	if (
		paths === 2 && userID === status.author ? status.author.pk : status.pk
	) {
		mythought = true;
	}

	if (userID === status.author ? status.author.pk : status.pk) {
		profileLink = "/myprofile";
	} else if (paths === 1) {
		mythought = false;
		profileLink = "/myprofile";
	} else {
		profileLink =
			"/profile/" + status.author ? status.author.pk : status.pk;
	}

	const setBookmark = (folderId, statusId) => {
		if (userID === statusId) return;

		const data = new FormData();

		data.append("statusId", statusId);
		data.append("folderID", folderId);

		setUserLoading(true);

		axiosConfig
			.post(setStatusAsFavURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUserLoading(false);
			})
			.catch((err) => {
				setUserLoading(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						setUser({ error: true });
					}
				}
			});
	};

	const callTurnoff = (status) => {
		if (userID === (status.userId || status.author.pk)) return;

		const data = new FormData();
		data.append("user_id", status.author.pk || status.userId);
		setUserLoading(true);
		axiosConfig
			.post(TurnOffURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUserLoading(false);
				getTurnedOffUsers();
				if (
					turnedOffUsersList.filter((i) =>
						[status.author.pk].includes(i.pk)
					).length > 0
				) {
					showAlert("Turned on thoughts thoughts from user", {
						header: "Turned on",
						buttonText: "close",
					});
				} else {
					showAlert("Turned off thoughts thoughts from user", {
						header: "Turned off",
						buttonText: "close",
					});
				}
			})
			.catch((err) => {
				setUserLoading(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "close",
							}
						);
					} else {
						setUser({ error: true });
					}
				}
			});
	};

	return (
		<>
			<Grow
				duration="1000"
				in={mounted}
				exit={true}
				{...(mounted ? { timeout: props.idx * 100 } : {})}
			>
				<Card
					className={`${classes.card} statusCard`}
					style={cardStyles}
				>
					<CardContent
						style={background}
						classes={{ root: classes.content }}
					>
						<div className={Styles.heading}>
							<div className={Styles.heading}>
								<ChatUserReview
									id={
										status.userId
											? status.userId
											: status.author.pk
									}
								>
									<OnlineBadge
										invisible={false}
										last_active={
											status.author
												? status.author.last_active
												: status.last_active
										}
										online={
											status.author
												? status.author.online
												: status.online
										}
										ripple={true}
									>
										<Avatar
											style={{
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
												border:
													theme === "dark"
														? "2px solid #d9def4"
														: "",
											}}
											onClick={(e) => e.stopPropagation()}
											onMouseOver={
												Number(userID) !==
													Number(status.author.pk) &&
												getShortUSerProfile
											}
											src={
												status.author
													? status.author.avatar
													: status.avatar
											}
										>
											{(status.author
												? status.author.username
												: status.username ||
												  status.author
												? status.author.full_name
												: status.full_name) &&
												(status.author
													? status.author.username
													: status.username ||
													  status.author
													? status.author.full_name
													: status.full_name)[0].toUpperCase()}
										</Avatar>
									</OnlineBadge>
								</ChatUserReview>

								<p
									className={Styles.name}
									onClick={viewProfile}
								>
									<span
										className={`${Styles.username} ${
											theme === "dark"
												? Styles.darkText
												: Styles.lgText
										}`}
									>
										<Username
											value={
												status.author
													? status.author.username
													: status.username
											}
										/>
									</span>
								</p>
							</div>
							{props.cta ? (
								<>{props.cta}</>
							) : (
								<>
									{status.author.pk === userID ? (
										<div>
											<SidebarContextMenu
												transformOrigin={{
													vertical: "top",
													horizontal: "left",
												}}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "center",
												}}
												listStyles={{ padding: 0 }}
												icon={(props) => (
													<IconButton
														size="small"
														{...props}
													>
                                                        {status.current_status !== 'deleted' &&
                                                            <>
                                                                {theme === "dark" ? (
                                                                    <img
                                                                        src="/cards-img/more-dark.png"
                                                                        alt=""
                                                                    />
                                                                    ) : (
                                                                    <img
                                                                        src="/cards-img/more-lg.png"
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </>
                                                        }
													</IconButton>
												)}
											>
												<button
													className={`${Styles.popoverbtn} popoverbtn`}
													style={{
														color:
															theme === "dark"
																? "#D9DEF4"
																: "#0D0F22",
													}}
													onClick={() => {
														props.onEdit(status);
													}}
												>
													Edit
												</button>
												<button
													className={`${Styles.popoverbtn} popoverbtn`}
													style={{
														color:
															theme === "dark"
																? "#D9DEF4"
																: "#0D0F22",
													}}
													onClick={() => {
														props.onDelete(status.pk);
														setOpenAlert(true);
														setAlertMsg(
															"Are you sure you want to delete the thought?"
														);
														setAlertHeader(
															"Delete Thought"
														);
													}}
												>
													Delete
												</button>
												<button
													className={`${Styles.popoverbtn} popoverbtn`}
													style={{
														color:
															theme === "dark"
																? "#D9DEF4"
																: "#0D0F22",
													}}
													onClick={() => {
														props.onDeactivate(
															status.pk
														);
													}}
												>
													{status.current_status ===
													"active" ? (
														<FormattedMessage
															id="deactive"
															defaultMessage={`Deactivate`}
														/>
													) : (
														<FormattedMessage
															id="activate"
															defaultMessage={`Activate`}
														/>
													)}
												</button>
											</SidebarContextMenu>
										</div>
									) : (
										<div>
											{status.author.pk !== userID && (
												<SidebarContextMenu
													transformOrigin={{
														vertical: "top",
														horizontal: "left",
													}}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "center",
													}}
													listStyles={{ padding: 0 }}
													icon={(props) => (
														<IconButton
															size="small"
															{...props}
														>
															{theme ===
															"dark" ? (
																<img
																	src="/cards-img/more-dark.png"
																	alt=""
																/>
															) : (
																<img
																	src="/cards-img/more-lg.png"
																	alt=""
																/>
															)}
														</IconButton>
													)}
												>
													<button
														className={
															Styles.popoverbtn
														}
														onClick={() =>
															setShowBookmark(
																true
															)
														}
														style={{
															color:
																theme === "dark"
																	? "#D9DEF4"
																	: "#0D0F22",
														}}
													>
														Save
														<p
															className={
																Styles.popoverCap
															}
															style={{
																color:
																	theme ===
																	"dark"
																		? "#7F89BE"
																		: "#4A517E",
															}}
														>
															Save thoughts to
															list
														</p>
													</button>
													<button
														className={
															Styles.popoverbtn
														}
														style={{
															color:
																theme === "dark"
																	? "#D9DEF4"
																	: "#0D0F22",
														}}
														onClick={() => {
															setOpenAlert(true);

															if (
																turnedOffUsersList.filter(
																	(i) =>
																		[
																			status
																				.author
																				.pk,
																		].includes(
																			i.pk
																		)
																).length > 0
															) {
																setAlertHeader(
																	"Turn on"
																);
																setAlertMsg(
																	"Are you sure you want to turn on the thoughts from the user?"
																);
															} else {
																setAlertHeader(
																	"Turn off"
																);
																setAlertMsg(
																	"Are you sure you want to turn off the thoughts from the user?"
																);
															}
														}}
													>
														{turnedOffUsersList.filter(
															(i) =>
																[
																	status
																		.author
																		.pk,
																].includes(i.pk)
														).length > 0
															? "Turn on"
															: "Turn off"}
														<p
															className={
																Styles.popoverCap
															}
															style={{
																color:
																	theme ===
																	"dark"
																		? "#7F89BE"
																		: "#4A517E",
															}}
														>
															{turnedOffUsersList.filter(
																(i) =>
																	[
																		status
																			.author
																			.pk,
																	].includes(
																		i.pk
																	)
															).length > 0
																? "Turn on thoughts from User"
																: "Turn off thoughts from User"}
														</p>
													</button>
												</SidebarContextMenu>
											)}
										</div>
									)}
								</>
							)}
						</div>

						<Typography
							onClick={viewChat}
							style={{
								fontFamily: "Poppins",
								color: theme === "dark" ? "#d9def4" : "#0D0F22",
							}}
							className={` content ${
								status.content.length <= 90
									? status.content.length < 32
										? Styles.content2
										: Styles.content1
									: Styles.content
							}`}
						>
							{status.content}
						</Typography>

						{status.background_image && (
							<ImagePopover
								background_image={status.background_image}
							>
								<img
									src={status.background_image}
									width="48px"
									height="32px"
									className={Styles.image}
									alt=""
								/>
							</ImagePopover>
						)}
					</CardContent>

					<CardContent className={classes.footer}>
						{status.profiles !== undefined
							? status.profiles && (
									<div className={`${Styles.users} users`}>
										<IconButton size="small">
											{theme === "dark" ? (
												<img
													src="/cards-img/users-dark.png"
													alt=""
												/>
											) : (
												<img
													src="/cards-img/users-lg.png"
													alt=""
												/>
											)}
										</IconButton>
										<span
											className={Styles.countText}
											style={{
												cursor: "pointer",
												color:
													theme === "dark"
														? "#d9def4"
														: "#0D0F22",
											}}
											onClick={viewJoined(status.pk)}
										>
											{additionalUsers === 1
												? "1 user"
												: additionalUsers + " users"}
										</span>
									</div>
							  )
							: status.author !== undefined && (
									<div className={`${Styles.users} users`}>
										<IconButton size="small">
											{theme === "dark" ? (
												<img
													src="/cards-img/users-dark.png"
													alt=""
												/>
											) : (
												<img
													src="/cards-img/users-lg.png"
													alt=""
												/>
											)}
										</IconButton>
										<span
											className={Styles.countText}
											style={{
												cursor: "pointer",
												color:
													theme === "dark"
														? "#d9def4"
														: "#0D0F22",
											}}
											onClick={viewJoined(status.pk)}
										>
											{additionalUsers === 1
												? "1 user"
												: additionalUsers + " users"}
										</span>
									</div>
							  )}

						<div className={`${Styles.footerRight} fRight`}>
							<div className={Styles.likes}>
								<IconButton
									size="small"
									onClick={setStatusLike}
									className="p-0 pr-1"
								>
									{liked ? (
										<FavFilledIcon
											width="14px"
											// height="12"
										/>
									) : theme === "dark" ? (
										<img
											src="/cards-img/Heart.svg"
											alt=""
											style={{ width: "14px" }}
										/>
									) : (
										<img
											src="/cards-img/heart-lg.png"
											alt=""
										/>
									)}
								</IconButton>
								<span
									className={Styles.countText}
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0D0F22",
										cursor: "pointer",
									}}
									onClick={viewLikers(status.pk)}
								>
									{formatNumber(
										!status.is_liked
											? liked
												? status.total_likes + 1
												: status.total_likes
											: liked
											? status.total_likes
											: status.total_likes - 1
									)}
								</span>
							</div>

							<div className={Styles.comments}>
								<IconButton
									size="small"
									className="p-0 pr-1"
									onClick={viewChat}
								>
									{theme === "dark" ? (
										<img
											src="/cards-img/comment.svg"
											alt=""
											style={{ width: "16px" }}
										/>
									) : (
										<img
											src="/cards-img/chat-lg.png"
											alt=""
										/>
									)}
								</IconButton>
								<span
									className={Styles.countText}
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0D0F22",
									}}
								>
									{formatNumber(status.message_count)}
								</span>
							</div>

							<div className={Styles.date}>
								<IconButton className="p-0 pr-1" size="small">
									{theme === "dark" ? (
										<img
											src="/cards-img/Clock.svg"
											alt=""
											style={{ width: "16px" }}
										/>
									) : (
										<img
											src="/cards-img/clock-lg.png"
											alt=""
										/>
									)}
								</IconButton>
								<span
									className={Styles.countText}
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0D0F22",
									}}
								>
									{timeConverter(lastUpdatedAt)}
								</span>
							</div>
						</div>
					</CardContent>

					<Alert
						show={openAlert}
						header={alertHeader}
						message={alertMsg}
						hideAlert={() => setOpenAlert(false)}
						callback={() => {
							if (alertHeader === "Delete thought")
								props.onDelete(status.pk);
							else callTurnoff(status);
						}}
						buttonText="Confirm"
						linkText="Cancel"
						linkClick={() => {
							setOpenAlert(false);
						}}
					/>

					<Dialog
						width="xs"
						open={showBookmark}
						onClose={() => {
							setShowBookmark(false);
						}}
						maxWidth="false"
					>
						<AddToBookmarksContent
							// isBookmarked={props.isBookmarked}
							folderID={props.folderID}
							// variant={props.variant}
							statusID={status.pk}
							onBookmarkStateChange={(state) => {
								props.onBookmarkStateChange(state);
								setShowBookmark(false);
							}}
							onClose={()=>setShowBookmark(false)}
						/>
					</Dialog>
				</Card>
			</Grow>
			<LikersDialog
				show={!!likesStatusID}
				statusID={likesStatusID}
				onClose={closeLikesDialog}
			/>
			<JoinedDialog
				show={!!joinedStatusID}
				statusID={joinedStatusID}
				onClose={closeJoinedDialog}
			/>
		</>
	);
};

export default withStyles(useStyles)(StatusCard);
