import Log from "../../../Utils/logger";
import {
	LOGOUT_REQUEST,
	TUTORIAL_COMPLETED,
	TUTORIAL_SET,
} from "../../Actions/actionTypes";

const initialState = {
	enabled: false,
	completed: false,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case TUTORIAL_SET:
			return {
				...state,
				enabled: action.payload.enabled,
			};
		case TUTORIAL_COMPLETED:
			return { ...state, completed: true };

		case LOGOUT_REQUEST:
			return initialState;

		default:
			return state;
	}
}
