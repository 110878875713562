import styles from "./NotificationSettings.module.css";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import debounce from "lodash/debounce";
import CheckedIcon from "../../Icons/CheckedIcon";
import CheckedBorder from "../../Icons/CheckedBorder";
import { toggleNotificationsURL } from "../../Utils/Constants";
import axiosConfig from "../../Utils/axiosConfig";
import withAlert from "../Extras/Alert/withAlert";
import { setTheme } from "../../Redux/Actions/themeAction";
import { connect } from "react-redux";
const isMobile = window.screen.width < 768;
const types = {
	friend_request_sent: 1,
	create_status: 2,
	like: 3,
	group_joined: 4,
	message: 5,
	liked_message: 6,
	remove_from_thought: 7,
	reply_to_message: 8,
	friend_request_accepted: 9,
	group_request: 10,
	admin_accepted_group_join_request: 11,
	make_subadmin: 12,
	subadmin_notifies_admin_on_accepting_request: 13,
	initial: "",
};

class NotificationSettings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			friend_request_accepted: true,
			remove_from_thought: true,
			friend_request_sent: true,
			create_status: true,
			liked_message: true,
			group_request: true,
			admin_accepted_group_join_request: true,
			make_subadmin: true,
			subadmin_notifies_admin_on_accepting_request: true,
			group_joined: true,
			message: true,
			like: true,
			reply_to_message: true,
			isLoading: false,
		};
	}
	componentDidMount() {
		this.settingsToggle("initial");
	}

	handleChange = (event) => {
		this.settingsToggle(event);
	};

	settingsToggle = debounce((event) => {
		this.toggleNotification1(event);
	}, 200);

	toggleNotification1 = (name) => {
		const form = new FormData();
		form.append("notification_type_id", types[name]);
		axiosConfig
			.post(toggleNotificationsURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.body.length > 1)
					res.data.body.forEach((el) => {
						this.setState({ [el.name]: el.choice });
					});
				else {
					this.setState({
						[res.data.body.name]: res.data.body.choice,
					});
				}
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						this.props.showAlert(err.message, {
							header: err.error,
							buttonText: "Try Again",
						});
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	render() {
		const textColorClass =
			this.props.theme === "dark" ? styles.darkText : styles.lgText;

		return (
			<div>
				{!isMobile && (
					<div className={styles.header}>
						<h2 className={textColorClass}>
							{/* <FormattedMessage
								id="notificationSettings"
								defaultMessage={`Notification Settings`}
							/> */}
							Notification Settings
						</h2>
					</div>
				)}
				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.handleChange("friend_request_sent")
							}
						>
							{this.state.friend_request_sent ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Connection requests
					</h4>
				</div>
				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.handleChange("friend_request_accepted")
							}
						>
							{this.state.friend_request_accepted ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Connection request accepted
					</h4>
				</div>

				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.handleChange(
									"admin_accepted_group_join_request"
								)
							}
						>
							{this.state.admin_accepted_group_join_request ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Admin accepted group join request
					</h4>
				</div>

				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => this.handleChange("make_subadmin")}
						>
							{this.state.make_subadmin ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Make subadmin
					</h4>
				</div>

				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.handleChange(
									"subadmin_notifies_admin_on_accepting_request"
								)
							}
						>
							{this.state
								.subadmin_notifies_admin_on_accepting_request ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Subadmin notifies admin on accepting request
					</h4>
				</div>

				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => this.handleChange("group_request")}
						>
							{this.state.group_request ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Group request
					</h4>
				</div>

				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => this.handleChange("create_status")}
						>
							{this.state.create_status ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Thoughts from my connection
					</h4>
				</div>
				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => this.handleChange("group_joined")}
						>
							{this.state.group_joined ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Joined a group
					</h4>
				</div>
				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => this.handleChange("like")}
						>
							{this.state.like ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Likes on my thoughts
					</h4>
				</div>
				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => this.handleChange("message")}
						>
							{this.state.message ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Recieved a message
					</h4>
				</div>

				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() => this.handleChange("liked_message")}
						>
							{this.state.liked_message ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Liked a message
					</h4>
				</div>

				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.handleChange("remove_from_thought")
							}
						>
							{this.state.remove_from_thought ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Removed from a thought
					</h4>
				</div>
				<div className={styles.toggleContainer}>
					<div className={styles.select}>
						<span
							style={{ cursor: "pointer" }}
							onClick={() =>
								this.handleChange("reply_to_message")
							}
						>
							{this.state.reply_to_message ? (
								<CheckedIcon />
							) : (
								<CheckedBorder
									color={
										this.props.theme === "dark"
											? "#d9def4"
											: "#4A517E"
									}
								/>
							)}
						</span>
					</div>
					<h4 className={`${styles.toggleText} ${textColorClass}`}>
						Reply to a message
					</h4>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	theme: state.theme.theme,
});

export default withRouter(
	withAlert(connect(mapStateToProps, { setTheme })(NotificationSettings))
);
