import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { pure } from "recompose";
import axiosConfig from "../../../../Utils/axiosConfig";
import {
	blockUnblock,
	getFriendsList,
	unFriend,
	userReport,
} from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import EmptyState from "../../../Extras/EmptyState";
import SmallCardSkeleton from "../../../Skeleton/SmallCardSkeleton/SmallCardSkeleton";
import styles from "../Contacts.module.css";
import FriendCard from "./FriendCard";
import { getCountInContact } from "./../../../../Redux/Actions/contactAction";
import { useDispatch } from "react-redux";

const Friends = (props) => {
	const [alert, setAlert] = useState(false);
	const [alertMessage, setAlertMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [friendList, setFriendList] = useState([]);
	const [isFriendLoaded, setIsFriendLoaded] = useState(false);
	const { showAlert } = useAlert();

	const dispatch = useDispatch();
	useEffect(() => {
		if (isFriendLoaded) {
			return;
		}
		setIsLoading(true);
		const request = new FormData();
		axiosConfig
			.get(getFriendsList, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setFriendList(res.data.result || res.data.body);
				dispatch(getCountInContact());
				setIsFriendLoaded(true);
				setIsLoading(false);
			})
			.catch((err) => {});
	}, [isFriendLoaded]);

	const blockFriend = (user) => {
		setAlert(false);
		let id = user.friend.pk;
		const request = new FormData();
		request.append("userId", `${id}`);

		axiosConfig
			.post(blockUnblock, request)

			.then((res) => {
				if (res.status !== 200) throw new Error();
				setFriendList(
					friendList.filter((friend) => friend.friend.pk !== id)
				);
				dispatch(getCountInContact());
			})
			.catch((err) => {
				if (err.response) {
					setAlert(true);
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const reportFriend = (user) => {
		let id = user.friend.pk;
		const request = new FormData();
		request.append("userId", id);
		setAlert(false);
		axiosConfig
			.post(userReport, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert(
					`We will take a look at @${user.friend.username}'s profile and take action if necessary`,
					{ header: "User Reported", buttonText: "Go Back" }
				);
				dispatch(getCountInContact());
			})
			.catch((err) => {
				if (err.response) {
					setAlert(true);
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const unFriendFriend = (user) => {
		let id = user.friend.pk;
		const request = new FormData();
		request.append("userId", id);
		setAlert(false);
		axiosConfig
			.post(unFriend, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setFriendList(
					friendList.filter((friend) => friend.friend.pk !== id)
				);
				dispatch(getCountInContact());
			})
			.catch((err) => {
				if (err.response) {
					setAlert(true);
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const displayFriends = (filteredFriends) => {
		const friendsByAlphabet = filteredFriends.reduce((acc, friend) => {
			const key = friend.friend.full_name[0].toUpperCase();
			return acc[key]
				? { ...acc, [key]: [...acc[key], friend] }
				: { ...acc, [key]: [friend] };
		}, {});
		return Object.keys(friendsByAlphabet)
			.sort()
			.map((key) => (
				<>
					{/* `      <div className={styles.sectionHeader}>{key}</div>` */}

					<div style={{ marginTop: "0.25rem" }}>
						{friendsByAlphabet[key].map((friend) => {
							return (
								<FriendCard
									friend={friend}
									onBlock={blockFriend}
									onUnfriend={unFriendFriend}
									onReport={reportFriend}
									closePopup={alert}
									key={friend.friend.pk}
									isMobile={isMobile}
								/>
							);
						})}
					</div>
				</>
			));
	};

	// const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
	const isMobile = window.screen.width < 768;
	const filterBySearchTerm = (searchText) =>
		friendList.filter((friend) =>
			friend.friend.full_name
				.toLowerCase()
				.includes(searchText?.toLowerCase())
		);
	if (isLoading) {
		return (
			<div className={styles.cards}>
				<SmallCardSkeleton width={!isMobile ? "90%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "90%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "90%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "90%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "90%" : "100%"} />
			</div>
		);
	} else {
		const filteredFriends = filterBySearchTerm(props.searchText);
		return (
			<>
				{filteredFriends.length > 0 ? (
					<div className={styles.alphabeticCards}>
						{displayFriends(filteredFriends)}
					</div>
				) : (
					<div className={styles.cards}>
						<EmptyState
							heading="Make New Friends!"
							className={styles.emptyState}
							imageUrl="/vectors/empty-states/emptyFriendsList.svg"
							subHeading="Looks like you don’t have any friends yet. Don’t be shy, connect with new people!"
						/>
					</div>
				)}
			</>
		);
	}
};

export default pure(Friends);
