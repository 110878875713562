import React, { PureComponent } from "react";
import { connect } from "react-redux";

import ArrowBack from "@material-ui/icons/ArrowBack";
import { withStyles } from "@material-ui/core/styles";

import StatusCard from "../../../Extras/StatusCard/StatusCard";
import CardSkeleton from "../../../Skeleton/CardSkeleton/CardSkeleton";

import { bdittoAPIv2 } from "../../../../Utils/axiosConfig";
import { getTagsSuggestionURLV2 } from "../../../../Utils/Constants";

import styles from "./TagsSuggestion.module.css";
import { countriesSelector } from "../../../../Redux/selectors/locationSelector";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import EmptyState from "../../../Extras/EmptyState";
import Arrows from "../../../../Icons/Arrows";

const isMobile = window.screen.width < 768;

const useStyles = (theme) => ({
	back: {
		color: "#ff5b5b",
		margin: "0 0 1rem  0",
	},
});

class TagsSuggestion extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			TagsSuggestion: [],
			tagName: null,
			tagId: null,
			isLoading: false,
		};
	}

	componentDidMount() {
		this.updateState();
		this.fetchTagSuggestions(this.props.match.params.tagId);
	}

	componentDidUpdate = (prevProps) => {
		if (
			prevProps.match.params.tagId !== this.props.match.params.tagId ||
			prevProps.countries !== this.props.countries
		) {
			this.updateState();
			this.fetchTagSuggestions(this.props.match.params.tagId);
		}
	};

	updateState = () => {
		this.setState({
			tagName: this.props.match.params.tagName,
			tagId: this.props.match.params.tagId,
		});
	};

	fetchTagSuggestions = (tagId) => {
		const { countries } = this.props;
		this.setState({ isLoading: true });

		const data = {
			status: "Active",
			hashtagID: tagId,
			location: countries,
			groupId: this.props.groupDataFromStore.id,
		};

		let url = getTagsSuggestionURLV2 + "?limit=5&offset=0";

		bdittoAPIv2
			.post(url, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					TagsSuggestion: res.data.result || res.data.body,
					isLoading: false,
				});
			})
			.catch((err) => {
				this.setState({ isLoading: false });
			});
	};

	displayHashtag() {
		return (
			<div className={styles.bredcrumb}>
				<h3 className={styles.selectBread}># {this.props.tagName}</h3>
			</div>
		);
	}

	displayTagsSuggestion() {
		if (this.state.isLoading) {
			return (
				<>
					<CardSkeleton width="20.5rem" />
					<CardSkeleton width="20.5rem" />
					<CardSkeleton width="20.5rem" />
					<CardSkeleton width="20.5rem" />
					<CardSkeleton width="20.5rem" />
					<CardSkeleton width="20.5rem" />
					<CardSkeleton width="20.5rem" />
					<CardSkeleton width="20.5rem" />
				</>
			);
		} else
			return (
				<>
					{this.state.TagsSuggestion.length ? (
						this.state.TagsSuggestion.map((item, index) => {
							return (
								<StatusCard
									status={item}
									key={item.pk}
									idx={index % 16}
									width="20.5rem"
									height="14rem"
								/>
							);
						})
					) : (
						<EmptyState
							heading="No Matches Yet!"
							imageUrl="/vectors/empty-states/emptySuggestions.svg"
							subHeading="Looks like the hashtag has no matches yet, but don’t worry you’ll find your perfect match soon too! "
						/>
					)}
				</>
			);
	}

	render() {
		return (
			<div className={styles.mainContainer}>
				<MediaQuery maxDeviceWidth={600}>
					{!isMobile && (
						<Link to="/tags" className={styles.back}>
							<IconButton size="small">
								<ArrowBack
									className={this.props.classes.back}
								/>
							</IconButton>
						</Link>
					)}
				</MediaQuery>
				<div className={styles.bredcrumb}>
					<IconButton
						size="small"
						variant="contained"
						style={{
							marginLeft: "0.2rem",
							marginRight: "0.9rem",
							marginTop: "-.4rem",
						}}
						onClick={() =>
							isMobile
								? this.props.history.push(
										"/explore/thoughts/trending"
								  )
								: this.props.history.push(
										"/explore/thoughts/all"
								  )
						}
					>
						{/* <Arrows.Left viewBox="0 0 27 27" /> */}
						<img src="/Home/ArrowRight.png" alt="" />
					</IconButton>{" "}
					<h3 className={styles.selectBread}>
						&nbsp;{this.state.tagName}
					</h3>
				</div>
				<div className={styles.statusDisplay}>
					{this.displayTagsSuggestion()}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	countries: countriesSelector(state),
	groupDataFromStore: state.groups.groupData,
});

export default connect(mapStateToProps)(withStyles(useStyles)(TagsSuggestion));
