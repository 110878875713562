import { GET_MY_GROUPS, UPDATE_MY_GROUPS,UPDATE_MY_SINGLE_GROUP } from "./actionTypes";

export const getMyGroups = (payload) => {
	return {
		type: GET_MY_GROUPS,
		payload,
	};
};

export const updateMyGroups = (payload) => {
	return {
		type: UPDATE_MY_GROUPS,
		payload,
	};
};

export const updateMySingleGroup = (payload) => {
    console.log(payload)
	return {
		type: UPDATE_MY_SINGLE_GROUP,
		payload,
	};
};
