import {
	Button,
	CircularProgress,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Close } from "../../../../Icons";
import { EditFolder } from "../../../../Redux/Actions/bookmarks/folderActions";
import axiosConfig from "../../../../Utils/axiosConfig";
import { foldersAPI } from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import DialogModal from "../../../Extras/DialogModal";
import styles from "./NewFolderDialog.module.scss";

const EditFolderDialog = ({ show, onClose, folderID, ...props }) => {
	const [folderName, setFoldername] = useState("");
	const [creating, setCreating] = useState(false);
	const dispatch = useDispatch();
	const { showAlert } = useAlert();
	const [error, setError] = useState("");
	const inputRef = useRef();
	useEffect(() => {
		if (show) {
			setTimeout(() => {
				inputRef.current.focus();
			}, 100);
		}
	}, [show]);
	const handleChange = (e) => {
		setError("");
		const value = e.target.value;
		setFoldername(value);
		if (value.length > 25) {
			setError("Very long folder name");
		}
	};
	const addFolder = async () => {
		if (!error) {
			if (folderName.length < 3) {
				setError("name is too short");
				return;
			}
			setCreating(true);
			try {
				const form = new FormData();
				form.append("foldername", folderName);
				form.append("ID", folderID);
				await axiosConfig.post(foldersAPI.ADD_UPDATE_FOLDER, form);
				setCreating(false);
				dispatch(EditFolder({ id: folderID, foldername: folderName }));
				onClose();
			} catch (err) {
				setCreating(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert(err.response.data.message, {
							header: err.response.data.error,
							buttonText: "Close",
						});
					}
				} else {
					showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			}
		}
	};
	return (
		<DialogModal
			width="xs"
			show={show}
			className={styles.dialog}
			onHide={onClose}
			maxWidth="false"
		>
			<IconButton className={styles.closeButton} onClick={onClose}>
				<Close.Outline />
			</IconButton>
			<DialogTitle className={styles.title}>Rename Folder</DialogTitle>
			<DialogContent>
				<div className={styles.content}>
					<TextField
						inputRef={inputRef}
						error={!!error}
						helperText={error}
						id="standard-basic"
						label="Folder name"
						onChange={handleChange}
					/>
					<div className={styles.buttonContainer}>
						<Button
							endIcon={
								creating && (
									<CircularProgress
										size="0.9rem"
										style={{ color: "white" }}
									/>
								)
							}
							onClick={addFolder}
							fullWidth={true}
							className={styles.button}
							color="primary"
							variant="contained"
						>
							Rename Folder
						</Button>
					</div>
				</div>
			</DialogContent>
		</DialogModal>
	);
};

export default EditFolderDialog;
