export function User3({ theme }) {
	const color = theme === "dark" ? "#D9DEF4" : "#0D0F22";

	return (
		<svg
			width="26"
			height="21"
			viewBox="0 0 26 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M19.8702 10.1734C19.4409 10.1734 19.0664 9.85724 19.0046 9.41974C18.9381 8.94024 19.2706 8.49807 19.7501 8.43157C20.9634 8.26124 21.8792 7.20891 21.8816 5.98157C21.8816 4.76474 21.0089 3.73574 19.8061 3.53857C19.3301 3.46041 19.0069 3.01007 19.0851 2.53291C19.1644 2.05574 19.6112 1.73607 20.0907 1.81191C22.1417 2.14907 23.6316 3.90374 23.6316 5.98391C23.6269 8.07807 22.0624 9.87474 19.9916 10.1652C19.9507 10.1711 19.9099 10.1734 19.8702 10.1734Z"
				fill={color}
			/>
			<mask
				id="mask0"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="21"
				y="12"
				width="5"
				height="6"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M21.1406 12.3359H25.5669V17.6204H21.1406V12.3359Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M23.3378 17.6204C22.9854 17.6204 22.6529 17.4058 22.5199 17.0569C22.3473 16.6054 22.5748 16.0991 23.0263 15.9276C23.8173 15.6266 23.8173 15.2941 23.8173 15.1343C23.8173 14.6233 23.1674 14.2663 21.8864 14.0749C21.4093 14.0026 21.0791 13.5581 21.1503 13.0786C21.2214 12.6003 21.6648 12.2818 22.1466 12.3436C24.9734 12.7671 25.5673 14.0948 25.5673 15.1343C25.5673 15.7689 25.3176 16.9274 23.6493 17.5633C23.5466 17.6018 23.4416 17.6204 23.3378 17.6204Z"
					fill={color}
				/>
			</g>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.8701 14.5664C10.0981 14.5664 6.79297 14.9071 6.79297 16.5287C6.79297 18.1632 10.0981 18.5062 12.8701 18.5062C15.6421 18.5062 18.9461 18.1667 18.9461 16.5486C18.9461 14.9106 15.6421 14.5664 12.8701 14.5664ZM12.8701 20.2562C10.9346 20.2562 5.04297 20.2562 5.04297 16.5287C5.04297 12.8164 10.9346 12.8164 12.8701 12.8164C14.8056 12.8164 20.6961 12.8164 20.6961 16.5486C20.6961 20.2562 15.011 20.2562 12.8701 20.2562Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.8684 2.41797C10.8944 2.41797 9.28911 4.02447 9.28911 5.99847C9.28561 6.95514 9.65194 7.84997 10.3228 8.52547C10.9936 9.20097 11.8873 9.57547 12.8393 9.57897L12.8684 10.454V9.57897C14.8424 9.57897 16.4489 7.97364 16.4489 5.99847C16.4489 4.02447 14.8424 2.41797 12.8684 2.41797ZM12.8684 11.329H12.8369C11.4136 11.3243 10.0801 10.7666 9.08144 9.75863C8.08044 8.7518 7.53328 7.4148 7.53911 5.99497C7.53911 3.05964 9.92961 0.667969 12.8684 0.667969C15.8084 0.667969 18.1989 3.05963 18.1989 5.99847C18.1989 8.9373 15.8084 11.329 12.8684 11.329Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.86954 10.1736C5.82988 10.1736 5.78904 10.1713 5.74821 10.1655C3.67738 9.87496 2.11404 8.07829 2.10938 5.98646C2.10938 3.90396 3.59921 2.14929 5.65021 1.81213C6.14138 1.73513 6.57654 2.05829 6.65588 2.53313C6.73404 3.01029 6.41088 3.46063 5.93488 3.53879C4.73204 3.73596 3.85938 4.76496 3.85938 5.98413C3.86171 7.20913 4.77754 8.26263 5.98971 8.43179C6.46921 8.49829 6.80171 8.94046 6.73521 9.41996C6.67338 9.85746 6.29888 10.1736 5.86954 10.1736Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.39864 17.6202C2.2948 17.6202 2.1898 17.6015 2.08714 17.563C0.417635 16.926 0.167969 15.7675 0.167969 15.134C0.167969 14.0957 0.761802 12.7669 3.5898 12.3434C4.07164 12.2827 4.51264 12.6 4.58497 13.0784C4.65614 13.5579 4.32597 14.0024 3.8488 14.0747C2.5678 14.266 1.91797 14.623 1.91797 15.134C1.91797 15.2939 1.91797 15.6252 2.71014 15.9274C3.16164 16.0989 3.38914 16.6052 3.21647 17.0567C3.08347 17.4055 2.75097 17.6202 2.39864 17.6202Z"
				fill={color}
			/>
		</svg>
	);
}
