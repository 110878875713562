import { ReactComponent as FilledIcon } from "../assets/icons/chats/starFilled.svg";
import { ReactComponent as OutlineIcon } from "../assets/icons/chats/starOutline.svg";
import { ReactComponent as WhiteOutlineIcon } from "../assets/icons/starWhiteOutline.svg";
import genIcons from "./genIcons";

const Star = {
  Filled: genIcons(FilledIcon),
  Outline: genIcons(OutlineIcon, { htmlColor: "transparent", style: { width: "28px" }, viewBox: "0 0 27 27" }),
  WhiteOutline: genIcons(WhiteOutlineIcon),
};

export default Star;
