import { axiosBaseConfig } from "../../../Utils/axiosConfig";
import { getPendingRequests } from "../../../Utils/Constants";
import {
	isContactRequestsLoading,
	isContactRequestsPolled,
} from "../../selectors/ContactRequests";
import {
	FETCH_CONTACT_REQUESTS,
	LOADING_CONTACT_REQUESTS,
	ERROR_CONTACT_REQUESTS,
	REMOVE_CONTACT_REQUESTS,
} from "./types";

const setContactRequests = (requests) => ({
	type: FETCH_CONTACT_REQUESTS,
	payload: requests,
});

const setLoading = (value) => ({
	type: LOADING_CONTACT_REQUESTS,
	payload: value,
});
const setError = (error) => ({ type: ERROR_CONTACT_REQUESTS, payload: error });

export const fetchContactRequests = () => async (dispatch, getState) => {
	const state = getState();
	const isPolled = isContactRequestsPolled(state);
	const isLoading = isContactRequestsLoading(state);
	if (!isPolled && !isLoading) {
		dispatch(setLoading(true));
		try {
			const res = await axiosBaseConfig.post(getPendingRequests);

			if (res.status !== 200) throw new Error();
			dispatch(setContactRequests(res.data.result || res.data.body));
		} catch (err) {
			dispatch(setError("Something went wrong."));
		}
	}
};

export const removeContactRequest = (requestID) => {
	return { type: REMOVE_CONTACT_REQUESTS, payload: requestID };
};
