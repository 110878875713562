import React from "react";

const CloseIcon = (props) => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21.0013 4.66797H7.0013C5.71264 4.66797 4.66797 5.71264 4.66797 7.0013V21.0013C4.66797 22.29 5.71264 23.3346 7.0013 23.3346H21.0013C22.29 23.3346 23.3346 22.29 23.3346 21.0013V7.0013C23.3346 5.71264 22.29 4.66797 21.0013 4.66797Z"
				stroke={props.color || "#7F89BE"}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M16.3346 11.668L11.668 16.3346M11.668 11.668L16.3346 16.3346L11.668 11.668Z"
				// stroke="#7F89BE"
				stroke={props.color || "#7F89BE"}
				strokeWidth="2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default CloseIcon;
