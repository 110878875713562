import React, { useEffect, useState } from "react";
import { Badge, withStyles } from "@material-ui/core";
import { differenceInMinutes } from "date-fns";
const StyledBadge = withStyles((theme) => ({
	badge: {
		backgroundColor: (props) => props.color,
		color: (props) => props.color,
		boxShadow: "0 0 0 1px #fff",
		borderWidth: "1px",
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: (props) => props.ripple && "1px solid currentColor",
			content: '""',
		},
	},

	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}))(Badge);

const OnlineBadge = (props) => {
	const [statusColor, setStatusColor] = useState("");
	const [ripple, setRipple] = useState(true);
	useEffect(() => {
		if (props.last_active) {
			calculateStatus();
		} else {
			setStatusColor("#44b700");
		}
	}, [props]);

	const calculateStatus = () => {
		var timeDifference = differenceInMinutes(
			new Date(),
			new Date(props.last_active)
		);
		if (props.online) {
			setStatusColor("#44b700");
		}
		// else if (timeDifference < 1) {
		//   setStatusColor("#44b700")
		// }
		else if (timeDifference <= 15) {
			setStatusColor("orange");
			setRipple(false);
		} else if (timeDifference > 15) {
			setStatusColor("#df2929");
			setRipple(false);
		}
	};
	return (
		<StyledBadge
			overlap="circle"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			variant={props.variant || "dot"}
			color={statusColor}
			invisible={props.invisible}
			ripple={ripple}
		>
			{props.children}
		</StyledBadge>
	);
};

export default OnlineBadge;
