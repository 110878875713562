import React, { useState } from "react";

import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	withStyles,
} from "@material-ui/core";

import faqs from "../../Info/pages/faqContent";
import styles from "./FAQs.module.css";

const StyledAccordian = withStyles({ root: { background: "transparent" } })(
	Accordion
);
const StylesHead = withStyles({
	root: {
		background: "rgba(244, 244, 244,0.5)",
	},
})(AccordionSummary);

export default function FAQs() {
	const [expandedPanel, setExpandedPanel] = useState(false);

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpandedPanel(isExpanded ? panel : false);
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Navbar />
				<div className={styles.cont}>
					<div className={styles.yellowBox}></div>
					<h1 className={styles.heading}>FAQs</h1>
					{faqs.map((faq, index) => (
						<StyledAccordian
							expanded={expandedPanel === index}
							onChange={handleAccordionChange(index)}
						>
							<StylesHead>
								<div className={styles.popoverHead}>
									<div>{faq.head}</div>
									<div>
										<button className={styles.popoverBtn}>
											{expandedPanel !== "insdex" ? (
												<AddIcon />
											) : (
												<RemoveIcon />
											)}
										</button>
									</div>
								</div>
							</StylesHead>
							<AccordionDetails>
								<div>{faq.content}</div>
							</AccordionDetails>
						</StyledAccordian>
					))}

					<div className={styles.blueBox}></div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
