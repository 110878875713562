import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sidebar from "./ThoughtsSidebar/Sidebar";
import EmptyState from "../../Extras/EmptyState";
import Suggestions from "./Suggestions/Suggestions";
import Interests from "./../Interests/InterestSidebar";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GroupsSidebar from "../Groups/GroupsSidebar";
import GroupInfoModal from "../Groups/GroupModals/GroupInfoModal";
import { Avatar } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled, { css } from "styled-components";
// import AddStatusModal from "../../Extras/AddStatusModal/AddStatusModal";

import "./Home.scss";
import styles from "./Home.module.css";

const useStyles = (theme) => ({
	addStatus: {
		background: " linear-gradient(225deg, #FF5B5B 0%, #FC4249 100%)",
	},
});

class Home extends Component {
	constructor(props) {
		super(props);

		this.state = {
			suggestionId: null,
			tagId: null,
			tagName: null,
			thoughtsPresent: true,
			show: true,
			askTutorial: false,
			showActiveSuggesstions: true,
			groupInfo: false,
			groupsDisplay: false,
			activeTab: 1,
			showMobileSuggestions: false,
			thoughtContent: "",
		};
	}

	componentDidMount = () => {
		const { state: routerState } = this.props.location;
		let path = this.props.location;

		if (path.pathname.includes("/group-info")) {
			this.setState({ groupsDisplay: true });
		}

		if (routerState?.startTutorial) {
			this.setState({ askTutorial: true });
		}
	};

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.location !== prevState.location) {
			return { location: nextProps.location };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.location !== this.props.location) {
			let path = this.props.location;
			if (path.pathname.includes("/group-info")) {
				this.setState({ groupsDisplay: true });
			}
		}
	}

	setSuggestionId = (id) => {
		this.setState({ suggestionId: id });
	};

	setThoughtContent = (content) => {
		this.setState({ thoughtContent: content });
	};

	handleMobileSuggestions = (status) => {
		this.setState({ showMobileSuggestions: status });
	};

	onClickStatus = () => {
		if (window.screen.width < 600) {
			this.setState({ show: !this.state.show });
		}
	};

	handleShowActiveSuggestions = (status) => {
		if (status !== this.state.showActiveSuggesstions)
			this.setState({ showActiveSuggesstions: status });
	};

	setGroupInfo = () => {
		this.setState({ groupInfo: !this.state.groupInfo });
	};

	setGroupsDisplay = () => {
		this.setState({ groupsDisplay: !this.state.groupsDisplay });
	};
	render() {
		const { classes } = this.props;
		const isMobile = window.screen.width < 768;

		const tabs = [
			{
				id: 1,
				label: "My Thoughts",
			},
			{
				id: 2,
				label: "Interests",
			},
			{
				id: 3,
				label: "Deactivated",
			},
		];

		return (
			<>
				{!isMobile && (
					<div
						className={`${
							this.props.theme === "dark"
								? "theme--dark"
								: "theme--default"
						} home ${styles.homeContainer}`}
					>
						<div className={styles.center}>
							<div
								className={`left-sideBar ${styles.leftSideBar}`}
							>
								{(this.state.show || !isMobile) && (
									<StyledSidebarContainer
										className={styles.sidebarContainer}
										theme={this.props.theme}
										isGroupsExpanded={
											this.state.groupsDisplay
										}
									>
										<div className={styles.grpContainer}>
											<div
												className={styles.groupName}
												// onClick={() => this.setGroupInfo()}
											>
												<Avatar
													style={{
														width: "1.7em",
														height: "1.7em",
														background:
															this.props.theme ===
															"dark"
																? "#d9def4"
																: "#0d0f22",
														color:
															this.props.theme ===
															"dark"
																? "#0d0f22"
																: "#d9def4",
													}}
													src={
														this.props
															.groupDataFromStore
															?.avatar
													}
												>
													{this.props
														.groupDataFromStore
														?.name &&
														this.props.groupDataFromStore?.name[0]?.toUpperCase()}
												</Avatar>
												<span
													className={`${styles.grpname} grpname`}
												>
													{
														this.props
															.groupDataFromStore
															?.name
													}
												</span>
											</div>
											<div
												onClick={this.setGroupsDisplay}
											>
												{this.state.groupsDisplay ? (
													<ExpandMoreIcon
														style={{
															fontSize: "1.5rem",
															color:
																this.props
																	.theme ===
																"dark"
																	? "#D9DEF4"
																	: "#0d0f22",
															transform:
																"rotate(180deg)",
															cursor: "pointer",
														}}
													/>
												) : (
													<ExpandMoreIcon
														style={{
															fontSize: "1.5rem",
															color:
																this.props
																	.theme ===
																"dark"
																	? "#D9DEF4"
																	: "#0d0f22",
															cursor: "pointer",
														}}
													/>
												)}
											</div>
										</div>

										{!this.state.groupsDisplay ? (
											<Sidebar
												handleIsActive={
													this
														.handleShowActiveSuggestions
												}
												setThoughtsPresent={(
													isPresent
												) =>
													this.setState({
														thoughtsPresent:
															isPresent,
													})
												}
												setTutorialActive={(start) =>
													this.setState({
														askTutorial: start,
													})
												}
												askTutorial={
													this.state.askTutorial
												}
												suggestionId={
													this.state.suggestionId
												}
												setSuggestionId={
													this.setSuggestionId
												}
												onClickStatus={
													this.onClickStatus
												}
												fetchHashtags={
													this.props.fetchHashtags
												}
											/>
										) : (
											<GroupsSidebar
												myGrp={
													this.props
														.groupDataFromStore
														?.name
												}
												setGroupsDisplay={
													this.setGroupsDisplay
												}
												groupId={
													this.props
														.groupDataFromStore.id
												}
												top="true"
											/>
										)}
										<GroupInfoModal
											show={this.state.groupInfo}
											onHide={this.setGroupInfo}
											groupId={
												this.props.groupDataFromStore
													?.id
											}
										/>
									</StyledSidebarContainer>
								)}
							</div>
							<div
								className={`center-container ${styles.centerContainer}`}
							>
								{this.state.thoughtsPresent ? (
									<Suggestions
										handleIsActive={
											this.handleShowActiveSuggestions
										}
										showActiveSuggestions={
											this.state.showActiveSuggesstions
										}
										suggestionId={this.state.suggestionId}
										onMobileClick={this.onClickStatus}
									/>
								) : (
									<EmptyState
										heading="Post Your First Status!"
										imageUrl="/vectors/empty-states/emptyUserStatus.svg"
										subHeading="Post a status about anything that’s on your mind and get matched with people thinking about the same thing."
									/>
								)}
							</div>
							<div
								className={`${styles.rightSideBar} rightSideBar`}
							>
								{!isMobile && (
									<Interests
										fetchHashtags={this.props.fetchHashtags}
									/>
								)}
							</div>
							{/* <GroupInfo /> */}
						</div>
					</div>
				)}
				{isMobile && (
					<>
						{this.state.showMobileSuggestions && (
							<div>
								{this.state.thoughtsPresent ? (
									<Suggestions
										handleIsActive={
											this.handleShowActiveSuggestions
										}
										showActiveSuggestions={
											this.state.showActiveSuggesstions
										}
										suggestionId={this.state.suggestionId}
										onMobileClick={this.onClickStatus}
										handleMobileSuggestions={
											this.handleMobileSuggestions
										}
										thoughtContent={
											this.state.thoughtContent
										}
									/>
								) : (
									<EmptyState
										heading="Post Your First Status!"
										imageUrl="/vectors/empty-states/emptyUserStatus.svg"
										subHeading="Post a status about anything that’s on your mind and get matched with people thinking about the same thing."
									/>
								)}
							</div>
						)}

						{!this.state.showMobileSuggestions && (
							<>
								<div className={styles.thoughtsTab}>
									{tabs.map((tab, i) =>
										tab.id === this.state.activeTab ? (
											<div className={styles.activeTab}>
												{tab.label}
											</div>
										) : (
											<div
												onClick={() =>
													this.setState({
														activeTab: tab.id,
													})
												}
												className={styles.tab}
											>
												{tab.label}
											</div>
										)
									)}
								</div>
								<div className="d-flex flex-column align-items-center justify-content-center">
									{this.state.activeTab === 1 && (
										<>
											<Sidebar
												fetchHashtags={
													this.props.fetchHashtags
												}
												handleIsActive={
													this
														.handleShowActiveSuggestions
												}
												setThoughtsPresent={(
													isPresent
												) =>
													this.setState({
														thoughtsPresent:
															isPresent,
													})
												}
												setTutorialActive={(start) =>
													this.setState({
														askTutorial: start,
													})
												}
												askTutorial={
													this.state.askTutorial
												}
												suggestionId={
													this.state.suggestionId
												}
												setSuggestionId={
													this.setSuggestionId
												}
												onClickStatus={
													this.onClickStatus
												}
												handleMobileSuggestions={
													this.handleMobileSuggestions
												}
												setThoughtContent={
													this.setThoughtContent
												}
												type="Active"
											/>
										</>
									)}
									{this.state.activeTab === 2 && (
										<Interests
											fetchHashtags={
												this.props.fetchHashtags
											}
										/>
									)}
									{this.state.activeTab === 3 && (
										<>
											<Sidebar
												fetchHashtags={
													this.props.fetchHashtags
												}
												handleIsActive={
													this
														.handleShowActiveSuggestions
												}
												setThoughtsPresent={(
													isPresent
												) =>
													this.setState({
														thoughtsPresent:
															isPresent,
													})
												}
												setTutorialActive={(start) =>
													this.setState({
														askTutorial: start,
													})
												}
												askTutorial={
													this.state.askTutorial
												}
												suggestionId={
													this.state.suggestionId
												}
												setSuggestionId={
													this.setSuggestionId
												}
												onClickStatus={
													this.onClickStatus
												}
												type="Deactive"
											/>
										</>
									)}
								</div>
							</>
						)}
					</>
				)}
			</>
		);
	}
}

const StyledSidebarContainer = styled.div`
	${({ theme, isGroupsExpanded }) => {
		if (isGroupsExpanded) {
			return theme === "dark"
				? css`
						background: #0d0f22;
						box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
				  `
				: css`
						background: white;
						box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
				  `;
		}
	}}
`;

const mapStateToProps = (state) => ({
	groupDataFromStore: state.groups?.groupData,
});

const mapDispatchToProps = (dispatch) => ({
	// resetUserData: (payload) => dispatch(resetUserData(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(useStyles)(withRouter(Home)));
