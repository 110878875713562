import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import styles from "./contentPolicy.module.css";

export default function ContentPolicy() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Navbar />
				<div className={styles.cont}>
					<div className={styles.yellowBox}></div>
					<h1 className={styles.heading}>CONTENT POLICY</h1>

					<p className={styles.content}>
						We created Bditto (A Bharg Technologies GmbH company) to
						help people come together to make things happen. We
						expect users will have a great experience meeting with
						millions of diverse communities and connect with old
						friends and new. We can't wait to see what's next.
					</p>
					<p className={styles.content}>
						Our guidelines are meant to explain what is and isn’t
						allowed on Bditto and ensure that everyone has a good
						experience. If you come across a message that appears to
						break these rules, please report it to us. User
						communities should create a sense of belonging for their
						members, not try to diminish it for others. Likewise,
						everyone on Bditto should have an expectation of privacy
						and safety, so please respect the privacy and safety of
						others.We may take a number of steps, including issuing
						a warning, removing the content, or removing the
						accounts responsible.
					</p>
					<p className={styles.content}>
						Bditto and its communities are only what we make of them
						together and can only exist if we operate by a shared
						set of rules. We ask that you abide by not just the
						letter of these rules, but the spirit as well.
					</p>
					<p className={styles.content}>
						We want to be clear about the expectations for our
						users. Every user of Bditto should feel like their voice
						can be heard, but not at the expense of someone else.
					</p>

					<h2 className={styles.subHeading}>
						HERE ARE SOME RULES FOR INTERACTING WITH OTHERS:
					</h2>
					<p className={styles.content}>
						<ol>
							<li>
								<b>
									Do not organize, participate in, or
									encourage harassment of others.
								</b>{" "}
								Disagreements happen and are normal, but
								continuous, repetitive, or severe negative
								comments may cross the line into harassment and
								are not okay.
							</li>
							<li>
								<b>
									Do not organize, promote, or coordinate
									groups around hate speech.
								</b>{" "}
								It’s unacceptable to attack a person or a
								community based on attributes such as their
								race, ethnicity, national origin, sex, gender,
								sexual orientation, religious affiliation, or
								disabilities.
							</li>
							<li>
								<b>
									Do not make threats of violence or threaten
									to harm others.
								</b>{" "}
								This includes indirect threats, as well as
								sharing or threatening to share someone’s
								private personal information (also known as
								doxxing).
							</li>
							<li>
								<b>Do not evade user blocks.</b> Do not send
								unwanted, repeated friend requests or messages,
								especially after they’ve made it clear they
								don’t want to talk to you anymore. Do not try to
								hide your identity in an attempt to contact
								someone who has blocked you, or otherwise
								circumvent the tools we have which enable users
								to protect themselves.
							</li>
							<li>
								<b>Do not send others viruses or malware,</b>{" "}
								attempt to phish others, or hack or DDoS them.
							</li>
						</ol>
					</p>

					<h2 className={styles.subHeading}>
						HERE ARE SOME RULES FOR CONTENT ON BDITTO:
					</h2>
					<p className={styles.content}>
						<ol start="6">
							<li>
								<b>
									You must apply the #NSFW label to statuses
									if there is adult content in that channel.
								</b>{" "}
								You may not share or post any sexually explicit
								or pornographic content.
							</li>
							<li>
								<b>You may not sexualize minors in any way.</b>{" "}
								This includes sharing content or links which
								depict minors in a pornographic, sexually
								suggestive, or violent manner, and includes
								illustrated or digitally altered pornography
								that depicts minors (such as lolicon, shotacon,
								or cub). We report illegal content to the{" "}
								<a
									target="_blank"
									href="https://www.missingkids.org/gethelpnow/cybertipline"
								>
									National Center for Missing and Exploited
									Children.
								</a>
							</li>
							<li>
								<b>
									You may not share sexually explicit content
									of other people without their consent,
								</b>{" "}
								or share or promote sharing of non-consensual
								intimate imagery (also known as revenge porn) in
								an attempt to shame or degrade someone.
							</li>
							<li>
								<b>
									You may not share content that glorifies or
									promotes suicide or self-harm,{" "}
								</b>
								including any encouragement to others to cut
								themselves, or embrace eating disorders such as
								anorexia or bulimia.
							</li>
							<li>
								<b>
									You may not share images of sadistic gore or
									animal cruelty.
								</b>
							</li>
							<li>
								<b>
									You may not use Bditto for the organization,
									promotion, or support of violent extremism.
								</b>
							</li>
							<li>
								<b>
									You may not operate or engage in any
									discussion that sells or facilitates the
									sales of prohibited or potentially dangerous
									goods.
								</b>
								This includes firearms, ammunition, drugs, and
								controlled substances.
							</li>
							<li>
								<b>
									You may not promote, distribute, or provide
									access to content involving the hacking,
									cracking, or distribution of pirated
									software or stolen accounts.
								</b>
								This includes sharing or selling cheats or hacks
								that may negatively affect others in multiplayer
								games.
							</li>
							<li>
								In general,
								<b>
									you should not promote, encourage or engage
									in any illegal behavior.
								</b>
								This is very likely to get you kicked off
								Bditto, and may get you reported to law
								enforcement.
							</li>
						</ol>
					</p>

					<h2 className={styles.subHeading}>
						FINALLY, WE ASK THAT YOU RESPECT BDITTO ITSELF:
					</h2>
					<p className={styles.content}>
						<ol start="15">
							<li>
								<b>You may not sell your account.</b>
							</li>
							<li>
								<b>
									You may not use self-bots or user-bots to
									access Bditto.
								</b>
							</li>
							<li>
								<b>
									You may not share content that violates
									anyone's intellectual property or other
									rights.
								</b>{" "}
							</li>
							<li>
								<b>You may not spam Bditto, </b> especially our
								Customer Support and Trust & Safety teams.
								Making false and malicious reports, sending
								multiple reports about the same issue, or asking
								a group of users to all report the same content
								may lead to action being taken on your account.
							</li>
						</ol>
					</p>

					<p className={styles.content}>
						If you see any activity that violates these guidelines,
						you can report it to us at{" "}
						<a href="mailto:admin@bditto.com">admin@bditto.com</a>
					</p>
					<h2 className={styles.subHeading}>Enforcement</h2>
					<p className={styles.content}>
						We have a variety of ways of enforcing our rules,
						including, but not limited to
						<ul>
							<li>Asking you nicely to knock it off</li>
							<li>Asking you less nicely</li>
							<li>
								Temporary or permanent suspension of content and
								accounts
							</li>
							<li>
								Removal of privileges from, or adding
								restrictions to, accounts
							</li>
							<li>
								Adding restrictions to Bditto group chats, such
								as adding NSFW tags
							</li>
							<li>Removal of content</li>
							<li>Banning of Bditto statuses and groups</li>
						</ul>
					</p>
				</div>
				<div className={styles.blueBox}></div>
				<Footer />
			</div>
		</div>
	);
}
