/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { pure } from "recompose";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Avatar from "@material-ui/core/Avatar";
import {
	CircularProgress,
	IconButton,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import chatConfig from "../../../../../Utils/axiosChatConfig";
import { getMediaByID } from "../../../../../Utils/Constants";
import styles from "./PrivateInfo.module.css";
import { Link } from "react-router-dom";
import { Close } from "@material-ui/icons";
import Media from "../Media";
import MediaPreview from "../MediaPreview";
import Username, { isDeleted } from "../../../../Extras/Username";
// import { Chevron } from "../../../../../Icons";
import { FormattedMessage } from "react-intl";
import Button1 from "../../../../Extras/Buttons/Button1/Button1";
import { useSelector } from "react-redux";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
const isMobile = window.screen.width < 768;

const useStyles = makeStyles((theme) => ({
	image: {
		width: "10rem",
		height: "10rem",
		color: "#c4c4c4",
	},
	profile: {
		width: !isMobile ? theme.spacing(7) : "5rem",
		height: !isMobile ? theme.spacing(7) : "5rem",
	},
	back: {
		color: "#ff5b5b",
		marginTop: "1rem",
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	leave: {
		color: "#ff5d5d",
		fontSize: "2rem",
	},
}));

const LIMIT = 30;

const PrivateInfo = (props) => {
	const { isLoading, data } = props;
	const [docs, setDocs] = useState([]);
	const [isLoad, setIsLoad] = useState(false);
	const [isMedia, setIsMedia] = useState(false);
	const completed = useRef(false);
    const theme = useSelector((state) => state.theme.theme);
    
	const groupMedia = (reset, bypass) => {
		if (reset) {
			completed.current = false;
		}
		if (completed.current) {
			return;
		}
		if (isLoad && !bypass) {
			return;
		}
		setIsLoad(true);
		const data = {
			conversationID: props.conversationID,
			isGroup: false,
			skip: reset ? 0 : docs.length,
			limit: LIMIT,
		};

		chatConfig
			.post(getMediaByID, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setDocs((prev) =>
					!reset ? [...prev, ...res.data.chats] : [...res.data.chats]
				);
				if (res.data.chats.length < LIMIT) {
					completed.current = true;
				}
				setIsLoad(false);
			})
			.catch((err) => {
				setIsLoad(false);
			});
	};

	const classes = useStyles();
	const container = props.show ? styles.contShow : styles.contHide;

	useEffect(() => {
		if (isMobile && props.activeTab === 2) {
			setIsMedia(false);
		}
		if (isMobile && props.activeTab === 3) {
			setIsMedia(true);
			groupMedia(true, true);
		}
	}, [isMedia, props.activeTab]);

	const handleMediaScroll = (e) => {
		const bottom =
			e.target.scrollHeight - Math.round(e.target.scrollTop) <=
			e.target.clientHeight + 10;

		if (bottom) {
			groupMedia();
		}
	};

	const isMobile = window.screen.width < 768;

	console.log("ada", data);

	return (
		<>
			{!isMobile ? (
				<div className={container}>
					{!isMedia ? (
						<div className={styles.content}>
							<div className={styles.backContainer}>
								<div
									className={styles.topContainer}
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
									}}
								>
									{/* <FormattedMessage
										id="createdOn"
										defaultMessage={`Chat Info`}
									/> */}
                                    Chat Info
								</div>
							</div>

							<div className={styles.header}>
								<div className={styles.imageCont}>
									{isLoading ? (
										<AccountCircleIcon
											className={classes.image}
										/>
									) : (
										<Avatar
											style={{
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											src={data.avatar}
											className={classes.profile}
											alt={data.username}
										/>
									)}
								</div>

								<div className={styles.nameCont}>
									{isLoading ? (
										<Skeleton width={"100%"} height={20} />
									) : (
										<h1 className={styles.username}>
											<Username
												value={data.user.username}
											/>
										</h1>
									)}
								</div>
							</div>

							{/* <Divider variant="middle" /> */}

							<div
								className={styles.profileCont}
								style={{
									borderBottom:
										theme === "dark"
											? "1px solid #2E2E48"
											: "1px solid  #7F89BE",
								}}
							>
								{!isDeleted(data?.user?.username) && (
									<Link
										style={{ margin: "20px 0" }}
										to={`/profile/${data.userID}`}
									>
										<Button1>
											{/* <FormattedMessage
												id="cancel"
												defaultMessage={`View Profile `}
											/> */}
                                            View Profile 
											<img
												style={{ marginLeft: "0.5rem" }}
                                                src="/icons/ColoredForwardArrow.svg"
                                                alt=""
											/>
										</Button1>
									</Link>
								)}
							</div>

							<div className="px-2">
								<MediaPreview
									isGroup={false}
									conversationID={props.conversationID}
									onExpand={() => {
										setIsMedia(true);
										groupMedia(true, true);
									}}
								/>
							</div>
						</div>
					) : (
						<>
							<div className={styles.heading}>
								<div className={styles.title}>
									<IconButton
										size="small"
										onClick={() => setIsMedia(false)}
									>
										<Close className={styles.closeBtn} />
									</IconButton>
									<h1 className={styles.selected}>
										Shared Media
									</h1>
								</div>
							</div>
							<div
								className={styles.mediaCont}
								onScroll={handleMediaScroll}
							>
								{docs.length
									? docs.map((data, key) => {
											return (
												<Media
													data={data}
													key={key}
													className={styles.media}
												/>
											);
									  })
									: !isLoad && (
											<p className={styles.no}>
												No Media Available
											</p>
									  )}
								{isLoad && (
									<CircularProgress
										style={{
											fontSize: "1.4rem",
											color: "#ddd",
											margin: "10px auto",
										}}
									/>
								)}
							</div>
						</>
					)}
				</div>
			) : (
				<div className={container}>
					{!isMedia && props.activeTab === 2 && (
						<div className={styles.content}>
							<div className={styles.header}>
								<div className={styles.imageCont}>
									{isLoading ? (
										<AccountCircleIcon
											className={classes.image}
										/>
									) : (
										<Avatar
											style={{
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											src={data.avatar}
											className={classes.profile}
											alt={data.username}
										/>
									)}
								</div>

								<div className={styles.nameCont}>
									{isLoading ? (
										<Skeleton width={"100%"} height={20} />
									) : (
										<>
											<h6 className="text-white mx-3">
												{data?.full_name}
											</h6>

											<p
												className="mx-3 mb-0"
												style={{ color: "#7f89be" }}
											>
												{data.profession}
											</p>
											<small
												className="mx-3"
												style={{ color: "#7f89be" }}
											>
												{data.city},{data.country}
											</small>
										</>
									)}
								</div>
							</div>

							{/* <Divider variant="middle" /> */}

							<div className={styles.profileCont}>
								{!isDeleted(data?.user?.username) && (
									<Link
										style={{ margin: "20px 0" }}
										to={`/profile/${data.userID}`}
									>
										<Button1>
											{/* <FormattedMessage
												id="cancel"
												defaultMessage={`View Profile`}
											/> */}
                                            View Profile
										</Button1>
									</Link>
								)}
							</div>

							<div>
								<p className="mb-2 text-white">Username</p>
								<h1 className={styles.username}>
									<Username value={data.user.username} />
								</h1>
							</div>

							<div className="mt-4">
								<p className="mb-2 text-white">About</p>
								<p className={styles.username}>{data.bio}</p>
							</div>

							<div className="mt-4">
								<p className="mb-2 text-white">Social Media</p>
								{data.WebUrls?.map((urls) => (
									<div className={styles.dataUrl}>
										{urls.key === "facebook" && (
											<>
												<div className={styles.urlbox}>
													<a
														href={urls.value}
														target="_blank"
														rel="noopener noreferrer"
													>
														<div
															className={
																styles.iconCont
															}
														>
															<FacebookIcon
																style={{
																	color:
																		this
																			.props
																			.theme ===
																		"dark"
																			? "#0d0f22"
																			: "#fff",
																}}
																className={
																	styles.icon
																}
															/>
														</div>
													</a>
												</div>
											</>
										)}
										{urls.key === "instagram" && (
											<>
												<div className={styles.urlbox}>
													<div
														className={
															styles.iconCont
														}
													>
														<InstagramIcon
															style={{
																color:
																	this.props
																		.theme ===
																	"dark"
																		? "#0d0f22"
																		: "#fff",
															}}
															className={
																styles.icon
															}
														/>
													</div>
												</div>
											</>
										)}
										{urls.key === "github" && (
											<>
												<div className={styles.urlbox}>
													<div
														className={
															styles.iconCont
														}
													>
														{/* <GitHubIcon
											style={{
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#fff",
											}}
											className={styles.icon}
										/> */}
														<img
															src="/icons/github.svg"
															alt=""
														/>
													</div>
												</div>
											</>
										)}
										{/* {urls.key === "linkedin" && (
											<>
												<div className={styles.urlbox}>
													<div
														className={
															styles.iconCont
														}
													>
														<LinkedInIcon
															style={{
																color:
																	this.props
																		.theme ===
																	"dark"
																		? "#0d0f22"
																		: "#fff",
															}}
															className={
																styles.icon
															}
														/>
										
													</div>
												</div>
											</>
										)} */}
										{urls.key === "twitter" && (
											<>
												<div className={styles.urlbox}>
													<div
														className={
															styles.iconCont
														}
													>
														<TwitterIcon
															style={{
																color:
																	this.props
																		.theme ===
																	"dark"
																		? "#0d0f22"
																		: "#fff",
															}}
															className={
																styles.icon
															}
														/>
													</div>
												</div>
											</>
										)}
										{/* {urls.key === "others" && (
											<>
												<div className={styles.urlbox}>
													<div
														className={
															styles.iconCont
														}
													>
														<LanguageIcon
															style={{
																color:
																	this.props
																		.theme ===
																	"dark"
																		? "#0d0f22"
																		: "#fff",
															}}
															className={
																styles.icon
															}
														/>
													</div>
												</div>
											</>
										)} */}
									</div>
								))}
							</div>
						</div>
					)}
					{Media && props.activeTab === 3 && (
						<>
							<div
								className={styles.mediaCont}
								onScroll={handleMediaScroll}
							>
								{docs.length
									? docs.map((data, key) => {
											return (
												<Media
													data={data}
													key={key}
													className={styles.media}
												/>
											);
									  })
									: !isLoad && (
											<p
												className={styles.no}
												style={{
													color:
														theme === "dark"
															? "#fff"
															: "",
												}}
											>
												No Media Available
											</p>
									  )}
								{isLoad && (
									<CircularProgress
										style={{
											fontSize: "1.4rem",
											color: "#ddd",
											margin: "10px auto",
										}}
									/>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default pure(PrivateInfo);
