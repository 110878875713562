import axios from "axios";
import { errorInterceptor } from "./axiosResponseInterceptor";
import { chatEndpoint } from "./Constants";
import * as Encrypt from "./Encryption";

const axiosChatConfig = axios.create({
  baseURL: chatEndpoint,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosChatConfig.interceptors.request.use(
  function (config) {
    const Token = Encrypt.getDecryptedToken();
    if (Token) config.headers["x-auth"] = Token;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosChatConfig.interceptors.response.use(undefined, errorInterceptor);

export default axiosChatConfig;
