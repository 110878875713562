import React from "react";

const LocationIcon = ({ color = "#7F89BE" }) => {
	return (
		<svg
			width="16"
			height="15"
			viewBox="0 0 16 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 11C9.65685 11 11 9.65685 11 8C11 6.34315 9.65685 5 8 5C6.34315 5 5 6.34315 5 8C5 9.65685 6.34315 11 8 11Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M15 7.92308C15 14.1538 8 19 8 19C8 19 1 14.1538 1 7.92308C1 6.08696 1.7375 4.32605 3.05025 3.02772C4.36301 1.72939 6.14348 1 8 1C9.85652 1 11.637 1.72939 12.9497 3.02772C14.2625 4.32605 15 6.08696 15 7.92308V7.92308Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
};

export default LocationIcon;
