import { Avatar, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import axiosConfig from "../../../../Utils/axiosConfig";
import { actionRequest, getPendingRequests } from "../../../../Utils/Constants";
import EmptyState from "../../../Extras/EmptyState";
import SmallCardSkeleton from "../../../Skeleton/SmallCardSkeleton/SmallCardSkeleton";
import styles from "../Contacts.module.css";
import { PendingRequestCard } from "./PendingRequestCard";
import { useDispatch } from "react-redux";
import { getCountInContact } from "../../../../Redux/Actions/contactAction";

export default function PendingRequest(props) {
	const [isLoading, setIsLoading] = useState(false);
	const [pendingRequests, setPendindRequests] = useState([]);
	const dispatch = useDispatch();
	const [ispendingRequestsLoaded, setPendindRequestsLoaded] = useState(false);

	useEffect(() => {
		if (ispendingRequestsLoaded) {
			return;
		}
		setIsLoading(true);
		const request = new FormData();
		axiosConfig
			.post(getPendingRequests, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (res.data.message === "No pending requests") {
				} else {
					setPendindRequests(res.data.body || res.data.result);
					dispatch(getCountInContact());
					setPendindRequestsLoaded(true);
				}
				setIsLoading(false);
			})
			.catch((err) => {});
	}, [ispendingRequestsLoaded]);

	// const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
	const isMobile = window.screen.width < 768;

	const filterBySearchTerm = (searchText) =>
		pendingRequests.filter((friend) =>
			friend.user.username
				.toLowerCase()
				.includes(searchText.toLowerCase())
		);

	if (isLoading) {
		return (
			<div className={styles.cards}>
				<SmallCardSkeleton width={!isMobile ? "80%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "80%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "80%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "80%" : "100%"} />
				<SmallCardSkeleton width={!isMobile ? "80%" : "100%"} />
			</div>
		);
	} else {
		const filteredRequests = filterBySearchTerm(props.searchText);
		return (
			<div className={styles.cards}>
				{filteredRequests.length > 0 ? (
					filteredRequests.map((friend, i) => (
						<PendingRequestCard
							key={i}
							friend={friend}
							setPendindRequests={setPendindRequests}
							pendingRequests={pendingRequests}
							isMobile={isMobile}
						/>
					))
				) : (
					<div className="h-100 overflow-auto ">
						<EmptyState
							heading="No Pending Requests"
							className={"users"}
							imageUrl="/vectors/empty-states/emptyPendingUsers.svg"
							subHeading="Looks like you don’t have any pending friends requests. When you do, this is where you’ll find them."
						/>
					</div>
				)}
			</div>
		);
	}
}
