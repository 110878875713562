import React from "react";
import classes from "./Button2.module.css";

function Button2(props) {
	const isMobile = window.screen.width < 768;
	return (
		<button
			{...props}
			className={classes.Violet_Button}
			disabled={props.disabled}
			style={{
				padding: props.padding,
				width: isMobile && props.width ? props.width : "100%",
			}}
		>
			{props.children}
		</button>
	);
}

export default Button2;
