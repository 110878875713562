import {
	Avatar,
	CircularProgress,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import { Close } from "../../../../../Icons";
import DialogModal from "../../../../Extras/DialogModal";
import styles from "./messageForward.module.scss";
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import {
	forwardMessage,
	getConversationListURL,
} from "../../../../../Utils/Constants";
import useAlert from "../../../../Extras/Alert/useAlert";
import { useSelector } from "react-redux";
import Close from "../../../../../Icons/CloseIcon";

const useStyles = makeStyles({
	avatarBackground: {
		backgroundColor: "#ff5b5b",
	},
	dialogContent: {
		padding: "1rem 0",
		"&::-webkit-scrollbar": {
			width: "5px",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#ff5b5b",
		},
	},
});

const MessageForwardDialog = ({
	open,
	closeModal,
	chatID,
	messageID,
	isGroup,
}) => {
	const classes = useStyles();
	const [friends, setFriends] = useState([]);
	const [loading, setLoading] = useState(false);
	const theme = useSelector((state) => state.theme.theme);
	const { showAlert } = useAlert();

	const fetchFriends = async () => {
		try {
			setLoading(true);
			const response = await axiosChatConfig.get(
				getConversationListURL.private
			);
			if (response.data.conversations) {
				setFriends(response.data.conversations);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	useEffect(() => {
		if (open) fetchFriends();
	}, [open]);

	const forwardTo = async (conversationID) => {
		try {
			await axiosChatConfig
				.post(forwardMessage, {
					messageID: messageID,
					to: conversationID,
					isGroup: isGroup,
					groupID: chatID,
				})
				.then((res) => {
					closeModal();
				});
		} catch (err) {
			showAlert("could not forward the message. ");
		}
	};
	return (
		<DialogModal
			width="xs"
			show={open}
			className={styles.dialog}
			// onHide={closeModal}
			maxWidth="false"
		>
			<IconButton className={styles.closeButton} onClick={closeModal}>
				<Close />
			</IconButton>
			<DialogTitle
				className={styles.title}
				style={{
					background: theme === "dark" ? "#0d0f22" : "#fff",
					color: theme === "dark" ? "#d9def4" : "#0d0f22",
				}}
			>
				Forward Message
			</DialogTitle>
			<DialogContent
				style={{ background: theme === "dark" ? "#0d0f22" : "#fff" }}
				classes={{ root: classes.dialogContent }}
			>
				<div className={styles.friendList}>
					{!loading ? (
						friends.map((friend) => {
							console.log(friend);
							return (
								<div
									className={styles.friend}
									onClick={() =>
										forwardTo(friend.conversationID)
									}
								>
									<div className={styles.avatar}>
										<Avatar
											style={{
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
											}}
											src={
												friend.profileURL &&
												friend.profileURL
											}
										>
											{friend.name &&
												friend.name[0].toUpperCase()}
										</Avatar>
									</div>
									<p
										style={{
											color:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
										}}
									>
										{friend.name}
									</p>
								</div>
							);
						})
					) : (
						<div className={styles.loader}>
							<CircularProgress
								style={{
									color:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
							/>
						</div>
					)}
				</div>
			</DialogContent>
		</DialogModal>
	);
};

export default MessageForwardDialog;
