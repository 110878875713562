export const whyData = [
	{
		heading: " Match Your Thoughts",
		subHeading:
			"When people are thinking of the same thing, Bditto brings them together and magic happens ",
	},

	{
		heading: "Unifying Global Minds",
		subHeading:
			"A place where people around the world can connect, share their thoughts and get inspired",
	},

	{
		heading: "Collaborate",
		subHeading:
			"Bditto is the perfect collaboration platform where things get done at the speed of thought",
	},
];
