import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { socialLoginErrorClose } from "../../../Redux/Actions/actionSocialLogin";

const SocialLoginsError = () => {
	const dispatch = useDispatch();
	const socialError = useSelector((state) => state.socialLogin);
	const { socialLoginError, socialLoginErrorCotent } = socialError;
	const [open, setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false);
		dispatch(socialLoginErrorClose());
	};

	useEffect(() => {
		if (socialLoginError) {
			setOpen(true);
		}
	}, [socialLoginError]);

	let vertical = "bottom";
	let horizontal = "center";
	return (
		<Snackbar
			anchorOrigin={{ vertical, horizontal }}
			open={open}
			autoHideDuration={6000}
			onClose={handleClose}
			key={vertical + horizontal}
		>
			<Alert onClose={handleClose} severity="error">
				{socialLoginErrorCotent}
			</Alert>
		</Snackbar>
	);
};

export default SocialLoginsError;
