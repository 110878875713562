export function UserAggIcon({ color, style }) {
	return (
		<svg
			style={style}
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 16.5V14.8333C1 13.9493 1.35119 13.1014 1.97631 12.4763C2.60143 11.8512 3.44928 11.5 4.33333 11.5H7.66667C8.55072 11.5 9.39857 11.8512 10.0237 12.4763C10.6488 13.1014 11 13.9493 11 14.8333V16.5M11.8333 8.16667L13.5 9.83333L16.8333 6.5M9.33333 4.83333C9.33333 6.67428 7.84095 8.16667 6 8.16667C4.15905 8.16667 2.66667 6.67428 2.66667 4.83333C2.66667 2.99238 4.15905 1.5 6 1.5C7.84095 1.5 9.33333 2.99238 9.33333 4.83333Z"
				stroke={color}
				strokeWidth="1.4"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
