import React from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import styles from "./index.module.css";
import BookMarks from "./Bookmark";
import Folders from "./Folders/Folders";
import { useMediaQuery } from "@material-ui/core";
import { Helmet } from "react-helmet";

const BookmarksRouter = (props) => {
	const isDesktop = useMediaQuery("(min-device-width:768px)");
	const location = useLocation();
	return (
		<div className={styles.wrapper}>
			<Helmet>
				<title>{`Bookmarks| bditto`} </title>
			</Helmet>
			<div className={styles.container}>
				{isDesktop ? (
					<>
						<Sidebar />
						<div className={styles.contentContainer}>
							<Route
								exact
								path="/bookmarks/folder/:id"
								component={BookMarks}
							/>
							{location.pathname === "/bookmarks" && (
								<Redirect
									exact
									from="/bookmarks"
									to="/bookmarks/folder/all"
								/>
							)}
						</div>
					</>
				) : (
					<>
						<Route exact path="/bookmarks" component={Sidebar} />
						<Route
							exact
							path="/bookmarks/folder/:id"
							component={BookMarks}
						/>
					</>
				)}
			</div>
		</div>
	);
};

const Sidebar = (props) => {
	return (
		<div className={styles.sidebar}>
			<Folders />
		</div>
	);
};

export default BookmarksRouter;
