import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { withRouter, Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";

import styles from "./UserPreview.module.css";
import Username from "../Username";
import OnlineBadge from "./../StyledBadge";
import ChatUserReview from "../ChatUserReview/ChatUserReview";

class UserPreview extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			onlineStatusColor: "",
		};
	}

	render() {
		const { user } = this.props;
		const posts = user.totalPost;
		console.log("here", user);
		return (
			<Link to={`/profile/${user.pk}`}>
				<div className={styles.container} style={{ cursor: "pointer" }}>
					<div className={styles.image}>
						<ChatUserReview
							id={user.pk}
							data={{}}
							data1={user}
							profileLink={"/profile/" + user.pk}
							userProfile={user}
							user={false}
						>
							<OnlineBadge
								invisible={false}
								last_active={this.props.user.last_active}
								online={this.props.user.online}
								ripple={true}
							>
								<Avatar
									src={user.avatar}
									style={{
										background:
											this.props.theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											this.props.theme === "dark"
												? "#0d0f22"
												: "#d9def4",
										border:
											this.props.theme === "dark"
												? "2px solid #d9def4"
												: "",
									}}
								>
									{user.username &&
										user.username[0]?.toUpperCase()}
								</Avatar>
							</OnlineBadge>
						</ChatUserReview>
					</div>
					<div className={styles.content}>
						<h4
							className={`${styles.name} ${
								this.props.theme === "dark"
									? styles.dark
									: styles.lg
							}`}
						>
							{user.username && (
								<Username value={user.username} />
							)}
						</h4>
						{/* <h4 className={styles.post}>{posts > 0 && <>Thoughts({posts})</>} </h4> */}
					</div>
				</div>
			</Link>
		);
	}
}

const mapStateToProps = (state) => ({
	theme: state.theme.theme,
});

export default connect(mapStateToProps)(withRouter(UserPreview));
