import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
	root: {
		width: (props) => props.width,
		margin: "1%",
		marginBottom: "2.5%",
		display: "flex",
		flexDirection: "column",
		padding: "1rem",
		height: "13rem",
		"@media (max-width:780px)": {
			width: "80% !important",
			marginBottom: "1%",
		},
	},
	username: {
		height: "1rem",
		flex: 1,
		background: "#111527",
	},
	firstRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	avatar: {
		marginRight: "10px",
		background: "#111527",
	},
	body: {
		flex: 1,
		background: "#111527",
	},
	footer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	rect: {
		background: "#111527",
	},

	darkmainbg: {
		background: "#0d0f22",
	},
	lgmainbg: {
		background: "#d9def4",
	},
	darkbg: {
		background: "#121528",
	},
	lgbg: {
		background: "#7F89BE",
	},
});

export default function CardSkeleton(props) {
	const classes = useStyles(props);
	const theme = useSelector((state) => state.theme.theme);

	return (
		<Card
			className={
				theme === "dark"
					? `${classes.root} ${classes.darkmainbg}`
					: `${classes.root} ${classes.lgmainbg}`
			}
		>
			<div className={classes.firstRow}>
				<Skeleton
					variant="circle"
					width="50px"
					height="50px"
					className={
						theme === "dark"
							? `${classes.avatar} ${classes.darkbg}`
							: `${classes.avatar} ${classes.lgbg}`
					}
				/>
				<Skeleton
					variant="rect"
					className={
						theme === "dark"
							? `${classes.username} ${classes.darkbg}`
							: `${classes.username} ${classes.lgbg}`
					}
				/>
			</div>
			<Skeleton
				variant="text"
				width="100%"
				className={
					theme === "dark"
						? `${classes.body} ${classes.darkbg}`
						: `${classes.body} ${classes.lgbg}`
				}
			/>
			<div className={classes.footer}>
				<Skeleton
					variant="circle"
					width="30px"
					height="30px"
					className={
						theme === "dark"
							? `${classes.avatar} ${classes.darkbg}`
							: `${classes.avatar} ${classes.lgbg}`
					}
				/>
				<Skeleton
					variant="text"
					width="30%"
					height="120%"
					className={
						theme === "dark"
							? `${classes.rect} ${classes.darkbg}`
							: `${classes.rect} ${classes.lgbg}`
					}
				/>
			</div>
		</Card>
	);
}
