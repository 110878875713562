import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import axiosConfig from "../../../Utils/axiosConfig";
import { verifyAccountURL } from "../../../Utils/Constants";
import useAlert from "./../../Extras/Alert/useAlert";

export default function VerifyAccount(props) {
	const history = useHistory();
	const { tag, token } = props.rootParams.params;
	const { showAlert } = useAlert();

	useEffect(() => {
		axiosConfig
			.get(verifyAccountURL + tag + "/" + token)
			.then((res) => {
				history.push("/login");
			})
			.catch((err) => {
				showAlert(err.response.data.message, {
					header: `Request Failed due to ${err.response.data.error}`,
					buttonText: "Close",
				});
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tag, token]);

	return <div>Verifying...</div>;
}
