import React, { useState } from "react";
import {
	Button,
	CircularProgress,
	Divider,
	IconButton,
	makeStyles,
} from "@material-ui/core";
import styles from "./bookmarkDialog.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	areFoldersLoading,
	selectFolders,
} from "../../../../Redux/selectors/Bookmarks/folders";
import axiosConfig from "../../../../Utils/axiosConfig";
import { foldersAPI } from "../../../../Utils/Constants";
import { incrementFolderStatusCount } from "../../../../Redux/Actions/bookmarks/folderActions";
import DialogModal from "../../../Extras/DialogModal";
import { Folder } from "../BookmarksDropdown/BookmarksDropdown";
import { Close } from "../../../../Icons";
import NewFolderDialog from "../CreateNewFolder/NewFolderDialog";
import { Add } from "@material-ui/icons";
const useStyles = makeStyles({
	newFolderBtn: {
		fontWeight: 400,
		fontSize: "1rem",
		textTransform: "none",
	},
});

const AddToBookmarksDialog = ({
	show,
	onClose,
	statusID,
	onAdd,
	statusText,
}) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [addFolder, setAddFolder] = useState(false);

	const addStatusToFolder = async (folderid) => {
		try {
			const form = new FormData();
			form.append("folderid", folderid);
			form.append("statusid", statusID);
			const res = await axiosConfig.post(
				foldersAPI.ADD_STATUS_TO_FOLDER,
				form
			);
			if (res.status === 200) {
				dispatch(incrementFolderStatusCount(folderid));
				onAdd(statusID);
				onClose();
			} else {
			}
		} catch (err) {
			if (err.response) {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						this.props.showAlert(err.response.data.message, {
							header: err.response.data.error,
							buttonText: "Try Again",
						});
					}
				} else {
					this.props.showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			}
		}
	};

	const folders = useSelector(selectFolders);
	const isFolderLoading = useSelector(areFoldersLoading);
	const getOnBookmarkHandler = (folderID) => () =>
		addStatusToFolder(folderID);

	return (
		<DialogModal
			width="xs"
			show={show}
			className={styles.dialog}
			onHide={onClose}
			maxWidth="false"
		>
			<IconButton
				size="small"
				className={styles.closeBtn}
				onClick={onClose}
			>
				<Close.Outline />
			</IconButton>
			<div className={styles.header}>
				<h2>Shift to another</h2>
			</div>
			<div className={styles.status}>{statusText}</div>
			<div className={styles.createFolder}>
				<Button
					onClick={() => setAddFolder(true)}
					variant="outlined"
					color="primary"
					className={classes.newFolderBtn}
					startIcon={<Add />}
				>
					Add New Folder
				</Button>
			</div>
			<div className={styles.folderContainer}>
				{!isFolderLoading ? (
					<>
						{folders.map((folder, i) => (
							<>
								<Folder
									onSelect={getOnBookmarkHandler(folder.id)}
									folder={folder}
								/>
								<Divider
									light={true}
									variant="middle"
									style={{ backgroundColor: "#f2f2f2" }}
								/>
							</>
						))}
					</>
				) : (
					<div className={styles.loaderContainer}>
						<CircularProgress color="primary" size="0.8rem" />
					</div>
				)}
			</div>
			<NewFolderDialog
				show={addFolder}
				onClose={() => setAddFolder(false)}
			/>
		</DialogModal>
	);
};

export default AddToBookmarksDialog;
