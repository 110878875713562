// Action Types for auth
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SOCIAL_LOGIN_ERROR = "SOCIAL_LOGIN_ERROR";
export const SOCIAL_LOGIN_ERROR_CLOSE = "SOCIAL_LOGIN_ERROR_CLOSE";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";

// Profile Action Types
export const SETGROUP = "SETGROUP";
export const INITGROUP = "INITGROUP";

export const PROFILE_RESET_REQUEST = "PROFILE_RESET_REQUEST";
export const PROFILE_URLS_RESET_REQUEST = "PROFILE_URLS_RESET_REQUEST";
export const PROFILE_URLS_UPDATE = "PROFILE_URLS_UPDATE";

// Current Location Action Types
export const GETCONTACTSCOUNT = "GETCONTACTSCOUNT";
export const GETCONTACTSCOUNTERROR = "GETCONTACTSCOUNTERROR";
export const LOCATION_SET = "LOCATION_SET";
export const LOCATION_LOCAL_GLOBAL = "LOCATION_GLOBAL_LOCAL";
export const REMOVE_LOCATION = "REMOVE_LOCATION";
export const CLEAR_LOCATIONS = "CLEAR_LOCATIONS";
export const ONLINE_SET = "ONLINE_SET";

//TUTORIAL ACTIONS
export const TUTORIAL_SET = "TUTORIAL_SET";
export const TUTORIAL_COMPLETED = "TUTORIAL_COMPLETED";

//chats ACTIONS
export const NEW_MESSAGE = "NEW_MESSAGE";
export const READ_MESSAGES = "READ_MESSAGES";

//device params ACTIONS
export const SOCKET_CONNECTED = "SOCKET_CONNECTED";
export const SOCKET_DISCONNECTED = "SOCKET_DISCONNECTED";
export const MAPS_PLACES_LOADED = "MAPS_PLACES_LOADED";
export const JOIN_PING = "JOIN_PING";
export const NOTIFICATIONS_POLLED = "NOTIFICATIONS_POLLED";

//Blocked User Actions
export const BLOCKED_USER_ALERT_OPEN = "BLOCKED_USER_ALERT_OPEN";
export const BLOCKED_USER_ALERT_CLOSE = "BLOCKED_USER_ALERT_CLOSE";

export const THEME_CHANGE = "THEME_CHANGE";
export const RECENT_SEARCHES = "RECENT_SEARCHES";

// // MyGroups Actions

// get my groups
export const GET_MY_GROUPS = "GET_MY_GROUPS";
export const UPDATE_MY_GROUPS = "UPDATE_MY_GROUPS";
export const UPDATE_MY_SINGLE_GROUP = "UPDATE_MY_SINGLE_GROUP";
