import React, { createRef, PureComponent } from "react";
import MediaQuery from "react-responsive";
import { withRouter } from "react-router-dom";
import axiosChatConfig from "../../../../Utils/axiosChatConfig";
import { getConversationListURL } from "../../../../Utils/Constants";
import EmptyState from "../../../Extras/EmptyState";
import ConvListSkeleton from "../../../Skeleton/ConvListSkeleton/ConvListSkeleton";
import Group from "../Extra/GroupConv/Group";
import Private from "../Extra/PrivateConv/Private";
import styles from "./Sidebar.module.css";
import SidebarContextMenu from "./SidebarContextMenu";
import {
	Badge,
	Button,
	Fade,
	IconButton,
	makeStyles,
	SvgIcon,
	withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Cancel";
import {
	MoreVertRounded,
	SignalCellularConnectedNoInternet4Bar,
} from "@material-ui/icons";
import { connect, shallowEqual, useSelector } from "react-redux";
import { joinPing } from "../../../../Redux/Actions/deviceParamsActions";
import { setUnreadMessageCount } from "../../../../Redux/Actions/messages/unreadMessageActions";
import { FormattedMessage } from "react-intl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Avatar } from "@material-ui/core";
import GroupsSidebar from "../../Groups/GroupsSidebar";
import Liked from "../Liked/Liked";

const useStyles = makeStyles({
	noNetworkButton: {
		backgroundColor: "white",
		marginLeft: "0.5rem",
		"&:disabled": { backgroundColor: "white" },
	},
});

export const StyledBadge = withStyles((theme) => ({
	root: {
		height: "100%",
	},
	badge: {
		backgroundColor: "#ff5b5b",
		color: "#ff5b5b",
		boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
		"&::after": {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			borderRadius: "50%",
			animation: "$ripple 1.2s infinite ease-in-out",
			border: "1px solid currentColor",
			content: '""',
		},
	},
	"@keyframes ripple": {
		"0%": {
			transform: "scale(.8)",
			opacity: 1,
		},
		"100%": {
			transform: "scale(2.4)",
			opacity: 0,
		},
	},
}))(Badge);

const NoConnection = ({ deviceError }) => {
	const classes = useStyles();
	return (
		<Fade in={deviceError.error} mountOnEnter unmountOnExit>
			<div className={styles.notConnected}>
				<IconButton
					disabled
					classes={{ root: classes.noNetworkButton }}
				>
					<SignalCellularConnectedNoInternet4Bar
						style={{ color: "#ff5b5b" }}
					/>
				</IconButton>
				<div className={styles.notConnectedContent}>
					<h3>{deviceError.heading}</h3>
					<p>{deviceError.subHeading}</p>
				</div>
			</div>
		</Fade>
	);
};
class Sidebar extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			searchText: "",
			isLoading: false,
			conversationList: [],
			fetchedList: [],
			skip: 0,
			allDataLoaded: false,
			activeType: "group",
			newMessages: { group: false, private: false },
			sideBar: true,
			groupInfo: false,
			groupsDisplay: false,
			activeTab: 1,
		};
	}
	componentDidMount = () => {
		this.fetchConvList(getConversationListURL[this.state.activeType], 0);
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { newMessage } = this.props;

		if (newMessage !== prevProps.newMessage) {
			if (newMessage[0].isGroup) {
				if (this.state.activeType !== "group") {
					this.setState({
						newMessages: { ...this.state.newMessages, group: true },
					});
				}
			} else {
				if (this.state.activeType !== "private") {
					this.setState({
						newMessages: {
							...this.state.newMessages,
							private: true,
						},
					});
				}
			}

			let data = this.state.conversationList;
			let element;

			data.map((item) => {
				if (item.conversationID === newMessage[0].conversationID)
					element = item;
				return true;
			});

			if (element) {
				element = { ...element, lastMessage: newMessage[0].message };
				data = data.filter(
					(item) =>
						item.conversationID !== newMessage[0].conversationID
				);
				data = [element, ...data];

				this.setState({ fetchedList: data, conversationList: data });
			}
		}

		if (this.state.activeType !== prevState.activeType) {
			this.props.history.push("/chat");
			this.fetchConvList(
				getConversationListURL[this.state.activeType],
				this.state.skip,
				true
			);
		}

		if (
			this.props.groupDataFromStore.id !== prevProps.groupDataFromStore.id
		) {
			this.props.history.push("/chat");
			this.fetchConvList(
				getConversationListURL[this.state.activeType],
				0,
				true
			);
		}

		if (
			this.props.refreshPing !== prevProps.refreshPing &&
			this.props.refreshPing
		) {
			if (this.state.activeType === "group") {
				this.setState({ skip: 0 });
				this.fetchConvList(
					getConversationListURL[this.state.activeType],
					0,
					true
				);
			} else {
				this.setState({ activeType: "group", skip: 0 });
			}
			this.props.joinGroupPingDisable();
		}
	};

	fetchConvList = (api, skip, reset = false) => {
		if (reset) {
			this.props.setChatsPresent(true);
		}
		if (
			reset !== true &&
			(this.state.isLoading || this.state.allDataLoaded)
		) {
			return;
		}
		this.setState((prev) => ({
			isLoading: true,
			conversationList: reset ? [] : prev.conversationList,
		}));
		const searchQuery = this.state.searchText;
		const data = {
			skip: skip,
			searchQuery: searchQuery,
		};

		axiosChatConfig
			.get(
				`${api}?skip=${skip}&groupId=${this.props.groupDataFromStore.id}`,
				data
			)
			.then((res) => {
				if (res.data.count) {
					this.setState((prevState) => ({
						isLoading: false,
						conversationList: !reset
							? [
									...prevState.conversationList,
									...res.data.conversations,
							  ]
							: res.data.conversations,
						fetchedList: [
							...prevState.fetchedList,
							...res.data.conversations,
						],
						skip: !reset
							? Number(this.state.skip) + res.data.count
							: res.data.count,
					}));
					if (searchQuery.length !== 0) {
						const searchConv = this.state.conversationList.filter(
							(conversation) => {
								return conversation.name
									.toLowerCase()
									.includes(searchQuery.toLowerCase());
							}
						);
						this.setState({
							conversationList: [...searchConv],
						});
					}
					this.props.setUnreadCount(
						res.data.conversations.map((conv) => ({
							key: conv.isGroup
								? `${conv.conversationID}group`
								: `${conv.userID}private`,
							unreadCount: conv.isGroup
								? conv.unreadMessagesCount
								: conv.unreadCount,
						}))
					);
				} else {
					if (
						this.state.conversationList.length === 0 &&
						searchQuery === ""
					) {
						this.props.setChatsPresent(false);
					}
					this.setState({
						isLoading: false,
						allDataLoaded: true,
					});
				}
			})
			.catch((err) => {
				this.setState({
					isLoading: false,
				});
			});
	};

	setActiveType = (type) => () => {
		this.setState({
			activeType: type,
			searchText: "",
			skip: 0,
			newMessages: { ...this.state.newMessages, [type]: false },
		});
	};

	handleSearch = (e) => {
		e.preventDefault();

		this.setState({ conversationList: [] });
		this.fetchConvList(
			getConversationListURL[this.state.activeType],
			0,
			true
		);
	};

	clearSearch = () => {
		if (this.state.searchText !== "") {
			this.setState({ conversationList: [], searchText: "" }, () => {
				this.fetchConvList(
					getConversationListURL[this.state.activeType],
					0,
					true
				);
			});
		}
	};

	handleScroll = (e) => {
		const bottom =
			e.target.scrollHeight - Math.round(e.target.scrollTop) <=
			e.target.clientHeight + 10;

		if (bottom) {
			this.fetchConvList(
				getConversationListURL[this.state.activeType],
				this.state.skip
			);
		}
	};

	setGroupInfo = () => {
		this.setState({ groupInfo: !this.state.groupInfo });
	};

	setGroupsDisplay = () => {
		this.setState({ groupsDisplay: !this.state.groupsDisplay });
	};

	render() {
		const { conversationList, isLoading } = this.state;

		const { newMessage, chatID } = this.props;

		const DisplaySkeleton = () => {
			return (
				<>
					<ConvListSkeleton />
					<ConvListSkeleton />
					<ConvListSkeleton />
					<ConvListSkeleton />
				</>
			);
		};

		const isMobile = window.screen.width < 768;

		const tabs = [
			{
				id: 1,
				label: "Thoughts",
				type: "group",
			},
			{
				id: 2,
				label: "Private",
				type: "private",
			},
			{
				id: 3,
				label: "Liked",
				type: "liked",
			},
			{
				id: 4,
				label: "Starred",
				type: "starred",
			},
		];

		const MobTabHandler = async (tab) => {
			if (tab.id > 2) {
				this.setState({ activeTab: tab.id });
				this.props.handleLikedState(tab.type, true);
			} else {
				this.setState({
					activeTab: tab.id,
					activeType: tab.type,
					searchText: "",
					skip: 0,
					newMessages: {
						...this.state.newMessages,
						[tab.type]: false,
					},
				});
			}
		};

		return (
			<>
				{!isMobile ? (
					<div
						className={
							chatID ? styles.container : styles.showContainer
						}
						onScroll={this.handleScroll}
					>
						<NoConnection deviceError={this.props.deviceError} />

						<div className={styles.grpContainer}>
							<div
								className={styles.groupName}
								onClick={() => this.setGroupInfo()}
							>
								<Avatar
									style={{
										background:
											this.props.theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											this.props.theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
									src={this.props.groupDataFromStore?.avatar}
								>
									{this.props.groupDataFromStore?.name &&
										this.props.groupDataFromStore?.name[0]?.toUpperCase()}
								</Avatar>
								<span
									className={`${styles.grpname} ${
										this.props.theme === "dark"
											? styles.textDark
											: styles.textlg
									}`}
								>
									{this.props.groupDataFromStore?.name}
								</span>
							</div>
							<div onClick={this.setGroupsDisplay}>
								{this.state.groupsDisplay ? (
									<ExpandMoreIcon
										style={{
											fontSize: "1.5rem",
											color:
												this.props.theme === "dark"
													? "#D9DEF4"
													: "#0d0f22",
											transform: "rotate(180deg)",
										}}
									/>
								) : (
									<ExpandMoreIcon
										style={{
											fontSize: "1.5rem",
											color:
												this.props.theme === "dark"
													? "#D9DEF4"
													: "#0d0f22",
										}}
									/>
								)}
							</div>
						</div>

						{this.state.groupsDisplay ? (
							<GroupsSidebar
								setGroupsDisplay={this.setGroupsDisplay}
                                top="true"
                                groupId={this.props.groupDataFromStore?.id}

							/>
						) : (
							<>
								<div className={styles.topBar}>
									<div className={styles.navigation}>
										<StyledBadge
											invisible={
												!this.state.newMessages.group
											}
											overlap="circle"
											anchorOrigin={{
												vertical: "top",
												horizontal: "right",
											}}
											variant="dot"
										>
											<button
												className={`${
													this.state.activeType ===
														"group" &&
													styles.activeTab
												} ${styles.tabButton} ${
													styles.firstButton
												}`}
												onClick={this.setActiveType(
													"group"
												)}
											>
												{/* <FormattedMessage
													id="groups"
													defaultMessage={`Thoughts`}
												/> */}
                                                    Groups
												{this.state.activeType ===
													"group" && (
													<div
														className={
															styles.underline
														}
													></div>
												)}
											</button>
										</StyledBadge>
										<StyledBadge
											invisible={
												!this.state.newMessages.private
											}
											overlap="circle"
											anchorOrigin={{
												vertical: "top",
												horizontal: "right",
											}}
											variant="dot"
										>
											<button
												className={`${
													this.state.activeType ===
														"private" &&
													styles.activeTab
												} ${styles.tabButton}`}
												onClick={this.setActiveType(
													"private"
												)}
											>
												<FormattedMessage
													id="private"
													defaultMessage={`Private Chat`}
												/>
												{this.state.activeType ===
													"private" && (
													<div
														className={
															styles.underline
														}
													></div>
												)}
											</button>
										</StyledBadge>
									</div>
									<SidebarContextMenu
										transformOrigin={{
											vertical: "top",
											horizontal: "left",
										}}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "center",
										}}
										trigger={
											<IconButton size="small">
												<MoreVertRounded htmlColor="#7F89BE" />
											</IconButton>
										}
									>
										<Button
											style={{
												width: "100%",
												fontSize: "1rem",
												textAlign: "left",
												textTransform: "capitalize",
												fontWeight: 400,
											}}
											onClick={() =>
												this.props.handleLikedState(
													"liked",
													true
												)
											}
											className="d-flex"
										>
											<IconButton
												className="pr-3"
												size="small"
											>
												{this.props.theme === "dark" ? (
													<img
														src="/cards-img/Heart.svg"
														alt=""
														style={{
															width: "20px",
														}}
													/>
												) : (
													<img
														src="/cards-img/heart-lg.png"
														alt=""
														style={{
															width: "20px",
														}}
													/>
												)}
											</IconButton>
											<div
												className={styles.popoverBtn}
												style={{
													color:
														this.props.theme ===
														"dark"
															? "#d9def4"
															: "#0d0f22",
												}}
											>
												<FormattedMessage
													id="likedMessages"
													defaultMessage={`Liked Messages`}
												/>
												<p
													className={styles.smallText}
													style={{
														color:
															this.props.theme ===
															"dark"
																? "#7f89be"
																: "#4A517E",
													}}
												>
													View search results from
													your chat
												</p>
											</div>
										</Button>
										<Button
											style={{
												width: "100%",
												fontSize: "1rem",
												textAlign: "left",
												textTransform: "capitalize",
												fontWeight: 400,
											}}
											onClick={() =>
												this.props.handleLikedState(
													"starred",
													true
												)
											}
											className="d-flex"
										>
											<IconButton
												className="pr-3"
												size="small"
											>
												{this.props.theme === "dark" ? (
													<img
														src="/Home/dark-active-bookmark.svg"
														alt=""
														style={{
															width: "20px",
														}}
													/>
												) : (
													<img
														src="/Home/BookmarkLg.svg"
														alt=""
														style={{
															width: "20px",
														}}
													/>
												)}
											</IconButton>
											<div
												className={styles.popoverBtn}
												style={{
													color:
														this.props.theme ===
														"dark"
															? "#d9def4"
															: "#0d0f22",
												}}
											>
												<FormattedMessage
													id="savedMessages"
													defaultMessage={`Saved Messages`}
												/>
												<p
													className={styles.smallText}
													style={{
														color:
															this.props.theme ===
															"dark"
																? "#7f89be"
																: "#4A517E",
													}}
												>
													View search results from
													your chat
												</p>
											</div>
										</Button>
									</SidebarContextMenu>
								</div>

								{this.props.likedBox.open ? (
									<Liked
										{...this.props.likedBox}
										handleClose={this.props.closeLikedBox}
									/>
								) : (
									<>
										<form onSubmit={this.handleSearch}>
											<div
												className={
													styles.searchContainer
												}
											>
												<img
													src="/Search.svg"
													className="m-2 "
													alt=""
												/>
												<input
													type="text"
													value={
														this.state.searchText
													}
													onChange={({ target }) =>
														this.setState({
															searchText:
																target.value,
														})
													}
													className={styles.search}
													placeholder={`Search thoughts (${this.props.groupDataFromStore?.name})`}
													disabled={isLoading}
												/>
												{this.state.searchText.length >
													0 && (
													<IconButton
														onClick={
															this.clearSearch
														}
														size="small"
													>
														<CloseIcon
															style={{
																color: "#ddd",
															}}
															fontSize="small"
														/>
													</IconButton>
												)}
											</div>
										</form>

										<div
											className={
												this.props.theme === "dark"
													? styles.convListDk
													: styles.convListLG
											}
										>
											{conversationList.map(
												(data, index) => {
													if (data.isGroup) {
														return (
															<Group
																isActive={
																	this.props
																		.chatID ===
																	data.conversationID
																}
																ref={createRef()}
																data={data}
																key={
																	data.conversationID
																}
																chatID={chatID}
																newMessage={
																	newMessage
																}
															/>
														);
													} else
														return (
															<Private
																isActive={
																	this.props
																		.chatID ===
																	data.userID
																}
																ref={createRef()}
																data={data}
																key={
																	data.conversationID
																}
																chatID={chatID}
																newMessage={
																	newMessage
																}
															/>
														);
												}
											)}
											{!this.props.chatsPresent && (
												<MediaQuery maxWidth={600}>
													<EmptyState
														id="suggestions-box"
														heading="No Messages Yet!"
														imageUrl="/vectors/empty-states/emptyChats.svg"
														subHeading="Looks like you haven’t initiated a conversation yet. What are you waiting for, send your first message!"
													/>
												</MediaQuery>
											)}
											{isLoading && <DisplaySkeleton />}
										</div>
									</>
								)}
							</>
						)}
					</div>
				) : (
					<>
						<div
							className={
								chatID ? styles.container : styles.showContainer
							}
							onScroll={this.handleScroll}
						>
							<NoConnection
								deviceError={this.props.deviceError}
							/>

							<div className={styles.topBar}>
								<div
									className={`${styles.navigation} ${styles.thoughtsTab}`}
								>
									{tabs.map((tab, i) =>
										tab.id === this.state.activeTab ? (
											<div
												className={styles.activeMobTab}
											>
												{tab.label}
											</div>
										) : (
											<div
												onClick={() =>
													MobTabHandler(tab)
												}
												className={styles.mobtab}
											>
												{tab.label}
											</div>
										)
									)}
								</div>
							</div>

							{(this.state.activeTab === 1 ||
								this.state.activeTab === 2) && (
								<>
									<form onSubmit={this.handleSearch}>
										<div className={styles.searchContainer}>
											<img
												src="/Search.svg"
												className="m-2 "
												alt=""
											/>
											<input
												type="text"
												value={this.state.searchText}
												onChange={({ target }) =>
													this.setState({
														searchText:
															target.value,
													})
												}
												className={styles.search}
												placeholder={`Search thoughts (${this.props.groupDataFromStore?.name})`}
												disabled={isLoading}
											/>
											{this.state.searchText.length >
												0 && (
												<IconButton
													onClick={this.clearSearch}
													size="small"
												>
													<CloseIcon
														style={{
															color: "#ddd",
														}}
														fontSize="small"
													/>
												</IconButton>
											)}
										</div>
									</form>
									<div className={styles.mobThoughts}>
										{conversationList.map((data, index) => {
											if (data.isGroup) {
												return (
													<Group
														isActive={
															this.props
																.chatID ===
															data.conversationID
														}
														ref={createRef()}
														data={data}
														key={
															data.conversationID
														}
														chatID={chatID}
														newMessage={newMessage}
													/>
												);
											} else
												return (
													<Private
														isActive={
															this.props
																.chatID ===
															data.userID
														}
														ref={createRef()}
														data={data}
														key={
															data.conversationID
														}
														chatID={chatID}
														newMessage={newMessage}
													/>
												);
										})}
										{!this.props.chatsPresent && (
											<MediaQuery maxWidth={768}>
												<EmptyState
													id="suggestions-box"
													heading="No Messages Yet!"
													imageUrl="/vectors/empty-states/emptyChats.svg"
													subHeading="Looks like you haven’t initiated a conversation yet. What are you waiting for, send your first message!"
												/>
											</MediaQuery>
										)}
										{isLoading && <DisplaySkeleton />}
									</div>
								</>
							)}
							{(this.state.activeTab === 3 ||
								this.state.activeTab === 4) && (
								<Liked
									{...this.props.likedBox}
									handleClose={this.props.closeLikedBox}
								/>
							)}
						</div>
					</>
				)}
			</>
		);
	}
}

const useDeviceError = () => {
	const deviceParameters = useSelector(
		(state) => state.deviceParameters,
		shallowEqual
	);
	let deviceError = { error: false, heading: "", subHeading: "" };
	if (
		deviceParameters.chatSocket &&
		deviceParameters.socketConnectionFailed
	) {
		deviceError.error = true;
		deviceError.heading = "Device not connected";
		deviceError.subHeading = "We cannot reach you at the moment.";
	}
	return deviceError;
};

const withDeviceError = (Component) => (props) => {
	const deviceError = useDeviceError();
	return <Component deviceError={deviceError} {...props} />;
};

const mapStateToProps = (state) => ({
	groupDataFromStore: state.groups.groupData,
	refreshPing: state.deviceParameters.joinGroupPing,
	theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
	joinGroupPingDisable: () => dispatch(joinPing(false)),
	setUnreadCount: (payload) => dispatch(setUnreadMessageCount(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withDeviceError(withRouter(Sidebar)));
