import {
  CLEAR_UNREAD_COUNT,
  SET_UNREAD_COUNT_MULTIPLE_CONVERSATIONS,
} from "./types";

export const setUnreadMessageCount = (payload) => {
  return { type: SET_UNREAD_COUNT_MULTIPLE_CONVERSATIONS, payload };
};

export const clearUnReadMessageCount = (conversationID, isGroup) => {
  return {
    type: CLEAR_UNREAD_COUNT,
    payload: { key: `${conversationID}${isGroup ? "group" : "private"}` },
  };
};
