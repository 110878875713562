import React, { useState, useEffect } from "react";

import { Avatar } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import ImageDisplay from "../../../../Extras/ImageDisplay/ImageDisplay";

import styles from "./LikedMessage.module.css";
import { Link } from "react-router-dom";
import { MessageTimeStamp, TextFormatter } from "../MessageBox/Helpers";
import { Markup } from "interweave";
import ChatUserReview from "../../../../Extras/ChatUserReview/ChatUserReview";
import Username from "../../../../Extras/Username";
import useAlert from "../../../../Extras/Alert/useAlert";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";

const MessageTextFormatter = TextFormatter({
	classes: { link: styles.linkColour },
});

export default function LikedMessage(props) {
	const [show, setShow] = useState(false);
	const isMobile = window.screen.width < 768;
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const theme = useSelector((state) => state.theme.theme);

	const date = MessageTimeStamp({ timestamp: props.data.timeStamp });

	// useEffect(() => {
	// 	debugger;
	// }, []);

	const ImageMessageBox = () => {
		return (
			<>
				<div className={styles.imgCont}>
					<img
						onClick={() => handleShow()}
						className={styles.img}
						src={props.data.media}
						alt={props.data.username}
					/>
				</div>
				<ImageDisplay
					show={show}
					img={props.data.media}
					handleClose={handleClose}
				/>
				<p className={styles.message}>
					{
						<Markup
							content={MessageTextFormatter({
								text: props.data.message,
							})}
						/>
					}
				</p>
				<p className={styles.date}>{date}</p>
			</>
		);
	};

	const VideoMessageBox = (props) => {
		return (
			<>
				<div>
					<a href={props.data.media}>
						<PlayCircleFilledIcon style={{ fontSize: "3rem" }} />
					</a>
					<p className={styles.message}>
						<Markup
							content={MessageTextFormatter({
								text: props.data.message,
							})}
						/>
					</p>
					<p className={styles.date}>{date}</p>
				</div>
			</>
		);
	};

	const TextMessageBox = (props) => {
		return (
			<>
				<p
					className={styles.message}
					style={{
						color: theme === "dark" ? "#d9def4" : "#0d0f22",
					}}
				>
					<Markup
						content={MessageTextFormatter({
							text: props.data.message,
						})}
					/>
				</p>
				<p className={styles.date}>{date}</p>
			</>
		);
	};

	const FileMessageBox = () => {
		return (
			<>
				<div className={styles.fileCont}>
					<p>{props.data.filename || "File"}</p>
					<GetAppIcon htmlColor="#aaa" href={props.data.media} />
				</div>
				<p className={styles.message}>
					<Markup
						content={MessageTextFormatter({
							text: props.data.message,
						})}
					/>
				</p>
				<p className={styles.date}>{date}</p>
			</>
		);
	};

	const [user, setUser] = useState({});
	const [userLoading, setUserLoading] = useState(false);

	const BoxContent = (props) => {
		if (props.data.type === "IMAGE" || props.data.type === "GIF") {
			return <ImageMessageBox data={props.data} />;
		} else if (props.data.type === "VIDEO") {
			return <VideoMessageBox data={props.data} />;
		} else if (props.data.type === "TEXT") {
			return <TextMessageBox data={props.data} />;
		} else if (props.data.type === "PDF") {
			return <FileMessageBox data={props.data} />;
		} else {
			return null;
		}
	};

	if (props.data.type === "INFO") return null;

	return (
		<>
			<div
				className={styles.likedMessage}
				style={{
					background: theme === "dark" ? "#121528" : "#fff",
				}}
			>
				<div style={{ width: "100%" }}>
					<div className={styles.nameavatar}>
						<div className="d-flex">
							<ChatUserReview
								id={props.data.from}
								data={props.data}
								user={false}
								userProfile={user}
								userLoading={userLoading}
								anchorOrigin={{
									vertical: "center",
									horizintal: "left",
								}}
								transformOrigin={{
									vertical: "center",
									horizontal: "right",
								}}
							>
								<Avatar
									src={props.data.profileURL}
									style={{
										width: "2rem",
										height: "2rem",
										marginRight: ".1rem",
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
									}}
									// onMouseOver={Number(props.data.from) !== Number(userData.userID) &&getShortUSerProfile}
								>
									{props.data.username &&
										props.data.username[0].toUpperCase()}
								</Avatar>
							</ChatUserReview>
							<span className={styles.author}>
								<Username value={props.data.username} />
							</span>
						</div>
						{/* <div>
							{props.button === "Dislike" ? (
								<IconButton
									onClick={(e) => {
										e.preventDefault();
										props.func();
									}}
								>
									<img src="/HeartLiked.svg" alt="" />
								</IconButton>
							) : theme === "dark" ? (
								<IconButton
									onClick={(e) => {
										e.preventDefault();
										props.func();
									}}
								>
									<img
										src="/Home/dark-active-bookmark.svg"
										alt=""
										style={{
											width: "20px",
										}}
									/>
								</IconButton>
							) : (
								<IconButton
									onClick={(e) => {
										e.preventDefault();
										props.func();
									}}
								>
									<img
										src="/Home/BookmarkLg.svg"
										alt=""
										style={{
											width: "20px",
										}}
									/>
								</IconButton>
							)}
						</div> */}
					</div>
					<div className={styles.chat}>
						<Link
							onClick={() => {
								if (isMobile) {
									props.closeSearchConv();
								}
							}}
							to={
								props.isGroup
									? `/chat/group/${props.data.groupID}?highlight=${props.data._id}&groupID=${props.data.groupID}&changed=true`
									: `/chat/private/${props.conversationID}?highlight=${props.data._id}&conversation=${props.conversationID}&changed=true`
							}
						>
							<BoxContent
								data={props.data}
								setReply={props.setReply}
							/>
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}
