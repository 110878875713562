import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocationPredictions } from "../../Extras/PlacesAutoComplete";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
	clearLocations,
	removeLocation,
	setLocation,
} from "../../../Redux/Actions/actionLocation";
import {
	Button,
	Chip,
	CircularProgress,
	ClickAwayListener,
	IconButton,
	Popover,
	Switch,
} from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import { useReverseGeoCoder } from "../../Extras/Geocoder";
import {
	getlocationsObject,
	isLocalSelector,
} from "../../../Redux/selectors/locationSelector";
import LocationIcon from "../../../Icons/Location";
import useAlert from "../../Extras/Alert/useAlert";
import styles from "./Location.module.scss";
import { Close } from "../../../Icons";
import MyLocationIcon from "../../../Icons/MyLocation";
import Locationicon from "../../../Icons/LocationIcon";
// import { style } from "@material-ui/system";
import LoveFace from "../../../Icons/Emogi/LoveFace";
import SearchIcon from "@material-ui/icons/Search";
import Button2 from "../../Extras/Buttons/Button2/Button2";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import { setLoading } from "../../../Redux/Reducers/NotificationsReducer/staticNotifications";

const useStyles = () => ({
	location: {
		display: "flex",
		justifyContent: "center",
		fontSize: "1rem",
		color: "#bfbfbf",
		cursor: "pointer",
		fontFamily: "Roboto",
		height: "100%",
		alignItems: "center",
	},
	popover: {
		borderRadius: 10,
		padding: "0.8rem 1rem",
		boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
		background: "#fff",
	},
	detectLocationIcon: {
		animation: "$blink 1.2s infinite ease-in-out",
	},

	"@keyframes blink": {
		"0%": {
			opacity: 1,
		},
		"50%": {
			color: "#999",
			opacity: 1,
		},
		"100%": {
			opacity: 1,
		},
	},
});

const StyledSwitch = withStyles(() => ({
	root: { height: 44, width: 70 },

	checked: {
		transform: "translateX(24px) !important",
		"& + $track": {
			backgroundColor: "transparent !important",
			opacity: "1 !important",
		},
	},
	switchBase: { left: 6, top: 6 },
	track: {
		background: "linear-gradient(92.12deg, #FC466B 0%, #3F5EFB 100%)",
		border: "transparent",
		borderRadius: 22,
		opacity: 1,
	},
	thumb: { width: 14, height: 14, background: "white" },
}))(Switch);

const Location = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [query, setQuery] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [isSuggestionOpen, setSuggestionsOpen] = useState(false);
	const textRef = useRef();
	const sessionToken = useRef();
	const { showAlert } = useAlert();

	const [userLocationCoordinates, setUserLocationCoordinates] = useState({
		lat: null,
		long: null,
	});

	const dispatch = useDispatch();
	const locations = useSelector(getlocationsObject);
	const theme = useSelector((state) => state.theme.theme);
	const isLocal = useSelector(isLocalSelector);

	const { libraryLoading, searched, predictions, loadingPredictions } =
		useLocationPredictions(query, {
			sessionToken,
			locationType: "(regions)",
		});
	const { location: userLocation, loading: locationLoading } =
		useReverseGeoCoder({
			location: userLocationCoordinates,
		});

	useEffect(() => {
		if (userLocation.country) {
			clearLocations();
			dispatch(
				setLocation({
					city: userLocation.city,
					country: userLocation.country,
				})
			);
		}
	}, [userLocation.city, userLocation.country, dispatch]);

	const clearPreferences = () => {
		dispatch(clearLocations());
	};

	const localize = () => {
		setIsLoading(true);
		if (!userLocation.country) {
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(
					function (position) {
						setUserLocationCoordinates({
							lat: position.coords.latitude,
							long: position.coords.longitude,
						});
						setIsLoading(false);
					},
					(err) => {
						if (err.code === err.PERMISSION_DENIED) {
							showAlert("cannot access your location");
							setIsLoading(false);
						}
					}
				);
			} else {
				showAlert("cannot access your location");
				setIsLoading(false);
			}
		} else {
			setIsLoading(false);
			dispatch(clearLocations());
			dispatch(
				setLocation({
					city: userLocation.city,
					country: userLocation.country,
				})
			);
		}
	};
	const onLocationSelect = (location) => {
		dispatch(setLocation(location));
		setQuery("");
		textRef.current.value = "";
		textRef.current.focus();
	};
	function getLocation() {
		if (!userLocation.country) {
			if ("geolocation" in navigator) {
				navigator.geolocation.getCurrentPosition(
					function (position) {
						setUserLocationCoordinates({
							lat: position.coords.latitude,
							long: position.coords.longitude,
						});
					},
					(err) => {
						if (err.code === err.PERMISSION_DENIED) {
							showAlert("cannot access your location");
						}
					}
				);
			} else {
				showAlert("cannot access your location");
			}
		} else {
			dispatch(clearLocations());
			dispatch(
				setLocation({
					city: userLocation.city,
					country: userLocation.country,
				})
			);
		}
	}
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const classes = useStyles();

	const open = Boolean(anchorEl);
	const popid = open ? "simple-popover" : undefined;
	const isMobile = window.screen.width < 768;

	return (
		<>
			{isMobile ? (
				<LocationOn
					onClick={handleClick}
					style={{ color: "#D9DEF4" }}
				/>
			) : (
				<div className={classes.location} onClick={handleClick}>
					<Locationicon
						color={theme === "dark" ? "#7F89BE" : "#4A517E"}
					/>
				</div>
			)}
			<Popover
				id={popid}
				className={`${styles.pop}`}
				// classes={{ paper: classes.popover }}
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{ horizontal: "center" }}
				style={{ outline: "none" }}
			>
				<div
					className={styles.popover}
					style={{
						width: isMobile ? "100%" : 300,
						background: theme === "dark" ? "#121528" : "#fff",
					}}
				>
					<div className={styles.container}>
						<div className={styles.globalSwitcher}>
							<span
								className={`${
									isLocal
										? styles.selected
										: styles.switchText
								} ${
									theme === "dark"
										? styles.darkText
										: styles.lgText
								}`}
								onClick={localize}
							>
								Local
							</span>
							<StyledSwitch
								onChange={isLocal ? clearPreferences : localize}
								checked={!isLocal}
							/>

							<span
								className={`${
									!isLocal
										? styles.selected
										: styles.switchText
								} ${
									theme === "dark"
										? styles.darkText
										: styles.lgText
								}`}
								onClick={clearPreferences}
							>
								Global
							</span>
						</div>
						<div style={{ textAlign: "center", margin: "0 auto" }}>
							{isLoading && (
								<CircularProgress
									size="20px"
									style={{
										color:
											theme === "dark"
												? "white"
												: "black",
									}}
								/>
							)}
						</div>
						<div
							className={`${styles.caption} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
						>
							{isLocal
								? "You decide where your thought matches come from."
								: "You’re seeing thoughts match from across the globe!"}
						</div>

						<div className={styles.caption}>
							{isLocal ? (
								<img
									style={{ width: "2.25rem" }}
									src="/search/Group.svg"
									alt=""
								/>
							) : (
								<LoveFace />
							)}
						</div>

						{isLocal ? (
							<>
								<ClickAwayListener
									onClickAway={() =>
										setSuggestionsOpen(false)
									}
								>
									<div className={styles.searchLine}>
										<div
											className={styles.searchContainer}
											style={{
												border: `1px solid ${
													theme === "dark"
														? "#7F89BE"
														: "#4A517E"
												}`,
											}}
										>
											{/* <Search color="disabled" /> */}
											{/* <div className={styles.searchIcon}>
												<SearchIcon
													style={{
														color: "#7F89BE",
														fontSize: "1.5rem",
													}}
												/>
											</div> */}
											<img
												src="/Search.svg"
												className="mr-2 ml-1"
												alt=""
											/>
											<input
												type="text"
												onFocus={() =>
													setSuggestionsOpen(true)
												}
												ref={textRef}
												className={styles.inputField}
												disabled={libraryLoading}
												placeholder="Search locations"
												onChange={(e) =>
													setQuery(e.target.value)
												}
											/>
											{isSuggestionOpen && (
												<LocationSelector
													display={query.length > 2}
													predictions={predictions}
													search={searched}
													loading={loadingPredictions}
													onSelect={onLocationSelect}
												/>
											)}
										</div>
										{/* <IconButton size="small" onClick={getLocation}>
                      <MyLocation
                        color="primary"
                        classes={{
                          root: locationLoading ? classes.detectLocationIcon : "",
                        }}
                      />
                    </IconButton> */}

										<div
											className={styles.myLocationBox}
											onClick={getLocation}
										>
											<div
												className={
													styles.myLocationIcon
												}
											>
												<MyLocationIcon.Outline />
											</div>
											<div
												className={`${
													styles.myLocation
												} ${
													theme === "dark"
														? styles.darkText
														: styles.lgText
												}`}
											>
												<div>Current Location</div>
											</div>
										</div>
									</div>
								</ClickAwayListener>

								{isSuggestionOpen && (
									<SelectedLocations
										locations={locations}
										onDelete={(location) =>
											dispatch(removeLocation(location))
										}
									/>
								)}

								<div className={styles.footer}>
									{/* <Button
											variant="contained"
											color="primary"
											fullWidth
											onClick={handleClose}
											className={styles.saveButton}
										>
											Save Preferences
										</Button> */}
									{isSuggestionOpen && (
										<Button1
											onClick={handleClose}
											className={styles.Save_Button}
										>
											Save Preferences
										</Button1>
									)}
								</div>
							</>
						) : (
							<p className={styles.caption2}>
								To set your location, switch to Local.
							</p>
						)}
					</div>
				</div>
			</Popover>
		</>
	);
};

const LocationSelector = ({
	predictions,
	onSelect,
	search,
	loading,
	display,
}) => {
	const theme = useSelector((state) => state.theme.theme);
	return display ? (
		<div className={theme === "dark" ? styles.darkBg : styles.lightBg}>
			<ListLoader loading={loading}>
				{predictions.length
					? predictions.map((pred) => (
							<div
								className={styles.option}
								onClick={() => onSelect(pred.params)}
							>
								<div className={styles.optionText}>
									<span>{pred.label.main_text}</span>
									<span className={styles.secondaryText}>
										{pred.label.secondary_text}
									</span>
								</div>
							</div>
					  ))
					: !loading &&
					  search && (
							<div className={styles.notfound}>
								Could not find any results
							</div>
					  )}
			</ListLoader>
		</div>
	) : null;
};

const ListLoader = ({ loading, children }) =>
	loading ? (
		<div className={styles.searchLoader}>
			<CircularProgress
				className={styles.loader}
				size="1.6rem"
				style={{ color: "#ff6c6c" }}
			/>
		</div>
	) : (
		children
	);

const SelectedLocations = ({ locations, onDelete }) => {
	const theme = useSelector((state) => state.theme.theme);
	const LocationChip = withStyles({
		colorPrimary: {
			borderColor: "#ff6c6c",
			color: "white",
			margin: 2,
			backgroundColor: "#ff6c6c",
		},
		deleteIcon: {
			color: "white",
		},
		deletableColorPrimary: {
			"&:focus": { backgroundColor: "#ff6c6c" },
		},
		icon: {
			color: "white",
		},
	})(Chip);
	const countries = Object.keys(locations);
	return (
		<div className={styles.selectedLocationsContainer}>
			{countries.map((country) => {
				const cities = locations[country];
				return cities.length > 0 ? (
					cities.map((city) => (
						<div
							className={`${styles.selectedLoc} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => {
								onDelete({ country, city });
							}}
						>
							<p
								style={{
									color:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
							>
								{`${city}, ${country}`}
							</p>
							<IconButton size="small">
								<Close.Outline fontSize="small" />
							</IconButton>
						</div>
					))
				) : (
					<div
						className={styles.selectedLoc}
						onClick={() => {
							onDelete({ country });
						}}
					>
						<p
							style={{
								color: theme === "dark" ? "#d9def4" : "#0d0f22",
							}}
						>
							{`${country}`}
						</p>
						<IconButton size="small">
							<Close.Outline fontSize="small" />
						</IconButton>
					</div>
				);
			})}
		</div>
	);
};

export default Location;
