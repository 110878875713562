import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles({
	root: {
		width: "14rem",
		margin: "1%",
		display: "flex",
		flexDirection: "column",
		padding: "1rem",
		height: "10rem",
		"@media (max-width:780px)": {
			width: "80% !important",
		},
	},
	username: {
		height: "1rem",
		flex: 1,
	},
	firstRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	avatar: {
		marginRight: "10px",
	},
	body: { flex: 1 },
	footer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	report: {
		display: "inline-flex",
	},
});

export default function CardSkeleton(props) {
	const classes = useStyles(props);

	return (
		<Card className={classes.root}>
			<div className={classes.firstRow}>
				<Skeleton
					variant="circle"
					width="50px"
					height="50px"
					className={classes.avatar}
				/>
				<Skeleton
					variant="rect"
					className={classes.username}
					width="80%"
					height="20%"
				/>
			</div>
			<Skeleton
				variant="text"
				width="100%"
				className={classes.body}
				height="30%"
			/>
			<div className={classes.footer}>
				<Skeleton
					variant="text"
					width="100%"
					className={classes.body}
					height="10%"
				/>
			</div>
		</Card>
	);
}
