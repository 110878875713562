import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { loginUser, logout } from "../../../Redux/Actions/actionAuth";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import BackToLanding from "../BackToLandingBtn";
import "./login.scss";
import InputField from "../../Extras/InputField";
import { socialKeys } from "../../../Utils/Constants";
import {
	googleCallbacks,
	facebookCallback,
	twitterCallback,
} from "./socialLogins";
import TwitterLoginComponent from "../../../twitter/TwitterLogin";
import SocialLoginsError from "./SocialLoginsError";
import { socialLoginError } from "../../../Redux/Actions/actionSocialLogin";
// import { FormattedMessage } from "react-intl";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

class Login extends Component {
	INITIAL_ERRORS = { email: null, password: null };
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			isLoggingIn: false,
			errors: this.INITIAL_ERRORS,
		};
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value });
	};

	socialSignUp = () => {
		this.props.history.push("/create-profile");
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		const { email, password } = this.state;
		const errors = {};
		if (
			password.length === 0 ||
			email.length === 0 ||
			!emailRegex.test(email)
		) {
			if (email.length === 0) {
				errors.email = "Email is required";
			} else if (!emailRegex.test(email)) {
				errors.email = "Email is not valid";
			}
			if (password.length === 0) {
				errors.password = "password is required";
			}
			this.setState({ errors });
			return;
		}
		this.setState({ errors: this.INITIAL_ERRORS, isLoggingIn: true });

		const res = await this.props.loginUser(this.state);

		if (res.status) {
			if (res.data.body.temp_token) {
				this.props.history.push("/code-verification");
			}
			if (this.props.user_data.role !== "normal_user") {
				this.props.logout();
				return;
			}
			this.setState({
				isLoggingIn: false,
			});
			if (
				this.props.location &&
				this.props.location.state &&
				this.props.location.state.referrer
			) {
				this.props.history.push(this.props.location.state.referrer);
			} else {
				this.props.history.push("/");
			}
		} else {
			const errors = {};
			if (res.error === "Your Account is Blocked") {
				this.props.history.push("/account-blocked-error");
			} else if (
				res.error.includes(
					"Your account is not activated. Please verify your account"
				)
			) {
				this.props.history.push("/code-verification");
			} else if (res.error.includes("signup")) {
				errors.email =
					"Account does not exist. Please signup to continue";
			} else if (res.error.includes("Password")) {
				errors.password = "The password is incorrect, try again.";
			} else {
				this.props.loginError(res.error);
			}
			this.setState({
				isLoggingIn: false,
				errors,
			});
		}
	};

	render() {
		return (
			<div className="auth-page-Container">
				<BackToLanding />
				<div className="pinkOval"></div>
				<div className="yellowDiv"></div>
				<div className="container">
					<div className="form-container login-form-container">
						<div className="header">
							<h1>
								{/* <FormattedMessage
									id="login.heading1"
									defaultMessage={`Login`}
								/> */}
								Login
							</h1>
							<p>
								{/* <FormattedMessage
									id="login.heading2"
									defaultMessage={`Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.`}
								/> */}
								Bditto is a powerful enterprise solution that
								will help you easily share, connect and explore
								thoughts in an expedited manner.
							</p>
						</div>

						<div className="form">
							<div className="left">
								<img
									className="logoM"
									src="/logoMain.svg"
									alt=""
								/>
								<div className="heading">
									<h1>
										Where people{" "}
										<span className="gradienth1">
											connect
										</span>{" "}
										&{" "}
										<span className="gradienth1">
											share...
										</span>
									</h1>
								</div>
								<div className="launchImg">
									<img src="/icons/Launch.svg" alt="" />
								</div>
							</div>

							<div className="right">
								<div className="heading">
									<div className="login">
										<img src="/icons/login.svg" alt="" />
										<p>Login</p>
									</div>
									<div className="underline"></div>
								</div>

								<div className="login-form">
									<div className="heading">
										<h5>Account details</h5>
									</div>
									<form
										noValidate
										onSubmit={this.handleSubmit}
									>
										<div className="inputDiv">
											<div className="input">
												<InputField
													placeholder="Email *"
													name="email"
													required
													autoComplete={false}
													errorText={
														this.state.errors.email
													}
													value={this.state.email}
													onChange={this.handleChange}
												/>
											</div>
											<div className="label">
												<p>
													Let's make the most of your
													work email
												</p>
											</div>
										</div>

										<div className="inputDiv">
											<div className="input">
												<InputField
													required
													autoComplete={false}
													name="password"
													placeholder="Password *"
													type="password"
													errorText={
														this.state.errors
															.password
													}
													value={this.state.password}
													onChange={this.handleChange}
												/>
											</div>
											<Link to="/forget-password">
												<p className="forget">
													{/* <FormattedMessage
														id="login.heading3"
														defaultMessage={`Forgot Password?`}
													/> */}
													Forgot Password?
												</p>
											</Link>
										</div>

										<div className="inputDiv">
											<button
												className="login-btn"
												type="submit"
											>
												{/* <FormattedMessage
													id="signIn"
													defaultMessage={`Login`}
												/> */}
												Login
												{this.state.isLoggingIn && (
													<CircularProgress
														className="loader"
														color="#FFFFFF"
														size={20}
														style={{
															marginLeft: "1rem",
														}}
													/>
												)}
											</button>
											<p className="link">
												{/* <FormattedMessage
													id="login.heading4"
													defaultMessage={` Don’t have an account? `}
												/> */}
												Don’t have an account? &nbsp;
												<Link to="/register">
													<span className="forget">
														{/* <FormattedMessage
															id="login.heading5"
															defaultMessage={`Sign up here`}
														/> */}
														Sign up here
													</span>
												</Link>
											</p>
										</div>
									</form>
								</div>

								<hr className="orLine"></hr>

								<div className="social">
									<GoogleLogin
										clientId={socialKeys.GOOGLE}
										onSuccess={googleCallbacks.onSuccess({
											onRequireSignUp: this.socialSignUp,
										})}
										onFailure={googleCallbacks.onFailure}
										buttonText="Login"
										render={(renderProps) => (
											<button
												onClick={renderProps.onClick}
												disabled={renderProps.disabled}
												className="button"
											>
												<img
													src="/icons/google.svg"
													alt="google"
												/>
												<p>
													Login with Google
													{/* <FormattedMessage
														id="login.heading3"
														defaultMessage={`Login with Google`}
													/> */}
												</p>
											</button>
										)}
									/>

									<TwitterLoginComponent
										authCallback={twitterCallback({
											onRequireSignUp: this.socialSignUp,
										})}
									>
										<button
											elevation={2}
											className="button"
										>
											<img
												src="/icons/twitter.svg"
												alt="twitter"
											/>
											<p>
												Login with Twitter
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={`Login with Twitter`}
												/> */}
											</p>
										</button>
									</TwitterLoginComponent>

									<FacebookLogin
										appId={socialKeys.FACEBOOK}
										callback={facebookCallback({
											onRequireSignUp: this.socialSignUp,
										})}
										render={(renderProps) => (
											<button
												onClick={renderProps.onClick}
												disabled={
													renderProps.isDisabled
												}
												elevation={2}
												className="button"
											>
												<img
													src="/icons/facebook.svg"
													alt="facebook"
												/>
												<p>
													Login with Facebook
													{/* <FormattedMessage
														id="login.heading3"
														defaultMessage={`Login with Facebook`}
													/> */}
												</p>
											</button>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SocialLoginsError />
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user_data: state.auth.user_data,
});

const mapDispatchToProps = (dispatch) => ({
	loginUser: (payload) => dispatch(loginUser(payload)),
	loginError: (payload) => dispatch(socialLoginError(payload)),
	logout: (payload) => dispatch(logout(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
