import {
  JOIN_PING,
  MAPS_PLACES_LOADED,
  NOTIFICATIONS_POLLED,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
} from "./actionTypes";

export const SocketConnected = () => ({ type: SOCKET_CONNECTED });
export const SocketdisConnected = () => ({ type: SOCKET_DISCONNECTED });
export const placesLibraryLoaded = () => ({ type: MAPS_PLACES_LOADED });

export const joinPing = (ping) => ({ type: JOIN_PING, payload: ping });

export const notificationsPolledOnce = () => ({ type: NOTIFICATIONS_POLLED });
