export const recieveNewNotification = (state, action) => ({
  ...state,
  data: [action.payload, ...state.data],
  newestData: [...state.newestData, { key: action.key, ...action.payload }],
});

export const closeNotification = (state, action) => {
  return {
    ...state,
    newestData: state.newestData.map((notification) =>
      notification.key !== action.key ? notification : { ...notification, dismissed: true }
    ),
  };
};

export const setNotificationCount = (state, action) => ({ ...state, unreadCount: action.payload });
export const incrementNotificationCount = (state, action) => ({ ...state, unreadCount: state.unreadCount + 1 });

export const setNewNotificationsPreset = (state, action) => ({
  ...state,
  newNotifications: action.payload,
});

export const removeNotification = (state, action) => ({
  ...state,
  newestData: state.newestData.filter((notification) => notification.key !== action.key),
});
