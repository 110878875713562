import { MESSAGE_STATUS } from "../../../Utils/Constants";
import { READ_MESSAGES } from "../../Actions/actionTypes";
import {
  CREATE_MESSAGE,
  DELETE_FROM_QUEUE,
  MESSAGE_DELETE,
  MESSAGE_RECEIVED,
  MESSAGE_SENT,
  UPDATE_DISPATCH_QUEUE,
} from "../../Actions/messages/types";

const initialState = [];

const dispatchQueueReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MESSAGE:
      return [action.payload, ...state];

    case UPDATE_DISPATCH_QUEUE:
      return [...action.payload];

    case MESSAGE_RECEIVED:
      const isOwnMessage =
        action.payload.message.type !== "INFO" && action.payload.message.from == action.context.userID;

      if (isOwnMessage) {
        const updatedQueue = state.map((message) =>
          message.message.hash === action.payload.message.hash
            ? {
                ...message,
                message: {
                  ...action.payload.message,
                  status: MESSAGE_STATUS.DELIVERED,
                  timeStamp: action.payload.message.timeStamp,
                },
              }
            : message
        );
        return updatedQueue;
      } else {
        const message = {
          ...action.payload,
          userID: action.payload.message.from,
        };
        return [message, ...state];
      }

    case MESSAGE_SENT:
      return state.map((message) =>
        message.message.hash === action.payload.hash
          ? {
              ...message,
              message: { ...message.message, status: MESSAGE_STATUS.SENT },
            }
          : message
      );

    case DELETE_FROM_QUEUE:
      return state.filter((message) => message.conversationID != action.payload.chatID);

    case MESSAGE_DELETE:
      return state.map((message) =>
        message.conversationID === action.payload.conversationID && message.message._id === action.payload.messageID
          ? { ...message, message: { ...message.message, isDeleted: true } }
          : message
      );

    case READ_MESSAGES:
      if (!action.payload.isGroup) {
        return state.map((message) =>
          message.conversationID === action.payload.conversationID
            ? { ...message, message: { ...message.message, seen: true } }
            : message
        );
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default dispatchQueueReducer;
