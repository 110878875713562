import {
  ERROR_CONTACT_REQUESTS,
  FETCH_CONTACT_REQUESTS,
  LOADING_CONTACT_REQUESTS,
  REMOVE_CONTACT_REQUESTS,
} from "../../Actions/notifications/types";
import createReducer from "../utils/createReducer";

const REQUESTS_INITIAL_STATE = { data: [], loading: false, isFetched: false, error: null };

const handleSetRequests = (state, action) => ({
  ...state,
  data: action.payload,
  isFetched: true,
  error: null,
  loading: false,
});
const handleLoading = (state, action) => ({ ...state, loading: action.payload });
const handleError = (state, action) => ({ ...state, error: action.payload });

const handleRemove = (state, action) => ({
  ...state,
  data: state.data.filter((req) => req.requestId !== action.payload),
});

export const RequestsReducer = createReducer(REQUESTS_INITIAL_STATE, {
  [FETCH_CONTACT_REQUESTS]: handleSetRequests,
  [LOADING_CONTACT_REQUESTS]: handleLoading,
  [ERROR_CONTACT_REQUESTS]: handleError,
  [REMOVE_CONTACT_REQUESTS]: handleRemove,
});
