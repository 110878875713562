import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Username from "../Username";
import useAlert from "../Alert/useAlert";
import HoverPopover from "../HoverPopover";
import Button1 from "../Buttons/Button1/Button1";
import axiosConfig from "../../../Utils/axiosConfig";
import CardSkeleton from "../../Skeleton/ShortProfileSkeleton";
import {
	friendRequestURL,
	getShortUserProfileUrl,
} from "./../../../Utils/Constants";
import { Avatar, Button } from "@material-ui/core";
import styles from "./ChatUserReview.module.css";

export default function ChatUserReview(props) {
	const history = useHistory();
	const { showAlert } = useAlert();
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(false);
	const [requested, setRequested] = useState();

	const me = useSelector((state) => state.auth.user_data);
	const theme = useSelector((state) => state.theme.theme);
	const loggedinUserID = useSelector((state) => state.auth.user_data?.userID);
	useEffect(() => {
		setUser(props.userProfile);
		getShortUSerProfile(props.id);
	}, [props.userProfile]);

	const sendFriendRequest = () => {
		const form = new FormData();
		form.append("userId", props.id);

		axiosConfig
			.post(friendRequestURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert("Friend Request Sent Succesfully", {
					header: "Reqested",
					buttonText: "Go Back",
				});
				setRequested(true);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const getShortUSerProfile = (id) => {
		if (id === loggedinUserID) {
			const user1 = {
				avatar: me.avatar,
				friendship_status: "me",
				profession: me.profession,
				total_post: me.post_count,
				username: me.user.username,
			};
			setUser(user1);
			return;
		}

		setLoading(true);
		const request = new FormData();
		request.append("userId", id);
		axiosConfig
			.post(getShortUserProfileUrl, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUser(res.data.body);
				setLoading(false);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						setUser({ error: true });
					}
				}
				setLoading(false);
			});
	};

	const UserButton = () => {
		switch (user.friendship_status) {
			case "Requested":
				return (
					<Link to="/myprofile/" style={{ margin: "0 auto" }}>
						<Button1
							variant="outlined"
							className="bottonText"
							color="primary"
							style={{ width: "100%", background: "transparent" }}
							disabled
						>
							Request Sent
						</Button1>
					</Link>
				);
			case "Request Received":
				return (
					<Link to="/myprofile/" style={{ width: "100%" }}>
						<Button
							variant="contained"
							className="bottonText"
							color="primary"
							style={{ width: "100%", background: "transparent" }}
						>
							Friend Request Received
						</Button>
					</Link>
				);
			case "NAF":
				return (
					<button
						variant="outlined"
						className="bottonText"
						color="primary"
						style={{
							width: "100%",
							background: "transparent",
							border: "none",
						}}
						onClick={sendFriendRequest}
						disabled={requested}
					>
						<Button1>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
							{requested ? "Request Sent " : "Connect"}
							&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
						</Button1>
					</button>
				);
			case "Friends":
				return (
					<Link
						to={`/chat/private/${user.userID}`}
						style={{ width: "100%" }}
					>
						<button
							variant="contained"
							className="bottonText"
							color="primary"
							style={{
								width: "100%",
								background: "transparent",
								outline: "none",
								border: "none",
							}}
						>
							<Button1>Message</Button1>
						</button>
					</Link>
				);
			case "me":
				return (
					<div
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Link to={`/myprofile`}>
							<Button1
								style={{
									width: "100%",
									marginTop: "1.2rem",
									marginBottom: "0.4rem",
								}}
							>
								&nbsp;&nbsp; My Profile &nbsp;&nbsp;
							</Button1>
						</Link>
					</div>
				);
			default:
				return (
					<Link to={"/profile/" + props.id} style={{ width: "100%" }}>
						<button
							variant="contained"
							className="bottonText"
							color="primary"
							style={{
								width: "100%",
								background: "transparent",
								outline: "none",
								border: "none",
							}}
						>
							<Button1>View Profile</Button1>
						</button>
					</Link>
				);
		}
	};

	const viewProfile = (e) => {
		e.stopPropagation();
		if (loggedinUserID === props.data.from) history.push("/myprofile/");
		else
			history.push(
				`/profile/${
					user.userID || props.data.from || props.data1.from
				}` || props.profileLink
			);
	};

	return (
		<>
			{user ? (
				<HoverPopover
					style={{ borderRadius: "16px" }}
					trigger={props.children}
					anchorOrigin={
						props.anchorOrigin || {
							vertical: "center",
							horizontal: "right",
						}
					}
					transformOrigin={
						props.transformOrigin || {
							vertical: "center",
							horizontal: "left",
						}
					}
				>
					{loading ? (
						<>
							<CardSkeleton width="100%" />
						</>
					) : (
						<div className={styles.popover}>
							<div className={styles.top}>
								<Avatar
									alt={user.username}
									src={user.avatar}
									style={{
										fontSize: "3rem",
										width: "5.5rem",
										height: "5.5rem",
										marginTop: "16px",
										color:
											theme === "dark"
												? "#0d0f22"
												: "#d9def4",
										background:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
										border: `5px solid ${
											theme === "dark" ? "#fff" : "golden"
										}`,
									}}
								>
									{user.username &&
										user.username[0].toUpperCase()}
								</Avatar>
								<div
									className={styles.infotext}
									onClick={viewProfile}
								>
									<div
										className={`${styles.username} ${
											theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
									>
										<Username value={user.username} />
									</div>

									<div
										className={`${styles.profession} ${
											theme === "dark"
												? styles.darkSText
												: styles.lgSText
										}`}
									>
										{user?.profession?.length &&
											user.profession !== "null" &&
											user.profession}
									</div>
									<div
										className={`${
											user && user.total_post < 10
												? styles.posts1
												: styles.posts
										} ${
											theme === "dark"
												? styles.darkTText
												: styles.lgTText
										}`}
									>
										{user && user.total_post}&nbsp;posts
									</div>
								</div>
							</div>

							<div className={styles.friendBtn}>
								{user && UserButton()}
							</div>
						</div>
					)}
				</HoverPopover>
			) : (
				props.children
			)}
		</>
	);
}
