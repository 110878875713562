import React from "react";
import Tour, { ACTIONS } from "react-joyride";
import { connect } from "react-redux";
import { completeTutorial } from "../../../Redux/Actions/actionTutorial";
import { history } from "./../../../Router/history";

import { GradientButton } from "../../Extras/InputField/Button";

import { CloseSharp } from "@material-ui/icons";
import "./styles.scss";

export const steps = [
	{
		target: ".home",
		content: "View all your statuses and matched statuses here.",
		disableBeacon: true,
	},
	{
		target: "#Add_Status",
		content:
			"Start connecting with similar thinking people by posting your first status!",
		disableBeacon: true,
		spotlightPadding: 0,
	},
	{
		target: "#active-suggestions",
		content:
			"View matched statuses here and view the conversation in chats.",
		disableBeacon: true,
	},
	{
		target: "#closed-suggestions",
		content: "View all your closed statuses here.",
		disableBeacon: true,
	},
	{
		target: "#Edit-delete",
		content:
			"Edit/delete your statuses or disable them to prevent them from getting matched",
		disableBeacon: true,
		placement: "left",
	},

	{
		target: "#Tags",
		content: "View popular tags here and explore relevant statuses.",
		disableBeacon: true,
		placement: "right",
	},
	{
		target: "#Explore",
		content: "Explore new statuses and connect with different people.",
		disableBeacon: true,
	},
	{
		target: "#newsfeed",
		content: "View the thoughts posted by your friends here.",
		disableBeacon: true,
	},
	{
		target: "#Chats",
		content: "View your group and personal chats here.",
		disableBeacon: true,
	},
	{
		target: "#Search",
		content: "Search for statuses, topics and users.",
		disableBeacon: true,
	},
	{
		target: "#Location",
		content: "Change location to explore statuses from that place.",
		disableBeacon: true,
	},
	{
		target: "#Notification",
		content: "View your notifications here.",
		disableBeacon: true,
	},
	{
		target: "#Settings",
		content: "Change your account settings here.",
		disableBeacon: true,
	},
	{
		target: ".App",
		content:
			"Hurray! You’ve completed the tutorial! To watch it again find it in help. ",
		disableBeacon: true,
	},
];

export const mobileSteps = [
	{
		target: ".home",
		content: "View all your statuses here.",
		disableBeacon: true,
	},
	{
		target: "#Add_Status_mobile",
		content:
			"Start connecting with similar thinking people by posting your first status!",
		disableBeacon: true,
	},

	{
		target: "#Edit-delete",
		content:
			"Edit/delete your statuses or disable them to prevent them from getting matched",
		disableBeacon: true,
	},

	{
		target: "#newsfeed_mobile",
		content: "View the thoughts posted by your friends here.",
		disableBeacon: true,
	},
	{
		target: "#Chats_mobile",
		content: "View your group and personal chats here.",
		disableBeacon: true,
	},
	{
		target: "#Explore_mobile",
		content: "Explore new statuses and connect with different people.",
		disableBeacon: true,
	},
	{
		target: "#Search",
		content: "Search for statuses, topics and users.",
		disableBeacon: true,
	},
	{
		target: "#Location",
		content: "Change location to explore statuses from that place.",
		disableBeacon: true,
	},
	{
		target: "#Notification",
		content: "View your all your notifications here.",
		disableBeacon: true,
	},
	{
		target: "#Settings",
		content: "Change your account settings here.",
		disableBeacon: true,
	},
	{
		target: ".App",
		content:
			"Hurray! You’ve completed the tutorial! To watch it again find it in help. ",
		disableBeacon: true,
	},
];

const ToolTip = ({
	continuous,
	index,
	step,
	backProps,
	closeProps,
	primaryProps,
	tooltipProps,
	size,
}) => {
	return (
		<div className="tourTooltip" {...tooltipProps}>
			<div className="navContainer">
				<div className="navItem">
					{[...Array(size).keys()].map((idx) => (
						<div
							className={`step ${
								idx === index ? "active-step" : ""
							}`}
						></div>
					))}
				</div>
				<CloseSharp
					{...closeProps}
					fontSize="small"
					className="close"
					style={{ color: "black" }}
				/>
			</div>
			<div className="content">
				{step.title && <h1>{step.title}</h1>}
				<p className="subhead">{step.content}</p>
			</div>
			<div className="navigation">
				{index > 0 && (
					<button {...backProps}>
						<img src="/icons/backArrowTour.svg" alt="" />
					</button>
				)}
				{continuous &&
					(index !== size - 1 ? (
						<button {...primaryProps}>
							<img src="/icons/forwardArrowTour.svg" alt="" />
						</button>
					) : (
						<GradientButton className="done" {...primaryProps}>
							Done
						</GradientButton>
					))}
			</div>
		</div>
	);
};

const TourComponent = ({ isMobile, run, onClose, ...props }) => (
	<Tour
		steps={!isMobile ? steps : mobileSteps}
		autoStart
		continuous
		run={run}
		tooltipComponent={ToolTip}
		callback={({ action }) => {
			if (action === ACTIONS.CLOSE || action === ACTIONS.RESET) {
				history.push("/explore/thoughts/latest");
				onClose();
				props.completeTutorial();
			}
		}}
	/>
);

const mapDispatchToProps = { completeTutorial };
export default connect(null, mapDispatchToProps)(TourComponent);
