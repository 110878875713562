import React, { Component } from "react";
import { Link } from "react-router-dom";

import axiosConfig from "../../../Utils/axiosConfig";
import { forgetPasswordURL } from "../../../Utils/Constants";

import "./forgetPassword.scss";
import InputField from "../../Extras/InputField";
import { GradientButton } from "../../Extras/InputField/Button";
import { CircularProgress } from "@material-ui/core";
import BackToLandingBtn from "../BackToLandingBtn";
import withAlert from "../../Extras/Alert/withAlert";

import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "../Login/login.scss";

import { socialKeys } from "../../../Utils/Constants";
import {
	googleCallbacks,
	facebookCallback,
	twitterCallback,
} from "../Login/socialLogins";
import TwitterLoginComponent from "../../../twitter/TwitterLogin";
import { FormattedMessage } from "react-intl";

class FrogetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: "",
			email: "",
		};

		this.tag = props.rootParams.params.tag;
		this.token = props.rootParams.params.token;
	}

	handleChange = (e) => {
		this.setState({ [e.target.name]: e.target.value, error: "" });
	};

	validate = (data) => {
		const emailRegex = new RegExp(
			"[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
		);
		if (this.state.email.length > 0) {
			if (!emailRegex.test(data)) {
				this.setState({
					error: "Invalid Email. Try with Valid Email Address ",
				});
			} else {
				return true;
			}
		} else {
			return false;
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();

		const { email } = this.state;
		if (email.length > 0) {
			if (this.validate(email)) {
				const form = new FormData();
				form.append("email", email);

				return axiosConfig
					.post(forgetPasswordURL, form)
					.then((res) => {
						if (res.status !== 200) throw new Error();

						this.props.showAlert(
							"We just emailed you with the instructions to reset your password",
							{
								header: "Change Your Password",
								callback: () =>
									this.props.history.push("/login"),
								buttonText: "Go back to Sign in",
							}
						);
					})
					.catch((err) => {
						if (err.response) {
							const catchError = err.response.data;
							if (
								err.response.status === 403 &&
								err.response.statusText === "Forbidden"
							) {
								this.props.showAlert(
									"Your account has been blocked from update actions. Please contact Admin.",
									{
										header: "You Account is Blocked",
										buttonText: "Go Back",
									}
								);
							}

							this.setState({
								error: catchError.message,
							});
						} else {
							this.setState({
								error: "Network Problem",
							});
						}
					});
			}
		} else {
			this.setState({ error: "Email is Required" });
		}
	};

	render() {
		return (
			<div className="auth-page-Container">
				<BackToLandingBtn />
				<div className="pinkOval"></div>
				<div className="yellowDiv"></div>
				<div className="container">
					<div className="form-container forget-form-container">
						<div className="headerN">
							<h1>
								{/* <FormattedMessage
									id="login.heading1"
									defaultMessage={`Recover your password`}
								/> */}
								Recover your password
							</h1>
							<p>
								<FormattedMessage
									id="login.heading2"
									defaultMessage={`Bditto is a powerful enterprise solution that will help you easily share, connect and explore thoughts in an expedited manner.`}
								/>
							</p>
						</div>

						<div className="form">
							<div className="left">
								<img
									className="logoM"
									src="/logoMain.svg"
									alt=""
								/>
								<div className="heading">
									<h1>
										Where people{" "}
										<span className="gradienth1">
											connect
										</span>{" "}
										&{" "}
										<span className="gradienth1">
											share...
										</span>
									</h1>
								</div>
								<div className="launchImg">
									<img src="/icons/Launch.svg" alt="" />
								</div>
							</div>

							<div className="right">
								<div className="heading">
									<div className="login">
										<img
											src="/icons/resetPass.svg"
											alt=""
										/>
										<p>Recover Password</p>
									</div>
									<div className="underline"></div>
								</div>

								<div className="login-form">
									<div className="heading">
										<h5>Account details</h5>
									</div>
									<form
										noValidate
										onSubmit={this.handleSubmit}
									>
										<div className="inputDiv">
											<div className="input">
												<InputField
													className="email-field"
													placeholder="Email"
													name="email"
													required
													errorText={this.state.error}
													value={this.state.email}
													onChange={this.handleChange}
												/>
											</div>
											<div className="label">
												<p>
													Let's make the most of your
													work email
												</p>
											</div>
										</div>

										<div className="inputDiv">
											<button
												className="login-btn"
												type="submit"
											>
												{/* <FormattedMessage
													id="signIn"
													defaultMessage={`Get Reset Link`}
												/> */}
												Get Reset Link
												{this.state.isLoggingIn && (
													<CircularProgress
														className="loader"
														color="primary"
														size={20}
													/>
												)}
											</button>
											<p className="link">
												{/* <FormattedMessage
													id="login.heading4"
													defaultMessage={` Don’t have an account? `}
												/> */}
												Don’t have an account? &nbsp;
												<Link to="/register">
													<span className="forget">
														{/* <FormattedMessage
															id="login.heading3"
															defaultMessage={`Sign up here`}
														/> */}
														Sign up here
													</span>
												</Link>
											</p>
										</div>
									</form>
								</div>

								<hr className="orLine"></hr>

								<div className="social">
									<GoogleLogin
										clientId={socialKeys.GOOGLE}
										onSuccess={googleCallbacks.onSuccess({
											onRequireSignUp: this.socialSignUp,
										})}
										onFailure={googleCallbacks.onFailure}
										buttonText="Login"
										render={(renderProps) => (
											<button
												onClick={renderProps.onClick}
												disabled={renderProps.disabled}
												className="button"
											>
												<img
													src="/icons/google.svg"
													alt="google"
												/>
												<p>
													Login with Google
													{/* <FormattedMessage
														id="login.heading3"
														defaultMessage={`Login with Google`}
													/> */}
												</p>
											</button>
										)}
									/>

									<TwitterLoginComponent
										authCallback={twitterCallback({
											onRequireSignUp: this.socialSignUp,
										})}
									>
										<button
											elevation={2}
											className="button"
										>
											<img
												src="/icons/twitter.svg"
												alt="twitter"
											/>
											<p>
												Login with Twitter
												{/* <FormattedMessage
													id="login.heading3"
													defaultMessage={`Login with Twitter`}
												/> */}
											</p>
										</button>
									</TwitterLoginComponent>

									<FacebookLogin
										appId={socialKeys.FACEBOOK}
										callback={facebookCallback({
											onRequireSignUp: this.socialSignUp,
										})}
										render={(renderProps) => (
											<button
												onClick={renderProps.onClick}
												disabled={
													renderProps.isDisabled
												}
												elevation={2}
												className="button"
											>
												<img
													src="/icons/facebook.svg"
													alt="facebook"
												/>
												<p>
													Login with Facebook
													{/* <FormattedMessage
														id="login.heading3"
														defaultMessage={`Login with Facebook`}
													/> */}
												</p>
											</button>
										)}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withAlert(FrogetPassword);
