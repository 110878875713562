/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
// import { useMediaQuery } from "react-responsive";
import axiosConfig from "../../Utils/axiosConfig";
import {
	allLikedStatus,
	// getAllStatusURL,
	changeStatusURL,
	deleteStatusURL,
} from "../../Utils/Constants";
import StatusCard from "../Extras/StatusCard/StatusCard";
import CardSkeleton from "../Skeleton/CardSkeleton/CardSkeleton";
import styles from "./LikedStatus.module.css";
import { FormattedMessage } from "react-intl";
// import { useHistory } from "react-router";
// import { IconButton } from "@material-ui/core";
// import Arrows from "../../Icons/Arrows";
import CancelIcon from "@material-ui/icons/Cancel";
import EditStatusModal from "../Extras/EditStatusModal/EditStatusModal";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";
import formatDate from "date-fns/format";
import EmptyState from "../Extras/EmptyState";
import { useSelector } from "react-redux";
// import { Alert as MuiAlert } from "@material-ui/lab";
import useAlert from "../Extras/Alert/useAlert";

const LIKED_PAGE_LIMIT = 20;
export default function LikedThoughts(prop) {
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [paginationLoading, setPaginationLoading] = useState(false);
	// const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const [searchText, setSearchText] = useState("");
	const [popEditStatus, setPopEditStatus] = useState(false);
	const [editStatusContent, setEditStatusContent] = useState(null);
	const { showAlert } = useAlert();
	let offset = useRef(0);
	let end = useRef(false);

	useEffect(() => {
		getLikedStatus();
	}, []);

	useEffect(() => {
		getLikedStatus(prop.searchText);
	}, [prop.searchText]);

	const onEdit = (status) => {
		setPopEditStatus(true);
		setEditStatusContent(status);
	};

	const onDeactivate = (id) => {
		const form = new FormData();
		form.append("statusId", id);
		axiosConfig
			.post(changeStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert("Thought status has been changes", {
					header: "Status changed",
					buttonText: "Go Back",
				});
				getLikedStatus("Refresh Liked Status");
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert(err.message, {
							header: err.error,
							buttonText: "Try Again",
						});
					}
				} else {
					showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	const onDelete = (id) => {
		const form = new FormData();
		form.append("statusId", id);
		axiosConfig
			.post(deleteStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert("Your status has been deleted", {
					header: "Status Deleted",
					buttonText: "Go Back",
				});
				getLikedStatus("Refresh Liked Status");
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
							}
						);
					} else {
						showAlert(err.message, {
							header: err.error,
							buttonText: "Try Again",
						});
					}
				} else {
					showAlert("Reqest Falied", {
						buttonText: "Try Again",
					});
				}
			});
	};

	const setCloseEditStatus = () => {
		setPopEditStatus(false);
	};

	const getLikedStatus = useCallback((sear) => {
		if (isLoading || end.current) return;
		const activeForm = new FormData();
        setLoading(true);
        
		let URL = `${allLikedStatus}?limit=${LIKED_PAGE_LIMIT}&offset=${offset.current}`;
		if (sear !== undefined) {
			offset.current = 0;
			if (sear === "Refresh Liked Status") {
				URL = `${allLikedStatus}?limit=${LIKED_PAGE_LIMIT}&offset=${offset.current}`;
			} else {
				URL = `${allLikedStatus}?limit=${LIKED_PAGE_LIMIT}&offset=${offset.current}&search_phrase=${sear}`;
			}
		}
		axiosConfig
			.get(URL, activeForm)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if (sear === undefined)
					setData([...data, ...(res.data.body || res.data.result)]);
				else setData([...(res.data.body || res.data.result)]);
				setLoading(false);
				offset.current += LIKED_PAGE_LIMIT;
                if (!res.data.next) {
                     end.current = true
                };
			})
			.catch((err) => {
				setLoading(false);
			});
	});

	const setLoading = (state) => {
		if (offset.current !== 0) {
			setPaginationLoading(state);
		} else {
			setIsLoading(state);
		}
	};

	const handleScroll = useCallback((e) => {
		const bottom =
			e.target.scrollHeight - Math.round(e.target.scrollTop) <=
			e.target.clientHeight + 200;
		if (bottom) {
			getLikedStatus();
		}
	});

	const handleDislike = (id) => {
		setData((prev) => prev.filter(({ status }) => status.pk !== id));
	};

	const searchHandler = (text) => {
		setSearchText(text);
		// if (text === "")
		// 	setSearchText("");
		getLikedStatus(text);
	};

	const isMobile = window.screen.width < 768;

	return (
		<>
			{!isMobile ? (
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<div className={styles.left} onScroll={handleScroll}>
							<div className={styles.header}>
								<h2
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0D0F22",
									}}
								>
									<FormattedMessage
										id="likedThoughts"
										defaultMessage={`Liked Thoughts`}
									/>
								</h2>
							</div>
							<div className={styles.searchContainer}>
								<img
									src="/Search.svg"
									className="ml-2 mr-1"
									alt=""
								/>
								<input
									type="text"
									value={searchText}
									className={styles.input}
									onChange={(e) =>
										searchHandler(e.target.value)
									}
									placeholder={`Search Liked Thoughts`}
								/>
								{searchText !== "" && (
									<CancelIcon
										onClick={() => setSearchText("")}
										style={{
											margin: "auto 0",
											color:
												theme === "dark"
													? "#7F89BE"
													: "#4A517E",
										}}
									/>
								)}
							</div>

							<div className={styles.c}>
								<Content
									theme={theme}
									items={data}
									isLoading={isLoading}
									handleDislike={handleDislike}
									onEdit={onEdit}
									onDelete={onDelete}
									onDeactivate={onDeactivate}
								/>

								{paginationLoading && (
									<div className={styles.cards}>
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
										<CardSkeleton width={"35%"} />
									</div>
								)}
							</div>
						</div>
						<EditStatusModal
							show={popEditStatus}
							onHide={setCloseEditStatus}
							status={editStatusContent}
							fetchMyStatus={getLikedStatus}
							liked={true}
						/>
					</div>
				</div>
			) : (
				<div style={{ height: "100%", overflow: "auto" }}>
					<div className={styles.content}>
						<Content
							theme={theme}
							items={data}
							isLoading={isLoading}
							handleDislike={handleDislike}
						/>

						{paginationLoading && (
							<div className={styles.cards}>
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
								<CardSkeleton width={"100%"} />
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
}

const Content = ({
	items,
	isLoading,
	handleDislike,
	theme,
	onEdit,
	onDelete,
	onDeactivate,
}) => {
	const isMobile = window.screen.width < 768;

	if (isLoading)
		return (
			<>
				<div className={styles.cards}>
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
					<CardSkeleton width={!isMobile ? "20rem" : "100%"} />
				</div>
			</>
		);
	else {
		const groups = items.reduce((acc, item) => {
			const createdDate = new Date(
				item.liked_at ||
					item.status.last_updated_at ||
					item.status.created_at
			);

			let tag = "";
			if (isToday(createdDate)) {
				tag = "Today";
			} else if (isYesterday(createdDate)) {
				tag = "Yesterday";
			} else {
				tag = formatDate(createdDate, "do MMMM");
			}

			if (acc[tag]) {
				return { ...acc, [tag]: [...acc[tag], item.status] };
			} else {
				return { ...acc, [tag]: [item.status] };
			}
		}, {});
		const tags = Object.keys(groups);
		return (
			<>
				{tags?.length > 0 ? (
					tags.map((tag, i) => (
						<React.Fragment key={i}>
							<div className={styles.section}>
								<h6
									className={styles.time}
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0D0F22",
									}}
								>
									{tag}
								</h6>
							</div>
							<div className={styles.cards}>
								{groups[tag].reverse().map((status, idx) => (
									<StatusCard
										status={status}
										onDislike={() =>
											handleDislike(status.pk)
										}
										key={status.pk}
										idx={
											idx %
											(groups[tag].length <
											LIKED_PAGE_LIMIT
												? groups[tag].length
												: LIKED_PAGE_LIMIT)
										}
										width={!isMobile ? "20rem" : "100%"}
										height="13rem"
										onEdit={onEdit}
										onDelete={onDelete}
										onDeactivate={onDeactivate}
									/>
								))}
							</div>
						</React.Fragment>
					))
				) : (
					<EmptyState
						style={{ marginTop: "1rem" }}
						id="suggestions-box"
						heading="Hey there! What are you upto?"
						imageUrl="/vectors/empty-states/noThoughts.svg"
						subHeading="Post a status about anything that’s on your mind and get matched with people thinking about the same thing."
					/>
				)}
			</>
		);
	}
};
