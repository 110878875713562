import React, { useState, useRef } from "react";

import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";

import styles from "./ImagePreview.module.css";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
} from "@material-ui/core";
import Button1 from "../Buttons/Button1/Button1";
import Button2 from "../Buttons/Button2/Button2";
import CloseIcon from "../../../Icons/CloseIcon";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
	dialoglg: {
		minWidth: "30vw",
		background: "#fff",
	},
	dialogdark: {
		minWidth: "30vw",
		color: "#fff",
		background: "#121528",
	},
});

export default function ImagePreview(props) {
	const [isOpen, setIsOpen] = useState(false);
	const imgRef = useRef(null);
	const classes = useStyles();

	const theme = useSelector((state) => state.theme.theme);

	const onOpen = () => {
		setIsOpen(true);
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const setImgRef = () => {
		imgRef.current.click();
	};

	const { image, changeImage, removeImage } = props;

	return (
		<div className={`${styles.container} `}>
			{/* <WallpaperIcon
        style={{ color: "blue", cursor: "pointer" }}
      /> */}
			<img
				src={image}
				onClick={onOpen}
				width="60px"
				height="42px"
				className={styles.image1}
				alt=""
			/>
			<Dialog
				maxWidth="lg"
				classes={{
					paper:
						theme === "dark"
							? classes.dialogdark
							: classes.dialoglg,
				}}
				open={isOpen}
				onClose={onClose}
				isCentered
			>
				<div className={styles.heading}>
					<DialogTitle>Background Image</DialogTitle>
					<span className={styles.closeBtn} onClick={onClose}>
						<CloseIcon />
					</span>
				</div>

				<DialogContent>
					<div className={styles.image}>
						<img
							src={image}
							className={styles.currentImage}
							alt="Background"
						/>
					</div>
				</DialogContent>

				<DialogActions>
					<Button1
						onClick={() => {
							removeImage();
							setIsOpen(false);
						}}
						variant="contained"
						color="primary"
						style={{ marginRight: "1rem" }}
					>
						Remove
					</Button1>
					<Button2
						variant="contained"
						color="primary"
						onClick={() => setImgRef()}
						style={{ marginRight: "1rem" }}
					>
						Change
					</Button2>
					<input
						type="file"
						style={{ display: "none" }}
						onChange={(e) => {
							changeImage(e);
							setIsOpen(false);
						}}
						ref={imgRef}
					/>
				</DialogActions>
			</Dialog>
		</div>
	);
}
