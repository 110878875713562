import {
  JOIN_PING,
  LOGOUT_REQUEST,
  MAPS_PLACES_LOADED,
  NOTIFICATIONS_POLLED,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECTED,
} from "../Actions/actionTypes";

const initialState = {
  chatSocket: false,
  socketConnectionFailed: false,
  mapsPlacesLoaded: false,
  joinGroupPing: false,
  notificationsPolled: false,
};
const deviceParametersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SOCKET_CONNECTED:
      return { ...state, chatSocket: true, socketConnectionFailed: false };
    case SOCKET_DISCONNECTED:
      return { ...state, socketConnectionFailed: true };
    case LOGOUT_REQUEST:
      const { mapsPlacesLoaded, ...newState } = initialState;
      return { ...state, ...newState };
    case MAPS_PLACES_LOADED:
      return { ...state, mapsPlacesLoaded: true };
    case JOIN_PING:
      return { ...state, joinGroupPing: action.payload };
    case NOTIFICATIONS_POLLED:
      return { ...state, notificationsPolled: true };
    default:
      return state;
  }
};

export default deviceParametersReducer;
