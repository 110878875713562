import React from 'react'

const ArrowDown = ({ color }) => {
    return (
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.32031 0.667969C8.76329 0.667969 9.12939 0.997148 9.18732 1.42424L9.19531 1.54297L9.19531 19.043C9.19531 19.5262 8.80356 19.918 8.32031 19.918C7.87733 19.918 7.51124 19.5888 7.4533 19.1617L7.44531 19.043L7.44531 1.54297C7.44531 1.05972 7.83706 0.667969 8.32031 0.667969Z" fill={color}/>
            <path d="M14.7301 11.367C15.0711 11.0245 15.6251 11.0234 15.9675 11.3643C16.2788 11.6743 16.3081 12.1603 16.0547 12.5034L15.9702 12.6018L8.94219 19.6601C8.63128 19.9723 8.14349 20.0007 7.8004 19.7453L7.70214 19.6601L0.672971 12.6018C0.33197 12.2594 0.333117 11.7054 0.675533 11.3644C0.98682 11.0544 1.47298 11.0271 1.815 11.282L1.91297 11.3669L8.32249 17.8023L14.7301 11.367Z" fill={color}/>
        </svg>
        
    )
}

export default ArrowDown
