import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import React, { useState } from "react";
import formatDistanceToNow from "date-fns/formatDistanceToNowStrict";
import { useHistory, useLocation } from "react-router-dom";
import { likeStatus } from "../../../Utils/Constants";
import Styles from "./SearchCard.module.css";
import { formatNumber } from "../../../Utils/numbers";

import axiosConfig from "../../../Utils/axiosConfig";
import { Grow, IconButton } from "@material-ui/core";
import ChatUserReview from "../ChatUserReview/ChatUserReview";
import { useSelector } from "react-redux";
import Username from "../Username";
import useAlert from "./../Alert/useAlert";
import OnlineBadge from "../StyledBadge";
import { getShortUserProfileUrl } from "./../../../Utils/Constants";
import ImagePopover from "../ImagePopover/ImagePopover";
import { timeConverter } from "../../../Utils/timeConversion";
import MessageIcon from "../../../Icons/MessageIcon";
import FavIcon from "../../../Icons/FavIcon";
import FavFilledIcon from "../../../Icons/FavFilledIcon";
import { gradients } from "../../../Utils/gradients";

const useStyles = (theme) => ({
	card: {
		width: (props) => props.width,
		height: (props) => props.height,
		margin: "1% 1% 2.5% 1%",
		boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.02);",
		fontFamily: "Metropolis-Medium",
		[theme.breakpoints.down("xs")]: {
			width: "80%",
			marginBottom: "1%",
		},
	},
	iconButton: { padding: "2px" },
	footer: {
		padding: "0 !important",
		height: "20%",
		backgroundColor: "white",

		fontSize: "12px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
	},
	avatar: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	view: {
		fontFamily: "Avenir-Demibold",
		color: "#626262",
		textTransform: "none",
	},
});

const StatusCard = ({ status, classes, ...props }) => {
	const { showAlert } = useAlert();
	const userID = useSelector((state) => state.auth?.user_data?.userID);
	const background = {
		height: "80%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-evenly",
		padding: "5px 15px 0 5px",
		background: gradients[status.background_color],
	};

	const path = useLocation().pathname;

	const history = useHistory();

	const [liked, setLiked] = useState(status.is_liked);
	const setStatusLike = () => {
		setLiked((prev) => !prev);

		const form = new FormData();
		form.append("statusId", status.pk);

		axiosConfig
			.post(likeStatus, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					isLiked: !this.state.isLiked,
				});
			})
			.catch((err) => {});
	};
	const [user, setUser] = useState({});
	const [userLoading, setUserLoading] = useState(false);
	const getShortUSerProfile = () => {
		let newUserid = status.author.pk || status.author.userID;
		if (newUserid === user.userID) {
			return;
		}

		if (Object.keys(user).length > 0) {
			return;
		}
		const request = new FormData();
		request.append("userId", status.author.pk || status.author.userID);
		setUserLoading(true);
		axiosConfig
			.post(getShortUserProfileUrl, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				setUserLoading(false);
				setUser(res.data.body);
			})
			.catch((err) => {
				setUserLoading(false);
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						setUser({ error: true });
					}
				}
			});
	};

	const viewChat = () => {
		history.push(`/chat/group/${status.pk}`);
	};
	const viewProfile = (e) => {
		e.stopPropagation();
		if (Number(userID) !== Number(status.userId)) {
			history.push(`/profile/${status.userId}`);
		} else {
			history.push("/myprofile");
		}
	};

	let lastUpdatedAt = formatDistanceToNow(
		new Date(status.last_activity || status.last_updated_at),
		{
			addSuffix: false,
		}
	);

	let profileLink = status.userId;
	if (Number(userID) !== Number(status.userId)) {
		profileLink = "/profile/" + status.userId;
	} else {
		profileLink = "/myprofile";
	}

	const additionalUsers = status.userCount - status.profiles.length;
	const [mounted, setMounted] = useState(false);
	React.useEffect(() => {
		setMounted(true);
	}, []);
	const explore = path.includes("explore");
	return (
		<Grow
			duration={1000}
			in={mounted}
			exit={true}
			{...(mounted ? { timeout: props.idx * 100 } : {})}
		>
			<Card className={classes.card}>
				<CardContent style={background}>
					<div className={Styles.heading}>
						<ChatUserReview
							data={{
								...status,
								profileURL: status.avatar || status.profileURL,
								from: status.userId,
							}}
							profileLink={profileLink}
							user={false}
							userProfile={user}
							userLoading={userLoading}
						>
							<OnlineBadge
								invisible={explore ? false : true}
								last_active={
									explore ? status.author.last_active : ""
								}
								online={explore ? status.author.online : ""}
								ripple={true}
							>
								<Avatar
									alt={status.username}
									onClick={(e) => e.stopPropagation()}
									onMouseOver={
										Number(userID) !==
											Number(status.userId) &&
										getShortUSerProfile
									}
									src={status.avatar || status.profileURL}
								>
									{(status.username || status.full_name) &&
										(status.username ||
											status.full_name)[0].toUpperCase()}
								</Avatar>
							</OnlineBadge>
						</ChatUserReview>
						<p onClick={viewProfile} className={Styles.name}>
							<Username value={status.username} />
						</p>
					</div>
					<Typography
						onClick={viewChat}
						className={
							status.content.length <= 90
								? status.content.length < 32
									? Styles.content2
									: Styles.content1
								: Styles.content
						}
					>
						{status.content}
					</Typography>

					{status.background_image && (
						<ImagePopover
							className={Styles.imgCont}
							background_image={status.background_image}
						>
							<img
								src={status.background_image}
								width="48px"
								height="32px"
								className={Styles.image}
								alt=""
							/>
						</ImagePopover>
					)}
				</CardContent>
				<CardContent className={classes.footer}>
					<div className={Styles.users}>
						<AvatarGroup max={3}>
							{status.profiles.map((profile, index) => {
								return (
									<Avatar
										alt=""
										src={profile.profileURL}
										key={index}
										className={classes.avatar}
									/>
								);
							})}
						</AvatarGroup>
						{additionalUsers > 0 && (
							<span className={Styles.count}>
								+{additionalUsers} Users
							</span>
						)}
					</div>
					<div className={Styles.footerRight}>
						<div className={Styles.dateTime}>
							<img
								style={{
									marginTop: "-1px",
									width: "13px",
									height: "13px",
								}}
								src="/vectors/empty-states/Clock.svg"
								alt=""
							/>
							<span> {timeConverter(lastUpdatedAt)}</span>
						</div>
						<div className={Styles.likes}>
							<IconButton size="small" onClick={setStatusLike}>
								{liked ? (
									<FavFilledIcon width="12.5" height="12" />
								) : (
									<FavIcon width="12.5" height="12" />
								)}
							</IconButton>
							<span className={Styles.count}>
								{formatNumber(
									!status.is_liked
										? liked
											? status.total_likes + 1
											: status.total_likes
										: liked
										? status.total_likes
										: status.total_likes - 1
								)}
							</span>
						</div>
						<div className={Styles.comments}>
							<IconButton size="small" onClick={viewChat}>
								<MessageIcon width="12" height="12" />
							</IconButton>
							<span className={Styles.count}>
								{formatNumber(status.message_count)}
							</span>
						</div>
					</div>
				</CardContent>
			</Card>
		</Grow>
	);
};

export default withStyles(useStyles)(StatusCard);
