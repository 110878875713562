import { GETCONTACTSCOUNT, GETCONTACTSCOUNTERROR } from "../Actions/actionTypes";

const initialState = {
  contactCount: [],
  error: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GETCONTACTSCOUNT:
      return {
        ...state,
        contactCount: action.payload,
      };
    case GETCONTACTSCOUNTERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
}
