import React from "react";

import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import styles from "./Footer.module.css";

import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

function Footer() {
	return (
		<>
			<div className={styles.container}>
				<div className={styles.blueBox}></div>
				<div className={styles.cont}>
					<div className={styles.info}>
						<p className={styles.brand}>
							<img
								src="/icons/bditto_logo_inverse.png"
								alt="BDITTO"
							/>
						</p>
						<p>
							<FormattedMessage
								id="landingPage.footer.heading"
								defaultMessage={`Share your thoughts in real-time and instantly get connected to
              people with similar thoughts.`}
							/>
						</p>
					</div>

					<div className={styles.options}>
						<div className={styles.options1}>
							<div className={styles.option}>
								<p className={styles.class}>
									<FormattedMessage
										id="landingPage.footer.heading2"
										defaultMessage={`Explore`}
									/>
								</p>
								<ul>
									<li className={styles.links}>
										<Link
											to={{
												pathname: "/landingPage",
												state: { block: "home" },
											}}
										>
											<FormattedMessage
												id="landingPage.navbar.home"
												defaultMessage={`Home`}
											/>
										</Link>
									</li>
									<li className={styles.links}>
										<Link to="/about">
											<FormattedMessage
												id="landingPage.footer.heading3"
												defaultMessage={`About`}
											/>
										</Link>
									</li>
									<li className={styles.links}>
										<Link to="/how-it-works">
											<FormattedMessage
												id="landingPage.navbar.howItWorks"
												defaultMessage={`How It Works`}
											/>
										</Link>
									</li>
									<li className={styles.links}>
										<Link to="/faqs">
											<FormattedMessage
												id="landingPage.footer.heading4"
												defaultMessage={`FAQs`}
											/>
										</Link>
									</li>
								</ul>
							</div>

							<div className={styles.option}>
								<p className={styles.class}>
									<FormattedMessage
										id="landingPage.footer.heading5"
										defaultMessage={`Legal`}
									/>
								</p>
								<ul>
									<li className={styles.links}>
										<Link to="/privacy">
											<FormattedMessage
												id="landingPage.footer.heading6"
												defaultMessage={`Privacy`}
											/>
										</Link>
									</li>
									<li className={styles.links}>
										<Link to="/user-agreement">
											<FormattedMessage
												id="landingPage.footer.heading7"
												defaultMessage={`User Agreement`}
											/>
										</Link>
									</li>
									<li className={styles.links}>
										<Link to="/guidelines">
											<FormattedMessage
												id="landingPage.footer.heading8"
												defaultMessage={`Guidelines`}
											/>
										</Link>
									</li>
								</ul>
							</div>
						</div>

						<div className={styles.option}>
							<p className={styles.class}>
								<FormattedMessage
									id="landingPage.footer.heading9"
									defaultMessage={`Contact`}
								/>
							</p>
							<ul>
								<li className={styles.links}>
									Pfaeffikonerstrasse 27, 8834 Schindellegi,
									Switzerland
								</li>
								<li className={styles.links}>
									<a href="mailto:hello@bditto.com">
										hello@bditto.com
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.footer}>
				<div className={styles.yellowCircle}></div>

				<p className={styles.copy}>
					{`© Bditto`} {new Date().getFullYear()},
					<FormattedMessage
						id="landingPage.footer.heading10"
						defaultMessage={` All Rights Reserved`}
					/>{" "}
				</p>
				<div className={styles.icons}>
					<a
						target="_blank"
						style={{ color: "white" }}
						rel="noopener noreferrer"
						href="https://www.instagram.com/bdittoapp/"
					>
						<InstagramIcon />
					</a>
					<a
						target="_blank"
						style={{ color: "white" }}
						rel="noopener noreferrer"
						href="https://twitter.com/bdittoapp"
					>
						<TwitterIcon />
					</a>
					<a
						target="_blank"
						style={{ color: "white" }}
						rel="noopener noreferrer"
						href="https://www.facebook.com/Bditto-105470858253412/"
					>
						<FacebookIcon />
					</a>
					<a
						target="_blank"
						style={{ color: "white" }}
						rel="noopener noreferrer"
						href="https://www.linkedin.com/company/bditto"
					>
						<LinkedInIcon />
					</a>
				</div>
			</div>
		</>
	);
}

export default Footer;
