import axios from "axios";
import { errorInterceptor } from "./axiosResponseInterceptor";
import { endpoint } from "./Constants";
import * as Encrypt from "./Encryption";

const axiosConfig = axios.create({
	baseURL: endpoint,
	headers: {
		"Content-Type": "multipart/form-data",
	},
});

export const axiosBaseConfig = axios.create({
	baseURL: endpoint,
	headers: {
		"Content-Type": "multipart/form-data",
	},
});

axiosBaseConfig.interceptors.request.use(
	function (config) {
		const Token = Encrypt.getDecryptedToken();
		console.log("toekn", Token);
		if (Token) config.headers["Authorization"] = "Token " + Token;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

axiosConfig.interceptors.request.use(
	function (config) {
		const Token = Encrypt.getDecryptedToken();
		if (Token) config.headers["Authorization"] = "Token " + Token;

		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
axiosConfig.interceptors.response.use(undefined, errorInterceptor);

export const bdittoAPIv2 = axios.create({
	baseURL: endpoint,
	headers: {
		"Content-Type": "application/json",
	},
});

bdittoAPIv2.interceptors.request.use(
	function (config) {
		const Token = Encrypt.getDecryptedToken();
		if (Token) config.headers["Authorization"] = "Token " + Token;

		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);
bdittoAPIv2.interceptors.response.use(undefined, errorInterceptor);

export default axiosConfig;
