import { axiosBaseConfig } from "../../../Utils/axiosConfig";
import { getNotificationURL } from "../../../Utils/Constants";
import {
	getFetchedNotificationCount,
	shouldFetchNotifications,
} from "../../selectors/notificationSelectors";
import {
	CLOSE_SINGLE_NOTIFICATION,
	FETCH_NOTIFICATIONS,
	INCREMENT_NOTIFICATION_COUNT,
	NEW_NOTIFICATION,
	NOTIFICATION_FETCH_ERROR,
	NOTIFICATION_LOADING,
	REMOVE_SINGLE_NOTIFICATION,
	SET_NEW_NOTIFICATIONS_PRESENT,
	SET_UNREAD_NOTIFICATION_COUNT,
	TOGGLE_NOTIFICATION,
} from "./types";

export const NOTIFICATION_FETCH_LIMIT = 30;

export const newNotification = (notification) => ({
	type: NEW_NOTIFICATION,
	payload: notification,
	key: new Date().getTime() + Math.random(),
});

const addFetchedNotifications = (notifications) => ({
	type: FETCH_NOTIFICATIONS,
	payload: notifications,
});

export const closeNotification = (key) => ({
	type: CLOSE_SINGLE_NOTIFICATION,
	key,
});

export const dismissNotification = (key) => ({
	type: REMOVE_SINGLE_NOTIFICATION,
	key,
});

const fetchError = () => ({ type: NOTIFICATION_FETCH_ERROR });

const notificationLoading = () => ({
	type: NOTIFICATION_LOADING,
});

export const setNewNotificationsPresent = (isPresent) => ({
	type: SET_NEW_NOTIFICATIONS_PRESENT,
	payload: isPresent,
});

export const setUnreadCount = (count) => ({
	type: SET_UNREAD_NOTIFICATION_COUNT,
	payload: count,
});

export const incrementCount = () => ({ type: INCREMENT_NOTIFICATION_COUNT });

export const fetchNotifications = () => async (dispatch, getState) => {
	const state = getState();
	const isValidCall = shouldFetchNotifications(state);
	const offset = getFetchedNotificationCount(state);
	if (isValidCall) {
		dispatch(notificationLoading());
		try {
			const params = { offset, limit: NOTIFICATION_FETCH_LIMIT };
			const res = await axiosBaseConfig.get(
				`${getNotificationURL}?limit=30&group_id=${
					state.groups.groupData.id ? state.groups.groupData.id : "1"
				}`,
				{ params }
			);
			if (res.status !== 200) throw new Error();
			console.log("NOTIFI", res.data.result);
			dispatch(addFetchedNotifications(res.data.result));
		} catch (err) {
			dispatch(fetchError());
		}
	}
};
