import React from "react";
import EmptyState from "../Extras/EmptyState";

const Error404Page = (props) => {
	return (
		<div
			style={{
				height: "100vh",
			}}
		>
			<EmptyState
				heading="Error 404! Looks like you’re lost"
				className="error404Page"
				imageUrl="/vectors/empty-states/404.svg"
				subHeading="The page you are looking for might have been removed, had its name changed or temporarily unavailable"
			/>
		</div>
	);
};
export default Error404Page;
