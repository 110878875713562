import React, { useState } from "react";
import { Avatar, makeStyles, Switch, withStyles } from "@material-ui/core";
import DialogModal from "../../../Extras/DialogModal";
import styles from "./GroupModal.module.css";
import "./CreateGroupModal.scss";
import CloseIcon from "../../../../Icons/CloseIcon";
import axiosConfig from "../../../../Utils/axiosConfig";
import { createGroupURL } from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import Button2 from "../../../Extras/Buttons/Button2/Button2";
import { useSelector } from "react-redux";

const StyledSwitch = withStyles((theme) => ({
	root: {
		height: "3rem",
		width: "5rem",
		transform: "rotateZ(90deg)",
	},
	checked: {
		transform: "translateX(28px) !important",
		"& + $track": {
			backgroundColor: "transparent !important",
			opacity: "1 !important",
		},
	},
	switchBase: {
		left: 8,
		top: 6,
	},
	track: {
		background: "linear-gradient(92.12deg, #00C6FF 0%, #0072FF 100%)",
		borderRadius: 22,
		opacity: 1,
	},
	thumb: {
		width: "1.1rem",
		height: "1.1rem",
		background: "#fff",
	},
}))(Switch);

const useStyles = makeStyles({
	cancel: {
		borderRadius: "6px",
		fontFamily: "Roboto",
		textTransform: "none",
		fontWeight: "400",
		marginRight: "1rem",
	},
	cancel: {
		marginLeft: "1rem",
		fontSize: "1rem",
		fontWeight: "400",
		color: "#fff",
		textTransform: "none",
		background: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		borderRadius: "1rem",
		border: "none",
	},
	share: {
		marginRight: "1rem",
		fontSize: ".5rem",
		fontWeight: "400",
		textTransform: "none",
		background: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		backgroundImage: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		backgroundOrigin: "borderBox",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		backgroundClip: "border-box text",
		border: "1px solid transparent",
		borderImageSlice: "1",
		borderImage: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		mozborderimage: "-moz-linear-gradient(90deg, #FC466B, #3F5EFB)",
		WebKitBorderImage: "-webkit-linear-gradient(90deg, #FC466B, #3F5EFB)",
	},
	save: {
		borderRadius: "6px",
		textTransform: "none",
		fontWeight: "400",
		marginLeft: "1rem",
	},
});

const CreateGroupModal = (props) => {
	const { showAlert } = useAlert();
	const isMobile = window.screen.width < 724;
	const classes = useStyles();
	const [public1, setPublic] = useState(true);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadImage, setuploadImage] = useState("");
	const [UploadUrl, setUploadUrl] = useState(null);
	const [grpName, setGrpname] = useState("");
	const [bio, setBio] = useState("");
	const imgRef = React.createRef();

	const theme = useSelector((state) => state.theme.theme);

	const handleUploadImage = (event) => {
		if (event.target.files[0].size < 5242880) {
			setuploadImage(event.target.files[0]);
			setUploadUrl(URL.createObjectURL(event.target.files[0]));
		} else {
			showAlert("image size must be less than 5MB.", {
				header: "File size too large",
				buttonText: "Try another one",
			});
		}
	};

	const handleBio = (e) => {
		if (e.length < 250) {
			setBio(e);
		} else {
			showAlert("bio must be less than 250 characters ", {
				header: "max 250 characters.",
				buttonText: "close",
			});
		}
	};

	const handleCamClick = () => {
		imgRef.current.click();
	};

	const createGroup = () => {
		setIsLoading(true);
		const form = new FormData();
		form.append("name", grpName);
		form.append("type", public1 ? "public" : "private");
		form.append("bio", bio);
		form.append("avatar", uploadImage);
		axiosConfig
			.post(createGroupURL, form)
			.then((res) => {
				showAlert("Successfull", {
					header: "Group Created Successfully",
					buttonText: "Close",
				});
				setGrpname("");
				setuploadImage("");
				setBio("");
				props.getMyGroups();
				setIsLoading(false);
				props.onHide();
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert(JSON.stringify(err.response.data?.error), {
					header: err.response.data?.message,
					buttonText: "Try Again",
				});
			});
	};

	const handleCancel = () => {
		setGrpname("");
		setBio("");
		setuploadImage("");
		props.onHide();
	};

	return (
		<DialogModal
			{...props}
			width={isMobile ? "lg" : "xs"}
			maxWidth="true"
			fullWidth={isMobile ? "true" : "false"}
			className={`${theme === "dark" ? "rootDark" : "rootlg"} ${
				styles.root
			}`}
		>
			<div
				className={`${styles.head} ${
					theme === "dark" ? "dark-heading" : "lg-heading"
				}`}
			>
				<h4> Create a group</h4>
				<span className={styles.crossIcon} onClick={props.onHide}>
					<CloseIcon />
				</span>
			</div>

			<div className={styles.container}>
				<div className={styles.infoWrapper}>
					<div className={styles.top}>
						<div className={styles.headerInfo}>
							<Avatar
								className={styles.ava}
								style={{
									width: "6.5rem",
									height: "6.5rem",
									margin: "0.5rem 0",
									fontSize: "2.5rem",
									background:
										theme === "dark"
											? "#002a55"
											: "#00C6FF",
								}}
								src={uploadImage ? UploadUrl : ""}
							></Avatar>

							<img
								className={styles.icon1}
								src="/Home/cam.svg"
								onClick={handleCamClick}
								alt="cancel"
							/>
							<input
								type="file"
								style={{ display: "none" }}
								ref={imgRef}
								onChange={handleUploadImage}
								accept="image/jpg, image/png, image/jpeg"
							/>

							<div className="mt-2 mb-2">
								<div className={styles.globalSwitcher}>
									<StyledSwitch
										onChange={() =>
											public1
												? setPublic(false)
												: setPublic(true)
										}
										checked={!public1}
									/>
									<div
										style={{
											display: "flex",
											flexDirection: "column",
										}}
									>
										<span
											className={
												public1
													? `${styles.selected} ${
															theme === "dark"
																? "selected-dark"
																: "selected-lg"
													  }`
													: `${styles.switchText} ${
															theme === "dark"
																? "text-dark"
																: "text-lg"
													  } `
											}
											onClick={() => setPublic(true)}
										>
											Public
										</span>

										<span
											className={
												!public1
													? `${styles.selected} ${
															theme === "dark"
																? "selected-dark"
																: "selected-lg"
													  }`
													: `${styles.switchText} ${
															theme === "dark"
																? "text-dark"
																: "text-lg"
													  } `
											}
											onClick={() => setPublic(false)}
										>
											Private
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.heading}>
							<input
								value={grpName}
								onChange={(e) => setGrpname(e.target.value)}
								placeholder="Group Name"
								className={styles.groupInput}
							/>
							<div
								style={{
									color:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
								className={styles.grpDanger}
								id="groupname-error"
							/>
						</div>

						<textarea
							value={bio}
							type="text"
							placeholder="Group description"
							className={styles.groupInputText}
							onChange={(e) => handleBio(e.target.value)}
						/>
						<div
							style={{
								color: theme === "dark" ? "#d9def4" : "#0d0f22",
							}}
							className={styles.grpDanger}
							id="groupbio-error"
						/>
					</div>
					<div
						className={styles.members}
						style={{ marginTop: "0.5rem" }}
					></div>

					<div className={styles.buttonsCreate}>
						<div style={{ marginRight: 10 }}>
							<Button1
								variant="outlined"
								onClick={handleCancel}
								className={classes.share}
							>
								Cancel
							</Button1>
						</div>
						<Button2
							variant="contained"
							onClick={() => {
								if (grpName && !bio) {
									document.getElementById(
										"groupname-error"
									).innerHTML = "";
									document.getElementById(
										"groupbio-error"
									).innerHTML = "Enter group bio";
								} else if (!grpName && bio) {
									document.getElementById(
										"groupbio-error"
									).innerHTML = "";
									document.getElementById(
										"groupname-error"
									).innerHTML = "Enter group name";
								} else if (!grpName) {
									document.getElementById(
										"groupname-error"
									).innerHTML = "Enter group name";
									// showAlert("Group Name", {
									// 	header: "Please provide group name",
									// 	buttonText: "Close",
									// });
								} else if (!bio) {
									document.getElementById(
										"groupbio-error"
									).innerHTML = "Enter group bio";
									// showAlert("Group Description", {
									// 	header: "Please enter group description",
									// 	buttonText: "Close",
									// });
								} else {
									document.getElementById(
										"groupname-error"
									).value = "";
									document.getElementById(
										"groupbio-error"
									).innerHTML = "";
									createGroup();
								}
							}}
							className={classes.cancel}
						>
							{isLoading ? "loading" : "Done"}
						</Button2>
					</div>
				</div>
			</div>
		</DialogModal>
	);
};

export default CreateGroupModal;
