import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	withStyles,
	IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import styles from "../Info.module.css";
import faqs from "./faqContent";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import Arrows, { ArrowDown, ArrowRight } from "../../../Icons/Arrows";
import { useSelector } from "react-redux";

const StylesHead = withStyles({
	root: {
		background: "#FFFFFF",
		borderRadius: "8px",
		borderTop: "none",
		borderBottom: "none",
		border: "none",
	},
})(AccordionSummary);

export default function Faq() {
	let history = useHistory();
	const isMobile = window.screen.width < 768;
	const [expandedPanel, setExpandedPanel] = useState(false);

	const handleAccordionChange = (panel) => (event, isExpanded) => {
		setExpandedPanel(isExpanded ? panel : false);
	};

	const [filteredData, setFilteredData] = useState(faqs);
	const [searchText, setSearchText] = useState("");
	const theme= useSelector((reduxState) => reduxState.theme.theme)

	useEffect(() => {
		if (searchText?.length > 0) {
			setFilteredData(
				faqs.filter((faq) =>
					faq.head.toLowerCase().includes(searchText.toLowerCase())
				)
			);
		} else {
			setFilteredData(faqs);
		}
	}, [searchText]);

	return (
		<div className="App">
			{!isMobile && <div className={styles.header}>
				{isMobile && (
					<IconButton
						size="small"
						variant="contained"
						onClick={history.goBack}
					>
						<Arrows.Left viewBox="0 0 27 27" />
					</IconButton>
				)}
				<h2
					style= {{ color: theme === "dark" ? "#d9def4" : "#0D0F22" }}
				>
					FAQs
				</h2>
			</div>}

			<div className={styles.searchContainer} style={{ border: `1px solid ${theme === "dark" ? "#7f89be" : "#4A517E"}` }}>
				<SearchIcon
					fontSize={isMobile ? "small" : "large"}
					style={{ color: theme === "dark" ? "#7f89be" : "#4A517E" , fontSize: "1.5rem" }}
				/>
				{/* <Searchicon.LargeOutline /> */}
				<input
					type="text"
					value={searchText}
					className={styles.input}
					onChange={(e) => {
						setSearchText(e.target.value);
					}}
					placeholder="How can we help you?"
				/>
				{searchText && (
					<Close
						onClick={() => setSearchText("")}
						style={{
							margin: "0",
							color: theme === "dark" ? "#d9def4" : "#0D0F22",
							cursor: "pointer"
						}}
					/>
				)}
			</div>

			<div className={styles.rightFaqWrapper}>
				{filteredData.map((faq, index) => (
					<Accordion
						expanded={expandedPanel === index}
						onChange={handleAccordionChange(index)}
						className={styles.boxes}
					>
						<StylesHead className={styles.boxHead}>
							<div className={styles.popoverHead} style={{ color: theme === "dark" ? "#7f89be" : "#4A517E" }}>
								<div>{faq.head}</div>
								<div>
									<button className={styles.popoverBtn}>
										{expandedPanel !== index ? (
											// <KeyboardArrowRight style={{ fontSize: "2rem" }} />
											<ArrowRight theme= { theme } />
										) : (
											// <KeyboardArrowDown style={{ fontSize: "2rem" }} />
											<ArrowDown theme= { theme } />
										)}
									</button>
								</div>
							</div>
						</StylesHead>
						<AccordionDetails className={styles.boxbody}>
							<div
								style= {{ color: theme === "dark" ? "#d9def4" : "#0D0F22" }}
							>
								{faq.content}
							</div>
						</AccordionDetails>
					</Accordion>
				))}
				{/* <div style={{ height: "5rem" }}></div> */}
			</div>
		</div>
	);
}
