import { SET_CHAT_LOADING } from "../../Actions/messages/types";
import createReducer from "../utils/createReducer";

const INITIAL_STATE = { isChatLoading: false };

const handleLoading = (state, { payload }) => ({ ...state, isChatLoading: payload });

const chatMetaReducer = createReducer(INITIAL_STATE, {
  [SET_CHAT_LOADING]: handleLoading,
});

export default chatMetaReducer;
