export const loadState = () => {
	try {
		const serializedState = localStorage.getItem("state");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return undefined;
	}
};

export const saveState = (state, options) => {
	const { exclude = [] } = options;
	const serializableState = Object.keys(state).reduce(
		(acc, key) =>
			!exclude.includes(key) ? { ...acc, [key]: state[key] } : acc,
		{}
	);
	try {
		const serializesState = JSON.stringify(serializableState);
		localStorage.setItem("state", serializesState);
	} catch (err) {}
};
