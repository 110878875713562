import DEV_CONFIG from "./environments/development.js";
import TEST_CONFIG from "./environments/test.json";
import PRODUCTION_CONFIG from "./environments/production.js";
import LOCAL_CONFIG from "./environments/local.json";
import COMMON_CONFIG from "./common";

const environment = process.env.REACT_APP_ENV;

export const environments = {
  DEV: "development",
  PROD: "production",
  TEST: "test",
};
const genConfig = (env) => {
  switch (env) {
    case environments.DEV:
      return DEV_CONFIG;
    case environments.TEST:
      return TEST_CONFIG;
    case environments.PROD:
      return PRODUCTION_CONFIG;
    default:
      return LOCAL_CONFIG;
  }
};

const config = { ...genConfig(environment), ...COMMON_CONFIG };

export default config;
