import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";
import { Helmet } from "react-helmet";

// import { useMediaQuery } from "react-responsive";
import Skeleton from "@material-ui/lab/Skeleton";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import BlockIcon from "@material-ui/icons/Block";
import DeleteIcon from "@material-ui/icons/Delete";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";

import SearchCard from "../../Extras/SearchCard/SearchCard";
import Mobile from "./MobileDisplay/Mobile";
import CardSkeleton from "../../Skeleton/CardSkeleton/CardSkeleton";

import axiosConfig from "../../../Utils/axiosConfig";
import {
	friendRequestURL,
	getUserProfileURL,
	getUserStatusURL,
	blockUnblock,
	userReport,
} from "../../../Utils/Constants";

import styles from "./UserProfile.module.css";
import withAlert from "../../Extras/Alert/withAlert";
import OnlineBadge from "./../../Extras/StyledBadge";
import { Dialog, Grid, IconButton, Tooltip } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import LanguageIcon from "@material-ui/icons/Language";
import { FormattedMessage } from "react-intl";
import Arrows from "../../../Icons/Arrows";
import ReportIcon from "../../../Icons/ReportIcon";
import Username from "../../Extras/Username";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import Button2 from "../../Extras/Buttons/Button2/Button2";
import Button1 from "../../Extras/Buttons/Button1/Button1";
import SidebarContextMenu from "../../Home/Chats/Sidebar/SidebarContextMenu";

const useStyles = (theme) => ({
	cancel: {
		fontFamily: "Roboto",
		textTransform: "none",
		height: "2.5rem",
		width: "12rem",
		fontWeight: "400",
		marginRight: "1rem",
	},
	save: {
		fontFamily: "Roboto",
		textTransform: "none",
		height: "2.5rem",
		width: "12rem",
		fontWeight: "400",
		marginLeft: "1rem",
	},
	add: {
		marginTop: "1rem",
		// color: "#ffffff",
		textTransform: "none",
		fontFamail: "Roboto",
		marginRight: "0.8rem",
		width: "12rem",
		// background:
		//   "transparent linear-gradient(71deg, #FF5B5B 0%, #DBA3DB 100%) 0% 0% no-repeat padding-box",
		// boxShadow: "0px 3px 10px #00000026",
	},
	reject: {
		background: "white",
		color: "#FF5B5B",
		textTransform: "none",
	},
	added: {
		marginTop: "1rem",
		width: "max-content",
		textTransform: "none",
		// color: "#000",
		fontFamail: "Roboto",
		background: "#ddd",
		boxShadow: "0px 3px 10px #00000026",
	},
});

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoadingProfile: false,
			isLoadingStatus: false,
			userStatus: [],
			email: "",
			username: "",
			fullName: "",
			country: "",
			city: "",
			gender: "",
			bio: "",
			avatar: null,
			friendship: "",
			userScore: null,
			online: false,
			userID: "",
			sending: false,
			text: "",
			onlineStatusColor: "",
			last_active: "",
			webUrls: [],
			socialUrls: false,
			profession: "",
			activeTab: 1,
		};
	}

	componentDidMount() {
		this.setState({ isLoadingProfile: true, isLoadingStatus: true });
		const userID = this.props.rootParams.params.userID;
		const form = new FormData();

		form.append("userId", userID);

		axiosConfig
			.post(getUserProfileURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					email: res.data.body.user.email,
					username: res.data.body.user.username,
					fullName: res.data.body.full_name,
					country: res.data.body.country,
					city: res.data.body.city,
					gender: res.data.body.gender,
					avatar: res.data.body.avatar,
					friendship: res.data.body.friendship,
					userScore: res.data.body.userScore,
					online: res.data.body.online,
					isLoadingProfile: false,
					userID: res.data.body.userID,
					last_active: res.data.body.last_active,
					webUrls: res.data.body.WebUrls,
					bio: res.data.body.bio,
					profession: res.data.body.profession,
				});
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;
					this.props.showAlert(catchError.error, {
						header: "Something went wrong",
						callback: () => this.props.history.push("/"),
						buttonText: "Go Back",
					});
				}
			});

		form.append("group_id", 1);
		axiosConfig
			.post(getUserStatusURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				this.setState({
					userStatus: res.data.body || res.data.result,
					isLoadingStatus: false,
				});
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response.data;

					this.props.showAlert(catchError.error, {
						header: "Something went wrong",
						buttonText: "Go Back",
						callback: () => this.props.history.push("/"),
					});
				}
				this.setState({ isLoadingStatus: false });
			});
	}

	UserButton = () => {
		const { classes } = this.props;
		switch (this.state.friendship) {
			case "Requested":
				return (
					<Button2 variant="contained" color="primary" disabled>
						Request Sent
					</Button2>
				);
			case "Request Received":
				return (
					<div className={styles.friendRequestContainer}>
						{/* <p>Friend Request Received</p> */}
						<div className={styles.friendRequestButtons}>
							<Link to="/myprofile/?active=requests">
								<Button2
									variant="contained"
									color="primary"
									className={classes.add}
								>
									ACCEPT
								</Button2>
							</Link>
							<Link to="/myprofile/contacts/pending">
								<Button1
									variant="outlined"
									color="primary"
									className={`${classes.reject} ${classes.add} `}
								>
									REJECT
								</Button1>
							</Link>
						</div>
					</div>
				);
			case "NAF":
				return (
					<Button2
						onClick={() => {
							this.sendFriendRequest();
						}}
					>
						{this.state.sending ? (
							<CircularProgress
								size="20px"
								style={{ color: "white" }}
							/>
						) : (
							<>
								&nbsp; &nbsp;
								{/* <FormattedMessage
									id="userProfile.addFriend"
									defaultMessage={"Send Request"}
                                    /> */}
                                    Send Request
								&nbsp; &nbsp;
							</>
						)}
					</Button2>
				);
			case "Friends":
				return (
					<Link to={`/chat/private/${this.state.userID}`}>
						<Button2>
							{/* <FormattedMessage
								id="userProfile.message"
								defaultMessage={"Message"}
							/> */}
                            Message
						</Button2>
					</Link>
				);
			default:
				return "";
		}
	};

	sendFriendRequest = () => {
		const form = new FormData();

		this.setState({ sending: true });

		form.append("userId", this.state.userID);
		form.append("note", "");

		axiosConfig
			.post(friendRequestURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({ sending: false, friendship: "Requested" });
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							("Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							})
						);
					}
				}
				this.setState({ sending: false });
			});
	};

	blockUser = () => {
		const request = new FormData();
		request.append("userId", this.state.userID);

		axiosConfig
			.post(blockUnblock, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.props.showAlert(
					`User @${this.state.username} blocked sucessfully`,
					{
						header: "User Blocked",
						// buttonText: "Go Back",
						callback: () => this.props.history.push("/"),
					}
				);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					}
				}
			});
	};

	reportUser = () => {
		const request = new FormData();
		request.append("userId", this.state.userID);

		axiosConfig
			.post(userReport, request)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.props.showAlert(
					`We will take a look at @${this.state.username}'s profile and take action if necessary`,
					{
						header: "User Reported",
						buttonText: "Go Back",
					}
				);
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						this.props.showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	displayOptions = () => {
		return (
			<div className={styles.changePassword}>
				{/* <div className={styles.option} onClick={this.blockUser}>
          <BlockIcon />
          <h1 className={styles.changePasswordText}>Block user</h1>
        </div>

        <div className={styles.option} onClick={this.reportUser}>
          <ReportIcon />
          <h1 className={styles.changePasswordText}>Report user</h1>
        </div> */}

				{this.state.friendship === "Friend" && (
					<div className={styles.option}>
						<DeleteIcon />
						<h1 className={styles.changePasswordText}>Unfriend</h1>
					</div>
				)}
			</div>
		);
	};

	showUserDetails() {
		const isDesktop = window.screen.width > 800;
		return (
			<>
				<div className={styles.data}>
					<label
						className={`${styles.label} ${
							this.props.theme === "dark"
								? styles.darkLinkText
								: styles.lgLinkText
						}`}
					>
						{/* <FormattedMessage
							id="username"
							defaultMessage={`Username`}
						/> */}
                        Username
					</label>
					<p className={styles.userContent}>{this.state.username}</p>
				</div>
				<div className={styles.dataBio}>
					<label
						className={`${styles.label} ${
							this.props.theme === "dark"
								? styles.darkLinkText
								: styles.lgLinkText
						}`}
					>
                        {/* <FormattedMessage id="bio" defaultMessage={`Bio`} /> */}
                        Bio
					</label>
					<p className={styles.userContent}>
						{this.state.bio !== "null" && this.state.bio != null
							? this.state.bio
							: "No Bio Available"}
					</p>
				</div>
			</>
		);
	}
	// The render function

	displayWeburls() {
		return (
			<>
				{this.state.webUrls?.map((urls) => (
					<Grid item xs={12} className={styles.dataUrl}>
						{urls.key === "facebook" && (
							<>
								<div className={styles.urlbox}>
									<a
										href={urls.value}
										target="_blank"
										rel="noopener noreferrer"
									>
										<div className={styles.iconCont}>
											<FacebookIcon
												style={{
													color:
														this.props.theme ===
														"dark"
															? "#0d0f22"
															: "#fff",
												}}
												className={styles.icon}
											/>
										</div>
										<Tooltip
											placement="top"
											title={urls.value}
										>
											<p
												className={`${styles.url} ${
													this.props.theme === "dark"
														? styles.darkLinkText
														: styles.lgLinkText
												}`}
											>
												{urls.value}
											</p>
										</Tooltip>
									</a>
								</div>
							</>
						)}
						{urls.key === "instagram" && (
							<>
								<div className={styles.urlbox}>
									<div className={styles.iconCont}>
										<InstagramIcon
											style={{
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#fff",
											}}
											className={styles.icon}
										/>
									</div>

									<Tooltip placement="top" title={urls.value}>
										<a
											href={urls.value}
											target="_blank"
											rel="noopener noreferrer"
										>
											{/* <input className={styles.fieldUrl} type="text" value={urls.value} disabled />{" "} */}
											<p
												className={`${styles.url} ${
													this.props.theme === "dark"
														? styles.darkLinkText
														: styles.lgLinkText
												}`}
											>
												{urls.value}
											</p>
										</a>
									</Tooltip>
								</div>
							</>
						)}
						{urls.key === "github" && (
							<>
								<div className={styles.urlbox}>
									<div className={styles.iconCont}>
										{/* <GitHubIcon
											style={{
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#fff",
											}}
											className={styles.icon}
										/> */}
										<img src="/icons/github.svg" alt="" />
									</div>

									<Tooltip placement="top" title={urls.value}>
										<a
											href={urls.value}
											target="_blank"
											rel="noopener noreferrer"
										>
											{/* <input className={styles.fieldUrl} type="text" value={urls.value} disabled />{" "} */}
											<p
												className={`${styles.url} ${
													this.props.theme === "dark"
														? styles.darkLinkText
														: styles.lgLinkText
												}`}
											>
												{urls.value}
											</p>
										</a>
									</Tooltip>
								</div>
							</>
						)}
						{urls.key === "linkedin" && (
							<>
								<div className={styles.urlbox}>
									<div className={styles.iconCont}>
										<LinkedInIcon
											style={{
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#fff",
											}}
											className={styles.icon}
										/>
										{/* <img
											src="/icons/linkedin.svg"
											className="bg-dark"
											alt=""
										/> */}
									</div>

									<Tooltip placement="top" title={urls.value}>
										<a
											href={urls.value}
											target="_blank"
											rel="noopener noreferrer"
										>
											{/* <input className={styles.fieldUrl} type="text" value={urls.value} disabled />{" "} */}
											<p
												className={`${styles.url} ${
													this.props.theme === "dark"
														? styles.darkLinkText
														: styles.lgLinkText
												}`}
											>
												{urls.value}
											</p>
										</a>
									</Tooltip>
								</div>
							</>
						)}
						{urls.key === "twitter" && (
							<>
								<div className={styles.urlbox}>
									<div className={styles.iconCont}>
										<TwitterIcon
											style={{
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#fff",
											}}
											className={styles.icon}
										/>
									</div>

									<Tooltip placement="top" title={urls.value}>
										<a
											href={urls.value}
											target="_blank"
											rel="noopener noreferrer"
										>
											{/* <input className={styles.fieldUrl} type="text" value={urls.value} disabled />{" "} */}
											<p
												className={`${styles.url} ${
													this.props.theme === "dark"
														? styles.darkLinkText
														: styles.lgLinkText
												}`}
											>
												{urls.value}
											</p>
										</a>
									</Tooltip>
								</div>
							</>
						)}
						{urls.key === "others" && (
							<>
								<div className={styles.urlbox}>
									<div className={styles.iconCont}>
										<LanguageIcon
											style={{
												color:
													this.props.theme === "dark"
														? "#0d0f22"
														: "#fff",
											}}
											className={styles.icon}
										/>
									</div>

									<Tooltip placement="top" title={urls.value}>
										<a
											href={urls.value}
											target="_blank"
											rel="noopener noreferrer"
										>
											{/* <input className={styles.fieldUrl} type="text" value={urls.value} disabled />{" "} */}
											<p
												className={`${styles.url} ${
													this.props.theme === "dark"
														? styles.darkLinkText
														: styles.lgLinkText
												}`}
											>
												{urls.value}
											</p>
										</a>
									</Tooltip>
								</div>
							</>
						)}
					</Grid>
				))}
			</>
		);
	}

	render() {
		const tabs = [
			{
				id: 1,
				label: "My Thoughts",
			},
			{
				id: 2,
				label: "About",
			},
		];
		const MobTabHandler = (tab) => {
			this.setState({ activeTab: tab.id });
		};
		return (
			<Dialog
				open={true}
				fullScreen={true}
				maxWidth={"100vw"}
				fullWidth={"100vw"}
				classes={{
					paper:
						this.props.theme === "dark"
							? styles.darkBg
							: styles.lgBg,
				}}
			>
				<MediaQuery maxDeviceWidth={1224}>
					{this.state.username && (
						<Helmet>
							<title>{`${this.state.username} | bditto`}</title>
						</Helmet>
					)}
					<div className={styles.mobTopBar}>
						<div
							className={styles.sidebarHeader}
							style={{ cursor: "pointer", margin: "auto 0" }}
						>
							<img
								onClick={() => {
									this.props.history.goBack();
								}}
								src="/icons/close.svg"
								alt=""
							/>
						</div>
						<div>{this.UserButton()}</div>
					</div>
					<div className={styles.mobMainContainer}>
						<div className={styles.header}>
							<div className={styles.imageBox}>
								{this.state.isLoading || !this.state.avatar ? (
									<OnlineBadge
										invisible={false}
										last_active={this.state.last_active}
										online={this.state.online}
										ripple={false}
										variant="standard"
									>
										<AccountCircleIcon
											style={{
												fontSize: "5rem",
												color: "#949494",
											}}
										/>
									</OnlineBadge>
								) : (
									<OnlineBadge
										invisible={false}
										last_active={this.state.last_active}
										online={this.state.online}
										ripple={false}
										variant="standard"
									>
										<Avatar
											src={this.state.avatar}
											alt={this.state.fullName}
											style={{
												width: "5rem",
												height: "5rem",
											}}
										/>
									</OnlineBadge>
								)}
							</div>
							<div className={styles.mobDetailsBox}>
								{this.state.username && (
									<div className={styles.username}>
										{this.state.fullName}
									</div>
								)}
								<div
									className={`${styles.profession} ${
										this.props.theme === "dark"
											? styles.darkLinkText
											: styles.lgLinkText
									}`}
								>
									<img
										className={styles.gpsIcon}
										src="/Home/profession.svg"
										alt=""
									/>
									{this.state.profession !== "null" &&
										this.state.profession}
								</div>
								<div
									className={`${styles.profession} ${
										this.props.theme === "dark"
											? styles.darkLinkText
											: styles.lgLinkText
									} ${styles.cap}`}
								>
									<img
										className={styles.gpsIcon}
										src="/Home/location.svg"
										alt=""
									/>
									{this.state.city !== "null"
										? this.state.city
										: ""}
									&nbsp;
									{this.state.country}
								</div>
							</div>
						</div>
						<div className={styles.thoughtsTab}>
							{tabs.map((tab, i) =>
								tab.id === this.state.activeTab ? (
									<div className={styles.activeMobTab}>
										{tab.label}
									</div>
								) : (
									<div
										onClick={() => MobTabHandler(tab)}
										className={styles.mobtab}
									>
										{tab.label}
									</div>
								)
							)}
						</div>
						<div className={styles.divider}></div>

						{this.state.activeTab === 1 && (
							<div className={styles.status1}>
								<div className={styles.resultDiv}>
									{this.state.isLoadingStatus ? (
										<>
											<div className={styles.statusLoad}>
												<CardSkeleton width="100%" />
												<CardSkeleton width="100%" />
												<CardSkeleton width="100%" />
												<CardSkeleton width="100%" />
												<CardSkeleton width="100%" />
												<CardSkeleton width="100%" />
												<CardSkeleton width="100%" />
												<CardSkeleton width="100%" />
											</div>
										</>
									) : (
										<div>
											<div
												style={{
													display: "flex",
													justifyContent:
														"space-between",
													marginBottom: "1rem",
												}}
											>
												<p style={{ margin: "auto 0" }}>
													{this.state.userStatus
														.length + " THOUGHTS"}
												</p>
												<img
													src="/icons/filter.svg"
													alt=""
												/>
											</div>
											{this.state.userStatus.map(
												(item, index) => {
													return (
														<StatusCard
															status={item}
															key={index}
															width="100%"
															height="15rem"
															idx={index % 12}
														/>
													);
												}
											)}
										</div>
									)}
								</div>
							</div>
						)}
						{this.state.activeTab === 2 && (
							<div className={styles.nameCont}>
								{this.state.isLoadingStatus ? (
									<Skeleton width={"100%"} height={20} />
								) : (
									<div>
										<div className={styles.aboutBox}>
											<p
												className="mb-2 text-white"
												className={styles.aboutHeading}
											>
												USERNAME
											</p>
											<h1 className={styles.aboutContent}>
												<Username
													value={this.state.username}
												/>
											</h1>
										</div>
										<div className={styles.aboutBox}>
											<p
												className="mb-2 text-white"
												className={styles.aboutHeading}
											>
												ABOUT
											</p>
											<p className={styles.aboutContent}>
												{this.state.bio != "null" &&
												this.state.bio != null
													? this.state.bio
													: "No Bio Available"}
											</p>
										</div>
										<div
											className={styles.aboutBox}
											style={{
												marginBottom: "5rem",
											}}
										>
											<p
												className="mb-2 text-white"
												className={styles.aboutHeading}
											>
												SOCILA MEDIA
											</p>
											<div className={styles.aboutSocial}>
												{this.displayWeburls()}
											</div>
										</div>
									</div>
								)}
							</div>
						)}
					</div>
				</MediaQuery>
				<MediaQuery minDeviceWidth={1224}>
					<div className={styles.coverContainer}>
						{this.state.username && (
							<Helmet>
								<title>{`${this.state.username} | bditto`}</title>
							</Helmet>
						)}

						<div className={styles.mainContainer}>
							<div className={styles.header}>
								<div className={styles.top}>
									<div className={styles.imageBox}>
										{this.state.isLoading ||
										!this.state.avatar ? (
											<OnlineBadge
												invisible={false}
												last_active={
													this.state.last_active
												}
												online={this.state.online}
												ripple={false}
												variant="standard"
											>
												<AccountCircleIcon
													style={{
														fontSize: "5rem",
														color: "#949494",
													}}
												/>
											</OnlineBadge>
										) : (
											<OnlineBadge
												invisible={false}
												last_active={
													this.state.last_active
												}
												online={this.state.online}
												ripple={false}
												variant="standard"
											>
												<Avatar
													src={this.state.avatar}
													alt={this.state.fullName}
													style={{
														width: "5rem",
														height: "5rem",
													}}
												/>
											</OnlineBadge>
										)}
									</div>{" "}
									<>
										<div className={styles.topDetail}>
											{this.state.username && (
												<p className={styles.username}>
													{this.state.fullName}
												</p>
											)}
											<div className={styles.detailsBox}>
												<div>
													<div
														className={`${
															styles.profession
														} ${
															this.props.theme ===
															"dark"
																? styles.darkLinkText
																: styles.lgLinkText
														}`}
													>
														{this.state.email !==
															"null" &&
															this.state.email}
													</div>
													<div
														style={{
															paddingTop:
																"0.5rem",
														}}
														className={`${
															styles.profession
														} ${
															this.props.theme ===
															"dark"
																? styles.darkLinkText
																: styles.lgLinkText
														}`}
													>
														{this.state
															.profession !==
															"null" &&
															this.state
																.profession}
													</div>
												</div>
												<div
													className={`${
														styles.profession
													} ${
														this.props.theme ===
														"dark"
															? styles.darkLinkText
															: styles.lgLinkText
													} ${styles.cap}`}
												>
													<img
														className={
															styles.gpsIcon
														}
														src="/Home/location.svg"
														alt=""
													/>
													{this.state.city !== "null"
														? this.state.city
														: ""}
													&nbsp;
													{this.state.country}
												</div>
											</div>
										</div>
									</>
								</div>
								<div className={styles.menuList1}>
									<div className={styles.contactBtn}>
										<SidebarContextMenu
											transformOrigin={{
												vertical: "top",
												horizontal: "left",
											}}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											listStyles={{ padding: 0 }}
											icon={(props) => (
												<Button1
													{...props}
													padding={"1.25rem 1rem"}
												>
													<img
														src="/vectors/dots.svg"
														alt=""
													/>
												</Button1>
											)}
										>
											<button
												onClick={this.reportUser}
												className={styles.popoverbtn}
												style={{
													color:
														this.props.theme ===
														"dark"
															? "#D9DEF4"
															: "#0D0F22",
												}}
											>
												Report
											</button>
											<button
												onClick={this.blockUser}
												className={styles.popoverbtn}
												style={{
													color:
														this.props.theme ===
														"dark"
															? "#D9DEF4"
															: "#0D0F22",
												}}
											>
												Block
											</button>
										</SidebarContextMenu>
										&nbsp; &nbsp; &nbsp;
										{this.UserButton()}
									</div>
								</div>
								<div
									className={styles.sidebarHeader}
									style={{ cursor: "pointer" }}
								>
									<img
										onClick={() => {
											this.props.history.goBack();
										}}
										src="/icons/squaredClose.svg"
										alt=""
									/>
								</div>
							</div>

							<div className={styles.partition}>
								<div className={styles.profile}>
									<div className={styles.content}>
										<div className={styles.content1}>
											<MediaQuery maxDeviceWidth={1224}>
												<Mobile
													statusResult={
														this.state.userStatus
													}
													username={
														this.state.fullName
													}
													bio={this.state.bio}
													country={this.state.country}
													webUrls={this.state.webUrls}
													isStatusLoading={
														this.state
															.isLoadingStatus
													}
													isUserLoading={
														this.state
															.isLoadingProfile
													}
													statusLoaded={false}
													userLoaded={false}
												/>
											</MediaQuery>
											<MediaQuery minDeviceWidth={1224}>
												<div className={styles.left}>
													<div
														className={
															styles.userDetails
														}
													>
														{this.showUserDetails()}
													</div>
												</div>

												<div className={styles.right}>
													<label
														className={`${
															styles.label
														} ${
															this.props.theme ===
															"dark"
																? styles.darkLinkText
																: styles.lgLinkText
														}`}
													>
														{/* <FormattedMessage
															id="Social Media"
															defaultMessage={`Social Media`}
														/> */}
                                                        Social Media
													</label>
													{this.displayWeburls()}
												</div>
											</MediaQuery>
										</div>
									</div>
								</div>

								<MediaQuery minDeviceWidth={1224}>
									<div className={styles.status}>
										<label
											className={`${styles.label} ${
												this.props.theme === "dark"
													? styles.darkLinkText
													: styles.lgLinkText
											}`}
										>
											{/* <FormattedMessage
												id="Social Media"
												defaultMessage={`Thoughts`}
											/> */}
                                            Thoughts
										</label>
										<div
											className={
												this.props.theme === "dark"
													? styles.statusCardsDk
													: styles.statusCardsLg
											}
										>
											{this.state.isLoadingStatus ? (
												<>
													<CardSkeleton width="48%" />
													<CardSkeleton width="48%" />
													<CardSkeleton width="48%" />
													<CardSkeleton width="48%" />
													<CardSkeleton width="48%" />
													<CardSkeleton width="48%" />
													<CardSkeleton width="48%" />
													<CardSkeleton width="48%" />
												</>
											) : (
												this.state.userStatus.map(
													(item, index) => {
														return (
															<StatusCard
																status={item}
																key={index}
																width="48%"
																idx={index % 12}
																height="13rem"
															/>
														);
													}
												)
											)}
										</div>
									</div>
								</MediaQuery>
							</div>

							<MediaQuery maxDeviceWidth={1224}>
								<div className={styles.status1}>
									<div className={styles.resultDiv}>
										{this.state.isLoadingStatus ? (
											<>
												<div
													className={
														styles.statusLoad
													}
												>
													<CardSkeleton width="100%" />
													<CardSkeleton width="100%" />
													<CardSkeleton width="100%" />
													<CardSkeleton width="100%" />
													<CardSkeleton width="100%" />
													<CardSkeleton width="100%" />
													<CardSkeleton width="100%" />
													<CardSkeleton width="100%" />
												</div>
											</>
										) : (
											this.state.userStatus.map(
												(item, index) => {
													return (
														<StatusCard
															status={item}
															key={index}
															width="100%"
															height="15rem"
															idx={index % 12}
														/>
													);
												}
											)
										)}
									</div>
								</div>
							</MediaQuery>
						</div>
					</div>
				</MediaQuery>
			</Dialog>
		);
	}
}

export default withStyles(useStyles)(withRouter(withAlert(UserProfile)));
