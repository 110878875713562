import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { pure } from "recompose";
import formatDate from "date-fns/format";
import styles from "./GroupInfo.module.scss";
//Utils
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import { getMediaByID, leaveGroupURL } from "../../../../../Utils/Constants";
import { emitLeaveGroup } from "../../../../../Utils/socket";
import { formatNumber } from "../../../../../Utils/numbers";

//Material UI
import Skeleton from "@material-ui/lab/Skeleton/Skeleton";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import {
	SentimentDissatisfiedOutlined,
	SentimentSatisfiedOutlined,
	SentimentVerySatisfiedOutlined,
} from "@material-ui/icons";

//Components
import UserSkeleton from "../../../../Skeleton/UserSkeleton/UserSkeleton";
import Media from "../Media";
import MediaPreview from "../MediaPreview";
import UserCard from "../../Extra/UserCard/UserCard";
import useAlert from "../../../../Extras/Alert/useAlert";
import Button1 from "../../../../Extras/Buttons/Button1/Button1";
import { Chevron } from "../../../../../Icons";

const SetUserOnline = (users, onlineMessage) => {
	let changed = false;
	const changedUsers = users.map((user) => {
		if (`${user.userID}` === onlineMessage.userID) {
			let temp = user;
			delete temp["onlineStatus"];

			if (onlineMessage.isOnline) {
				temp["onlineStatus"] = true;
			} else {
				temp["onlineStatus"] = false;
			}
			changed = true;

			return temp;
		} else return user;
	});
	return { changedUsers, changed };
};

const MemberList = ({
	isLoading,
	users,
	groupAuthor,
	groupID,
	active,
	label,
	icon,
	onClick,
	trim,
	theme,
}) =>
	users && users.length > 0 ? (
		<>
			<div
				className={`${styles.buttonHeader} ${
					!active && styles.inactive
				}`}
				onClick={() => onClick && onClick()}
			>
				<h1
					className={styles.title}
					style={{ color: theme === "dark" ? "#D9DEF4" : "#0d0f22" }}
				>
					{label} ({formatNumber(users && users.length)})
				</h1>
				{icon}
			</div>

			<div
				className={`${styles.memberList} ${!active && styles.inactive}`}
			>
				{isLoading && (
					<>
						<UserSkeleton radius={50} />
						<UserSkeleton radius={50} />
						<UserSkeleton radius={50} />
					</>
				)}

				{(users || []).length > 0 ? (
					(trim ? users.slice(0, 3) : users).map((data, key) => {
						return (
							<UserCard
								active={active}
								author={groupAuthor}
								pk={groupID}
								data={data}
								key={key}
							/>
						);
					})
				) : (
					<p>No other Members</p>
				)}
			</div>
		</>
	) : null;

//From backend rating value is coming as NODE_ERR
const Rating = ({ value }) => {
	value = typeof value === "number" ? value?.toFixed(1) : null;
	let Icon;
	let color;
	if (value === null) {
		Icon = <SentimentDissatisfiedOutlined htmlColor="#ddd" />;
		color = "#ddd";
	} else if (value < 2.5) {
		Icon = <SentimentDissatisfiedOutlined color="error" />;
		color = "red";
	} else if (value >= 2.5 && value < 3.5) {
		Icon = <SentimentSatisfiedOutlined htmlColor="orange" />;
		color = "orange";
	} else {
		Icon = <SentimentVerySatisfiedOutlined htmlColor="#42DA93" />;
		color = "#42DA93";
	}
	return (
		<Tooltip
			title={
				<FormattedMessage
					id="groupRating"
					defaultMessage={`Group Rating`}
				/>
			}
		>
			<div className={styles.rating}>
				{Icon}
				<span style={{ color }}>{value}</span>
			</div>
		</Tooltip>
	);
};

const GroupInfo = (props) => {
	const [docs, setDocs] = useState([]);
	const [page, setPage] = useState("info");
	const [isLoad, setIsLoad] = useState(false);
	const [memberList, setMemberList] = useState([]);
	const [inactiveMembers, setInactiveMembers] = useState([]);
    const theme = useSelector((reduxState) => reduxState.theme.theme);
	const { isLoading, onlineMessage } = props;

	const {
		content,
		background_image,
		created_at,
		members,
		inactive,
		pk,
		isMember,
		author,
		// rating,
	} = props.data;

	const history = useHistory();
	const { showAlert } = useAlert();

	useEffect(() => {
		// debugger;
	}, []);

	useEffect(() => {
		if (!memberList && !inactiveMembers) return;
		let { changedUsers, changed } = SetUserOnline(
			memberList || [],
			onlineMessage
		);
		if (!changed) {
			changedUsers = SetUserOnline(
				inactiveMembers || [],
				onlineMessage
			).changedUsers;
			setInactiveMembers(changedUsers);
		} else {
			setMemberList(changedUsers);
		}
	}, [onlineMessage]);

	useEffect(() => {
		setMemberList(members);
	}, [members]);

	useEffect(() => {
		setInactiveMembers(inactive);
	}, [inactive]);

	useEffect(() => {
		if (isMobile && props.activeTab === 2) {
			setPage("info");
		}
		if (isMobile && props.activeTab === 3) {
			setPage("media");
			groupMedia(true);
		}
	}, [page, props.activeTab]);

	const groupMedia = (reset) => {
		const data = {
			conversationID: pk,
			isGroup: true,
			skip: reset ? 0 : docs.length,
		};
		setIsLoad(true);
		axiosChatConfig
			.post(getMediaByID, data)
			.then((res) => {
				if (res.status !== 200) throw new Error(); //res is not coming

				setDocs((prev) =>
					reset ? res.data.chats : [...prev, ...res.data.chats]
				);
				setIsLoad(false);
			})
			.catch((err) => {
				showAlert("Please Try again later", {
					header: "Something went wrong",
					buttonText: "Go Back",
				});
				setIsLoad(false);
			});
	};

	const leaveGroup = () => {
		if (isLoad) return;
		setIsLoad(true);
		const data = {
			statusID: pk,
		};
		axiosChatConfig
			.post(leaveGroupURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				emitLeaveGroup(pk);
				setIsLoad(false);
				showAlert("You left the group", {
					buttonText: "Go Back",
					callback: history.push("/chat/"),
				});
				props.onMemberLeave();
			})
			.catch((err) => {
				setIsLoad(false);
				showAlert("Please Try again later", {
					header: "Something went wrong",
					buttonText: "Go Back",
				});
			});
	};

	const requestLeaveConfirmation = () => {
		showAlert("Are you user You want to leave the group?", {
			header: "Leave Group",
			buttonText: "LEAVE",
			callback: leaveGroup,
			linkText: "No Thanks.",
		});
	};

	const container = props.show ? styles.contShow : styles.contHide;
	const created = created_at && new Date(created_at);

	const isMobile = window.screen.width < 768;

	return (
		<>
			{!isMobile ? (
				<div className={container}>
					<>
						{page === "info" ? (
							<>
								<div
									className={styles.topContainer}
									style={{
										color:
											theme === "dark"
												? "white"
												: "#0D0F22",
									}}
								>
									{/* <FormattedMessage
										id="createdOn"
										defaultMessage={`Thoughts Info`}
									/> */}
                                    Thoughts Info
								</div>
								<div className={styles.dataCont}>
									<div className={styles.nameCont}>
										<h1
											className={styles.name}
											style={{
												color:
													theme === "dark"
														? "#D9DEF4"
														: "#4A517E",
											}}
										>
											{isLoading ? (
												<Skeleton
													width={"100%"}
													height={20}
												/>
											) : (
												content
											)}
										</h1>
										<div className={styles.belowName}></div>
									</div>
									<div
										className={styles.imageCont}
										style={{
											borderBottom:
												theme === "dark"
													? "1px solid #2E2E48"
													: "1px solid  #7F89BE",
										}}
									>
										{background_image && (
											<img
												src={background_image}
												alt="icon"
												className={styles.image}
											/>
										)}
										<p
											className={styles.date}
											style={{
												color:
													theme === "dark"
														? "#D9DEF4"
														: "#0d0f22",
											}}
										>
											{/* <FormattedMessage
												id="createdOn"
												defaultMessage={`Posted	 On`}
                                            /> */}
                                            Posted	 On
                                            {" "}
											:{" "}
											{created &&
												" " +
													formatDate(
														created,
														"dd/MM/yyyy"
													)}
										</p>
									</div>

									<div className="px-2">
										<MediaPreview
											isGroup
											conversationID={pk}
											onExpand={() => {
												setPage("media");
												groupMedia(true);
											}}
										/>
									</div>

									<div
										style={{
											borderTop:
												theme === "dark"
													? "1px solid #2E2E48"
													: "1px solid  #7F89BE",
											padding: "10px 5px",
										}}
									>
										<MemberList
											groupID={pk}
											groupAuthor={author}
											isLoading={isLoading}
											icon={
												<Chevron.Right theme={theme} />
											}
											onClick={() =>
												setPage("participants")
											}
											theme={theme}
											users={memberList || []}
											trim={true}
											label={
												<FormattedMessage
													id="Participants"
													defaultMessage={`Participants`}
												/>
											}
											key={"active"}
											active
										/>

										<MemberList
											groupID={pk}
											groupAuthor={author}
											isLoading={isLoading}
											users={inactiveMembers}
											key={"inactive"}
											label={
												<FormattedMessage
													id="inactiveMembers"
													defaultMessage={`Inactive Members`}
												/>
											}
                                            theme={theme}
										/>
									</div>
								</div>
								{isMember && (
									<Button1 onClick={requestLeaveConfirmation}>
										{/* <LeaveGroup theme={theme} /> */}
										<div className="">
											<img src="/leave.svg" alt="" />
										</div>
										<span className={styles.leaveTitle}>
											{/* <FormattedMessage
												id="leaveGroup"
												defaultMessage={`Leave Conversation`}
											/> */}
                                            Leave Conversation
										</span>
									</Button1>
								)}
							</>
						) : page === "media" ? (
							<>
								<div className={styles.heading}>
									<IconButton
										size="small"
										onClick={() => setPage("info")}
									>
										<img src="/icons/ColoredBackArrow.svg" alt="" />
										{/* <Chevron.Left theme={theme} /> */}
									</IconButton>
									{/* <img src="/ColoredBackArrow.svg" /> */}
									<h1
										className={styles.pageTitle}
										style={{
											color:
												theme === "dark"
													? "#D9DEF4"
													: "#0d0f22",
										}}
									>
										<FormattedMessage
											id="statusMedia"
											defaultMessage={`Shared Media`}
										/>
									</h1>
								</div>
								<div className={styles.mediaCont}>
									{docs.length ? (
										docs.map((data, key) => {
											return (
												<Media
													data={data}
													key={key}
													className={styles.media}
												/>
											);
										})
									) : isLoad ? (
										<CircularProgress
											style={{
												fontSize: "2rem",
												color: "#555",
												margin: "auto",
											}}
										/>
									) : (
										<p
											className={styles.no}
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "#0D0F22",
											}}
										>
											<FormattedMessage
												id="noMediaAvailable"
												defaultMessage={`No Media Available`}
											/>
										</p>
									)}
								</div>
							</>
						) : (
							<>
								<div className={styles.participantsList}>
									<div className={styles.heading}>
										<IconButton
											size="small"
											onClick={() => setPage("info")}
										>
											<Chevron.Left theme={theme} />
										</IconButton>
										<h1
											className={styles.pageTitle}
											style={{
												color:
													theme === "dark"
														? "#D9DEF4"
														: "#0d0f22",
											}}
										>
											<FormattedMessage
												id="participants"
												defaultMessage={`Participants`}
											/>
										</h1>
									</div>
									{(memberList || []).length > 0 ? (
										memberList.map((data, key) => {
											return (
												<UserCard
													active
													author={author}
													pk={pk}
													data={data}
													key={key}
												/>
											);
										})
									) : (
										<p>No active Members</p>
									)}

									<div className={styles.heading}>
										<h1
											className={styles.pageTitle}
											style={{
												color:
													theme === "dark"
														? "#D9DEF4"
														: "#4A517E",
											}}
										>
											<FormattedMessage
												id="inctiveParticipants"
												defaultMessage={`Inactive Participants`}
											/>
										</h1>
									</div>
									{(inactiveMembers || []).length > 0 ? (
										inactiveMembers.map((data, key) => {
											return (
												<UserCard
													author={author}
													pk={pk}
													data={data}
													key={key}
												/>
											);
										})
									) : (
										<p style={{ color: "#4A517E" }}>
											No Inactive Members
										</p>
									)}
								</div>
								{isMember && (
									<Button1 onClick={requestLeaveConfirmation}>
										<div className="">
											<img src="/leave.svg" alt="" />
										</div>
										&nbsp; &nbsp;
										<span className={styles.leaveTitle}>
											{/* <FormattedMessage
												id="leaveGroup"
												defaultMessage={`Leave Conversation`}
											/> */}
                                                    Leave Conversation
										</span>
									</Button1>
								)}
							</>
						)}
					</>
				</div>
			) : (
				<div className={container}>
					{props.activeTab === 2 && page === "info" && (
						<>
							<div className={styles.dataCont}>
								<div className={styles.nameCont}>
									<h1
										className={styles.name}
										style={{
											color:
												theme === "dark"
													? "#D9DEF4"
													: "#4A517E",
										}}
									>
										{isLoading ? (
											<Skeleton
												width={"100%"}
												height={20}
											/>
										) : (
											content
										)}
									</h1>
									<div className={styles.belowName}></div>
								</div>
								<div
									className={styles.imageCont}
									style={{
										borderBottom:
											theme === "dark"
												? "1px solid #2E2E48"
												: "1px solid  #7F89BE",
									}}
								>
									{background_image && (
										<img
											src={background_image}
											alt="icon"
											className={styles.image}
										/>
									)}
									<p
										className={styles.date}
										style={{
											color:
												theme === "dark"
													? "#D9DEF4"
													: "#0d0f22",
										}}
									>
										{/* <FormattedMessage
											id="createdOn"
											defaultMessage={`Posted	 On`}
										/> */}
                                        Posted	 On
                                            {" "}
										:{" "}
										{created &&
											" " +
												formatDate(
													created,
													"dd/MM/yyyy"
												)}
									</p>
								</div>

								<div
									style={{
										borderTop:
											theme === "dark"
												? "1px solid #2E2E48"
												: "1px solid  #7F89BE",
										padding: "10px 5px",
									}}
								>
									<MemberList
										groupID={pk}
										groupAuthor={author}
										isLoading={isLoading}
										icon={<Chevron.Right theme={theme} />}
										onClick={() => setPage("participants")}
										theme={theme}
										users={memberList || []}
										trim={true}
										label={
											<FormattedMessage
												id="Participants"
												defaultMessage={`Participants`}
											/>
										}
										key={"active"}
										active
									/>

									<MemberList
										groupID={pk}
										groupAuthor={author}
										isLoading={isLoading}
										users={inactiveMembers}
										key={"inactive"}
										label={
											<FormattedMessage
												id="inactiveMembers"
												defaultMessage={`Inactive Members`}
											/>
										}
									/>
								</div>
							</div>
							{isMember && (
								<Button1 onClick={requestLeaveConfirmation}>
									{/* <LeaveGroup theme={theme} /> */}
									<div className="">
										<img src="/leave.svg" alt="" />
									</div>
									<span className={styles.leaveTitle}>
										{/* <FormattedMessage
											id="leaveGroup"
											defaultMessage={`Leave Conversation`}
										/> */}
                                        Leave Conversation
									</span>
								</Button1>
							)}
						</>
					)}
					{props.activeTab === 3 && page === "media" && (
						<>
							<div className={styles.mediaCont}>
								{docs.length ? (
									docs.map((data, key) => {
										return (
											<Media
												data={data}
												key={key}
												className={styles.media}
											/>
										);
									})
								) : isLoad ? (
									<CircularProgress
										style={{
											fontSize: "2rem",
											color: "#555",
											margin: "auto",
										}}
									/>
								) : (
									<p
										className={styles.no}
										style={{
											color:
												theme === "dark"
													? "#d9def4"
													: "#0D0F22",
										}}
									>
										<FormattedMessage
											id="noMediaAvailable"
											defaultMessage={`No Media Available`}
										/>
									</p>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
};

export default pure(GroupInfo);
