import React, { useState } from "react";
import {
	Avatar,
	makeStyles,
	Switch,
	withStyles,
	CircularProgress,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import styles from "./GroupModal.module.css";
import "./CreateGroupModal.scss";
// import AddIcon from "../../../../Icons/AddIcon";
// import CameraEditIcon from "../../../../Icons/CameraEditIcon";
// import DialogModal from "../../../Extras/DialogModal";
// import CloseIcon from "../../../../Icons/CloseIcon";
// import SettingsIcon from "../../../../Icons/SettingsIcon";
// import Settings from "../../../../Icons/Settings";
// import SidebarContextMenu from "../../Chats/Sidebar/SidebarContextMenu";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
// import { ShareCopyLink } from "../../Chats/Extra/ShareURL/Share";
// import { Search } from "@material-ui/icons";
// import { parseJSON } from "date-fns";
// import { connect } from "react-redux";
import axiosConfig from "../../../../Utils/axiosConfig";
import useAlert from "../../../Extras/Alert/useAlert";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import Button2 from "../../../Extras/Buttons/Button2/Button2";
import { useSelector } from "react-redux";
import { editGroupURL } from "../../../../Utils/Constants";
import { updateMySingleGroup } from "../../../../Redux/Actions/myGroupsAction";

const StyledSwitch = withStyles((theme) => ({
	root: {
		height: "3rem",
		width: "5rem",
		transform: "rotateZ(90deg)",
	},
	checked: {
		transform: "translateX(28px) !important",
		"& + $track": {
			backgroundColor: "transparent !important",
			opacity: "1 !important",
		},
	},
	switchBase: {
		left: 8,
		top: 6,
	},
	track: {
		background: "linear-gradient(92.12deg, #00C6FF 0%, #0072FF 100%)",
		borderRadius: 22,
		opacity: 1,
	},
	thumb: {
		width: "1.1rem",
		height: "1.1rem",
		background: "#fff",
	},
}))(Switch);

const useStyles = makeStyles({
	cancel: {
		borderRadius: "6px",
		fontFamily: "Roboto",
		textTransform: "none",
		fontWeight: "400",
		marginRight: "1rem",
	},
	cancel: {
		marginLeft: "1rem",
		fontSize: "1rem",
		fontWeight: "400",
		color: "#fff",
		textTransform: "none",
		background: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		borderRadius: "1rem",
		border: "none",
	},
	share: {
		marginRight: "1rem",
		fontSize: ".5rem",
		fontWeight: "400",
		textTransform: "none",
		background: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		backgroundImage: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		backgroundOrigin: "borderBox",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
		backgroundClip: "border-box text",
		border: "1px solid transparent",
		borderImageSlice: "1",
		borderImage: "linear-gradient(90deg, #FC466B, #3F5EFB)",
		mozborderimage: "-moz-linear-gradient(90deg, #FC466B, #3F5EFB)",
		WebKitBorderImage: "-webkit-linear-gradient(90deg, #FC466B, #3F5EFB)",
	},
	save: {
		borderRadius: "6px",
		textTransform: "none",
		fontWeight: "400",
		marginLeft: "1rem",
	},
});

const EditGroupModal = (props) => {
    const dispatch = useDispatch();
	const { showAlert } = useAlert();
	const classes = useStyles();
	const [public1, setPublic] = useState(props.data.group_details.type);
	const [isLoading, setIsLoading] = useState(false);
	const [uploadImage, setuploadImage] = useState("");
	const [UploadUrl, setUploadUrl] = useState(props.data.group_details.avatar);
	const [grpName, setGrpname] = useState(props.data.group_details.name);
	const [bio, setBio] = useState(props.data.group_details.bio);
	const imgRef = React.createRef();
	const theme = useSelector((state) => state.theme.theme);


	const handleUploadImage = (event) => {
        if(event.target.files[0].size < 5242880){
            setuploadImage(event.target.files[0]);
            setUploadUrl(URL.createObjectURL(event.target.files[0]));
        }
        else{
            showAlert(
                "image size must be less than 5MB.",
                {
                    header: "File size too large",
                    buttonText: "Try another one",
                }
            );
        }
	};

    const handleBio=(e)=>{
        if(e.length < 250){
            setBio(e)
        }
        else{
            showAlert("bio must be less than 250 characters ", {
				header: "max 250 characters.",
				buttonText: "close",
			});
        }

    }

	const setImgRef = () => {
		imgRef.current.click();
	};

	const EditGroup = () => {
		const form = new FormData();
		setIsLoading(true);
		form.append("groupId", props.data.group_details.id);
		form.append("name", grpName);
		form.append("type", public1 ? "public" : "private");
		form.append("bio", bio);
		form.append("avatar", uploadImage);

		axiosConfig
			.patch(editGroupURL, form)
			.then((res) => {
				showAlert("Edit Group Successfully", {
					header: "",
					buttonText: "Close",
				});
				props.cancel();
				setIsLoading(false);
                props.getGroupInfo()
                dispatch(updateMySingleGroup({...props.data.group_details, name:grpName, avatar:UploadUrl }))

			})
			.catch((err) => {
				showAlert(JSON.stringify(err.response.data?.error), {
					header: err.response.data?.message,
					buttonText: "Close",
				});
				setIsLoading(false);
			});
	};

	return (
		<>
			<div className={styles.container} style={{ width: "100%" }}>
				<div className={styles.infoWrapper}>
					<div className={styles.top}>
						<div className={styles.headerInfo}>
							<Avatar
								style={{
									width: "6.5rem",
									height: "6.5rem",
									margin: "0.5rem 0",
									fontSize: "2.5rem",
									background:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
									color:
										theme === "dark"
											? "#0d0f22"
											: "#d9def4",
								}}
								src={
									uploadImage
										? UploadUrl
										: props.data.group_details.avatar
								}
								onClick={setImgRef}
							>
								{grpName[0]?.toUpperCase()}
							</Avatar>

							<input
								type="file"
								ref={imgRef}
								style={{ display: "none" }}
								onChange={(e) => handleUploadImage(e)}
                                accept="image/png, image/jpg, image/jpeg"
							/>

							<img
								onClick={setImgRef}
								src="/Home/cam.svg"
								className={styles.icon1}
                                alt=""
							/>

							<div className="mt-2 mb-2">
								<div className={styles.globalSwitcher}>
									<StyledSwitch
										onChange={() =>
											public1
												? setPublic(false)
												: setPublic(true)
										}
										checked={!public1}
									/>
									<div
										style={{
											marginLeft: "-.75rem",
											display: "flex",
											flexDirection: "column",
										}}
									>
										<span
											className={
												public1
													? `${styles.selected} ${
															theme === "dark"
																? "selected-dark"
																: "selected-lg"
													  }`
													: `${styles.switchText} ${
															theme === "dark"
																? "text-dark"
																: "text-lg"
													  } `
											}
											onClick={() => setPublic(true)}
										>
											Public
										</span>

										<span
											className={
												!public1
													? `${styles.selected} ${
															theme === "dark"
																? "selected-dark"
																: "selected-lg"
													  }`
													: `${styles.switchText} ${
															theme === "dark"
																? "text-dark"
																: "text-lg"
													  } `
											}
											onClick={() => setPublic(false)}
										>
											Private
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.heading}>
							<input
								value={grpName}
								onChange={(e) => setGrpname(e.target.value)}
								placeholder="Group Name"
								className={styles.groupInput}
							/>
						</div>

						<textarea
							value={bio}
							type="text"
							placeholder="Group description"
							className={styles.groupInputText}
							onChange={(e) => handleBio(e.target.value)}
						/>
					</div>
					<div
						className={styles.members}
						style={{ marginTop: "0.5rem" }}
					></div>

					<div className={styles.buttonsCreate}>
						<Button1
							variant="outlined"
							onClick={props.cancel}
							className={classes.share}
						>
							Cancel
						</Button1>
						&nbsp;&nbsp;
						<Button2
							variant="contained"
							onClick={EditGroup}
							className={classes.cancel}
						>
							{isLoading ? (
								<div>
									<CircularProgress
                                    fontSize="small"
										style={{
											color:
												theme === "dark"
													? "#fff"
													: "#0d0f22",
                                                    width:18,
                                                    height:18
										
										}}
									/>
								</div>
							) : (
								"Done"
							)}
						</Button2>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditGroupModal;
