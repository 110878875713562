import { MESSAGE_STATUS } from "../../Utils/Constants";
import Log from "../../Utils/logger";
import { emitMediaMessage, emitMessage } from "../../Utils/socket";
import { updateDispatchQueue } from "../Actions/messages/actions";
import store from "../store";
const compareArrays = (arr1, arr2) => {
  const comArr1 = arr1.reduce(
    (acc, curr) => (curr.message.status === MESSAGE_STATUS.PENDING ? [...acc, curr.hash] : acc),
    []
  );
  const comArr2 = arr2.reduce(
    (acc, curr) => (curr.message.status === MESSAGE_STATUS.PENDING ? [...acc, curr.hash] : acc),
    []
  );
  return JSON.stringify(comArr1) == JSON.stringify(comArr2);
};

const onMessageSendRequest = () => {
  let isRunning = false;
  let prevState = [];
  return () => {
    const state = store.getState();
    const { dispatch } = store;

    const connectionEstablished = state.deviceParameters.chatSocket;

    const { messages } = state;
    const { dispatchQueue } = messages;
    Log.debug(connectionEstablished, isRunning, compareArrays(prevState, dispatchQueue));
    if (connectionEstablished && !isRunning && !compareArrays(prevState, dispatchQueue)) {
      isRunning = true;
      const newDispatchQueue = dispatchQueue.map((request) => {
        if (request.message.status === MESSAGE_STATUS.PENDING) {
          if (request.message.type === "TEXT") {
            emitMessage({
              message: request.message.message,
              ID: request.conversationID,
              isGroup: request.isGroup || false,
              type: request.message.type,
              hash: request.message.hash,
              reply: request.message.replyMessage,
              meta: request.message.meta,
              mentions: request.message.mentions,
            });
            const newMessage = {
              ...request.message,
              status: MESSAGE_STATUS.SENT,
            };
            return { ...request, message: newMessage };
          } else {
            Log.debug("sending media message");
            emitMediaMessage({
              message: request.message.message,
              ID: request.conversationID,
              isGroup: request.isGroup,
              type: request.message.type,
              hash: request.message.hash,
              files: request.message.file,
            });
            const newMessage = {
              ...request.message,
              status: MESSAGE_STATUS.sending,
            };
            return { ...request, message: newMessage };
          }
        }
        return request;
      });
      dispatch(updateDispatchQueue(newDispatchQueue));
      prevState = dispatchQueue;
      isRunning = false;
    }
  };
};

export default onMessageSendRequest;
