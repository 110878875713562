import React, { PureComponent } from "react";
import { connect } from "react-redux";

import StatusCard from "../../../Extras/StatusCard/StatusCard";
import CardSkeleton from "../../../Skeleton/CardSkeleton/CardSkeleton";
import { withStyles } from "@material-ui/core/styles";

import { bdittoAPIv2 } from "../../../../Utils/axiosConfig";
import { getSuggestionsURLV2 as getSuggestionsURL } from "../../../../Utils/Constants";

import "./Suggestions.scss";
import styles from "./Suggestions.module.css";
import EmptyState from "../../../Extras/EmptyState";
import dummy from "../../../../dummyData/suggestions";
import { getParsedLocations } from "../../../../Redux/selectors/locationSelector";
import { FormattedMessage } from "react-intl";
import Chevron from "../../../../Icons/Chevron";
import { IconButton, Dialog } from "@material-ui/core";
import SettingsIcon from "../../../../Icons/SettingsIcon";
import { Link } from "react-router-dom";
import ArrowDown from "../../../../Icons/ArrowDown";
import Inactive from "../../../../Icons/Inactive";
import {
	ArrowBackIos,
} from "@material-ui/icons";

const useStyles = (theme) => ({
	back: {
		color: "#ff5b5b",
		marginTop: "1rem",
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
	back1: {
		color: "#ff5b5b",
		marginTop: "1rem",
		float: "right",
		marginLeft: "auto",
		marginRight: "0.6rem",
		display: "none",
		[theme.breakpoints.down("xs")]: {
			display: "block",
		},
	},
});

class Suggestions extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			Suggestions: [],
			Closed: [],
			fetching: false,
			activeFetched: false,
			inactiveFetched: false,
			activeTab: 1,
		};
	}

	componentDidMount(){
		this.fetchSuggestions();
	}

	componentDidUpdate(prevProps) {
		if (prevProps !== this.props && !this.props.isStatusLoading) {
			if (
				this.props.showActiveSuggestions !==
					prevProps.showActiveSuggestions ||
				this.props.suggestionId !== prevProps.suggestionId
			) {
				this.fetchSuggestions();
			}
		}

		if (this.props.groupDataFromStore !== prevProps.groupDataFromStore) {
			this.fetchSuggestions();
		}
		if (this.props.locations !== prevProps.locations) {
			this.fetchSuggestions();
		}
	}

	fetchSuggestions() {
		if (this.props.showActiveSuggestions) {
			this.fetchActiveStatusSuggestions();
		} else {
			this.fetchInactiveStatusSuggestions();
		}
	}

	fetchActiveStatusSuggestions() {
		
		const { locations } = this.props;
		// if (!this.props.groupDataFromStore.id) return;
		// if (!this.props.suggestionId || this.state.fetching) return;

		this.setState({
			Suggestions: [],
			Closed: [],
			activeFetched: false,
			inactiveFetched: false,
			fetching: true,
		});
		const activeForm = {
			status: "Active",
			statusID: `${this.props.suggestionId}`,
			location: locations,
			groupId: this.props.groupDataFromStore.id,
		};
		bdittoAPIv2
			.post(getSuggestionsURL, activeForm)
			.then((res) => {
			
				console.log(res,"pop");
				if (res.status !== 200)
				throw new Error();
				else
				this.setState({
					Suggestions: res.data.result || res.data.body,
					activeFetched: true,
					fetching: false,
				});
			})
			.catch((err) => {
				this.setState({ Suggestions: [], activeFetched: true, fetching: false });
			});
	}

	fetchInactiveStatusSuggestions() {
		const { locations } = this.props;
		if (!this.props.groupDataFromStore.id) return;
		if (!this.props.suggestionId || this.state.fetching) return;
		this.setState({
			fetching: true,
			Suggestions: [],
			Closed: [],
			activeFetched: false,
			inactiveFetched: false,
		});

		const inactiveForm = {
			status: "Deactive",
			statusID: `${this.props.suggestionId}`,
			location: locations,
			groupId: this.props.groupDataFromStore.id,
		};
		bdittoAPIv2
			.post(getSuggestionsURL, inactiveForm)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					Closed: res.data.result || res.data.body,
					inactiveFetched: true,
					fetching: false,
				});
			})
			.catch((err) => {
				this.setState({ inactiveFetched: true, fetching: false });
			});
	}

	displayBread() {
		const { classes, onMobileClick } = this.props;
		return (
			<div className={styles.bredcrumb}>
				<span>
					{" "}
					<IconButton
						size="small"
						variant="contained"
						style={{ color: "#ff5d5d" }}
						className={classes.back}
						onClick={onMobileClick}
					>
						<Chevron.leftDark viewBox="0 0 27 27" />
					</IconButton>
				</span>

				<h3
					className={
						this.props.showActiveSuggestions
							? "selectBread"
							: "notSelected"
					}
					id={"active-suggestions"}
					onClick={() => this.props.handleIsActive(true)}
				>
					{" "}
					<ArrowDown
						color={
							this.props.theme === "dark"
								? this.props.showActiveSuggestions
									? "#D9DEF4"
									: "#7F89BE"
								: this.props.showActiveSuggestions
								? "#121528"
								: "#4A517E"
						}
					/>
					&nbsp;
					<span className="mx-2">
						{/* <FormattedMessage
							id="Suggestions"
							defaultMessage={`Matches`}
						/> */}
                        Matches
					</span>
					&nbsp; &nbsp;
				</h3>
				<h3
					id={"closed-suggestions"}
					className={
						!this.props.showActiveSuggestions
							? "selectBread"
							: "notSelected"
					}
					onClick={() => this.props.handleIsActive(false)}
				>
					&nbsp;&nbsp;&nbsp;
					<Inactive
						color={
							this.props.theme === "dark"
								? !this.props.showActiveSuggestions
									? "#D9DEF4"
									: "#7F89BE"
								: !this.props.showActiveSuggestions
								? "#121528"
								: "#4A517E"
						}
					/>
					&nbsp;
					<span className="mx-2">
						{/* <FormattedMessage
							id="closed"
							defaultMessage={`Inactive`}
						/> */}
                        Inactive
					</span>
				</h3>
				<span className={classes.back1}>
					<Link to="/intrests">
						<SettingsIcon viewBox="0 0 27 27" />
					</Link>
				</span>
			</div>
		);
	}

	displayDummy() {
		return dummy.map((item, index) => (
			<StatusCard
				status={item}
				key={index}
				width="47%"
				height="14.25rem"
			/>
		));
	}

	displayStatusSuggestion() {
		// if (this.props.isTutorial) return this.displayDummy();
		return this.props.showActiveSuggestions
			? this.displayActiveSuggestion()
			: this.displayInactiveSuggestion();
	}

	displayActiveSuggestion() {
		const { activeFetched, Suggestions, fetching } = this.state;
		const isMobile = window.screen.width < 768;
		console.log(activeFetched, fetching, "sjsdjhasb");
		if (!activeFetched || fetching) {
			return (
				<>
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
				</>
			);
		} else
			return (
				<>
					{Suggestions.length > 0 ? (
						Suggestions.map((item, index) => (
							<StatusCard
								idx={index}
								status={item}
								key={index}
								width="47%"
								height="14.25rem"
							/>
						))
					) : (
						<EmptyState
							heading="No Matches Yet!"
							imageUrl="/vectors/empty-states/emptySuggestions.svg"
							subHeading="Looks like your status has no matches yet, but don’t worry you’ll find your perfect match soon too! "
						/>
					)}
				</>
			);
	}

	displayInactiveSuggestion() {
		const { inactiveFetched, fetching } = this.state;

		if (!inactiveFetched || fetching) {
			this.fetchInactiveStatusSuggestions();
			return (
				<>
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
					<CardSkeleton width="47%" />
				</>
			);
		} else
			return (
				<>
					{this.state.Closed.length > 0 ? (
						this.state.Closed.map((item, index) => (
							<StatusCard
								status={item}
								key={index}
								width="47%"
								height="14.25rem"
							/>
						))
					) : (
						<EmptyState
							heading="No Closed Statuses"
							imageUrl="/vectors/empty-states/closedThoughts.svg"
							subHeading=" We found no previous deactivated discussions from other users matching
              your status. When we do, you’ll find them here."
						/>
					)}
				</>
			);
	}

	render() {
		const { fetching } = this.state;
		const isMobile = window.screen.width < 768;
		const tabs = [
			{
				id: 1,
				label: "Matches",
			},
			{
				id: 2,
				label: "Inactive",
			},
		
		];
		return (
		<>
		{!isMobile && (	<div
				className={`${styles.mainContainer} mainContainer`}
				id="Suggestions"
			>
				{/* <ArrowBackIcon className={classes.back} onClick={onMobileClick} /> */}

				{this.displayBread()}

				<div
					className={
						this.props.theme === "dark"
							? styles.statusDisplayDk
							: styles.statusDisplayDk
					}
				>
					{fetching && !this.props.isTutorial ? (
						<>
							<CardSkeleton width="47%" />
							<CardSkeleton width="47%" />
							<CardSkeleton width="47%" />
							<CardSkeleton width="47%" />
							<CardSkeleton width="47%" />
							<CardSkeleton width="47%" />
						</>
					) : (
						this.displayStatusSuggestion()
					)}
				</div>
			</div>)}
			{isMobile && (
				<Dialog
				open={true}
				fullScreen={true}
				maxWidth={"100vw"}
				fullWidth={"100vw"}
				classes={{
					paper:
						this.props.theme === "dark"
							? styles.rootDark
							: styles.rootLg,
				}}
			>
				<div className={styles.mobNav}>
				<span className="btn p-1">
				<ArrowBackIos
                    onClick={() => this.props.handleMobileSuggestions(false)}
                    style={{ color: "#D9DEF4" }}
                />
					</span>
					<div
                        className={`${styles.headerText} ${
                            this.props.theme === "dark"
                                ? styles.darkPText
                                : styles.lgPText
                        }`}
                    >
                        <h2 className="mb-0">
                            <FormattedMessage
                                id="thought"
                                defaultMessage={this.props.thoughtContent}
                            />
                        </h2>
                    </div>
				</div>
			<div className={styles.mobileContainer}>	<div className={styles.thoughtsTab}>
			{tabs.map((tab, i) =>
				tab.id === this.state.activeTab ? (
					<div className={styles.activeTab}>
						{tab.label}
					</div>
				) : (
					<div
						onClick={() =>
							{this.setState({ activeTab: tab.id })
						this.fetchSuggestions()
						}
						}
						className={styles.tab}
					>
						{tab.label}
					</div>
				)
			)}
			
		</div>
		{this.state.activeTab === 1 && (
			<>
			{this.displayActiveSuggestion()}
			</>

		)}
		{this.state.activeTab === 2 && (
			<>
			{this.displayInactiveSuggestion()}
			</>
		)}
		</div>	</Dialog>
			)}
		</>
		);
	}
}

const mapStateToProps = (state) => ({
	locations: getParsedLocations(state),
	isTutorial: state.tutorial.enabled,
	groupDataFromStore: state.groups.groupData,
	theme: state.theme.theme,
});

export default connect(mapStateToProps)(withStyles(useStyles)(Suggestions));
