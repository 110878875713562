import {
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Close } from "../../../../Icons";
import { addNewFolder } from "../../../../Redux/Actions/bookmarks/folderActions";
import axiosConfig from "../../../../Utils/axiosConfig";
import { foldersAPI } from "../../../../Utils/Constants";
import useAlert from "../../../Extras/Alert/useAlert";
import DialogModal from "../../../Extras/DialogModal";
import styles from "./NewFolderDialog.module.scss";
import { withStyles } from "@material-ui/core/styles";
import Button1 from "../../../Extras/Buttons/Button1/Button1";
import Button2 from "../../../Extras/Buttons/Button2/Button2";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const NewFolderDialog = ({ show, onClose, ...props }) => {
	const [folderName, setFoldername] = useState("");
	const [creating, setCreating] = useState(false);
	const dispatch = useDispatch();
	const { showAlert } = useAlert();
	const [error, setError] = useState("");
	const inputRef = useRef();
	const theme = useSelector((reduxState) => reduxState.theme.theme);

	const isMobile = window.screen.width < 768;

	// useEffect(() => {
	//   if (show) {
	//     setTimeout(() => {
	//       inputRef.current.focus();
	//     }, 100);
	//   }
	// }, [show]);
	const handleChange = (e) => {
		setError("");
		if (e.target.value.length < 25) {
			setFoldername(e.target.value);
		} else {
			setError("Very long folder name");
		}
	};

	const addFolder = async (e) => {
		e.preventDefault();

		if (folderName) {
			if (!error) {
				if (folderName.length < 3) {
					setError("name is too short");
					return;
				}
				setCreating(true);
				try {
					const form = new FormData();
					form.append("foldername", folderName);
					form.append("ID", "");
					const res = await axiosConfig.post(
						foldersAPI.ADD_UPDATE_FOLDER,
						form
					);

					setCreating(false);
					onClose();
					setFoldername("");
					dispatch(addNewFolder(res.data.body));
				} catch (err) {
					setCreating(false);
					if (err.response) {
						if (
							err.response.status === 403 &&
							err.response.statusText === "Forbidden"
						) {
							showAlert(
								"Your account has been blocked from update actions. Please contact Admin.",
								{
									header: "You Account is Blocked",
									buttonText: "Go Back",
								}
							);
						} else {
							showAlert(err.response.data.message, {
								header: err.response.data.error,
								buttonText: "Close",
							});
						}
					} else {
						showAlert("Reqest Falied", {
							buttonText: "Try Again",
						});
					}
				}
			}
		} else {
			showAlert("folder name is required", {
				buttonText: "close",
			});
		}
	};

	const CustomInput = withStyles({
		root: {
			"& label.Mui-focused": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
			},
			"& .MuiFormControl-root": {
				width: "100% !important",
			},
			"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
				padding: "0 10px",
				zIndex: "5",
				background: theme === "dark" ? "#121528" : "#fff",
			},
			"& .MuiInputLabel-outlined": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
			},
			"& .MuiOutlinedInput-input": {
				color: theme === "dark" ? "#7F89BE" : "#0d0f22",
				border:
					theme === "dark" ? "1px solid white" : "1px solid #0d0f22",
				borderRadius: "8px",
				padding: "20px",
			},
			"&::label": {
				color: "#7F89BE !important",
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: "#7F89BE !important",
			},
			"& .MuiOutlinedInput-root": {
				"& fieldset": {
					borderColor: "#7F89BE !important",
					color: "#7F89BE !important",
				},
				"&:hover fieldset": {
					borderColor: "#7F89BE !important",
					color: "#7F89BE !important",
				},
				"&.Mui-focused fieldset": {
					borderColor: "#7F89BE !important",
					color: "#7F89BE !important",
				},
				"& .MuiFormLabel-root , .MuiInputBase-root": {
					color: "#7F89BE !important",
				},
				".MuiOutlinedInput-multiline": {
					padding: "0",
				},
			},
		},
	})(TextField);

	return (
		<DialogModal
			width="xs"
			className={theme === "dark" ? styles.dialogDark : styles.dialogLg}
			show={show}
			onHide={() => {
				onClose();
				setFoldername("");
			}}
			maxWidth="false"
		>
			<div className={styles.header}>
				<DialogTitle className={styles.title}>
					Create a New Collection
				</DialogTitle>
				<IconButton
					className={styles.closeButton}
					onClick={() => {
						onClose();
						setFoldername("");
					}}
				>
					<Close.Outline />
				</IconButton>
			</div>

			<DialogContent>
				<div className={styles.content}>
					<form onSubmit={addFolder}>
						<CustomInput
							inputRef={inputRef}
							error={!!error}
							helperText={error}
							variant="outlined"
							label="Collection name"
							onChange={handleChange}
							value={folderName}
							autoFocus
						/>
						<div className={styles.buttonContainer}>
							<div
								onClick={() => {
									onClose();
									setFoldername("");
								}}
							>
								<Button1 type="button">
									{/* <FormattedMessage
										id="cancel"
										defaultMessage={`cancel`}
									/> */}
									cancel
								</Button1>
							</div>
							{isMobile && <div className="mb-2"></div>}
							<div onClick={addFolder}>
								<Button2>
									{/* <FormattedMessage
										id="createCollection"
										defaultMessage={`Create Collection`}
									/> */}
									Create Collection
								</Button2>
							</div>
						</div>
					</form>
				</div>
			</DialogContent>
		</DialogModal>
	);
};

export default NewFolderDialog;
