import React from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import styles from "./Privacy.module.css";

export default function Privacy() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<Navbar />
				<div className={styles.cont}>
					<div className={styles.yellowBox}></div>
					<h1 className={styles.heading}>BDITTO PRIVACY POLICY</h1>
					<p className={styles.content}>
						Last updated and effective: January 29, 2021
					</p>

					<h2 className={styles.subHeading}>WELCOME TO BDITTO!</h2>

					<p className={styles.content}>
						Bharg Technologies GmbH, provides a social online and
						mobile chat platform via the BDITTO website (the
						“Site”), and related Internet services (collectively,
						the “Service(s)”). The Service is operated by Bharg
						Technologies GmbH(the “Company”, “we” or “us”) for users
						of the Service (“you”). This Privacy Policy sets forth
						our policy with respect to information that is collected
						from visitors to the Site and users of the Webapp and/or
						the Services. Under applicable law, Bharg Technologies
						GmbH is the “data controller” of personal data collected
						through the Services.
					</p>
					<h2 className={styles.subHeading}>
						INFORMATION WE COLLECT
					</h2>

					<p className={styles.content}>
						When you interact with us through the Services, we may
						collect information from you, as further described
						below:
						<br />
						<b> Information You Provide: </b>We collect information
						from you when you voluntarily provide such information,
						such as when you register for access to the Services or
						use certain Services. Information we collect may include
						but not be limited to username, name, email address,
						age, location, and any messages, images, transient VOIP
						data (to enable communication delivery only) or other
						content you send via the chat feature.
					</p>
					<h2 className={styles.subHeading}>OTHER INFORMATION:</h2>

					<p className={styles.content}>
						<ul>
							<li>
								<b>Data We Collect Automatically</b>: When you
								interact with us through the Services, we
								receive and store certain information such as an
								IP address, device ID, and your activities
								within the Services. We may store such
								information or such information may be included
								in databases owned and maintained by affiliates,
								agents or service providers. The Services may
								use such information and pool it with other
								information to track, for example, the total
								number of visitors to our Site, the number of
								messages users have sent, as well as the sites
								which refer visitors to Bditto.
							</li>
							<li>
								<b>Aggregated Information</b>: In an ongoing
								effort to better understand and serve the users
								of the Services, we may conduct research on our
								customer demographics, interests and behavior
								based on the information collected. This
								research may be compiled and analyzed on an
								aggregate basis, and we may share this aggregate
								data with our affiliates, agents and business
								partners. We may also disclose aggregated user
								statistics in order to describe our services to
								current and prospective business partners, and
								to other third parties for other lawful
								purposes.
							</li>
							<li>
								<b>Info through other Services</b>: You may give
								us permission to collect your information in
								other services. For example, you may connect a
								social networking service ("SNS") such as
								Google, Facebook or Twitter to your BDITTO
								account. When you do this, it allows us to
								obtain information from those accounts (for
								example, your friends or contacts).
							</li>
							<li>
								<b>Cookies</b>: We employ cookies and similar
								technologies to keep track of your local
								computer’s settings such as which account you
								have logged into and notification settings.
								Cookies are pieces of data that sites and
								services can set on your browser or device that
								can be read on future visits. We may expand our
								use of cookies to save additional data as new
								features are added to the Service. In addition,
								we use technologies such as web beacons and
								single-pixel gifs to record log data such as
								open rates for emails sent by the system.
							</li>
							<li>
								We may use third party web site analytic tools
								such as Google Analytics on our website that
								employ cookies to collect certain information
								concerning your use of our Services. However,
								you can disable cookies by changing your browser
								settings. Further information about the
								procedure to follow in order to disable cookies
								can be found on your Internet browser provider's
								website via your help screen.
							</li>
							<li>
								<b>Advertisements</b>: You may see our Service
								advertised in other applications or websites.
								After clicking on one of these advertisements
								and installing our Service, you will become a
								user of the Service. Advertising platforms,
								which include Twitter and Facebook (and whose
								SDKs are integrated within our Service), may
								collect information for optimizing advertising
								campaigns outside of the Service.
							</li>
						</ul>
						If you do not wish to receive personalized advertising
						that is delivered by third parties outside of the BDITTO
						Service, you may be able to exercise that choice through
						opt-out programs that are administered by third parties,
						including the Network Advertising Initiative (NAI), the
						Digital Advertising Alliance (DAA). Our Services
						currently do not respond to “Do Not Track” (DNT) signals
						and operate as described in this Privacy Policy whether
						or not a DNT signal is received, as there is no
						consistent industry standard for compliance.
					</p>

					<h2 className={styles.subHeading}>
						WHERE INFORMATION IS PROCESSED
					</h2>

					<p className={styles.content}>
						The Company is based in Switzerland. No matter where you
						are located, you consent to the processing and
						transferring of your information in and to Switzerland
						and other countries. The laws of Switzerland and other
						countries governing data collection and use may not be
						as comprehensive or protective as the laws of the
						country where you live.
					</p>
					<h2 className={styles.subHeading}>
						OUR USE OF YOUR INFORMATION
					</h2>

					<p className={styles.content}>
						We use the information you provide in a manner that is
						consistent with this Privacy Policy. If you provide
						information for a certain reason, we may use the
						information in connection with the reason for which it
						was provided. For instance, if you contact us by email,
						we will use the information you provide to answer your
						question or resolve your problem. Also, if you provide
						information in order to obtain access to the Services,
						we will use your information to provide you with access
						to such services and to monitor your use of such
						services. The Company and its subsidiaries and
						affiliates (the “Related Companies”) may also use your
						information collected through the Services to help us
						improve the content and functionality of the Services,
						to better understand our users and to improve the
						Services. The Company and its affiliates may use this
						information to contact you in the future to tell you
						about services we believe will be of interest to you. If
						we do so, each marketing communication we send you will
						contain instructions permitting you to "opt-out" of
						receiving future marketing communications. In addition,
						if at any time you wish not to receive any future
						marketing communications or you wish to have your name
						deleted from our mailing lists, please contact us as
						indicated below.
					</p>
					<h2 className={styles.subHeading}>
						OUR LEGAL BASES FOR HANDLING OF YOUR PERSONAL DATA
					</h2>

					<p className={styles.content}>
						The laws in some jurisdictions require companies to tell
						you about the legal ground they rely on to use or
						disclose your personal data. To the extent those laws
						apply, our legal grounds are as follows:
						<ul>
							<li>
								To honor our contractual commitments to you:
								Much of our processing of personal data is to
								meet our contractual obligations to our users,
								or to take steps at users’ request in
								anticipation of entering into a contract with
								them. For example, we handle personal data on
								this basis to create your account and provide
								our Services.
								<ul>
									<li type="circle">
										Legitimate interests: In many cases, we
										handle personal data on the ground that
										it furthers our legitimate interests in
										ways that are not overridden by the
										interests or fundamental rights and
										freedoms of the affected individuals:
										This includes:
										<ul>
											<li type="square">
												Providing a safe and enjoyable
												user experience;
											</li>
											<li type="square">
												Customer service;
											</li>
											<li type="square">
												Marketing, e.g. sending emails
												or other communications to let
												you know about new features;
											</li>
											<li type="square">
												Protecting our users, personnel,
												and property;
											</li>
											<li type="square">
												Analyzing and improving our
												business, e.g. collecting
												information about how you use
												our Services to optimize the
												design and placement of certain
												features;{" "}
											</li>
											<li type="square">
												{" "}
												Processing job applications;{" "}
											</li>
											<li type="square">
												{" "}
												Managing legal issues.{" "}
											</li>
											<li type="square">
												Legal compliance: We need to use
												and disclose personal data in
												certain ways to comply with our
												legal obligations.
											</li>
											<li type="square">
												To protect the vital interests
												of the individual or others: For
												example, we may collect or share
												personal data to help resolve an
												urgent medical situation.
											</li>
										</ul>
									</li>
									<li type="circle">
										Consent: Where required by law, and in
										some other cases, we handle personal
										data on the basis of your implied or
										express consent.
									</li>
								</ul>
							</li>
						</ul>
					</p>

					<h2 className={styles.subHeading}>
						OUR DISCLOSURE OF YOUR INFORMATION
					</h2>

					<p className={styles.content}>
						The Company is not in the business of selling your
						personal information. We consider this information to be
						a vital part of our relationship with you. There are,
						however, certain circumstances in which we may share
						your information with certain third parties, as set
						forth below:
						<ul>
							<li>
								<b>Business Transfers</b>: As we develop our
								business, we might sell or buy businesses or
								assets. In the event of a corporate sale,
								merger, reorganization, bankruptcy, dissolution
								or similar event, your information may be part
								of the transferred assets.
							</li>
							<li>
								<b>Consent</b>: We may transfer your information
								with your consent.
							</li>
							<li>
								<b>Related Companies</b>: We may also share your
								information with our Related Companies for
								purposes consistent with this Privacy Policy.
							</li>
							<li>
								<b>Developers</b>: Developers using our SDK or
								API will have access to their end users’
								information, including message content, message
								metadata, and voice metadata. Developers must
								use such information only to provide the SDK/API
								functionality within their applications and/or
								services.
							</li>
							<li>
								<b>
									Agents, Consultants and Related Third
									Parties
								</b>
								:Like many businesses, we sometimes hire other
								companies or individuals to perform certain
								business-related functions. Examples of such
								functions include mailing information,
								maintaining databases and processing payments.
							</li>
							<li>
								<b>Legal Requirements</b>: We may disclose your
								information if required to do so by law or in
								the good faith belief that such action is
								necessary to (i) comply with a legal obligation,
								(ii) protect and defend the rights or property
								of the Company or Related Companies, (iii)
								protect the personal safety of users of the
								Services or the public, or (iv) protect against
								legal liability.
							</li>
							<li>
								<b>Aggregated or Non-identifiable Data</b>: We
								may also share aggregated or non-personally
								identifiable information with our partners or
								others for business purposes.
							</li>
						</ul>
					</p>

					<h2 className={styles.subHeading}>
						UNSOLICITED INFORMATION
					</h2>

					<p className={styles.content}>
						You may provide us with ideas for new products or
						modifications to existing products, and other
						unsolicited submissions (collectively, “Unsolicited
						Information”). All Unsolicited Information shall be
						deemed to be non-confidential and we shall be free to
						reproduce, use, disclose, and distribute such
						Unsolicited Information to others without limitation or
						attribution.
					</p>

					<h2 className={styles.subHeading}>CHILDREN</h2>

					<p className={styles.content}>
						Our Services are for users age 13 and over and we do not
						knowingly collect personal information from children
						under the age of 13. If you are a parent or guardian of
						a child under the age of 13 and believe he or she has
						disclosed personal information to us please contact us
						at{" "}
						<a href="mailto:admin@bditto.com">admin@bditto.com</a>.
						Note: In some countries, the age of digital consent is
						older than 13 (In that case, please verify the legal
						requirement in your respective country). If you are in
						those countries, you must be at least that age to use
						the Services. For example, for residents of the EEA,
						where processing of personal information is based on
						consent, Bditto will not knowingly engage in that
						processing for users under the age of consent
						established by applicable data protection law. If we
						learn that we are engaged in that processing with such
						users, we will halt such processing and will take
						reasonable measures to promptly remove applicable
						information from our records.
					</p>
					<h2 className={styles.subHeading}>
						LINKS TO OTHER WEB SITES
					</h2>

					<p className={styles.content}>
						This Privacy Policy applies only to the Services. The
						Services may contain links to other web sites not
						operated or controlled by us (the “Third Party Sites”).
						The policies and procedures we described here do not
						apply to the Third Party Sites. The links from the
						Services do not imply that we endorse or have reviewed
						the Third Party Sites. We suggest contacting those sites
						directly for information on their privacy policies.
					</p>
					<h2 className={styles.subHeading}>DATA RETENTION</h2>

					<p className={styles.content}>
						We generally retain personal data for so long as it may
						be relevant to the purposes identified herein. To
						dispose of personal data, we may anonymize it, delete it
						or take other appropriate steps. Data may persist in
						copies made for backup and business continuity purposes
						for additional time.
					</p>
					<h2 className={styles.subHeading}>SECURITY</h2>

					<p className={styles.content}>
						We take reasonable steps to protect the information
						provided via the Services from loss, misuse, and
						unauthorized access, disclosure, alteration, or
						destruction. However, no Internet or email transmission
						is ever fully secure or error free. In particular, email
						sent to or from the Services may not be secure.
						Therefore, you should take special care in deciding what
						information you send to us via email. Please keep this
						in mind when disclosing any information via the
						Internet.
					</p>
					<h2 className={styles.subHeading}>
						YOUR DATA RIGHTS AND CHOICES
					</h2>

					<p className={styles.content}>
						We believe that users should be treated equally no
						matter where they are, and so we are making the
						following options to control your data available to all
						users, regardless of their location.
					</p>
					<p className={styles.content}>
						Individuals in California, the European Economic Area,
						Canada, Costa Rica and some other jurisdictions outside
						the United States have certain legal rights to obtain
						confirmation of whether we hold personal data about
						them, to access personal data we hold about them
						(including, in some cases, in portable form), and to
						obtain its correction, update, amendment or deletion in
						appropriate circumstances. They may also object to our
						uses or disclosures of personal data, to request a
						restriction on its processing, or withdraw any consent,
						though such actions typically will not have retroactive
						effect. They also will not affect our ability to
						continue processing data in lawful ways.
					</p>
					<p className={styles.content}>
						<ul>
							<li>
								<b>
									How can I access the personal data you have
									about me?
								</b>
							</li>
							<li style={{ listStyleType: "none" }}>
								If you would like to submit a data access
								request, you can request via sending an email to{" "}
								<a href="mailto:admin@bditto.com">
									admin@bditto.com
								</a>
								. We will then start the process and provide you
								the personal data that Bditto has on you within
								30 days.
							</li>
							<li>
								<b>
									How do I correct, update, amend, or delete
									the personal data you have about me?
								</b>
							</li>
							<li style={{ listStyleType: "none" }}>
								Through the “Settings” of the Services, you can
								correct, update, amend, or delete certain
								personal data, you can also request other
								modifications from us directly. Please write us
								at{" "}
								<a href="mailto:admin@bditto.com">
									admin@bditto.com
								</a>{" "}
								with the words “Personal Data Request” in the
								subject or body of your message, along with an
								explanation of what data subject right you are
								seeking to exercise. For your protection, we may
								take steps to verify identity before responding
								to your request.
							</li>
							<li>
								<b>
									How do I object or restrict the manner in
									which Bditto processes my personal data?
								</b>
							</li>
							<li style={{ listStyleType: "none" }}>
								You have a right to ask us to stop using or
								limit our use of your personal data in certain
								circumstances—for example, if we have no lawful
								basis to keep using your data, or if you think
								your personal data is inaccurate.
								<br />
								Bditto offers you the ability to restrict the
								processing of your data for specific uses, which
								you can find in the “Settings” page of the
								services. Individuals in the European Economic
								Area have the right to opt out of all of our
								processing of their personal data for direct
								marketing purposes by deleting their account. To
								exercise this right, please see the “Settings”
								page for your Account and option to delete the
								account.
								<br />
								The rights and options described above are
								subject to limitations and exceptions under
								applicable law. In addition to those rights, you
								have the right to lodge a complaint with the
								relevant supervisory authority. However, we
								encourage you to contact us first, and we will
								do our very best to resolve your concern.
							</li>
						</ul>
					</p>

					<h2 className={styles.subHeading}>
						WHO IS BDITTO’S EEA REPRESENTATIVE?
					</h2>

					<p className={styles.content}>
						For data protection matters, pursuant to Article 27 of
						the General Data Protection Regulation of the European
						Economic Area, only on matters related to the processing
						of personal data.
						<br />
						<br />
						You can contact Bditto at the following address:
						<br />
						Bharg Technologies GmbH,
						<br />
						Pfaeffikonerstrasse 27,
						<br />
						8834 Schindellegi,
						<br />
						Switzerland
					</p>

					<h2 className={styles.subHeading}>
						YOUR CALIFORNIA PRIVACY RIGHTS
					</h2>

					<p className={styles.content}>
						Consumers residing in California are afforded certain
						additional rights with respect to their personal
						information under the California Consumer Privacy Act or
						(“CCPA”) and the “Shine the Light” Law. If you are a
						California resident, this section applies to you.
						<br />
						<br />
						<b>California Consumer Privacy Act</b>
						<br />
						<b>Our Collection and Use of Personal Information</b>:
						We collect the following categories of personal
						information: identifiers (such as your username, the
						email address you use to sign up, and your phone number,
						if you’ve chosen to provide it); internet or other
						network information (how you interact with the
						application); location information (because your IP
						address may indicate your general location); inference
						data about you; and other information that identifies or
						can be reasonably associated with you. For examples of
						the precise data points, we collect and the sources of
						such collection, please see the “INFORMATION WE COLLECT”
						section above. We collect personal information for the
						business and commercial purposes described in “OUR USE
						OF YOUR INFORMATION” above.
						<br />
						<b>Disclosure of Personal Information</b>: We may share
						your personal information with third parties as
						described in the “OUR DISCLOSURE OF YOUR INFORMATION”
						section above. We disclose the categories of personal
						information mentioned above for business or commercial
						purposes.
						<br />
						<b>No Sale of Personal Information</b>: The CCPA sets
						forth certain obligations for businesses that sell
						personal information. We do not sell the personal
						information of our users.
						<br />
						<b>Exercising Your Consumer Rights: </b> If you are a
						California resident, you have the right to request (1)
						more information about the categories and specific
						pieces of personal information we have collected and
						disclosed for a business purpose in the last 12 months,
						(2) deletion of your personal information, and (3) to
						opt out of sales of your personal information, if
						applicable. Details on how to make these requests are in
						the “YOUR DATA RIGHTS AND CHOICES” section above. We
						will not discriminate against you if you exercise your
						rights under the CCPA.
						<br />
					</p>

					<h2 className={styles.subHeading}>
						CHANGES TO THIS PRIVACY POLICY
					</h2>

					<p className={styles.content}>
						We reserve the right to update or modify this Privacy
						Policy at any time and from time to time without prior
						notice. Please review this policy periodically, and
						especially before you provide any information. This
						Privacy Policy was last updated on the date indicated
						above. Your continued use of the Services after any
						changes or revisions to this Privacy Policy shall
						indicate your agreement with the terms of such revised
						Privacy Policy.
					</p>
					<h2 className={styles.subHeading}>CONTACTING US</h2>

					<p className={styles.content}>
						Please also feel free to contact us if you have any
						questions about this Privacy Policy or the information
						practices of the Services. You may contact us as
						follows:{" "}
						<a href="mailto:admin@bditto.com">admin@bditto.com</a>.
					</p>
				</div>

				<div className={styles.blueBox}></div>
				<Footer />
			</div>
		</div>
	);
}
