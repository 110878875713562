import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./Reducers";
import thunk from "redux-thunk";

import { loadState, saveState } from "./localstorage";
import onMessageSendRequest from "./actors/MessageSendActor";

const persistedState = loadState();

const Middlewares = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(...Middlewares)));

store.subscribe(() => {
  saveState(store.getState(), {
    exclude: [
      "messages",
      "deviceParameters",
      "notifications",
      "socialLogin",
      "blockedUser",
      "bookmarks",
      "contactRequests",
      "contacts",
      "videoCalls",
    ],
  });
});

store.subscribe(onMessageSendRequest());

export default store;
