import { CLEAR_LOCATIONS, LOCATION_SET, ONLINE_SET, REMOVE_LOCATION } from "./actionTypes";

export const setLocation = (payload) => {
  return {
    type: LOCATION_SET,
    payload,
  };
};
export const removeLocation = (payload) => {
  return {
    type: REMOVE_LOCATION,
    payload,
  };
};

export const clearLocations = () => ({ type: CLEAR_LOCATIONS });

export const setOnline = (payload) => {
  return {
    type: ONLINE_SET,
    payload,
  };
};
