import React from "react";
import { Redirect, withRouter } from "react-router-dom";

const UnprotectedRoute = ({
	component: Component,
	isAuthenticated,
	...rest
}) => {
	return !isAuthenticated ? (
		<>
			<Component rootParams={rest.computedMatch} {...rest} />
		</>
	) : (
		<Redirect to="/" />
	);
};

export default withRouter(UnprotectedRoute);
