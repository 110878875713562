import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import CryptoHexENC from "crypto-js/enc-hex";
import CryptoSHA256 from "crypto-js/hmac-sha256";

const Key = "#@24idfnc!3892jfds@#@^*9";

export const encryptToken = (token) => {
	var encryptToken = CryptoAES.encrypt(token, Key).toString();
	return encryptToken;
};

export const decryptToken = (token) => {
	var bytes = CryptoAES.decrypt(token, Key, "");
	var decryptedToken = bytes.toString(CryptoENC);

	return decryptedToken;
};

export const encryptMessage = (messageText, convId) => {
	const payload = {
		message: messageText,
		conversationID: convId,
		timeStamp: new Date().toISOString(),
	};
	return CryptoSHA256(JSON.stringify(payload), "secret").toString(
		CryptoHexENC
	);
};

export const getDecryptedToken = () => {
	const secretToken = localStorage.getItem("authToken");

	if (secretToken) {
		var bytes = CryptoAES.decrypt(secretToken, Key, "");
		var decryptedToken = bytes.toString(CryptoENC);

		return decryptedToken;
	}

	return "";
};
