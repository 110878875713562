import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { scroller, Element as ScrollElement } from "react-scroll";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import MediaQuery from "react-responsive";
import throttle from "lodash/throttle";

import {
	removeFromQueue,
	setChatLoading,
	deleteMesage,
} from "../../../../../Redux/Actions/messages/actions";
import { joinPing } from "../../../../../Redux/Actions/deviceParamsActions";
import { clearUnReadMessageCount } from "../../../../../Redux/Actions/messages/unreadMessageActions";

//Utils
import * as Socket from "../../../../../Utils/socket";
import axiosConfig from "../../../../../Utils/axiosConfig";
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import {
	getGroupInfoURL,
	getChatsURL,
	likeStatus,
} from "../../../../../Utils/Constants";
import { formatNumber } from "../../../../../Utils/numbers";
import { getUrlParams } from "../../../../../Utils/useQuery";

//Material UI
import {
	Avatar,
	CircularProgress,
	Button,
	IconButton,
	Slide,
	Tooltip,
	withStyles,
	Dialog,
} from "@material-ui/core";
import {
	MoreVert,
	Close,
	MoreHoriz as MoreHorizIcon,
	ArrowBackIos,
	Search,
} from "@material-ui/icons";

//Components
import MessageInfo from "../../Extra/MessageInfo/MessageInfo";
import { BookmarksDialog } from "../../../Bookmark/BookmarksDropdown/BookmarksDropdown";
import SidebarContextMenu from "../../Sidebar/SidebarContextMenu";
import MessageForwardDialog from "../../Extra/MessageForwardDialog/MessageForward";
import MessageBox from "../../Extra/MessageBox";
import InputBox from "../../Extra/InputBox/InputBox";
import Share from "../../Extra/ShareURL/Share";
import withAlert from "./../../../../Extras/Alert/withAlert";
import Username from "../../../../Extras/Username";
import SearchInConv from "../../SearchInConv/SearchInConv";
import GroupInfo from "../../GroupInfo/Group/GroupInfo";
import HoverPopover from "../../../../Extras/HoverPopover";
import { Chevron, Like } from "../../../../../Icons";

import styles from "./ChatSection.module.css";

const useStyles = (theme) => ({
	avatar: {
		avatar: {
			width: theme.spacing(3),
			height: theme.spacing(3),
		},
	},
	contextMenuButton: {
		textTransform: "capitalize",
		textAlign: "left",
	},
});

class ChatSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			open: false,
			groupLoading: false,
			chatLoading: false,
			moreChats: true,
			chats: [],
			chatID: this.props.chatID,
			chatType: this.props.chatType,

			replyMessage: null,
			memberTyping: "",

			chatGroupInfo: {},
			isLiked: false,
			isSaved: false,

			showLiked: false,
			showSearch: false,
			replyHighlight: "",
			messageInfoId: undefined,
			isOwnMessage: undefined,

			forwardMessage: { open: false, messageID: null },

			activeTab: 1,
		};

		this.timer = null;
		this.scrollRef = React.createRef();
	}

	componentDidMount = () => {
		this.props.clearFromQueue(this.state.chatID);

		this.getGroupInfo(this.state.chatID);
		Socket.listenTyping(this.setMemberTyping);
		Socket.listenNewMembers(this.listenNewMembers, this.state.chatID);
		Socket.listenMessageDeletes(
			this.listenMessageDeletes,
			this.state.chatID
		);
		Socket.listenLeaveGroup(this.listenMembersLeaving, this.state.chatID);
		Socket.ListenRemovalFromGroup(this.onRemovalFromGroup, {
			conversationID: this.state.chatID,
			userID: this.props.userID,
		});

		const queryParams = getUrlParams(this.props.location.search);
		if (queryParams.highlight) {
			this.fetchChats(
				this.state.chatID,
				0,
				queryParams.highlight,
				(isValid) => {
					if (isValid) {
						this.highlightReply(
							queryParams.highlight,
							this.state.chatID
						);
					}
				}
			);
		} else {
			this.fetchChats(this.state.chatID, 0);
		}
	};

	componentDidUpdate = (prevProps, prevState) => {
		const { chatType, chatID } = this.props;
		const queryParams = getUrlParams(this.props.location.search);
		const prevQueryParams = getUrlParams(prevProps.location.search);
		if (
			chatType.toLowerCase() !== prevProps.chatType ||
			chatID !== prevProps.chatID
		) {
			this.setState({
				chatType: chatType.toLowerCase(),
				chatID,
				chats: [],
				showSearch: false,
				open: false,
			});
			this.props.handleRightBar({ searchConv: false, chatInfo: false });
			this.getGroupInfo(chatID);

			this.props.clearFromQueue(chatID);
			Socket.listenNewMembers(this.listenNewMembers, chatID);
			Socket.listenMessageDeletes(this.listenMessageDeletes, chatID);
			Socket.listenLeaveGroup(this.listenMembersLeaving, chatID);
			Socket.ListenRemovalFromGroup(this.onRemovalFromGroup, {
				conversationID: this.state.chatID,
				userID: this.props.userID,
			});
			if (queryParams.highlight) {
				this.fetchChats(chatID, 0, queryParams.highlight, (isValid) => {
					if (isValid) {
						this.highlightReply(queryParams.highlight, chatID);
					}
				});
			} else {
				this.fetchChats(chatID, 0);
			}
		} else {
			if (
				queryParams.highlight &&
				prevQueryParams.highlight !== queryParams.highlight
			) {
				if (
					!queryParams.changed ||
					!prevQueryParams.groupID ||
					queryParams.groupID === prevQueryParams.groupID
				) {
					this.highlightReply(queryParams.highlight, chatID);
				} else {
				}
			}
		}

		if (prevProps.newChats.length < this.props.newChats.length) {
			Socket.emitRead(
				this.state.chatID,
				this.props.newChats[0].message._id,
				true
			);
		}
	};

	listenNewMembers = (memberMessage, isMember, addMessage = true) => {
		const { user, ...message } = memberMessage;

		this.setState({
			chats: addMessage
				? [message, ...this.state.chats]
				: this.state.chats,
			chatGroupInfo: {
				...this.state.chatGroupInfo,
				...(isMember ? { isMember: true } : {}),
				members: [...this.state.chatGroupInfo.members, user],
				inactive: [
					...this.state.chatGroupInfo.inactive.filter(
						(member) => member.userID === user.userID
					),
				],
			},
		});
	};

	onRemovalFromGroup = () => {
		this.setState({
			chatGroupInfo: { ...this.state.chatGroupInfo, isMember: false },
		});
	};

	listenMembersLeaving = (memberMessage) => {
		const { userID, ...message } = memberMessage;
		const user = this.state.chatGroupInfo.members.find(
			(member) => Number(member.userID) === Number(userID)
		);
		if (user) {
			this.setState({
				chats: [message, ...this.state.chats],
				chatGroupInfo: {
					...this.state.chatGroupInfo,
					inactive: [...this.state.chatGroupInfo.inactive, user],
					members: [
						...this.state.chatGroupInfo.members.filter(
							(member) => member.userID !== userID
						),
					],
				},
			});
		} else {
			this.setState({
				chats: [message, ...this.state.chats],
			});
		}
	};

	onMemberLeave = (userID) => {
		const user = this.state.chatGroupInfo.members.find(
			(member) =>
				Number(member.userID) === Number(userID || this.props.userID)
		);
		if (user) {
			this.setState({
				chatGroupInfo: {
					...this.state.chatGroupInfo,
					isMember: false,
					members: this.state.chatGroupInfo.members.filter(
						(member) => member.userID !== this.props.userID
					),
					inactive: [...this.state.chatGroupInfo.inactive, user],
				},
			});
		} else {
			this.setState({
				chatGroupInfo: { ...this.state.chatGroupInfo, isMember: false },
			});
		}
	};

	listenMessageDeletes = (deletedMessage) => {
		this.setState((prev) => ({
			chats: prev.chats.map((chat) =>
				deletedMessage.messageID !== chat._id
					? chat
					: { ...chat, isDeleted: true }
			),
		}));
		this.props.deleteMessage(deletedMessage.messageID, this.state.chatID);
	};

	fetchChats = throttle((ID, skip, requiredMessageId, callback) => {
		if (this.state.chatLoading) return;

		this.setState({ chatLoading: true });
		this.props.setChatLoading(true);

		let data = { groupID: ID, skip };

		if (requiredMessageId) {
			data["requiredMessageID"] = requiredMessageId;
		}

		axiosChatConfig
			.post(getChatsURL, data)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				if (
					this.state.chats.length === 0 &&
					res.data.chats.length > 0
				) {
					Socket.emitRead(
						this.state.chatID,
						res.data.chats[0]._id,
						true
					);
					this.props.clearUnreadMessageCount(this.state.chatID);
				}

				this.setState(
					{
						chats: [...this.state.chats, ...res.data.chats],
						chatLoading: false,
						moreChats: res.data.chats.length > 0,
					},
					() => {
						if (callback) callback(res.data.isValidRequiredMessage);
					}
				);
				this.props.setChatLoading(false);
			})
			.catch((err) => {
				if (err.response) {
					const catchError = err.response;
                    

					alert(catchError.error);
				}

				this.setState({ chatLoading: false });
			});
	}, 500);

	setMemberTyping = (data) => {
		if (data.conversationID !== this.state.chatID) return;

		if (!this.state.memberTyping) {
			this.setState({ memberTyping: data.message });

			this.timer = setTimeout(
				() => this.setState({ memberTyping: "" }),
				2000
			);
		} else if (this.state.memberTyping === data.message) {
			clearTimeout(this.timer);
			this.timer = setTimeout(
				() => this.setState({ memberTyping: "" }),
				2000
			);
		}
	};

	setStatusLike = (ID) => {
		const form = new FormData();
		form.append("statusId", ID);

		axiosConfig
			.post(likeStatus, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					isLiked: !this.state.isLiked,
				});
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							("Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							})
						);
					}
				}
			});
	};

	getGroupInfo = (ID) => {
		this.setState({ groupLoading: true });
		const form = new FormData();

		form.append("statusId", ID);

		axiosConfig
			.post(getGroupInfoURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				this.setState({
					chatGroupInfo: res.data.body,
					groupLoading: false,
					isLiked: res.data.body.is_liked,
					isSaved: res.data.body.isSaved,
				});
			})

			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						this.props.showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						const catchError = err.response;

						alert(catchError.error);
					}
				}
				this.setState({ groupLoading: false });
			});
	};

	showGroupInfo = () => {
		this.props.handleRightBar({
			searchConv: false,
			chatInfo: !this.props.chatInfoOpen,
		});
	};

	setJoinSuccess = (message) => {
		this.listenNewMembers(message, true, false);
		this.props.joinGroupPing();
	};

	handleSearchConv = () => {
		this.props.handleRightBar({
			chatInfo: false,
			searchConv: !this.props.searchOpen,
		});
	};
	handleSearchConv1 = () => {
		this.props.handleRightBar({
			chatInfo: false,
			searchConv: !this.state.showSearch,
		});
		this.setState({
			showSearch: false,
		});
	};

	handleScroll = (event) => {
		const bottom =
			event.target.scrollHeight + event.target.scrollTop <
			event.target.clientHeight + 2;

		if (bottom && this.state.moreChats) {
			this.fetchChats(this.state.chatID, this.state.chats.length);
		}
	};

	handleSeeConvList = () => {
		this.props.handleRightBar({
			searchConv: false,
			chatInfo: !this.props.chatInfoOpen,
		});
	};

	isMessagePresent = (messageId) => {
		return this.state.chats.some((message) => message._id === messageId);
	};

	scrollToMessage = (id) => {
		scroller.scrollTo(id, {
			containerId: "chatbox",
			smooth: true,
			offset: -100,
		});
		setTimeout(() => this.setState({ replyHighlight: "" }), 3000);
	};
	highlightReply = (replyID, chatID) => {
		this.setState({ replyHighlight: replyID });
		if (this.isMessagePresent(replyID)) {
			this.scrollToMessage(replyID);
		} else {
			this.fetchChats(chatID, this.state.chats.length, replyID, () => {
				this.scrollToMessage(replyID);
			});
		}
	};

	goToMessage = (messageID) => {
		this.props.history.push(
			`/chat/group/${this.state.chatID}?highlight=${messageID}`
		);
	};

	displayChats = () => {
		const { chatLoading } = this.state;
		const chats = this.state.chats;

		return (
			<div
				className={
					this.props.theme === "dark"
						? styles.mainChatBoxDk
						: styles.mainChatBoxLG
				}
				onScroll={this.handleScroll}
				id="chatbox"
			>
				<div ref={this.scrollRef} className={styles.typing}>
					{this.state.memberTyping}
				</div>
				{this.props.newChats.map((val, key) => {
					const { message } = val;
					return (
						<ScrollElement
							name={message._id}
							className={` ${styles.messageCont}`}
						>
							<MessageBox
								highlightReply={this.goToMessage}
								data={message}
								isHighlighted={
									message._id &&
									(this.state.replyHighlight ===
										message._id ||
										this.state.messageInfoId ===
											message._id)
								}
								isGroup
								showUsername={
									key < this.props.newChats.length - 2
										? this.props.newChats[key + 1].type !==
										  "INFO"
											? this.props.newChats.length[key]
													?.message?.from !==
											  this.props.newChats.length[
													key + 1
											  ]?.message?.from
											: true
										: chats.length > 0
										? this.props.newChats[key]?.message
												?.from != chats[0].from
										: true
								}
								showAvatar={
									key !== this.props.newChats.length - 1
										? key > 0
											? this.props.newChats[key].message
													.from !==
											  this.props.newChats[key - 1]
													.message.from
											: true
										: chats.length > 0
										? this.props.newChats[key].message
												.from === chats[0].from
										: true
								}
								isMember={this.state.chatGroupInfo.isMember}
								showMessageInfo={this.openMessageInfoBox}
								forwardMessage={() =>
									this.openMessageForward(message._id)
								}
								key={message._id || message.hash}
								chatID={this.state.chatID}
								listenMessageDeletes={this.listenMessageDeletes}
								setReply={(data) =>
									this.setState({ replyMessage: data })
								}
							/>
						</ScrollElement>
					);
				})}
				{chats.map((val, key) => {
					return (
						<ScrollElement
							name={val._id}
							className={`${styles.messageCont}`}
						>
							<MessageBox
								highlightReply={this.goToMessage}
								data={val}
								key={val._id}
								isHighlighted={
									this.state.replyHighlight === val._id ||
									this.state.messageInfoId === val._id
								}
								showUsername={
									key < chats.length - 1
										? chats[key + 1].type !== "INFO"
											? chats[key].from !==
											  chats[key + 1].from
											: true
										: true
								}
								showAvatar={
									key > 0
										? chats[key].from !==
										  chats[key - 1].from
										: this.props.newChats.length === 0 ||
										  this.props.newChats[
												this.props.newChats.length - 1
										  ].message.from != chats[key].from
								}
								isMember={this.state.chatGroupInfo.isMember}
								showMessageInfo={this.openMessageInfoBox}
								isGroup
								forwardMessage={() =>
									this.openMessageForward(val._id)
								}
								chatID={this.state.chatID}
								listenMessageDeletes={this.listenMessageDeletes}
								setReply={(data) =>
									this.setState({ replyMessage: data })
								}
							/>
						</ScrollElement>
					);
				})}
				<Slide
					direction="down"
					in={chatLoading}
					mountOnEnter
					unmountOnExit
				>
					<div className={styles.loaderContainer}>
						<CircularProgress
							size="2.5rem"
							thickness="5"
							style={{ color: "#ff5b5b" }}
							className={styles.loader}
						/>
					</div>
				</Slide>
			</div>
		);
	};

	openMessageInfoBox = (messageID, isOwnMessage) => {
		this.setState({ messageInfoId: messageID, isOwnMessage });
		this.props.handleRightBar({
			searchConv: false,
			chatInfo: false,
		});
	};
	closeMessageInfo = () => {
		this.setState((prev) => ({
			messageInfoId: undefined,
			isOwnMessage: undefined,
		}));
	};

	onBookmarkStateChange = (isBookmarked, folderID) => {
		this.setState({
			chatGroupInfo: {
				...this.state.chatGroupInfo,
				is_bookmarked: isBookmarked,
				bookmarked_folder_id: folderID,
			},
		});
	};

	closeMessageForward = () => {
		this.setState({ forwardMessage: { open: false, messageID: null } });
	};

	openMessageForward = (messageID) => {
		this.setState({ forwardMessage: { open: true, messageID } });
	};

	render() {
		const { classes } = this.props;

		const { chatGroupInfo } = this.state;
		const likes = formatNumber(
			!chatGroupInfo.is_liked
				? this.state.isLiked
					? chatGroupInfo.total_likes + 1
					: chatGroupInfo.total_likes
				: this.state.isLiked
				? chatGroupInfo.total_likes
				: chatGroupInfo.total_likes - 1
		);

		const isMobile = window.screen.width < 768;

		const tabs = [
			{
				id: 1,
				label: "Messages",
			},
			{
				id: 2,
				label: "Info",
			},
			{
				id: 3,
				label: "Files",
			},
		];

		const MobTabHandler = (tab) => {
			this.setState({ activeTab: tab.id });
			if (tab.id === 2) {
				this.props.handleRightBar({
					searchConv: false,
					chatInfo: true,
				});
			}
		};

		return (
			<>
				{!isMobile ? (
					<>
						<div className={styles.container}>
							{chatGroupInfo.content && (
								<Helmet>
									<title>
										{`${chatGroupInfo.content} | bditto`}{" "}
									</title>
								</Helmet>
							)}
							<div
								className={
									this.props.searchOpen ||
									this.props.chatInfoOpen ||
									!!this.state.messageInfoId
										? styles.chatBoxHalf
										: styles.chatBox
								}
							>
								<hr
									className={`${styles.titletop_breakline} ${
										this.props.theme === "dark"
											? styles.darkhr
											: styles.lghr
									}`}
								/>
								<div className={styles.title}>
									<div className={styles.chatsection_content}>
										<div className={styles.avatarname}>
											{chatGroupInfo.author && (
												<Avatar
													src={
														chatGroupInfo.author
															.avatar
													}
													style={{
														background:
															this.props.theme ===
															"dark"
																? "#d9def4"
																: "#0d0f22",
														color:
															this.props.theme ===
															"dark"
																? "#0d0f22"
																: "#d9def4",
													}}
												>
													{chatGroupInfo.author
														.full_name &&
														chatGroupInfo.author.full_name[0].toUpperCase()}
												</Avatar>
											)}

											<span
												className={`${styles.author} ${
													this.props.theme === "dark"
														? styles.darktext
														: styles.lgtext
												}`}
											>
												<Username
													value={
														chatGroupInfo?.author
															?.username
													}
												/>
											</span>
										</div>

										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Tooltip
												title={
													<FormattedMessage
														id="searchMessages"
														defaultMessage={`Search Messages`}
													/>
												}
											>
												<div
													className={styles.textIcon}
													onClick={
														this.handleSearchConv
													}
												>
													{this.props.theme ===
													"dark" ? (
														<img
															src="/chat/Search-dk.svg"
															alt=""
														/>
													) : (
														<img
															src="/chat/Search-lg.svg"
															alt=""
														/>
													)}
												</div>
											</Tooltip>

											<div
												className={styles.textIcon}
												onClick={this.handleSeeConvList}
											>
												{this.props.chatInfoOpen ? (
													<Close
														style={{
															marginRight: "1rem",
															fontSize: "2rem",
															color:
																this.props
																	.theme ===
																"dark"
																	? "#D9DEF4"
																	: "#0d0f22",
														}}
													/>
												) : (
													<MoreHorizIcon
														style={{
															marginRight: "1rem",
															fontSize: "2rem",
															color:
																this.props
																	.theme ===
																"dark"
																	? "#D9DEF4"
																	: "#0d0f22",
														}}
													/>
												)}
											</div>
										</div>
									</div>

									<div
										className={`${styles.content} ${
											this.props.theme === "dark"
												? styles.darktext
												: styles.lgtext
										}`}
									>
										<h1>{chatGroupInfo.content}</h1>
										{chatGroupInfo.background_image && (
											<HoverPopover
												trigger={
													<img
														src={
															chatGroupInfo.background_image
														}
														width="48px"
														height="32px"
														className={styles.image}
														alt=""
													/>
												}
												anchorOrigin={{
													vertical: "bottom",
													horizontal: "right",
												}}
												transformOrigin={{
													vertical: "center",
													horizontal: "left",
												}}
											>
												<div className={styles.popover}>
													{chatGroupInfo.background_image && (
														<img
															src={
																chatGroupInfo.background_image
															}
															style={{
																borderRadius:
																	"4px",
																maxWidth:
																	"300px",
																maxHeight:
																	"200px",
															}}
														/>
													)}
												</div>
											</HoverPopover>
										)}
									</div>
								</div>

								<hr
									className={`${styles.titletop_breakline} ${
										this.props.theme === "dark"
											? styles.darkhr
											: styles.lghr
									}`}
								/>

								{this.displayChats()}

								<InputBox
									chatID={this.state.chatID}
									chatType={this.state.chatType}
									isGroup
									members={chatGroupInfo.members}
									groupLoading={this.state.groupLoading}
									isMember={chatGroupInfo.isMember}
									statusState={chatGroupInfo.current_status}
									setJoinSuccess={this.setJoinSuccess}
									replyMessage={this.state.replyMessage}
									resetReply={() => {
										this.setState({ replyMessage: null });
									}}
								/>

								<GroupInfo
									show={this.props.chatInfoOpen}
									hideSlider={this.showGroupInfo}
									isLoading={this.state.groupLoading}
									data={chatGroupInfo}
									onMemberLeave={this.onMemberLeave}
									onlineMessage={this.props.onlineMessage}
								/>

								<SearchInConv
									show={this.props.searchOpen}
									ID={this.state.chatID}
									type={this.state.chatType}
									handleSearchConv={this.handleSearchConv}
								/>

								<MessageForwardDialog
									{...this.state.forwardMessage}
									chatID={this.state.chatID}
									isGroup={true}
									closeModal={this.closeMessageForward}
								/>

								<MessageInfo
									statusBackground={
										chatGroupInfo.background_color
									}
									isOwnMessage={this.state.isOwnMessage}
									group={chatGroupInfo}
									messageID={this.state.messageInfoId}
									isOpen={!!this.state.messageInfoId}
									groupID={this.state.chatID}
									handleClose={this.closeMessageInfo}
								/>
							</div>
						</div>
					</>
				) : (
					<>
						<Dialog
							fullScreen={true}
							maxWidth={"100vw"}
							fullWidth={"100vw"}
							open={true}
							classes={{
								paper:
									this.props.theme === "dark"
										? styles.rootDark
										: styles.rootLg,
							}}
						>
							{chatGroupInfo.content && (
								<Helmet>
									<title>
										{`${chatGroupInfo.content} | bditto`}{" "}
									</title>
								</Helmet>
							)}
							<div className={styles.mobChatNav}>
								<div className="d-flex align-content-center">
									<span className="d-flex align-items-center mr-3">
										{this.state.showSearch ? (
											<Close
												onClick={() => {
													this.setState({
														showSearch: false,
													});
												}}
												style={{ color: "#D9DEF4" }}
											/>
										) : (
											<ArrowBackIos
												onClick={() => {
													this.props.history.goBack();
												}}
												style={{
													color:
														this.props.theme ===
														"dark"
															? "#d9def4"
															: "#0d0f22",
												}}
											/>
										)}
										{/* <ArrowBackIos
											onClick={() => {
												this.props.history.goBack();
											}}
											style={{
												color:
													this.props.theme === "dark"
														? "#d9def4"
														: "#0d0f22",
											}}
										/> */}
										{this.state.showSearch && (
											<SearchInConv
												show={this.state.showSearch}
												ID={this.state.chatID}
												type={this.state.chatType}
												handleSearchConv={
													this.handleSearchConv1
												}
											/>
										)}
									</span>

									{!this.state.showSearch && (
										<>
											{chatGroupInfo.author && (
												<Avatar
													src={
														chatGroupInfo.author
															.avatar
													}
													style={{
														background:
															this.props.theme ===
															"dark"
																? "#d9def4"
																: "#0d0f22",
														color:
															this.props.theme ===
															"dark"
																? "#0d0f22"
																: "#d9def4",
													}}
												>
													{chatGroupInfo.author
														.full_name &&
														chatGroupInfo.author.full_name[0].toUpperCase()}
												</Avatar>
											)}

											<span
												className={`${styles.author} ${
													this.props.theme === "dark"
														? styles.darktext
														: styles.lgtext
												} d-flex align-items-center mx-2`}
											>
												<Username
													value={
														chatGroupInfo?.author
															?.username
													}
												/>
											</span>
										</>
									)}
								</div>
								{!this.state.showSearch && (
									<div>
										<span
											className="mr-3"
											onClick={() =>
												this.setState({
													showSearch: true,
												})
											}
										>
											<Search />
										</span>
										<span>
											<img
												src="/Mobile/Call.svg"
												alt=""
											/>
										</span>
									</div>
								)}
							</div>

							<div className={styles.thoughtsTab}>
								{tabs.map((tab, i) =>
									tab.id === this.state.activeTab ? (
										<div className={styles.activeMobTab}>
											{tab.label}
										</div>
									) : (
										<div
											onClick={() => MobTabHandler(tab)}
											className={styles.mobtab}
										>
											{tab.label}
										</div>
									)
								)}
							</div>

							{this.state.activeTab === 1 && (
								<>
									{this.displayChats()}

									<InputBox
										chatID={this.state.chatID}
										chatType={this.state.chatType}
										isGroup
										members={chatGroupInfo.members}
										groupLoading={this.state.groupLoading}
										isMember={chatGroupInfo.isMember}
										statusState={
											chatGroupInfo.current_status
										}
										setJoinSuccess={this.setJoinSuccess}
										replyMessage={this.state.replyMessage}
										resetReply={() => {
											this.setState({
												replyMessage: null,
											});
										}}
									/>
								</>
							)}

							{(this.state.activeTab === 2 ||
								this.state.activeTab === 3) && (
								<GroupInfo
									show={this.props.chatInfoOpen}
									hideSlider={this.showGroupInfo}
									isLoading={this.state.groupLoading}
									data={chatGroupInfo}
									onMemberLeave={this.onMemberLeave}
									onlineMessage={this.props.onlineMessage}
									activeTab={isMobile && this.state.activeTab}
								/>
							)}
						</Dialog>
					</>
				)}
			</>
		);
	}
}

const mapStateToProps = (state, ownProps) => ({
	newChats: state.messages.dispatchQueue.filter(
		(message) => message.conversationID === ownProps.chatID
	),
	userID: state.auth.user_data?.userID,
	theme: state.theme.theme,
});

const mapDispatchToProps = (dispatch) => ({
	clearFromQueue: (chatID) => dispatch(removeFromQueue(chatID)),
	joinGroupPing: () => dispatch(joinPing(true)),
	clearUnreadMessageCount: (id) =>
		dispatch(clearUnReadMessageCount(id, true)),
	setChatLoading: (state) => dispatch(setChatLoading(state)),
	deleteMessage: (messageID, conversationID) =>
		dispatch(deleteMesage(messageID, conversationID)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(useStyles)(withRouter(withAlert(ChatSection))));
