import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { logout } from "../../Redux/Actions/actionAuth";
import Location from "./LocationBox/Location";
import * as Socket from "../../Utils/socket";
import "./Navbar.scss";
import Styles from "./Navbar.module.css";
import SidebarContextMenu from "../Home/Chats/Sidebar/SidebarContextMenu";
import OnlineBadge from "../Extras/StyledBadge";
import { StyledBadge } from "../Home/Chats/Sidebar/Sidebar";
import { withSnackbar } from "notistack";
import { getUnreadCount } from "../../Redux/selectors/notificationSelectors";
import { Badge } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import HelpIcon from "../../Icons/Help";
import PreferencesIcon from "../../Icons/Preferences";
import LogoutIcon from "../../Icons/Logout";
import TermsIcon from "../../Icons/Terms";
import ShareIcon from "../../Icons/ShareIcon";
import ThoughtsHubIcon from "../../Icons/Thoughtshub";
import MessengerIcon from "../../Icons/Messenger";
import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import Close from "../../Icons/CloseIcon";
import {
	ShareCopyLink,
	ShareSocial,
} from "../Home/Chats/Extra/ShareURL/Share.jsx";
import LoveFace from "../../Icons/Emogi/LoveFace";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import NotificationDropdown from "./Notification/NotificationDropDown/NotificationDropdown";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Log from "../../Utils/logger";
import { Share } from "../../Icons";
import Show from "../../Icons/feed/show";
import ExploreBlue from "../../assets/icons/navbar/explore-blue.svg";
import ExploreS from "../../assets/icons/navbar/explore.svg";
import MyHome from "../../Icons/Home";
import Policies from "../Info/Policies";
import Info from "../Info/Info";
import Search from "../Search/Search";
import ProfileSidebar from "../Profile/ProfileSidebar/ProfileSidebar";
import SettingSidebar from "../Settings/SettingsSidebar";
import styled from "styled-components";

import {
	NotificationsNone,
	Search as SearchIcon,
	LocationOn,
} from "@material-ui/icons";

import SideNavbar from "../Navbar/SideNavbar/SideNavbar";
import GroupInfo from "../Home/Groups/Mobile/GroupInfo";

const useStyles = (theme) => ({
	menuButton: {
		cursor: "pointer",
		height: "100%",
		color: theme === "dark" ? "#7F89BE" : "#4A517E",
		fontFamily: "Roboto",
		fontSize: "14px",
		fontWeight: "400",
		textTransform: "none",
		borderRadius: "0",
	},

	selectedBtn: {
		cursor: "pointer",
		height: "100%",
		fontWeight: "400",
		fontFamily: "Roboto",
		fontSize: "14px",
		textTransform: "none",
		borderRadius: "0",
		background: "linear-gradient(90deg, #00B6FB, #0079F9)",
		WebkitBackgroundClip: "text",
		WebkitTextFillColor: "transparent",
	},

	notification: {
		height: "100%",
		cursor: "pointer",
		opacity: "1",
		borderLeft: "1px #f4f4f4 solid",
		borderRight: "1px #f4f4f4 solid",
		[theme.breakpoints.down("xs")]: {
			border: 0,
		},
		color: "rgba(0, 0, 0, 0.25)",
	},

	newNotification: {
		height: "100%",
		cursor: "pointer",
		opacity: "1",
		color: "#ff5b5b",
		borderLeft: "1px #f4f4f4 solid",
		borderRight: "1px #f4f4f4 solid",
		[theme.breakpoints.down("xs")]: {
			border: 0,
		},
	},

	userData: {
		marginRight: "1rem",
		padding: "0.25rem",
		width: "12rem",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		cursor: "pointer",
		[theme.breakpoints.down("xs")]: {
			width: "4rem",
		},
	},

	userMenuButton: {
		fontSize: "0.875rem",
		color: "var(--heading-color2)",
		width: "110%",
		margin: "0.15rem 0",
		lineHeight: "1.3rem",
		marginLeft: ".05rem",
		textTransform: "none",
		fontStyle: "normal",
		fontWeight: "normal",
		fontFamily: "Roboto",
		display: "flex",
		justifyContent: "flex-start",
		"&:hover": {
			color: "var(--text-primary)",
		},
	},
	avatar: {
		fontSize: "40px",
		color: "#c4c4c4",
		[theme.breakpoints.down("xs")]: {
			width: theme.spacing(4),
			height: theme.spacing(4),
		},
	},
	online: {
		width: "1.5rem",
		[theme.breakpoints.down("xs")]: {
			position: "absolute",
			top: 6,
			left: 4,
			width: "0.9rem",
		},
	},
});

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locationShow: false,
			isOnline: false,
			isOpen: false,
			notification: [],
			newMessages: false,
			loading: false,
			newNoti: false,
			selectedValue: "online",
			url: props.rootParams.url.split("/")[1],
			dialogOpen: false,
			openSideNavbar: false,
			openGroupInfo: false,
			groupInfo: null,
			groupData: null,
			selectedGroupId: null,
			unreadNotificationCount: null,
		};

		this.initialRef = React.createRef();
		this.handleChange = this.handleChange.bind(this);
		this.url = "https://www.bditto.com";
	}

	displayNewMessaageSnackbar = ({ message, username, isGroup }) => {
		if (
			isGroup === false &&
			this.props.user_data.userID !== Number(message.from)
		) {
			this.props.enqueueSnackbar({
				notification_type: "new_message",
				isUncontrolled: true,
				metas: {
					user: { username: message.username, userID: message.from },
				},
				content: `new message from ${username}`,
			});
		}
	};

	listenMessages = (data) => {
		if (
			!this.props.currentPath.startsWith(
				`/chat/private/${data.message.from}`
			)
		) {
			this.displayNewMessaageSnackbar(data);
		}
		if (
			data.message.username !== this.props.user_data.user.username &&
			!this.props.currentPath.startsWith("/chat")
		) {
			this.setState((prev) => ({
				newMessages: true,
			}));
		}
	};

	componentDidMount() {
		Socket.ListenMessages(this.listenMessages);
		if (this.state.notification.length !== 0) {
			this.notifications();
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.notifications !== prevState.notifications) {
			return { location: nextProps.location };
		} else return null;
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.notifications !== this.props.notifications) {
			if (this.props.notifications.data.length > 0) {
				let newNotif = this.props.notifications.data.filter(
					(item) => item.seen != true
				);

				this.setState({
					unreadNotificationCount: newNotif.length,
				});
				if (this.props.notificationCount == 0) {
					this.setState({
						unreadNotificationCount: 0,
					});
				}
			}
		}
	}

	handleLogOut = () => {
		this.props.logout();
	};

	resetNewNoti = () => {
		this.setState({
			newMessages: false,
		});
	};

	handleChange = (event) => {
		this.setState({ selectedValue: event.target.value });
	};

	handleOpen = () => {
		this.setState({ dialogOpen: true });
	};

	handleClose = () => {
		this.setState({ dialogOpen: false });
	};

	handleSideNavbar = (cond) => {
		this.setState({
			openSideNavbar: cond,
		});
	};

	handleGroupInfo = (cond, type) => {
		this.setState({
			openGroupInfo: cond,
			groupInfo: type,
		});
	};

	getGroupData = (data) => {
		this.setState({
			groupData: data,
		});
	};

	getGroupId = (id) => {
		this.setState({
			selectedGroupId: id,
		});
	};

	render() {
		const { classes, user_data, currentPath, goToThisRouter } = this.props;
		const name = user_data.full_name || user_data.username;
		const isMobile = window.screen.width < 768;

		return (
			<>
				{!isMobile && (
					<div className={`${Styles.container} container`}>
						<div
							className={`${Styles.navbarContainer} navbarContainer`}
						>
							<div className={Styles.left}>
								<Link className={Styles.title} to="/">
									{this.props.theme === "dark" ? (
										<Box>
											<img
												src="/logoMain.svg"
												alt="logo"
											/>
										</Box>
									) : (
										<Box>
											<img
												src="/logoMainLight.png"
												alt="logo"
											/>
										</Box>
									)}
								</Link>
								<div className={Styles.links}>
									<div className={Styles.navElementBox}>
										<div className={Styles.navElement}>
											<Link to="/">
												{" "}
												<ThoughtsHubIcon
													color={
														currentPath === "/"
															? "#00C6FF"
															: this.props
																	.theme ===
															  "dark"
															? "#7F89BE"
															: "#4A517E"
													}
												/>{" "}
											</Link>
											<Button
												onClick={() =>
													goToThisRouter("/")
												}
												color="inherit"
												className={
													currentPath === "/"
														? classes.selectedBtn
														: classes.menuButton
												}
												style={{ borderRadius: "3px" }}
											>
												<NavLinkText
													theme={this.props.theme}
												>
													<FormattedMessage
														id="thoughts"
														defaultMessage={`ThoughtsHub`}
													/>
												</NavLinkText>
											</Button>
										</div>
										{currentPath === "/" ? (
											<img
												className={Styles.underline}
												src="/icons/navbar-icons/underline.svg"
												width="50px"
                                                height="4px"
                                                alt=""
											/>
										) : null}
									</div>

									<div className={Styles.navElementBox}>
										<div className={Styles.navElement}>
											<Link to="/explore/thoughts">
												{/* <img src="/icons/navbar-icons/Explore.svg"/> */}
												{/* {currentPath.startsWith("/explore") ? <img src="/icons/navbar-icons/location.svg"/> : <img src="/icons/navbar-icons/Explore.svg"/>} */}
												{currentPath.startsWith(
													"/explore"
												) ? (
													<img
														src="/icons/navbar-icons/explore-blue.svg"
														alt=""
													/>
												) : this.props.theme ===
												  "dark" ? (
													<img
														src={ExploreS}
														alt=""
													/>
												) : (
													<img
														src="/nav/ExploreIcon.svg"
														alt=""
													/>
												)}
											</Link>
											<Button
												id="Explore"
												onClick={() =>
													goToThisRouter(
														"/explore/thoughts"
													)
												}
												color="inherit"
												className={
													currentPath.startsWith(
														"/explore"
													)
														? classes.selectedBtn
														: classes.menuButton
												}
												style={{ borderRadius: "3px" }}
											>
												<NavLinkText
													theme={this.props.theme}
												>
													<FormattedMessage
														id="explore"
														defaultMessage={`Explore`}
													/>
												</NavLinkText>
											</Button>
										</div>
										{currentPath.startsWith("/explore") && (
											<img
												className={Styles.underline}
												src="/icons/navbar-icons/underline.svg"
												width="50px"
												height="4px"
                                                alt=""
											/>
										)}
									</div>

									<div className={Styles.navElementBox}>
										<div className={Styles.navElement}>
											<Link to="/myfeed">
												{/* <MyFeedIcon  color = {currentPath.startsWith("/myfeed") ? "#00C6FF" : "#7F89BE"} /> */}
												{/* {currentPath.startsWith("/myfeed") ? <img src="/icons/navbar-icons/my-feed-white.svg"/> : <img src="/icons/navbar-icons/my-feed.svg"/>} */}
												<Link to="/">
													{currentPath ===
													"/myfeed" ? (
														<img
															src="/nav/Home.png"
															alt=""
														/>
													) : this.props.theme ===
													  "dark" ? (
														<img
															src="/cards-img/Home.png"
															alt=""
														/>
													) : (
														<img
															src="/nav/HomeIcon.svg"
															alt=""
														/>
													)}
												</Link>
											</Link>
											<Button
												id="newsfeed"
												onClick={() =>
													goToThisRouter("/myfeed")
												}
												color="inherit"
												className={
													currentPath.startsWith(
														"/myfeed"
													)
														? classes.selectedBtn
														: classes.menuButton
												}
												style={{ borderRadius: "3px" }}
											>
												<NavLinkText
													theme={this.props.theme}
												>
													<FormattedMessage
														id="myFeed"
														defaultMessage={`My Feed`}
													/>
												</NavLinkText>
											</Button>
										</div>
										{currentPath.startsWith("/myfeed") ? (
											<img
												className={Styles.underline}
												src="/icons/navbar-icons/underline.svg"
												width="50px"
												height="4px"
                                                alt=""
											/>
										) : null}
									</div>

									<div className={Styles.navElementBox}>
										<div className={Styles.navElement}>
											<Link to="/chat">
												<MessengerIcon
													color={
														currentPath.startsWith(
															"/chat"
														)
															? "#00C6FF"
															: this.props
																	.theme ===
															  "dark"
															? "#7F89BE"
															: "#4A517E"
													}
												/>
											</Link>
											<StyledBadge
												invisible={
													currentPath.startsWith(
														"/chat"
													) || !this.state.newMessages
												}
												overlap="circle"
												anchorOrigin={{
													vertical: "top",
													horizontal: "right",
												}}
												variant="dot"
											>
												<Button
													id="Chats"
													onClick={() => {
														goToThisRouter("/chat");
														this.resetNewNoti();
													}}
													color="inherit"
													className={
														currentPath.startsWith(
															"/chat"
														)
															? classes.selectedBtn
															: classes.menuButton
													}
													style={{
														borderRadius: "3px",
													}}
												>
													<NavLinkText
														theme={this.props.theme}
													>
														<FormattedMessage
															id="chats"
															defaultMessage={`Messenger`}
														/>
													</NavLinkText>
												</Button>
											</StyledBadge>
										</div>
										{currentPath.startsWith("/chat") ? (
											<img
												className={Styles.underline}
												src="/icons/navbar-icons/underline.svg"
												width="50px"
												height="4px"
                                                alt=""
											/>
										) : null}
									</div>
								</div>
							</div>

							<div className={Styles.right}>
								<Link to="/search">
									<div className={Styles.search} id="Search">
										<div className={Styles.searchIcon}>
											{this.props.theme === "dark" ? (
												<img src="/icons/navbar-icons/Search.svg" alt="" />
											) : (
												<img
													src="/nav/SearchLG.svg"
													alt=""
												/>
											)}
										</div>
									</div>
								</Link>

								<div
									className={Styles.locationContainer}
									id="Location"
								>
									<Location />
								</div>

								<div
									className={Styles.notification}
									id="Notification"
								>
									<NotificationDropdown>
										<Badge
											className={Styles.notificationIcon}
											color="error"
											max={10}
											// badgeContent={
											// 	this.props.notificationCount
											// }
											badgeContent={
												this.state
													.unreadNotificationCount
											}
											anchorOrigin={{
												vertical: "top",
												horizontal: "right",
											}}
										>
											{this.props.theme === "dark" ? (
												<img
													src="/icons/navbar-icons/Notification.svg"
													htmlColor="white"
													alt=""
												/>
											) : (
												<img
													src="/nav/Notification.svg"
													alt=""
												/>
											)}
										</Badge>
									</NotificationDropdown>
								</div>

								<SidebarContextMenu
									listItemStyle={{
										padding: " 0 10px",
									}}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{ horizontal: "center" }}
									className={classes.userData}
									color={
										this.props.theme === "dark"
											? "#121528"
											: "#fff"
									}
									trigger={
										<Typography
											id="Settings"
											component={"span"}
											variant="body2"
										>
											<div className={Styles.user}>
												<OnlineBadge
													invisible={
														!this.props.online
													}
													color="#44b700"
													ripple={true}
												>
													<Avatar
														style={{
															background:
																this.props
																	.theme ===
																"dark"
																	? "#d9def4"
																	: "#0d0f22",
															color:
																this.props
																	.theme ===
																"dark"
																	? "#0d0f22"
																	: "#d9def4",
														}}
														src={user_data.avatar}
													>
														{name &&
															name[0].toUpperCase()}
													</Avatar>
												</OnlineBadge>
												<h2 className={Styles.username}>
													{name}
												</h2>
												<ExpandMoreIcon
													style={{
														fontSize: "1.25rem",
														marginLeft: ".5rem",
														color: "#7F89BE",
													}}
												/>
											</div>
										</Typography>
									}
								>
									<div className={Styles.upperMenu}>
										<div
											className={`${Styles.menuBox} ${
												this.props.theme === "dark"
													? Styles.menuDark
													: Styles.menulg
											}`}
										>
											<PersonOutlineIcon
												style={{
													color:
														this.props.theme ===
														"dark"
															? "#D8DEF3"
															: "#0D0F22",
												}}
											/>
											<Button
												onClick={() => {
													goToThisRouter(
														"/myprofile"
													);
												}}
												className={
													classes.userMenuButton
												}
												disableRipple={true}
											>
												<FormattedMessage
													id="account"
													defaultMessage={`My Account`}
												/>
											</Button>
										</div>

										<div
											className={`${Styles.menuBox} ${
												this.props.theme === "dark"
													? Styles.menuDark
													: Styles.menulg
											}`}
										>
											{/* <PreferencesIcon.Outline /> */}
											{this.props.theme === "dark" ? (
												<img
													src="/PreferenceDk.svg"
													alt=""
													className="px-1"
												/>
											) : (
												<img
													src="/PreferenceLG.svg"
													alt=""
													className="px-1"
												/>
											)}
											<Button
												onClick={() => {
													goToThisRouter("/settings");
												}}
												className={
													classes.userMenuButton
												}
												disableRipple={true}
											>
												<FormattedMessage
													id="settings"
													defaultMessage={`Preferences`}
												/>
											</Button>
										</div>

										<div
											className={`${Styles.menuBox} ${
												this.props.theme === "dark"
													? Styles.menuDark
													: Styles.menulg
											}`}
										>
											<ShareIcon
												color={
													this.props.theme === "dark"
														? "#D8DEF3"
														: "#0D0F22"
												}
											/>
											<Button
												className={
													classes.userMenuButton
												}
												disableRipple={true}
												// onClick={() => {
												//   goToThisRouter("/share-the-app");
												// }}
												onClick={this.handleOpen}
											>
												<FormattedMessage
													id="shareTheApp"
													defaultMessage={`Share Bditto`}
												/>
											</Button>
										</div>
									</div>

									<div
										className={`${Styles.menuBox} ${
											this.props.theme === "dark"
												? Styles.menuDark
												: Styles.menulg
										}`}
									>
										<HelpIcon
											color={
												this.props.theme === "dark"
													? "#D9DEF4"
													: "#0D0F22"
											}
										/>
										<Button
											className={classes.userMenuButton}
											disableRipple={true}
											onClick={() => {
												goToThisRouter(
													isMobile ? "/info" : "/info"
												);
											}}
										>
											<FormattedMessage
												id="help"
												defaultMessage={`Help Center`}
											/>
										</Button>
									</div>

									<div
										className={`${Styles.menuBox} ${
											this.props.theme === "dark"
												? Styles.menuDark
												: Styles.menulg
										}`}
									>
										<TermsIcon
											color={
												this.props.theme === "dark"
													? "#D9DEF4"
													: "#0D0F22"
											}
										/>
										<Button
											className={classes.userMenuButton}
											disableRipple={true}
											onClick={() => {
												goToThisRouter(
													isMobile
														? "/policies"
														: "/policies"
												);
											}}
										>
											<FormattedMessage
												id="termsAndPolicies"
												defaultMessage={`Terms and Policies`}
											/>
										</Button>
									</div>

									<div
										className={`${Styles.menuBox} ${
											this.props.theme === "dark"
												? Styles.menuDark
												: Styles.menulg
										}`}
									>
										<LogoutIcon
											color={
												this.props.theme === "dark"
													? "#D9DEF4"
													: "#0D0F22"
											}
										/>
										<Button
											className={classes.userMenuButton}
											disableRipple={true}
											onClick={this.handleLogOut}
										>
											<FormattedMessage
												id="logout"
												defaultMessage={`Log Out`}
											/>
										</Button>
									</div>
								</SidebarContextMenu>

								<Dialog
									open={this.state.dialogOpen}
									aria-labelledby="share-dialog"
									onClose={this.handleClose}
									classes={{
										paper: `${Styles.dialog} ${
											this.props.theme === "dark"
												? Styles.darkbg
												: Styles.lgbg
										}`,
									}}
								>
									<ShareBdittoWrapper
										theme={this.props.theme}
									>
										<div className={Styles.header}>
											<h2 className="mb-0 d-flex align-items-center">
												Share Bditto
											</h2>
											<IconButton
												onClick={this.handleClose}
												size="small"
											>
												<Close
													color={
														this.props.theme ===
														"dark"
															? "#fff"
															: "#000"
													}
												/>
											</IconButton>
										</div>

										<div>
											<div className={Styles.textBox}>
												<LoveFace />
												<p>
													Recommendation: <br />
													We are competing with
													leading companies & to stand
													out, we need to focus on
													fantastic design with an
													engaging copy.
												</p>
											</div>

											<div className={Styles.textBox}>
												<LoveFace />
												<p>
													You can invite anyone to
													participate in Bditto and
													have fun sharing thoughts &
													ideas.
												</p>
											</div>

											<div className={Styles.textBox}>
												<LoveFace />
												<p>
													Finding people who you know
													or finding people with
													similar interests is a lot
													easier on bditto.
												</p>
											</div>
										</div>

										<div
											className={Styles.shareSocialIcons}
										>
											<ShareSocial
												url={this.url}
												className={Styles.ShareSocial}
												iconSize={40}
											/>
										</div>
									</ShareBdittoWrapper>
								</Dialog>
							</div>
						</div>
					</div>
				)}
				{isMobile && (
					<div className={Styles.mobNavbar}>
						{this.state.openSideNavbar && (
							<SideNavbar
								isOpen={this.state.openSideNavbar}
								handleSideNavbar={this.handleSideNavbar}
								handleGroupInfo={this.handleGroupInfo}
								getGroupData={this.getGroupData}
								getGroupId={this.getGroupId}
							/>
						)}
						{this.state.openGroupInfo && (
							<GroupInfo
								type={this.state.groupInfo}
								// groupData={this.state.groupData}
								handleGroupInfo={this.handleGroupInfo}
								handleSideNavbar={this.handleSideNavbar}
								groupId={this.state.selectedGroupId}
							/>
						)}
						<div className="d-flex align-items-center ">
							<img
								src="/Mobile/Navbar/menuBar.svg"
								alt=""
								onClick={() => {
									this.setState({
										openSideNavbar:
											!this.state.openSideNavbar,
									});
								}}
							/>
							<div>
								<p className="ml-4 mb-0">Group Name</p>
							</div>
						</div>
						<div className="d-flex">
							<div className="mx-2" id="Location">
								<Location />
							</div>
							<div className="mx-2" id="Search">
								<Link to="/search">
									<SearchIcon style={{ color: "#D9DEF4" }} />
								</Link>
							</div>
							<div className="mx-2" id="Notification">
								<NotificationDropdown>
									<Badge
										className={Styles.notificationIcon}
										color="error"
										max={10}
										// badgeContent={
										// 	this.props.notificationCount
										// }
										badgeContent={
											this.state.unreadNotificationCount
										}
										anchorOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
									>
										<NotificationsNone
											style={{ color: "#D9DEF4" }}
										/>
									</Badge>
								</NotificationDropdown>
							</div>
						</div>
					</div>
				)}
			</>
		);
	}
}

const ShareBdittoWrapper = styled.div`
	background: ${({ theme }) => (theme === "dark" ? "#111528" : "#fff")};
	color: ${({ theme }) => (theme === "dark" ? "#d9def4" : "#0D0F22")};
	padding-bottom: 20px;
`;

const NavLinkText = styled.span`
	transition: all, 0.3s;
`;

const mapStateToProps = (state) => ({
	user_data: state.auth.user_data,
	online: state.location.online,
	notificationCount: getUnreadCount(state),
	theme: state.theme.theme,
	notifications: state.notifications,
});

const mapDispatchToProps = (dispatch) => ({
	logout: (payload) => dispatch(logout(payload)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withSnackbar(withStyles(useStyles)(Navbar)));
