import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Route, Link, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import Faq from "./pages/Faqs.jsx";
import Report from "./pages/Report.jsx";
import styles from "./Info.module.css";
import { Helmet } from "react-helmet";

import { GradientButton } from "../Extras/InputField/Button";
import { Dialog, IconButton } from "@material-ui/core";
import Arrows from "../../Icons/Arrows.js";
import { FormattedMessage } from "react-intl";
import Chevron from "../../Icons/Chevron.js";
import Button2 from "../Extras/Buttons/Button2/Button2.js";
import Dropdown, {
	StyledDropdownItem,
	StyledIcon,
} from "../Extras/Dropdown/Dropdown";
import { useSelector } from "react-redux";
import { AboutIcon } from "../../Icons/About.js";
import { FeedbackIcon } from "../../Icons/Feedback.js";
import { TutorialIcon } from "../../Icons/Tutorial.js";

import { FAQIcon } from "../../Icons/FAQ";
import { Star } from "../../Icons/Star(Helpcenter)";
import { TicketIcon } from "../../Icons/Ticket";
import { LockIcon } from "../../Icons/lock";
import { DiscountIcon } from "../../Icons/Discount";

function About() {
	let history = useHistory();
	let theme = useSelector((reduxState) => reduxState.theme.theme);
const isMobile = useMediaQuery({ query: "(max-width: 724px)" });
	const subheadingClasses = `
        ${styles.aboutSubHeading}
        ${theme === "dark" ? styles.textDarkTheme : styles.textLightTheme}
    `;
	const textPColor = theme === "dark" ? styles.textDarkP : styles.textLightP;

	return (
		<>
			<div className={styles.header}>
				{!isMobile && <h2
					className={
						theme === "dark"
							? styles.textDarkTheme
							: styles.textLightTheme
					}
				 >
					About Us
				</h2>}
			</div>

			<div className={styles.rightFaqWrapper}>
				<div>
					<h2 className={subheadingClasses}>
						We are driven by a simple idea!
					</h2>
					<p className={`${styles.content} ${textPColor}`}>
						Bditto is on a mission to bring people together and
						empower open communication. We believe you have
						interesting thoughts and it’s easy to create something
						together. We must provide a clear explanation of what
						Bditto does.
					</p>
				</div>

				<div>
					<div className={styles.aboutUsImage}>
						<img src="/images/aboutUs/Row1.svg" />
					</div>
					<h2 className={subheadingClasses}>Our Mission</h2>
					<p className={`${styles.content} ${textPColor}`}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						Duis bibendum congue scelerisque. Vivamus. Lorem ipsum
						dolor sit amet, consectetur adipiscing elit. Duis
						bibendum congue scelerisque. Vivamus.
					</p>
				</div>

				<div>
					<div className={styles.aboutUsImage}>
						<img src="/images/aboutUs/Row2.svg" />
					</div>
					<h2 className={subheadingClasses}>
						The idea behind Bditto
					</h2>
					<p className={`${styles.content} ${textPColor}`}>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						Duis bibendum congue scelerisque. Vivamus. Lorem ipsum
						dolor sit amet, consectetur adipiscing elit. Duis
						bibendum congue scelerisque. Vivamus.
					</p>
				</div>

				<div>
					<div className={styles.aboutUsImage}>
						<img src="/images/aboutUs/Row4.svg" />
					</div>
					<h2 className={subheadingClasses}>Fuel creativity</h2>
					<p className={`${styles.content} ${textPColor}`}>
						Bditto is a place where we keep it light and work hard
						at the same time. A joy-fuelled company that believes in
						the power of creative energy.
					</p>
				</div>

				<div>
					<div className={styles.aboutUsImage}>
						<img src="/images/aboutUs/Row4.svg" />
					</div>
					<h2 className={subheadingClasses}>
						Collaborate effectively
					</h2>
					<p className={`${styles.content} ${textPColor}`}>
						Bditto is a team of collective genius, who believes that
						the best outcomes are achieved when people are able to
						create and collaborate in open, diverse, & inclusive
						environments.
					</p>
				</div>

				<div>
					<div className={styles.aboutUsImage}>
						<img src="/images/aboutUs/Row5.svg" />
					</div>
					<h2 className={subheadingClasses}>Have fun. Grow.</h2>
					<p className={`${styles.content} ${textPColor}`}>
						Our culture is to share ideas, make bold bets and have
						fun while we grow. We will try something crazy, think
						differently and have a blast trying.
					</p>
				</div>

				<div>
					<div className={styles.aboutUsImage}>
						<img src="/images/aboutUs/Row6.svg" />
					</div>

					<h2 className={subheadingClasses}>Openness in action</h2>
					<p className={`${styles.content} ${textPColor}`}>
						Bditto is a friendly environment that moves quickly
						where ideas flow freely. We firmly believe sharing ideas
						and results with the whole team is essential to an open
						culture where our community can thrive.
					</p>
				</div>
			</div>
		</>
	);
}

const Tutorial = (props) => {
	let history = useHistory();
	let theme = useSelector((reduxState) => reduxState.theme.theme);
	const textPColor = theme === "dark" ? styles.textDarkP : styles.textLightP;
const isMobile = useMediaQuery({ query: "(max-width: 724px)" });
	return (
		<div
			className={
				theme === "dark" ? styles.textDarkTheme : styles.textLightTheme
			}
		>
			{!isMobile &&	<div className={styles.header}>
				<h2>Tutorial</h2>
			</div>}

			<div className={styles.rightWrapper}>
				<div className={`${styles.contentTutorial} ${textPColor}`}>
					<p className={styles.tutorialContent}>
						This tutorial will take you through all the major
						features of the site.
					</p>
					<div className={`${styles.contentTutorial} ${textPColor}`}>
						<Link
							className={styles.tutorialLink}
							to={{
								pathname: "/",
								state: { startTutorial: true },
							}}
						>
							<Button2>Start tutorial</Button2>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

const data = [
	{ id: 0, label: "General" },
	{ id: 1, label: "Subscription Plans" },
	{ id: 2, label: "Free Credits" },
	{ id: 3, label: "Piracy" },
];

export default function Info(props) {
	const [active, setActive] = useState(0);
	const [selectedDropdownItem, setSelectedDropdownItem] = useState(
		data[0].id
	);

	const history = useHistory();
	const location = useLocation();
	const isMobile = useMediaQuery({ query: "(max-width: 724px)" });

	const theme = useSelector((state) => state.theme.theme);

	const textcolorClass =
		theme === "dark" ? styles.textDarkTheme : styles.textLightTheme;

	const iconStyle = {
		width: "1rem",
		height: "1rem",
		marginRight: "0.5rem",
	};

	function getColor(activeID) {
		if (active === activeID) return "#D9DEF4";
		else return theme === "dark" ? "#D9DEF4" : "black";
	}

	const iconColor = theme === "dark" ? "#D9DEF4" : "#0d0f22";

	function SideBar({ isMobile }) {
		return (
			<>
				{isMobile && (
					<div className={styles.header}>
						<IconButton
							size="small"
							variant="contained"
							style={{ color: "#ff5d5d" }}
							onClick={history.goBack}
						>
							<Arrows.Left viewBox="0 0 27 27" />
						</IconButton>
						<h2>
							<FormattedMessage
								id="helpCenter"
								defaultMessage={`Help Center`}
							/>
						</h2>
					</div>
				)}
				<ul className={styles.sidebar}>
					<StyledSidebarItem
						className={
							active === 0 ||
							active === 5 ||
							active === 6 ||
							active === 7
								? styles.selected
								: styles.link
						}
						onClick={() => setActive(0)}
						isActive={
							active === 0 ||
							active === 5 ||
							active === 6 ||
							active === 7
						}
						theme={theme}
					>
						<FAQIcon
							color={
								active === 0 ||
								active === 5 ||
								active === 6 ||
								active === 7
									? theme === "dark"
										? "#d9def4"
										: "#d9def4"
									: theme === "dark"
										? "#7f89be"
										: "black"
							}
							style={iconStyle}
						/>
						<FormattedMessage
							id="editProfile"
							defaultMessage={`FAQs`}
						/>

						<StyledIcon
							className={`fa fa-chevron-right ${styles.icon} ${
								(active === 0 ||
									active === 5 ||
									active === 6 ||
									active === 7) &&
								styles.open
							}`}
							theme={theme}
							hasBackground={
								active === 0 ||
								active === 5 ||
								active === 6 ||
								active === 7
							}
						/>
					</StyledSidebarItem>

					<ul
						className={
							active === 0 ||
							active === 5 ||
							active === 6 ||
							active === 7
								? styles.dropdownActive
								: styles.dropdownNone
						}
					>
						<StyledDropdownItem
							className={`${
								active === 0
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => setActive(0)}
							isActive={active === 0}
							theme={theme}
						>
							<Star color={iconColor} />
							<FormattedMessage
								id="editProfile"
								defaultMessage={`General`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 5
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => setActive(5)}
							isActive={active === 5}
							theme={theme}
						>
							<TicketIcon color={iconColor} />
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Subscription Plans`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 6
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => setActive(6)}
							isActive={active === 6}
							theme={theme}
						>
							<DiscountIcon color={iconColor} />
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Free Credits`}
							/>
						</StyledDropdownItem>
						<StyledDropdownItem
							className={`${
								active === 7
									? styles.selectedSub
									: styles.linkSub
							} ${
								theme === "dark"
									? styles.darkText
									: styles.lgText
							}`}
							onClick={() => setActive(7)}
							isActive={active === 7}
							theme={theme}
						>
							<LockIcon color={iconColor} />
							<FormattedMessage
								id="editProfile"
								defaultMessage={`Privacy`}
							/>
						</StyledDropdownItem>
					</ul>

					<StyledSidebarItem
						className={styles.links}
						onClick={() => setActive(1)}
						replace={!isMobile}
						isActive={active === 1}
						theme={theme}
					>
						<li
							className={
								active === 1
									? styles.selected + ` ${textcolorClass}`
									: styles.link
							}
						>
							<AboutIcon color={getColor(1)} style={iconStyle} />
							About
							{isMobile && (
								<IconButton
									size="small"
									variant="contained"
									style={{
										color: "#ff5d5d",
										float: "right",
										marginRight: "-1.8rem",
									}}
								>
									<Chevron.Right viewBox="0 0 27 27" />
								</IconButton>
							)}
						</li>
					</StyledSidebarItem>

					<StyledSidebarItem
						className={styles.links}
						onClick={() => setActive(2)}
						replace={!isMobile}
						isActive={active === 2}
						theme={theme}
					>
						<li
							className={
								active === 2
									? styles.selected + ` ${textcolorClass}`
									: styles.link
							}
						>
							<FeedbackIcon
								style={iconStyle}
								color={getColor(2)}
							/>
							Send Feedback
							{isMobile && (
								<IconButton
									size="small"
									variant="contained"
									style={{
										color: "#ff5d5d",
										float: "right",
										marginRight: "-1.8rem",
									}}
								>
									<Chevron.Right viewBox="0 0 27 27" />
								</IconButton>
							)}
						</li>
					</StyledSidebarItem>

					<StyledSidebarItem
						className={styles.links}
						onClick={() => setActive(3)}
						replace={!isMobile}
						isActive={active === 3}
						theme={theme}
					>
						<li
							className={
								active === 3
									? styles.selected + ` ${textcolorClass}`
									: styles.link
							}
						>
							<TutorialIcon
								style={iconStyle}
								color={getColor(3)}
							/>
							Tutorial
							{isMobile && (
								<IconButton
									size="small"
									variant="contained"
									style={{
										color: "#ff5d5d",
										float: "right",
										marginRight: "-1.8rem",
									}}
								>
									<Chevron.Right viewBox="0 0 27 27" />
								</IconButton>
							)}
						</li>
					</StyledSidebarItem>
				</ul>
			</>
		);
	}

	return ( <>

		{ !isMobile ? <Dialog
			open={true}
			fullScreen={true}
			maxWidth={"100vw"}
			fullWidth={"100vw"}
			classes={{
				paper: theme === "dark" ? styles.rootDark : styles.rootLg,
			}}
									>
			<Helmet>
				<title>{`Help and support | bditto`} </title>
			</Helmet>
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.policyHeader}>
						<h1
							style={{
								color: theme === "dark" ? "white" : "#0D0F22",
							}}
						>
							Help Center
						</h1>
						<img
							style={{ cursor: "pointer" }}
							onClick={() => {
								history.goBack();
							}}
							src="/icons/squaredClose.svg"
							alt=""
						/>
					</div>

					<div className={styles.policyBody}>
						{!isMobile && (
							<>
								<div className={styles.left}>
									<SideBar
										active={active}
										setActive={setActive}
									/>
								</div>
								<div className={styles.right}>
									{active === 0 ||
										active === 5 ||
										active === 6 ||
										active === 7 ? (
											<Faq isMobile={isMobile} />
										) : active === 1 ? (
											<About isMobile={isMobile} />
										) : active === 2 ? (
											<Report isMobile={isMobile} />
										) : (
											active === 3 && (
												<Tutorial isMobile={isMobile} />
											)
										)}
								</div>
							</>
						)}
					</div>
					{/* <div className={styles.right}>
            <Route exact path="/info">
              <SideBar isMobile={isMobile} />
            </Route>
            <Route exact path="/info/about">
              <About isMobile={isMobile} />
            </Route>
            <Route path="/info/faqs/">
              <Faq isMobile={isMobile} />
            </Route>
            <Route exact path="/info/report">
              <Report isMobile={isMobile} />
            </Route>
            <Route exact path="/info/tour">
              <Tutorial isMobile={isMobile} />
            </Route>

            <div className={styles.blank} style={{ height: "5rem" }}></div>
          </div> */}
				</div>
			</div>
		</Dialog> : <div>
			{props?.active === 1 && <Report/>}
			{props?.active === 2 && <About/>}
			{props?.active === 3 && <Tutorial/>}
		</div>}
	</>
	);
}

export const StyledSidebarItem = styled.div`
	background: ${function ({ isActive }) {
		return isActive
			? "linear-gradient(112.23deg, rgba(196, 196, 196, 0.17) 0%, #00C6FF 0.01%, #0072FF 100%)"
			: "none";
	}};

	border-radius: 8px;

	color: ${function ({ isActive, theme }) {
		if (isActive) return "#D9DEF4";
		else return theme === "dark" ? "#7F89BE" : "#4A517E";
	}} !important;
`;
