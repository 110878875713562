import { ReactComponent as leftArrow } from "../assets/icons/LeftArrow.svg";
import { ReactComponent as rightArrow } from "../assets/icons/extras/arrowRight.svg";
import { ReactComponent as downArrow } from "../assets/icons/DownArrow.svg";
import genIcons from "./genIcons";

const Arrows = { 
    Left: genIcons(leftArrow, { htmlColor: "transparent" }), 
    Right: genIcons(rightArrow, { htmlColor: "transparent" }),
    Down: genIcons(downArrow, { htmlColor: "transparent" }) 
};

export function ArrowRight({ theme }) {
    const color= theme === "dark" ? "#7F89BE": "#4A517E"

    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.58847e-09 6.00427C1.14302e-08 5.68211 0.2394 5.41586 0.550006 5.37372L0.636356 5.36791L13.3635 5.36791C13.7149 5.36791 13.9998 5.65282 13.9998 6.00427C13.9998 6.32643 13.7604 6.59268 13.4498 6.63482L13.3635 6.64063L0.636356 6.64063C0.284906 6.64063 3.39747e-09 6.35572 7.58847e-09 6.00427Z" fill= { color } />
            <path d="M7.7811 1.34131C7.53206 1.09334 7.53119 0.69042 7.77917 0.441373C8.0046 0.214967 8.35808 0.193667 8.6076 0.377975L8.67911 0.439435L13.8124 5.55065C14.0395 5.77675 14.0601 6.13151 13.8743 6.38102L13.8124 6.45249L8.67914 11.5645C8.43012 11.8125 8.0272 11.8117 7.7792 11.5627C7.55375 11.3363 7.53395 10.9827 7.7193 10.734L7.78107 10.6627L12.4613 6.00133L7.7811 1.34131Z" fill= { color } />
        </svg>
    )
}

export function ArrowDown({ theme }) {
    const color= theme === "dark" ? "#7F89BE": "#4A517E"

    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3203 4.66797C14.7633 4.66797 15.1294 4.99715 15.1873 5.42424L15.1953 5.54297L15.1953 23.043C15.1953 23.5262 14.8036 23.918 14.3203 23.918C13.8773 23.918 13.5112 23.5888 13.4533 23.1617L13.4453 23.043L13.4453 5.54297C13.4453 5.05972 13.8371 4.66797 14.3203 4.66797Z" fill= { color } />
            <path d="M20.7301 15.367C21.0711 15.0245 21.6251 15.0234 21.9675 15.3643C22.2788 15.6743 22.3081 16.1603 22.0547 16.5034L21.9702 16.6018L14.9422 23.6601C14.6313 23.9723 14.1435 24.0007 13.8004 23.7453L13.7021 23.6601L6.67297 16.6018C6.33197 16.2594 6.33312 15.7054 6.67553 15.3644C6.98682 15.0544 7.47298 15.0271 7.815 15.282L7.91297 15.3669L14.3225 21.8023L20.7301 15.367Z" fill= { color } />
        </svg>
    )
}

export default Arrows;
