import * as React from "react";

import TwitterLoginButton from "./TwitterLoginButton";
import { openWindow, observeWindow } from "./services/window";
import { obtainOauthRequestToken, obtainOauthAccessToken } from "./services/oauth1";
import Log from "../Utils/logger";

export default class TwitterLoginComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCompleted: false,
    };
  }

  componentDidMount() {
    this.initializeProcess();
  }

  initializeProcess = () => {
    if (window.opener) {
      const [, oauthToken, oauthVerifier] =
        window.location.search.match(/^(?=.*oauth_token=([^&]+)|)(?=.*oauth_verifier=([^&]+)|).+$/) || [];
      window.opener.postMessage(
        {
          type: "authorized",
          data: {
            oauthToken,
            oauthVerifier,
          },
        },
        window.origin
      );
    } else {
      const { authCallback } = this.props;
      window.onmessage = async ({ data: { type, data } }) => {
        if (type === "authorized") {
          const { popup } = this.state;
          try {
            const accessTokenData = await obtainOauthAccessToken({
              oauthToken: data.oauthToken,
              oauthVerifier: data.oauthVerifier,
            });
            this.setState(
              {
                isCompleted: true,
              },
              () => {
                popup && popup.close();
                authCallback && authCallback(undefined, accessTokenData);
              }
            );
          } catch (err) {
            this.props.authCallback && this.props.authCallback(err, undefined);
            popup && popup.close();
          }
        }
      };
    }
  };

  handleLoginClick = async () => {
    const { callbackUrl } = this.props;
    const popup = openWindow({
      url: ``,
      name: "Log in with Twitter",
    });

    if (callbackUrl) {
      Log.debug(
        `DEPRECATED: "callbackUrl" is not supported and ignored from version 1.2.0 and higher. It's hardcoded inside the package with "window.location.href". More details: https://github.com/alexandrtovmach/react-twitter-login/issues/8`
      );
    }
    const obtainRequestTokenConfig = {
      callbackUrl: window.location.href,
    };
    try {
      const requestTokenData = await obtainOauthRequestToken(obtainRequestTokenConfig);
      if (requestTokenData.oauth_callback_confirmed === "true" && popup !== null) {
        popup.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${requestTokenData.oauth_token}`;

        if (popup) {
          observeWindow({ popup, onClose: this.handleClosingPopup });
          this.setState({
            popup,
          });
        }
      } else {
        this.handleError(
          `Callback URL "${window.location.href}" is not confirmed. Please check that is whitelisted within the Twitter app settings.`
        );
      }
    } catch (err) {
      this.props.authCallback && this.props.authCallback(err, undefined);
      popup && popup.close();
    }
  };

  handleClosingPopup = () => {
    const { authCallback } = this.props;
    const { isCompleted } = this.state;
    if (!isCompleted) {
      authCallback && authCallback("User closed OAuth popup");
    }
  };

  handleError = (message) => {
    const { authCallback } = this.props;
    authCallback(message);
  };

  render() {
    const { buttonTheme, className, children } = this.props;

    return children ? (
      <div onClick={this.handleLoginClick} className={className}>
        {children}
      </div>
    ) : (
      <TwitterLoginButton
        buttonTheme={buttonTheme || "light"}
        buttonClassName={className}
        onClick={this.handleLoginClick}
      />
    );
  }
}
