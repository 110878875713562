import React from "react";
import {
	Redirect,
	useHistory,
	useLocation,
	withRouter,
} from "react-router-dom";
import Navbar from "../Components/Navbar/Navbar";

const ProtectedRoute = ({
	component: Component,
	isAuthenticated,
	noNavbar,
	noBottomNavigation,
	...rest
}) => {
	const history = useHistory();
	const currentPath = useLocation().pathname;
	const goToThisRouter = (path) => {
		history.push(path);
	};
	const isMobile = window.screen.width < 768;
	return isAuthenticated ? (
		<>
			{isMobile && !noNavbar && (
				<Navbar
					{...rest}
					rootParams={rest.computedMatch || rest.match}
					currentPath={currentPath}
					goToThisRouter={goToThisRouter}
				/>
			)}
			{!isMobile && !noNavbar && (
				<Navbar
					{...rest}
					rootParams={rest.computedMatch || rest.match}
					currentPath={currentPath}
					goToThisRouter={goToThisRouter}
				/>
			)}
			<div
				style={
					!noNavbar && {
						flex: 1,
						position: "relative",
						overflow: "hidden",
						paddingBottom: !noBottomNavigation
							? !isMobile && "60px"
							: "0",
						background: rest.theme === "dark" ? "#121528" : "#fff",
					}
				}
			>
				<div style={{ height: "88%" }}>
					<Component {...rest} rootParams={rest.computedMatch} />
				</div>
			</div>
		</>
	) : (
		<Redirect
			//  to="/login"
			to={{
				pathname: "/login",
				state: { referrer: currentPath },
			}}
		/>
	);
};

export default withRouter(ProtectedRoute);
