import React, { useState, useEffect, useRef } from "react";
import BlockUsers from "./BlockUsers/BlockUsers";
import styles from "./Contacts.module.css";
import Friends from "./Friends/Friends";
import PendingRequest from "./Requests/PendingRequest";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useAlert from "./../../Extras/Alert/useAlert";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { countsInContactUrl } from "../../../Utils/Constants";
import { getCountInContact } from "./../../../Redux/Actions/contactAction";
import { IconButton, Popover } from "@material-ui/core";
import Arrows from "../../../Icons/Arrows";
import SearchIcon from "@material-ui/icons/Search";
import Requested from "./Requested/Requested";
import Chevron from "../../../Icons/Chevron";
import BlockDialog from "../../Extras/BlockDialogBox/BlockDialog";
import {
	ChevronRight,
	Cancel as CancelIcon,
	Close,
	ArrowBackIos,
	Search,
} from "@material-ui/icons";
import TurnedOff from "./TurnedOff/TurnedOff";
import { Dialog, Avatar } from "@material-ui/core";

const Contacts = (props) => {
	const dispatch = useDispatch();
	const [searchText, setSearchText] = useState("");
	const [activeTab, setActiveTab] = useState(0);
	const [showSearch, setShowSearch] = useState(false);
	const history = useHistory();
	const [data, setData] = useState("Connected");
	const [count, setcount] = useState({
		friendscount: 0,
		requestcount: 0,
		blockedcount: 0,
	});

	const { showAlert } = useAlert();

	const counts = useSelector((state) => state.contacts);
	let { error, contactCount } = counts;

	const theme = useSelector((reduxState) => reduxState.theme.theme);

	useEffect(() => {
		setcount(contactCount);
		if (error) {
			showAlert("Please Try again later", {
				header: "Something went wrong",
				buttonText: "Go Back",
			});
		}
	}, [contactCount, error]);

	useEffect(() => {
		if (props.path === 6) {
			setData("Connected");
		} else if (props.path === 7) {
			setData("Requests");
		} else if (props.path === 8) {
			setData("Blocked");
		} else if (props.path === 9) {
			setData("Sent");
		} else if (props.path === 10) {
			setData("Turned Off");
		}
	}, []);

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			event.preventDefault();
		}
	};

	const isMobile = window.screen.width < 768;
	const [anchorEl, setAnchorEl] = React.useState(null);
	const initialRef = useRef();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const setDropdown = (data) => {
		setData(data);
		handleClose();
	};

	const searchHandler = (text) => {
		setSearchText(text);
	};

	const open = Boolean(anchorEl);
	const popid = open ? "simple-popover" : undefined;

	const tabs = [
		{
			id: 1,
			label: "Connections",
		},
		{
			id: 2,
			label: "Requests",
		},
		{
			id: 3,
			label: "Invitation Sent",
		},
		{
			id: 4,
			label: "Blocked Users",
		},
		{
			id: 5,
			label: "Turned Off Users",
		},
	];

	return (
		<>
			{!isMobile ? (
				<div className={styles.wrapper}>
					<div className={styles.container}>
						<div className={styles.header}>
							{!isMobile &&
								(props.path === 6 ? (
									<div className={styles.headItem}>
										<h1
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "black",
											}}
										>
											<FormattedMessage
												id="active"
												defaultMessage={`Network (Connections)`}
											/>{" "}
											&nbsp;
										</h1>
									</div>
								) : props.path === 7 ? (
									<div className={styles.headItem}>
										<h1
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "black",
											}}
										>
											<FormattedMessage
												id="active"
												defaultMessage={`Network (Requests)`}
											/>{" "}
											&nbsp;
										</h1>
									</div>
								) : props.path === 8 ? (
									<div className={styles.headItem}>
										<h1
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "black",
											}}
										>
											<FormattedMessage
												id="active"
												defaultMessage={`Network (Invitation Sent)`}
											/>{" "}
											&nbsp;
										</h1>
									</div>
								) : props.path === 9 ? (
									<div className={styles.headItem}>
										<h1
											style={{
												color:
													theme === "dark"
														? "#d9def4"
														: "black",
											}}
										>
											<FormattedMessage
												id="active"
												defaultMessage={`Network (Blocked Users)`}
											/>{" "}
											&nbsp;
										</h1>
									</div>
								) : (
									props.path === 10 && (
										<div className={styles.headItem}>
											<h1
												style={{
													color:
														theme === "dark"
															? "#d9def4"
															: "black",
												}}
											>
												<FormattedMessage
													id="active"
													defaultMessage={`Network (Turned Off Users)`}
												/>{" "}
												&nbsp;
											</h1>
										</div>
									)
								))}
						</div>

						<div className={styles.searchContainer}>
							<img
								src="/Search.svg"
								className="ml-2 mr-1"
								alt=""
							/>
							<input
								type="text"
								value={searchText}
								className={styles.input}
								onChange={(e) => searchHandler(e.target.value)}
								placeholder="Type a contact's name"
							/>
							{searchText !== "" && (
								<CancelIcon
									onClick={() => setSearchText("")}
									style={{
										color:
											theme === "dark"
												? "#7F89BE"
												: "#4A517E",
									}}
								/>
							)}
						</div>

						{props.path === 6 ? (
							<Friends searchText={searchText} />
						) : props.path === 7 ? (
							<PendingRequest searchText={searchText} />
						) : props.path === 8 ? (
							<Requested searchText={searchText} />
						) : props.path === 9 ? (
							<BlockUsers searchText={searchText} />
						) : (
							props.path === 10 && <TurnedOff />
						)}
					</div>
				</div>
			) : (
				<>
					<div className="mt-5">
						{tabs.map((tab) => (
							<div
								className={styles.networkTabs}
								key={tab.id}
								onClick={() => setActiveTab(tab.id)}
							>
								<p className="mb-0">
									<span className="mr-3">
										<img src={tab.ico} alt="" />
									</span>
									{tab.label}
								</p>
								<span>
									<ChevronRight
										style={{ color: "#7F89BE" }}
									/>
								</span>
							</div>
						))}
					</div>

					{activeTab === 1 && (
						<Dialog
							open={true}
							fullScreen={true}
							maxWidth={"100vw"}
							fullWidth={"100vw"}
							classes={{
								paper:
									theme === "dark"
										? styles.rootDark
										: styles.rootLg,
							}}
						>
							<div className={styles.mobNav}>
								<span className="btn p-1">
									{showSearch ? (
										<Close
											onClick={() => setShowSearch(false)}
											style={{ color: "#D9DEF4" }}
										/>
									) : (
										<ArrowBackIos
											onClick={() => setActiveTab(0)}
											style={{ color: "#D9DEF4" }}
										/>
									)}
								</span>
								{!showSearch && (
									<div
										className={`${styles.headerText} ${
											theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
									>
										<h2 className="mb-0">
											<FormattedMessage
												id="editProfile"
												defaultMessage={`Connections`}
											/>
										</h2>
									</div>
								)}
								{!showSearch && (
									<div>
										<span
											onClick={() => setShowSearch(true)}
											className="btn p-1"
										>
											<Search
												style={{ color: "#D9DEF4" }}
											/>
										</span>
									</div>
								)}
								{showSearch && (
									<div className={styles.searchContainer}>
										<img
											src="/Search.svg"
											className="ml-2 mr-1"
											alt=""
										/>
										<input
											type="text"
											// value={searchText}
											className={styles.input}
											onChange={(e) =>
												setSearchText(
													e.target.value.trim()
												)
											}
											placeholder={`Search connections`}
										/>
										{searchText !== "" && (
											<Close
												onClick={() =>
													setSearchText("")
												}
												style={{
													margin: "auto 0",
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											/>
										)}
									</div>
								)}
							</div>
							<div style={{ height: "90vh" }}>
								<Friends searchText={searchText} />
							</div>
						</Dialog>
					)}
					{activeTab === 2 && (
						<Dialog
							open={true}
							fullScreen={true}
							maxWidth={"100vw"}
							fullWidth={"100vw"}
							classes={{
								paper:
									theme === "dark"
										? styles.rootDark
										: styles.rootLg,
							}}
						>
							<div className={styles.mobNav}>
								<span className="btn p-1">
									{showSearch ? (
										<Close
											onClick={() => setShowSearch(false)}
											style={{ color: "#D9DEF4" }}
										/>
									) : (
										<ArrowBackIos
											onClick={() => setActiveTab(0)}
											style={{ color: "#D9DEF4" }}
										/>
									)}
								</span>
								{!showSearch && (
									<div
										className={`${styles.headerText} ${
											theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
									>
										<h2 className="mb-0">
											<FormattedMessage
												id="editProfile"
												defaultMessage={`Requests`}
											/>
										</h2>
									</div>
								)}
								{!showSearch && (
									<div>
										<span
											onClick={() => setShowSearch(true)}
											className="btn p-1"
										>
											<Search
												style={{ color: "#D9DEF4" }}
											/>
										</span>
									</div>
								)}
								{showSearch && (
									<div className={styles.searchContainer}>
										<img
											src="/Search.svg"
											className="ml-2 mr-1"
											alt=""
										/>
										<input
											type="text"
											// value={searchText}
											className={styles.input}
											onChange={(e) =>
												setSearchText(
													e.target.value.trim()
												)
											}
											placeholder={`Search Requests`}
										/>
										{searchText !== "" && (
											<Close
												onClick={() =>
													setSearchText("")
												}
												style={{
													margin: "auto 0",
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											/>
										)}
									</div>
								)}
							</div>
							<div style={{ height: "90vh" }}>
								<PendingRequest searchText={searchText} />
							</div>
						</Dialog>
					)}
					{activeTab === 3 && (
						<Dialog
							open={true}
							fullScreen={true}
							maxWidth={"100vw"}
							fullWidth={"100vw"}
							classes={{
								paper:
									theme === "dark"
										? styles.rootDark
										: styles.rootLg,
							}}
						>
							<div className={styles.mobNav}>
								<span className="btn p-1">
									{showSearch ? (
										<Close
											onClick={() => setShowSearch(false)}
											style={{ color: "#D9DEF4" }}
										/>
									) : (
										<ArrowBackIos
											onClick={() => setActiveTab(0)}
											style={{ color: "#D9DEF4" }}
										/>
									)}
								</span>
								{!showSearch && (
									<div
										className={`${styles.headerText} ${
											theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
									>
										<h2 className="mb-0">
											<FormattedMessage
												id="editProfile"
												defaultMessage={`Invitation Sent`}
											/>
										</h2>
									</div>
								)}
								{!showSearch && (
									<div>
										<span
											onClick={() => setShowSearch(true)}
											className="btn p-1"
										>
											<Search
												style={{ color: "#D9DEF4" }}
											/>
										</span>
									</div>
								)}
								{showSearch && (
									<div className={styles.searchContainer}>
										<img
											src="/Search.svg"
											className="ml-2 mr-1"
											alt=""
										/>
										<input
											type="text"
											// value={searchText}
											className={styles.input}
											onChange={(e) =>
												setSearchText(
													e.target.value.trim()
												)
											}
											placeholder={`Search Requests`}
										/>
										{searchText !== "" && (
											<Close
												onClick={() =>
													setSearchText("")
												}
												style={{
													margin: "auto 0",
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											/>
										)}
									</div>
								)}
							</div>
							<div style={{ height: "90vh" }}>
								<Requested searchText={searchText} />
							</div>
						</Dialog>
					)}
					{activeTab === 4 && (
						<Dialog
							open={true}
							fullScreen={true}
							maxWidth={"100vw"}
							fullWidth={"100vw"}
							classes={{
								paper:
									theme === "dark"
										? styles.rootDark
										: styles.rootLg,
							}}
						>
							<div className={styles.mobNav}>
								<span className="btn p-1">
									{showSearch ? (
										<Close
											onClick={() => setShowSearch(false)}
											style={{ color: "#D9DEF4" }}
										/>
									) : (
										<ArrowBackIos
											onClick={() => setActiveTab(0)}
											style={{ color: "#D9DEF4" }}
										/>
									)}
								</span>
								{!showSearch && (
									<div
										className={`${styles.headerText} ${
											theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
									>
										<h2 className="mb-0">
											<FormattedMessage
												id="editProfile"
												defaultMessage={`Blocked Users`}
											/>
										</h2>
									</div>
								)}
								{!showSearch && (
									<div>
										<span
											onClick={() => setShowSearch(true)}
											className="btn p-1"
										>
											<Search
												style={{ color: "#D9DEF4" }}
											/>
										</span>
									</div>
								)}
								{showSearch && (
									<div className={styles.searchContainer}>
										<img
											src="/Search.svg"
											className="ml-2 mr-1"
											alt=""
										/>
										<input
											type="text"
											// value={searchText}
											className={styles.input}
											onChange={(e) =>
												setSearchText(
													e.target.value.trim()
												)
											}
											placeholder={`Search Blocked Users`}
										/>
										{searchText !== "" && (
											<Close
												onClick={() =>
													setSearchText("")
												}
												style={{
													margin: "auto 0",
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											/>
										)}
									</div>
								)}
							</div>
							<div style={{ height: "90vh" }}>
								<BlockUsers searchText={searchText} />
							</div>
						</Dialog>
					)}
					{activeTab === 5 && (
						<Dialog
							open={true}
							fullScreen={true}
							maxWidth={"100vw"}
							fullWidth={"100vw"}
							classes={{
								paper:
									theme === "dark"
										? styles.rootDark
										: styles.rootLg,
							}}
						>
							<div className={styles.mobNav}>
								<span className="btn p-1">
									{showSearch ? (
										<Close
											onClick={() => setShowSearch(false)}
											style={{ color: "#D9DEF4" }}
										/>
									) : (
										<ArrowBackIos
											onClick={() => setActiveTab(0)}
											style={{ color: "#D9DEF4" }}
										/>
									)}
								</span>
								{!showSearch && (
									<div
										className={`${styles.headerText} ${
											theme === "dark"
												? styles.darkPText
												: styles.lgPText
										}`}
									>
										<h2 className="mb-0">
											<FormattedMessage
												id="editProfile"
												defaultMessage={`Turned Off Users`}
											/>
										</h2>
									</div>
								)}
								{!showSearch && (
									<div>
										<span
											onClick={() => setShowSearch(true)}
											className="btn p-1"
										>
											<Search
												style={{ color: "#D9DEF4" }}
											/>
										</span>
									</div>
								)}
								{showSearch && (
									<div className={styles.searchContainer}>
										<img
											src="/Search.svg"
											className="ml-2 mr-1"
											alt=""
										/>
										<input
											type="text"
											// value={searchText}
											className={styles.input}
											onChange={(e) =>
												setSearchText(
													e.target.value.trim()
												)
											}
											placeholder={`Search Turned Off Users`}
										/>
										{searchText !== "" && (
											<Close
												onClick={() =>
													setSearchText("")
												}
												style={{
													margin: "auto 0",
													color:
														theme === "dark"
															? "#7F89BE"
															: "#4A517E",
												}}
											/>
										)}
									</div>
								)}
							</div>
							<div style={{ height: "90vh" }}>
								<TurnedOff />
							</div>
						</Dialog>
					)}
				</>
			)}
		</>
	);
};

export default Contacts;
