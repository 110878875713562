export function TutorialIcon({ style, color }) {
	return (
		<svg
			style={style}
			width="15"
			height="18"
			viewBox="0 0 15 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 1V17L14 9L1 1Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
