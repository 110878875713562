import {
	facebookRegex,
	githubRegex,
	instagramRegex,
	linkedinRegex,
	twitterRegex,
	websiteRegex,
} from "./regexUrlConstants";

export const validateUrls = (urls, form) => {
	let socialUrls = [];
	let error = null;
	if (urls.facebook || urls.facebookId) {
		if (urls.facebook.length > 0 && !facebookRegex.test(urls.facebook))
			error = "Please Enter Correct Facebook URL";
		else {
			let newUrl = urls.facebook;
			if (urls.facebook.includes("http")) {
				newUrl = urls.facebook;
			} else if (urls.facebook.length > 0)
				newUrl = "https://" + urls.facebook;

			form.append("facebook.id", urls.facebookId);
			form.append("facebook.value", newUrl);
		}
	}
	if (urls.instagram || urls.instagramId) {
		if (urls.instagram.length > 0 && !instagramRegex.test(urls.instagram))
			error = "Please Enter Correct Instagram URL";
		else {
			let newUrl = urls.instagram;
			if (urls.instagram.includes("http")) {
				newUrl = urls.instagram;
			} else if (urls.instagram.length > 0)
				newUrl = "https://" + urls.instagram;

			form.append("instagram.id", urls.instagramId);
			form.append("instagram.value", newUrl);
		}
	}
	if (urls.twitter || urls.twitterId) {
		if (urls.twitter.length > 0 && !twitterRegex.test(urls.twitter))
			error = "Please Enter Correct Twitter URL";
		else {
			let newUrl = urls.twitter;
			if (urls.twitter.includes("http")) {
				newUrl = urls.twitter;
			} else if (urls.twitter.length > 0)
				newUrl = "https://" + urls.twitter;

			form.append("twitter.id", urls.twitterId);
			form.append("twitter.value", newUrl);
		}
	}

	if (urls.github || urls.githubId) {
		if (urls.github.length > 0 && !githubRegex.test(urls.github))
			error = "Please Enter Correct Github URL";
		else {
			let newUrl = urls.github;
			if (urls.github.includes("http")) {
				newUrl = urls.github;
			} else if (urls.github.length > 0)
				newUrl = "https://" + urls.github;

			form.append("github.id", urls.githubId);
			form.append("github.value", newUrl);
		}
	}
	if (urls.linkedin || urls.linkedinId) {
		if (urls.linkedin.length > 0 && !linkedinRegex.test(urls.linkedin))
			error = "Please Enter Correct LinkedIn URL";
		else {
			let newUrl = urls.linkedin;
			if (urls.linkedin.includes("http")) {
				newUrl = urls.linkedin;
			} else if (urls.linkedin.length > 0)
				newUrl = "https://" + urls.linkedin;

			form.append("linkedin.id", urls.linkedinId);
			form.append("linkedin.value", newUrl);
		}
	}
	if (urls.others || urls.othersId) {
		if (urls.others.length > 0 && !websiteRegex.test(urls.others))
			error = "Please Enter Correct Website URL";
		else {
			let newUrl = urls.others;
			if (urls.others.includes("http")) {
				newUrl = urls.others;
			} else if (urls.others.length > 0)
				newUrl = "https://" + urls.others;

			form.append("others.id", urls.othersId);
			form.append("others.value", newUrl);
		}
	}

	if (error) return error;
	else return form;
};
