import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../Redux/Actions/actionAuth";
import { useSelector } from "react-redux";
import useAlert from "./../Extras/Alert/useAlert";
import { useHistory } from "react-router-dom";
export const BlockedAccountError = () => {
	const { showAlert } = useAlert();
	const history = useHistory();
	const dispatch = useDispatch();

	const auth = useSelector((state) => state.auth?.isAuthenticated);
	useEffect(() => {
		showAlert(
			"Your account has been permanently blocked from update actions. Please contact Admin.",
			{
				header: "Your Account Permanently Blocked",
				callback: () => history.push("/"),
				buttonText: "Go Back",
			}
		);
		if (auth) {
			dispatch(logout());
		}
	}, []);

	return (
		<div
			style={{
				height: "100vh",
			}}
		>
			{/* <Alerts
                show={true}
                message={"Your account has been permanently blocked from update actions. Please contact Admin."}
                header={"Account Permanently Blocked"}
                buttonText={"Close"} /> */}
		</div>
	);
};
