import { Avatar, Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import AddIcon from "@material-ui/icons/Add";
import "./GroupsSidebar.scss";
import styles from "./GroupsSidebar.module.css";
import { getGroups, getMyGroup, joinGroupsURL } from "../../../Utils/Constants";
import axiosConfig from "../../../Utils/axiosConfig";
import SidebarContextMenu from "../Chats/Sidebar/SidebarContextMenu";
import CreateGroupModal from "./GroupModals/CreateGroupModal";
import { useHistory, useLocation } from "react-router-dom";
import useAlert from "../../Extras/Alert/useAlert";
import ConvListSkeleton from "../../Skeleton/ConvListSkeleton/ConvListSkeleton";
import { useDispatch } from "react-redux";
import { setGroup } from "../../../Redux/Actions/groupsAction";
import DarkAddIcon from "../../../Icons/DarkAddIcon";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MyAllGroup from "./GroupModals/MyAllGroup";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { User3 } from "../../../Icons/User3";
import InfiniteScroll from "react-infinite-scroll-component";
import { getMyGroups } from "../../../Redux/Actions/myGroupsAction"

const GroupsSidebar = (props) => {
	const [myGroups, setMygroups] = useState([]);
	const [id, setId] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const [joinLoading, setJoinLoading] = useState(false);
	const [exploreGroups, setExploregroups] = useState([]);
	const [groupDisplay, setGroupDisplay] = useState(false);
	const [exploreLoading, setexploreLoading] = useState(false);
	const [exploreDisplay, setExploreDisplay] = useState(false);
	const [createGroupDisplay, setcreateGroupDisplay] = useState(false);
	const [grpShow, setGrpShow] = useState(true);
	const [groupsCount, setGroupsCount] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const [pgLoading, setPgLoading] = useState(false);
	const [skip, setSkip] = useState(10);
	const [searchQuery, setSearchQuery] = useState("");
	const [searchQuery1, setSearchQuery1] = useState("");
	const theme = useSelector((reduxState) => reduxState.theme.theme);
	const history = useHistory();
	const pathname = useLocation().pathname;
	const dispatch = useDispatch();
	const { showAlert } = useAlert();

    let myGroupsData = useSelector((state) => state.myGroups.myGroups);

    useEffect(() => {
        setMygroups(myGroupsData)
    }, [myGroupsData])

    console.log('prosp', props)



	const notifications = useSelector(
		(state) => state.notifications.newestData
	);

	useEffect(() => {
		if (pathname.includes("/group-info/")) {
			let gpId = pathname.split("/group-info/")[1];
			openInfoModal(gpId);
		}
		setIsLoading(true);
		fetchMyGroups();
		setexploreLoading(true);
		getExploreGroups();
	}, []);

	useEffect(() => {
		if (notifications.length === 0) {
			setTimeout(setId(0), 30000);
		}
		notifications[0] && setId(notifications[0].group_id);
	}, [notifications]);

	const fetchMyGroups = (searchText) => {
		axiosConfig
			.get(
				getMyGroup +
					`?limit=10&offset=0&search_phrase=${
						searchText ? searchText : ""
					}`
			)
            .then((res) => {
                dispatch(getMyGroups(res.data.result))
				setMygroups(res.data.result || res.data.body);
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
			});
	};
	const pagination = async (searchText) => {
		setPgLoading(true);

		let data = await axiosConfig
			.get(
				getGroups +
					`?limit=10&offset=${skip}&search_phrase=${
						searchText ? searchText : ""
					}`
			)
			.then((res) => {
				if (res.status !== 200) return;
				return res.data;
			})
			.catch((err) => {
				setPgLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
				return "error";
			});
		return data;
	};
	const fetchData = () => {
		pagination()
			.then((data) => {
				if (data === "error") return;
				setExploregroups([...exploreGroups, ...data.result]);
				setSkip(skip + 10);
				console.log("ada", skip, hasMore, data.count);

				if (skip >= data?.count) {
					setHasMore(false);
				}
				setPgLoading(false);
			})
			.catch((err) => {
				console.log("ada", err.message);
				setPgLoading(false);
				showAlert("Error while Fetching Data", {
					header: "",
					buttonText: "Close",
				});
				setPgLoading(false);
			});
	};
	const getExploreGroups = (searchText) => {
		axiosConfig
			.get(
				getGroups +
					`?limit=10&offset=0&search_phrase=${
						searchText ? searchText : ""
					}`
			)
			.then((res) => {
				setExploregroups(res.data.result || res.data.body);
				setGroupsCount(res.data.count || 0);
				setexploreLoading(false);
			})
			.catch((err) => {
				setexploreLoading(false);
			});
	};

	const joinGroup = (id) => {
		setJoinLoading(true);
		const form = new FormData();
		form.append("groupId", id);
		axiosConfig
			.post(joinGroupsURL, form)
            .then((res) => {
                
                console.log("res", res.data)
				if (res.status !== 200) throw new Error();

				if (res.data.status_code === 200) {
					showAlert(res.data.message, {
						header: res.data.message === "Request forwarded to admin or subadmin." ? "Request Sended Successfully":  "Joined Successfully",
						buttonText: "Close",
					});
				}
				fetchMyGroups();
				getExploreGroups();
				setJoinLoading(false);
			})
			.catch((err) => {
				setJoinLoading(false);

				if (err.response.status === 400) {
					if (
						err.response.data.message &&
						err.response.data.message.length > 0 &&
						err.response.data.message[0] ===
							"Request already pending."
					) {
						showAlert(err.response.data.message[0], {
							header: "Request Pedning",
							buttonText: "Close",
						});
					}
				} else {
					showAlert("Bad Request", {
						header: err.response.data?.error,
						buttonText: "Close",
					});
				}
			});
	};

	const openInfoModal = (id) => {
		history.push({
			pathname: `/group-info/${id}`,
			state: {
				modal: true,
			},
		});
	};

	const setcreateGroupDisplayChange = () => {
		setcreateGroupDisplay(!createGroupDisplay);
	};

	const DisplaySkeleton = () => {
		return (
			<>
				<ConvListSkeleton />
				<ConvListSkeleton />
				<ConvListSkeleton />
			</>
		);
	};

	const handleChangeGroup = (grp) => {
		dispatch(setGroup(grp));

		if (pathname.includes("/group")) {
			history.push(`/group/${grp.id}`);
		}
	};

	const searchHandler = (e) => {
		setSearchQuery(e.target.value);
		if (e.target.value === "") {
			fetchMyGroups();
			return;
		}
		fetchMyGroups(e.target.value);
	};

	const searchHandler2 = (e) => {
		setSearchQuery1(e.target.value);
		if (e.target.value === "") {
			getExploreGroups();
			return;
		}
		getExploreGroups(e.target.value);
		// setExploregroups(
		// 	exploreGroups.filter((grp) =>
		// 		grp.name.toLowerCase().includes(e.target.value.toLowerCase())
		// 	)
		// );
	};

	const handleGrpDisplay = () => {
		setExploreDisplay(!exploreDisplay);
		setGrpShow(!grpShow);
	};

	return (
		<div
			className={
				props.top === "true" ? styles.container1 : styles.container
			}
		>
			<div className={styles.section}>
				<div className={styles.groupHeading}>
					<h6
						className={styles.heading}
						style={{
							color: theme === "dark" ? "#d9def4" : "#0D0F22",
						}}
					>
						My Groups
					</h6>
					<span
						className={styles.groupBtn}
						onClick={() => setGroupDisplay(!groupDisplay)}
					>
						{!groupDisplay ? (
							<p style={{ cursor: "pointer" }}>View All</p>
						) : (
							<p style={{ cursor: "pointer" }}>Hide All</p>
						)}
					</span>
				</div>

				{grpShow && (
					<div className={styles.searchBox}>
						<img src="/Search.svg" className="mr-2 ml-1" alt="" />
						<input
							type="text"
							className={styles.searchField}
							placeholder="Search your groups"
							value={searchQuery}
							onChange={(e) => searchHandler(e)}
						/>
						{searchQuery && (
							<Close
								onClick={() => {
									setSearchQuery("");
									fetchMyGroups();
								}}
								style={{
									margin: ".25rem .5rem",

									color:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
							/>
						)}
					</div>
				)}

				{isLoading ? (
					<DisplaySkeleton />
				) : (
					grpShow && (
						<div className={styles.groupsCont}>
							{myGroups?.map((grp) => (
								<div
									className={
										props.groupId === grp?.id
											? `${styles.myGrp} myGrp`
											: `${styles.item} item`
									}
									style={{
										background:
											theme === "dark"
												? "#121528"
												: "white",
										boxShadow:
											theme === "dark"
												? "0px 0px 10px rgba(0, 0, 0, 0.05)"
												: "0px 0px 10px rgba(0, 0, 0, 0.08)",
										border:
											"1px solid " +
											(props.groupId === grp?.id
												? "rgb(3,166,216)"
												: "transparent"),
									}}
									onClick={() => handleChangeGroup(grp)}
								>
									<Avatar
										style={{
											border: "3px solid #fff",
											background:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
											color:
												theme === "dark"
													? "#0d0f22"
													: "#d9def4",
										}}
										className="mr-2"
										src={grp.avatar}
									>
										{grp.name[0]?.toUpperCase()}
									</Avatar>

									<span
										className={styles.grpname}
										style={{
											color:
												theme === "dark"
													? "#d9def4"
													: "#0D0F22",
										}}
									>
										{grp.name}
									</span>

									<span className={styles.bulb}>
										{id === grp.id && (
											<img src="/Home/bulb.png" alt='bulb' />
										)}
									</span>
									<span className={styles.moreIcon}>
										<SidebarContextMenu
											className={styles.hoverBtn1}
											transformOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
											icon={(props) => (
												<IconButton
													size="small"
													{...props}
												>
													{theme === "dark" ? (
														<img
															src="/cards-img/more-dark.png"
															alt=""
														/>
													) : (
														<img
															src="/cards-img/more-lg.png"
															alt=""
														/>
													)}
												</IconButton>
											)}
										>
                                            <Button
                                                disableRipple
												className={styles.deleteBtn}
												style={{
													fontSize: "0.9rem",
													color:
														theme === "dark"
															? "#D9DEF4"
                                                            : "#0D0F22",
                                                    padding: 10,
                                                    margin:-15
                                                    
												}}
												onClick={() =>
													openInfoModal(grp.id)
												}
											>
												More Info
											</Button>
										</SidebarContextMenu>
									</span>
								</div>
							))}
							<div
								className={
									props.myGrp === "Global"
										? `${styles.myGrp} myGrp`
										: `${styles.item} item`
								}
								style={{
									background:
										theme === "dark" ? "#121528" : "white",
									boxShadow:
										theme === "dark"
											? "0px 0px 10px rgba(0, 0, 0, 0.05)"
											: "0px 0px 10px rgba(0, 0, 0, 0.08)",
									border:
										"1px solid " +
										(props.myGrp === "Global"
											? "rgb(3,166,216)"
											: "transparent"),
								}}
								onClick={() =>
									handleChangeGroup({ id: 1, name: "Global" })
								}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Avatar
										className="mr-2"
										style={{
											border: "3px solid #fff",
											background:
												theme === "dark"
													? "#d9def4"
													: "#0d0f22",
											color:
												theme === "dark"
													? "#0d0f22"
													: "#d9def4",
										}}
									>
										G
									</Avatar>
									<span
										className={styles.grpname}
										style={{
											color:
												theme === "dark"
													? "#d9def4"
													: "#0D0F22",
										}}
									>
										Global
									</span>
								</div>
							</div>
						</div>
					)
				)}
			</div>

			{props.top === "true" && (
				<Button
					variant="contained"
					className={styles.createBtn}
					onClick={setcreateGroupDisplayChange}
				>
					{/* <FormattedMessage
						id="createGroup"
						defaultMessage={`Create a group`}
					/> */}
                    Create a group
					<AddIcon style={{ fontSize: "1.1rem", color: "#fffff" }} />
				</Button>
			)}

			<div className={styles.section}>
				<div className={styles.groupHeading}>
					<User3 theme={theme} />
					<DiscoverMoreText theme={theme}>
						Discover More
					</DiscoverMoreText>
					<span
						className={styles.groupBtn}
						onClick={handleGrpDisplay}
					>
						{!exploreDisplay ? (
							<p style={{ cursor: "pointer" }}>View All</p>
						) : (
							<p style={{ cursor: "pointer" }}>Hide All</p>
						)}
					</span>
				</div>

				{!grpShow && (
					<div className={styles.searchBox}>
						<img src="/Search.svg" className="mr-2 ml-1" alt="" />
						<input
							type="text"
							className={styles.searchField}
							placeholder="search groups"
							onChange={(e) => searchHandler2(e)}
							value={searchQuery1}
						/>
						{searchQuery1 && (
							<Close
								onClick={() => {
									setSearchQuery1("");
									getExploreGroups();
								}}
								style={{
									margin: ".25rem .5rem",

									color:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
								}}
							/>
						)}
					</div>
				)}

				{exploreLoading ? (
					<DisplaySkeleton />
				) : (
					<div
						className={
							exploreDisplay ? styles.groupsCont : styles.hide
						}
					>
						<InfiniteScroll
							dataLength={exploreGroups.length}
							next={fetchData}
							hasMore={hasMore}
							loader={pgLoading && <DisplaySkeleton />}
							// scrollableTarget="discover-all-groups"
							height={500}
							className={styles.groupCont}
						>
							{exploreGroups?.map((grp) => (
								<div
									key={grp.id}
									className={`${
										props.myGrp === grp?.name
											? styles.myGrp
											: styles.item
									} item`}
								>
									<div
										style={{
											display: "flex",
											alignItems: "center",
										}}
									>
										<Avatar
											style={{
												background:
													theme === "dark"
														? "#d9def4"
														: "#0d0f22",
												color:
													theme === "dark"
														? "#0d0f22"
														: "#d9def4",
												border: "1px solid #fff",
											}}
											className="mr-2"
											src={grp.avatar}
										>
											{grp.name[0]?.toUpperCase()}
										</Avatar>

										<span
											className={styles.grpname}
											style={{ pointer: "cursor" }}
										>
											{grp?.name}
										</span>

										{grp.type === "private" && (
											<span className={styles.editPhoto}>
												<img src="/Home/Lock.svg" alt="" />
											</span>
										)}
									</div>
									<div style={{display: "flex" , marginLeft:4, flexWrap: "nowrap", alignItems: "center", justifyContent: "center"}}>
										<span
											className={styles.moreIcon}
											onClick={() => joinGroup(grp.id)}
										>
											<DarkAddIcon />
										</span>
										<span
											style={{ marginLeft: "10px" }}
											className={styles.moreIcon}
										>
											<SidebarContextMenu
												className={styles.hoverBtn1}
												transformOrigin={{
													vertical: "top",
													horizontal: "center",
												}}
												icon={(props) => (
													<IconButton
														size="small"
														{...props}
													>
														{theme === "dark" ? (
															<img
																src="/cards-img/more-dark.png"
																alt=""
															/>
														) : (
															<img
																src="/cards-img/more-lg.png"
																alt=""
															/>
														)}
													</IconButton>
												)}
											>
                                                <Button
                                                    disableRipple
                                                    className={styles.deleteBtn}
                                                    style={{
                                                        fontSize: "0.9rem",
                                                        color:
                                                            theme === "dark"
                                                                ? "#D9DEF4"
                                                                : "#0D0F22",
                                                        margin: -15,
                                                        padding:10
                                                        
                                                    }}
                                                    onClick={() =>
                                                        openInfoModal(grp.id)
                                                    }
                                                >
                                                    More Info
                                                </Button>
											</SidebarContextMenu>
										</span>
									</div>
								</div>
							))}
						</InfiniteScroll>
					</div>
				)}
			</div>

			<CreateGroupModal
				show={createGroupDisplay}
				getMyGroups={fetchMyGroups}
				onHide={setcreateGroupDisplayChange}
			/>

			<MyAllGroup
				show={groupDisplay}
				getMyGroups={fetchMyGroups}
				onHide={() => setGroupDisplay(!groupDisplay)}
				myGroups={myGroups}
				handleChangeGroup={handleChangeGroup}
				openInfoModal={openInfoModal}
				setMygroups={setMygroups}
			/>
			<MyAllGroup
				show={groupDisplay}
				getMyGroups={fetchMyGroups}
				onHide={() => setGroupDisplay(!groupDisplay)}
				myGroups={myGroups}
				handleChangeGroup={handleChangeGroup}
				openInfoModal={openInfoModal}
				setMygroups={setMygroups}
			/>
		</div>
	);
};

const DiscoverMoreText = styled.h6`
	font-size: 1.25rem;
	color: ${({ theme }) =>
		theme === "dark" ? "#d9def4" : "#0D0F22"} !important;
`;

export default GroupsSidebar;
