
import {
    SOCIAL_LOGIN_ERROR_CLOSE,
    SOCIAL_LOGIN_ERROR,
} from "../../Actions/actionTypes";

const initialState = {

    socialLoginError: false,
    socialLoginErrorCotent: null
};

export default function (state = initialState, action) {
    switch (action.type) {

        case SOCIAL_LOGIN_ERROR:
            return {
                ...state,
                socialLoginErrorCotent: action.payload,
                socialLoginError: true
            }
        case SOCIAL_LOGIN_ERROR_CLOSE:
            return {
                ...state,
                socialLoginErrorCotent: null,
                socialLoginError: false
            }
        default:
            return state;
    }
}
