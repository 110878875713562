import {
	incrementCount,
	newNotification,
	setNewNotificationsPresent,
	setUnreadCount,
} from "../Redux/Actions/notifications";
import store from "../Redux/store";
import Log from "../Utils/logger";

const onNotification = (event) => {
	console.log("events", event);
	const { data: dataString } = event;
	const { data, ...notification } = JSON.parse(dataString);

	if (notification.type === "notifi") {
		if (notification.event !== "notify_count") {
			store.dispatch(newNotification(data));
			store.dispatch(setNewNotificationsPresent(true));
			store.dispatch(incrementCount());
		} else {
			Log.debug(data);
			store.dispatch(setUnreadCount(data));
		}
	}
};
export default onNotification;
