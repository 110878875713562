export default function ClipBoardtext({ theme }) {
	const mainColor = theme === "dark" ? "#D9DEF4" : "#0D0F22";

	return (
		<svg
			width="27"
			height="27"
			viewBox="0 0 27 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 5V3C13 2.46957 12.7893 1.96086 12.4142 1.58579C12.0391 1.21071 11.5304 1 11 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V11C1 11.5304 1.21071 12.0391 1.58579 12.4142C1.96086 12.7893 2.46957 13 3 13H5M7 5H15C16.1046 5 17 5.89543 17 7V15C17 16.1046 16.1046 17 15 17H7C5.89543 17 5 16.1046 5 15V7C5 5.89543 5.89543 5 7 5Z"
				stroke={mainColor}
				strokeWidth="1.2"
				strokeLinecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
