import React, { useState } from "react";
import { useSelector } from "react-redux";
import { PictureAsPdf, PlayCircleOutline } from "@material-ui/icons";
import styled from "styled-components";

import ImageDisplay from "../../../Extras/ImageDisplay/ImageDisplay";

const Media = ({ data, imgClass, className }) => {
	const [show, setShow] = useState(false);
	const theme = useSelector((state) => state.theme.theme);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	let icon = 3;

	if (
		data.type === "IMAGE" ||
		data.type === "GIF" ||
		data.file_type === "image/jpeg"
	) {
		icon = 1;
	} else if (data.type === "VIDEO") {
		icon = 2;
	}

	return (
		<PDFContainer
			className={className}
			style={{ background: theme === "dark" ? "#7f89be" : "#4a517e" }}
		>
			{icon === 1 ? (
				<>
					<img
						onClick={() => handleShow()}
						src={data.type === "IMAGE" ? data.media : data.file}
						className={imgClass}
						alt=""
					/>
					<ImageDisplay
						show={show}
						img={data.type === "IMAGE" ? data.media : data.file}
						handleClose={handleClose}
					/>
				</>
			) : icon === 2 ? (
				<>
					<a
						href={data.type === "VIDEO" ? data.media : data.file}
						onclick="window.open(this.href,'_blank');return false;"
						download={data.file}
						target="_blank"
						rel="noreferrer noopener"
					>
						<PlayCircleOutline />
					</a>
				</>
			) : (
				<>
					<a href={data.media || data.file}>
						<PictureAsPdf
							style={{ fontSize: "4rem", color: "#ccc" }}
						/>
					</a>
				</>
			)}
		</PDFContainer>
	);
};

const PDFContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 130px;

	a {
		display: flex;
		justify-content: center;
		height: fit-content;
	}
`;

export default Media;
