import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Navbar from "../Navbar/Navbar";
import styles from "./About.module.css";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
export default function About() {
	const [show, doShow] = useState({
		itemOne: false,
	});
	const [zoom, setZoom] = useState(false);
	const ourRef = useRef(null);

	useLayoutEffect(() => {
		const topPos = (element) => element.getBoundingClientRect().top;
		const div1Pos = topPos(ourRef.current);

		const onScroll = () => {
			const scrollPos = window.scrollY + window.innerHeight;
			if (div1Pos < scrollPos) {
				doShow((state) => ({ ...state, itemOne: true }));
			}
		};

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, []);
	useEffect(() => {
		window.visualViewport.addEventListener("resize", viewportHandler);
	}, []);

	const viewportHandler = (event) => {
		// NOTE: This doesn't actually work at time of writing
		if (event.target.scale < 1) {
			setZoom(true);
		} else {
			setZoom(false);
		}
	};
	return (
		<div className={styles.wrapper}>
			<div
				className={styles.container}
				style={{ overflow: zoom ? "hidden" : "" }}
			>
				<Navbar />
				<div className={styles.cont}>
					<div className={styles.first}>
						<h1 className={styles.heading}>
							<FormattedMessage
								id="landingPage.about.simpleIdea"
								defaultMessage={`We are driven by a simple idea!`}
							/>
						</h1>
						<p className={styles.content}>
							<strong>Bditto</strong>
							<FormattedMessage
								id="landingPage.about.simpleIdeaContent"
								defaultMessage={` is on a mission to bring people together
              and empower open communication. We believe you have interesting
              thoughts and it’s easy to create something together. As a member
              of the Bditto community, you’ll experience an exciting environment
              that supports teamwork, empowerment, flexibility, integrity,
              innovation, and feedback. It is this thinking and open approach,
              that allows us to create a unique culture where we thrive through
              collaboration.`}
							/>
						</p>
						<p className={styles.content}>
							<FormattedMessage
								id="landingPage.about.simpleIdeaContent2"
								defaultMessage={`We do it and get better together.`}
							/>
						</p>
					</div>

					<div>
						<h2
							style={{ paddingLeft: 0, marginTop: "1.5em" }}
							className={styles.subHeading}
						>
							<FormattedMessage
								id="landingPage.about.vision"
								defaultMessage={`Vision`}
							/>
						</h2>
						<p className={styles.content}>
							<FormattedMessage
								id="landingPage.about.visionContent"
								defaultMessage={`We enable people to share thoughts and build connections for
              endless opportunities.`}
							/>
						</p>
					</div>

					<div className={styles.pinkCircle}></div>
					<section>
						<div className={styles.valueCont}>
							<div className={styles.article}>
								<div className={styles.aside}>
									<Div animate={show.itemOne} ref={ourRef}>
										<h1 className={styles.ourCulture}>
											<FormattedMessage
												id="landingPage.about.OurCulture"
												defaultMessage={`Our Cultures,
                        Values,`}
											/>
											<br></br>
											<FormattedMessage
												id="landingPage.about.OurCulture1"
												defaultMessage={`
                        and Beliefs`}
											/>
										</h1>
									</Div>
								</div>

								{/* <div className={styles.values}> */}
								<div className={styles.valuesScrollOne}>
									<h3 className={styles.value}>
										<FormattedMessage
											id="landingPage.about.RightHeading1"
											defaultMessage={`Fun`}
										/>
									</h3>
									<h4 className={styles.content}>
										<FormattedMessage
											id="landingPage.about.RightContent1"
											defaultMessage={`Our culture is to share ideas, make bold bets and have fun
                    while we grow. We will try something crazy, think differently
                    and have a blast trying. We do it all together.`}
										/>
									</h4>
								</div>
								<div className={styles.valuesScroll}>
									<h3 className={styles.value}>
										<FormattedMessage
											id="landingPage.about.RightHeading2"
											defaultMessage={` Passion & Energy`}
										/>
									</h3>
									<h4 className={styles.content}>
										<FormattedMessage
											id="landingPage.about.RightContent2"
											defaultMessage={` We are passionate about sharing good vibes and building
                    something great. Bditto is a place where we keep it light and
                    work hard at the same time. A joy-fuelled company that
                    believes in the power of creative energy.`}
										/>
									</h4>
								</div>
								<div className={styles.valuesScroll}>
									<h3 className={styles.value}>
										<FormattedMessage
											id="landingPage.about.RightHeading3"
											defaultMessage={`Openness`}
										/>
									</h3>
									<h4 className={styles.content}>
										<FormattedMessage
											id="landingPage.about.RightContent3"
											defaultMessage={`Bditto is a friendly, collaborative environment that moves
                    quickly where ideas flow freely. We firmly believe sharing
                    ideas and results with the whole team is essential to an open
                    culture where our community can thrive.`}
										/>
									</h4>
								</div>
								<div className={styles.valuesScroll}>
									<h3 className={styles.value}>
										<FormattedMessage
											id="landingPage.about.RightHeading4"
											defaultMessage={`Happiness`}
										/>
									</h3>
									<h4 className={styles.content}>
										<FormattedMessage
											id="landingPage.about.RightContent4"
											defaultMessage={`We believe that happiness is the most important focus in a
                    company. Its about living life to the fullest and finding
                    passion in every day. Be happy to think free and share it with
                    others.`}
										/>
									</h4>
								</div>

								<div className={styles.valuesScroll}>
									<h3 className={styles.value}>
										<FormattedMessage
											id="landingPage.about.RightHeading5"
											defaultMessage={`Collaboration`}
										/>
									</h3>
									<h4 className={styles.content}>
										<FormattedMessage
											id="landingPage.about.RightContent5"
											defaultMessage={`Bditto is a team of collective genius, who believes that the
                    best outcomes are achieved when people are able to create and
                    collaborate in open, diverse, and inclusive environments. Its
                    a workplace for the best minds to create something amazing.`}
										/>
									</h4>
								</div>
							</div>
						</div>
					</section>
					{/* </div> */}

					<div className={styles.team}>
						<div className={styles.yellowBox}></div>
						<h1 className={styles.subHeading}>
							<FormattedMessage
								id="landingPage.about.MeetOurTeam"
								defaultMessage={`Meet Our Team`}
							/>
						</h1>
						<div className={styles.teamCont}>
							<div className={styles.imgCont}>
								<img
									className={styles.img}
									src="/images/founders/siva.jfif"
									alt="Siva Dantuluri"
								/>
								<p className={styles.name}>Siva Dantuluri</p>
								<p className={styles.role}>
									Founder{" "}
									<FormattedMessage
										id="and"
										defaultMessage={`and`}
									/>{" "}
									CEO
								</p>
							</div>
							<div className={styles.imgCont}>
								<img
									className={styles.img}
									src="/images/founders/florian.jpg"
									alt="Florian Villegas"
								/>
								<p className={styles.name}>Florian Villegas</p>
								<p className={styles.role}>
									Co-Founder{" "}
									<FormattedMessage
										id="and"
										defaultMessage={`and`}
									/>{" "}
									CFO
								</p>
							</div>
						</div>
						<div className={styles.contact}>
							<h2 className={styles.partOfTeam}>
								<FormattedMessage
									id="landingPage.about.BePartOfTeam"
									defaultMessage={`Be a part of the team`}
								/>
							</h2>
							<p
								className={styles.content}
								style={{ textAlign: "center" }}
							>
								<FormattedMessage
									id="landingPage.about.IfYouArePassionateText"
									defaultMessage={`If you are passionate about our mission, Bditto is the place for you.
                    We're a start-up and we need people who share the same passion and excitement!
                    You will be working alongside talented people who brought Bditto up from just
                    an idea to what it is now!`}
								/>
							</p>
							<a href="mailto:hello@bditto.com">
								<button className={styles.contactBtn}>
									<FormattedMessage
										id="landingPage.about.sayHelloButton"
										defaultMessage={`Say Hello`}
									/>
								</button>
							</a>
						</div>
					</div>

					<div className={styles.blueBox}></div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
const Div = styled.div`
	@media screen and (min-width: 1100px) {
		transform: translateY(${({ animate }) => (animate ? "0" : "20vh")});
		transition: transform 1.5s;
	}
`;
