import { THEME_CHANGE } from "../Actions/actionTypes";

const initialState = {
    theme: "dark"
};

export default function (state = initialState, action) {
    switch (action.type) {
        case THEME_CHANGE:
            return {
                ...state,
                theme: action.payload,
            };
        default:
            return state;
    }
}
