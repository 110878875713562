import {
	Avatar,
	CircularProgress,
	IconButton,
	useMediaQuery,
} from "@material-ui/core";
import { Close, DoneAll } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Like } from "../../../../../Icons";
import axiosChatConfig from "../../../../../Utils/axiosChatConfig";
import {
	getMessageDetails,
	getMessageLikes,
	getMessageReads,
} from "../../../../../Utils/Constants";
import Username from "../../../../Extras/Username";
import MessageBox from "../MessageBox";
import styles from "./MessageInfo.module.scss";

const MessageInfo = ({ isOpen, handleClose, group, ...props }) => {
	const [messageInfo, setMessageInfo] = useState({
		message: undefined,
		readBy: [],
		likes: [],
		likesCount: undefined,
		readCount: undefined,
	});
	const [loadingReadReceipts, setLoadingReadReceipts] = useState(false);
	const [loadingLikes, setLoadingLikes] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState(false);
	async function fetchReadReceipts(messageID, groupID) {
		setLoadingReadReceipts(true);
		const data = { messageID, groupID };
		try {
			const { data: response } = await axiosChatConfig.post(
				getMessageReads,
				data
			);
			setMessageInfo((prev) => ({
				...prev,
				readBy: response.readBy,
				readCount: response.remainingCount,
			}));
			setLoadingReadReceipts(false);
		} catch (err) {
			setMessageInfo((prev) => ({ ...prev, likedBy: undefined }));
			setLoadingReadReceipts(false);
		}
	}
	async function fetchMessage(messageID, groupID) {
		setLoadingMessage(true);
		const data = { messageID, groupID };
		try {
			const { data: response } = await axiosChatConfig.get(
				getMessageDetails,
				{ params: data }
			);
			setMessageInfo((prev) => ({ ...prev, message: response.message }));
			setLoadingMessage(false);
		} catch (err) {
			setMessageInfo((prev) => ({ ...prev, message: undefined }));
			setLoadingReadReceipts(false);
		}
	}
	async function fetchLikes(messageID, groupID) {
		setLoadingLikes(true);
		try {
			const { data: response } = await axiosChatConfig.get(
				getMessageLikes(messageID)
			);
			setMessageInfo((prev) => ({
				...prev,
				likes: response.users,
				likesCount: response.count,
			}));
			setLoadingLikes(false);
		} catch (err) {
			setMessageInfo((prev) => ({ ...prev, likes: undefined }));
			setLoadingLikes(false);
		}
	}

	useEffect(() => {
		if (isOpen && props.messageID) {
			fetchMessage(props.messageID, props.groupID);
			fetchLikes(props.messageID, props.groupID);

			if (props.isOwnMessage) {
				fetchReadReceipts(props.messageID, props.groupID);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.messageID, isOpen]);

	const isMobile = useMediaQuery("(max-width:768px)");
	return (
		<div className={isOpen ? styles.contShow : styles.contHide}>
			<div className={styles.topContainer}>
				<IconButton size="small" onClick={handleClose}>
					<Close htmlColor="black" />
				</IconButton>
			</div>
			<div className={styles.header}>
				<h2>{group.content}</h2>
			</div>
			<div className={styles.container}>
				{isMobile && !loadingMessage && messageInfo.message && (
					<MessageBox
						showUsername
						showAvatar
						noSpace
						restrictHeight
						noActions
						data={messageInfo.message}
					/>
				)}
			</div>

			<div className={styles.body}>
				{props.isOwnMessage && (
					<div className={styles.readBy}>
						<div className={styles.header}>
							<DoneAll color="primary" />{" "}
							<span>
								Seen{" "}
								{!loadingReadReceipts &&
									`(${messageInfo.readBy.length})`}
							</span>
						</div>
						{!loadingReadReceipts ? (
							messageInfo.readBy.length > 0 ? (
								<>
									{messageInfo.readBy.map((user) => (
										<div className={styles.userCard}>
											<div className={styles.avatar}>
												<Avatar
													src={user.profileURL}
													style={{
														width: "2.2rem",
														height: "2.2rem",
													}}
												>
													{user.username[0].toUpperCase()}
												</Avatar>
											</div>
											<div className={styles.name}>
												@{user.username}
											</div>
										</div>
									))}
									{messageInfo.readCount > 0 && (
										<div className={styles.userCard}>
											<div
												className={styles.avatar}
											></div>
											<div className={styles.name}>
												and {messageInfo.readCount} more
											</div>
										</div>
									)}
								</>
							) : (
								<div className={styles.emptyList}>
									No one has Read the message at the moment
								</div>
							)
						) : (
							<div className={styles.loaderContainer}>
								<CircularProgress className={styles.loader} />
							</div>
						)}
					</div>
				)}
				<div className={styles.readBy}>
					<div className={styles.header}>
						<Like.Filled
							viewBox="0 0 27 27"
							style={{ width: "28px" }}
						/>
						<span>
							liked{" "}
							{!loadingLikes && `(${messageInfo.likes.length})`}
						</span>
					</div>
					{!loadingLikes ? (
						messageInfo.likes.length > 0 ? (
							<>
								{messageInfo.likes.map((user) => (
									<div className={styles.userCard}>
										<div className={styles.avatar}>
											<Avatar
												src={user.profileURL}
												style={{
													width: "2.2rem",
													height: "2.2rem",
												}}
											>
												{user.username[0].toUpperCase()}
											</Avatar>
										</div>
										<div className={styles.name}>
											<Username value={user.username} />
										</div>
									</div>
								))}
								{messageInfo.likesCount > 0 && (
									<div className={styles.userCard}>
										<div className={styles.avatar}></div>
										<div className={styles.name}>
											and {messageInfo.likesCount} more
										</div>
									</div>
								)}
							</>
						) : (
							<div className={styles.emptyList}>
								No one has Liked the message
							</div>
						)
					) : (
						<div className={styles.loaderContainer}>
							<CircularProgress className={styles.loader} />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default MessageInfo;
