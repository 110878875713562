import { combineReducers } from "redux";
import chatMetaReducer from "./chatMetaReducer";
import dispatchQueueReducer from "./dispatchQueueReducer";
import unreadCountReducer from "./unreadCountReducer";

const messagesReducer = combineReducers({
  dispatchQueue: dispatchQueueReducer,
  unreadMessages: unreadCountReducer,
  meta: chatMetaReducer,
});

export default messagesReducer;
