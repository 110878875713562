import React from "react";

import useAlert from "./useAlert";

const withAlert = (Component) => (props) => {
	const alertProps = useAlert();
	return <Component {...props} {...alertProps} />;
};

export default withAlert;
