import React from "react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: "none",
	},
	paperlg: {
		padding: theme.spacing(1),
		pointerEvents: "auto",
		background: "#fff",
		borderRadius: "16px",
	},
	paperdark: {
		padding: theme.spacing(1),
		pointerEvents: "auto",
		background: "#121528",
		borderRadius: "16px",
	},
}));

export default function HoverPopover(props) {
	const classes = useStyles();
	const anchorEl = React.useRef();
	const [open, setIsOpen] = React.useState(false);
	const theme = useSelector((state) => state.theme.theme);

	const handlePopoverOpen = (event) => {
		setIsOpen(true);
	};

	const handlePopoverClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<div
				aria-owns={open ? "mouse-over-popover" : undefined}
				aria-haspopup="true"
				ref={anchorEl}
				className={props.className}
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			>
				{props.trigger}
			</div>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper:
						theme === "dark" ? classes.paperdark : classes.paperlg,
				}}
				open={open}
				anchorEl={anchorEl.current}
				anchorOrigin={props.anchorOrigin}
				transformOrigin={props.transformOrigin}
				PaperProps={{
					onMouseEnter: handlePopoverOpen,
					onMouseLeave: handlePopoverClose,
				}}
			>
				{props.children}
			</Popover>
		</>
	);
}
