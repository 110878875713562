import throttle from "lodash/throttle";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { placesLibraryLoaded } from "../../../Redux/Actions/deviceParamsActions";
let autoComplete;

function scriptExists(url) {
	const script = document.querySelectorAll(`script[src="${url}"]`);
	return script.length > 0 ? script[0] : null;
}

export const loadScript = (url, callback) => {
	const existingScript = scriptExists(url);
	if (!existingScript) {
		let script = document.createElement("script");
		script.type = "text/javascript";

		if (script.readyState) {
			script.onreadystatechange = function () {
				if (
					script.readyState === "loaded" ||
					script.readyState === "complete"
				) {
					script.onreadystatechange = null;
					callback();
				}
			};
		} else {
			script.onload = () => {
				callback();
			};
		}

		script.src = url;
		document.getElementsByTagName("head")[0].appendChild(script);
	}
};

function handleScriptLoad() {
	autoComplete = new window.google.maps.places.AutocompleteService();
}

export const useLocationPredictions = (
	searchQuery,
	{ sessionToken, locationType, onlyCountry, minLength = 3 }
) => {
	const libraryLoading = useSelector(
		(state) => !state.deviceParameters.mapsPlacesLoaded
	);
	const dispatch = useDispatch();
	const libraryLoaded = () => dispatch(placesLibraryLoaded());
	useEffect(() => {
		loadScript(
			`https://maps.googleapis.com/maps/api/js?key=AIzaSyBGv_qdmw0do3DOf000yOSSv1iTqi2hEMw&libraries=places`,
			() => {
				libraryLoaded();
				sessionToken.current =
					new window.google.maps.places.AutocompleteSessionToken();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!libraryLoading) {
			handleScriptLoad();
		}
	}, [libraryLoading]);

	const [predictions, setPredictions] = useState([]);
	const [loadingPredictions, setLoadingPredictions] = useState(false);
	const [searched, setSearch] = useState(false);

	const searchForPlaces = throttle((searchQuery) => {
		if (loadingPredictions) return;
		setLoadingPredictions(true);
		autoComplete.getPlacePredictions(
			{
				types: [locationType],
				input: searchQuery,
				sessionToken: sessionToken.current,
				fields: ["formatted_address"],
			},
			function (results, serviceStatus) {
				setLoadingPredictions(false);
				if (results) {
					const predictions = results.reduce(
						(acc, currPrediction) => {
							const isValidPrediction =
								currPrediction.types.some(function (x) {
									return (
										x === "country" ||
										(!onlyCountry &&
											(x === "administrative_area1" ||
												x === "locality"))
									);
								}) && currPrediction.terms.length <= 3;
							if (isValidPrediction) {
								const isCity = currPrediction.terms.length > 1;
								const params = {};
								params.country =
									currPrediction.terms[
										currPrediction.terms.length - 1
									].value;
								if (isCity) {
									params.city = currPrediction.terms[0].value;
								}
								return [
									...acc,
									{
										label: currPrediction.structured_formatting,
										params,
									},
								];
							} else {
								return acc;
							}
						},
						[]
					);
					setPredictions(predictions);
				} else {
					setPredictions([]);
				}
			}
		);
	}, 1000);

	useEffect(() => {
		if (!libraryLoading && searchQuery.length >= minLength) {
			searchForPlaces(searchQuery);
			setSearch(true);
		} else {
			setSearch(false);
			setPredictions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	return { libraryLoading, searched, predictions, loadingPredictions };
};
