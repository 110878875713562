/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";

import styles from "./Friends.module.scss";

//Utils
import axiosConfig from "../../../Utils/axiosConfig";
import {
	getFriendStatus,
	getFriendSuggestion,
	getUserStatusURL,
	friendRequestURL,
} from "../../../Utils/Constants";
import useQuery from "../../../Utils/useQuery";

//Material UI
import { CircularProgress, Avatar } from "@material-ui/core";

//Components
import FriendsSidebar from "./Sidebar/FriendsSidebar";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import EmptyState from "../../Extras/EmptyState";
import ChatUserReview from "../../Extras/ChatUserReview/ChatUserReview";
import OnlineBadge from "../../Extras/StyledBadge";
import useAlert from "../../Extras/Alert/useAlert";
import MyFeedIcon from "../../../Icons/MyFeedIcon";
import CardSkeleton from "../../Skeleton/CardSkeleton/CardSkeleton";
import Plus from "../../../assets/violetPlus.svg";

let FETCH_LIMIT = 24;
const Friends = () => {
	const [data, setData] = useState([]);
	const [friendSuggestion, setFriendSuggestion] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [show, setShow] = useState(false);
	const theme = useSelector((state) => state.theme.theme);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const [active, setactive]= useState("My Feed");
	const [view, setview]= useState({
		myfeed:true,
		myconnections:true,
		suggestion:true
	});
	const isMobile = window.screen.width < 768;
	const { showAlert } = useAlert();

	let offset = useRef(0);
	let end = useRef(false);

	const location = useLocation();
	const { friend: activeFriendID } = useQuery(location.search);
	const isDesktop = useMediaQuery({ query: "(min-device-width: 768px)" });

	useEffect(() => {
		setTimeout(() => {
			setShow(true);
		}, 2000);

		//check if its Desktop or Mobile
		if(isMobile){
		setview(	{
				myfeed:true,
				myconnections:false,
				suggestion:false
			});
		}
	}, []);

	const delay = () => {
		return show ? (
			<p
				style={{ margin: "1rem" }}
				className={`${
					theme === "dark" ? styles.darkText : styles.lgText
				}`}
			>
				No Suggestions
			</p>
		) : null;
	};

	useEffect(() => {
		getFriendsSuggestions();
		offset.current=0;
	}, [groupDataFromStore.id]);

	const getFriendsStatus = (reset, activeFriendID) => {
		setIsLoading(true);
		if(activeFriendID === undefined)
		offset.current=0;
		if (!reset && (isLoading || end.current)) return;
		const form = new FormData();
		form.append("group_id", groupDataFromStore.id);
		form.append("userId", activeFriendID);

		let URL = `${
			!activeFriendID ? getFriendStatus : getUserStatusURL
		}?limit=${FETCH_LIMIT}&offset=${offset.current}`;

		const dataPromise = !activeFriendID
			? axiosConfig.get(URL + "&groupId=" + groupDataFromStore.id)
			: axiosConfig.post(getUserStatusURL, form);

		dataPromise
			.then((res) => {
				if (res.status !== 200) throw new Error();
				if(res.data.result)
				setData((prev) =>
					reset ? res.data.result : [...prev, ...res.data.result]
				);
				else
				setData((prev) =>
					reset ? res.data.body : [...prev, ...res.data.body]
				);
				setIsLoading(false);

				offset.current += FETCH_LIMIT;

                if (!res.data.next) {
                    end.current = true;
                }
			})
			.catch((err) => {
				setIsLoading(true);
				showAlert("Something went wrong",{
					header: `${err.response.data.message}`,
					buttonText: "Go Back",
				});
			});
	};

	const getFriendsSuggestions = () => {
		let URL = `${getFriendSuggestion}?group_id=${groupDataFromStore.id}`;
		axiosConfig
			.get(URL)
			.then((res) => {
				if (res.data.status_code !== 200) throw new Error();
				setFriendSuggestion([...res.data.body]);
				if (res.data && !res.data.next) end.current = true;
			})
			.catch((err) => {
				// showAlert("Please Try again later", {
				// 	header: "Something went wrong",
				// 	buttonText: "Go Bac",
				// });
			});
	};

	const sendFriendRequest = (userID, note = "") => {
		const form = new FormData();
		form.append("userId", userID);
		form.append("note", note);

		axiosConfig
			.post(friendRequestURL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();
				showAlert("Friend Request Sent Succesfully", {
					header: "Reqested",
					buttonText: "Go Back",
				});
				getFriendsSuggestions();
			})
			.catch((err) => {
				if (err.response) {
					if (
						err.response.status === 403 &&
						err.response.statusText === "Forbidden"
					) {
						showAlert(
							"Your account has been blocked from update actions. Please contact Admin.",
							{
								header: "You Account is Blocked",
								buttonText: "Go Back",
							}
						);
					} else {
						showAlert("Please Try again later", {
							header: "Something went wrong",
							buttonText: "Go Back",
						});
					}
				}
			});
	};

	const handleScroll = (e) => {
		if (activeFriendID) return;
		const bottom =
			e.target.scrollHeight - Math.round(e.target.scrollTop) <=
			e.target.clientHeight + 200;
		if (bottom) {
			getFriendsStatus(false);
		}
	};

	useEffect(() => {
		setData([]);
		getFriendsStatus(true, activeFriendID);
		getFriendsSuggestions();
	}, [activeFriendID, groupDataFromStore.id]);


	const tabs = [
		{
			id: 1,
			label: "My Feed",
			target:"myfeed"
		},
		{
			id: 2,
			label: "My Connections",
			target:"myconnections"
		},
		{
			id: 3,
			label: "Suggestions",
			target:"suggestion"
		}
	];

	return (
		<>
			{isMobile
				&&
				<div className={styles.friendsTab}>
					{tabs.map((tab, i)=>{
						return <div className={active === tab.label ? styles.activeTab : styles.tab} onClick={()=>{
							setactive(tab.label);
							let state= view;
							if(tab.target === "myfeed")
							state={
									myfeed:true,
									myconnections:false,
									suggestion:false
							}
							if(tab.target === "myconnections")
							state={
									myfeed:false,
									myconnections:true,
									suggestion:false
							}
							if(tab.target === "suggestion")
							state={
									myfeed:false,
									myconnections:false,
									suggestion:true
							}

							setview(state);
						}}>
							{tab.label}
						</div>
					})}
				</div>}

			<div className={styles.wrapper}>
				<Helmet>
					<title>{`My Feed | bditto`} </title>
				</Helmet>
				<div className={styles.container}>
					{view.myconnections && <FriendsSidebar />}

					{view.myfeed && <div className={styles.left} onScroll={handleScroll}>
						{!isMobile && <div
							className={`${styles.header} ${
						theme === "dark" ? styles.darkText : styles.lgText
							}`}
													>
							{theme === "dark" ? (
								<MyFeedIcon />
							) : (
								<img src="/Home/myFeedIconlg.svg" alt="" />
							)}
							<h2>
								<FormattedMessage
									id="myFeed"
									defaultMessage={`My Feed`}
								/>
							</h2>
						</div>}

						<div className={styles.cards}>
							{data?.length > 0 ? (
								<>
									{data.map((item, i) => {
										return (
											<StatusCard
												idx={i % FETCH_LIMIT}
												status={item}
												key={i}
												height="13rem"
												width={isDesktop ? "48%" : "80%"}
											/>
										);
									})}
								</>
							) : (
								!isLoading &&
								data?.length === 0 && (
									<div
										style={{ marginTop: "10%", width: "100%" }}
									>
										<EmptyState
											heading="No Results"
											subHeading="Your friend haven’t posted anything yet but when they do, you can view all their statutes here!"
										/>
									</div>
								)
							)}
							{isLoading && (
								<>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
									<CardSkeleton
										width={isDesktop ? "48%" : "80%"}
									/>
								</>
							)}
						</div>
					</div>}

					{view.suggestion && <div className={styles.right} onScroll={handleScroll} style={!isMobile ? {	marginTop: "4.5rem"} : {	marginTop: "0rem"}}>
						{!isMobile ? (<div
							className={`${styles.header} ${
						theme === "dark" ? styles.darkText : styles.lgText
							}`}
													>
							<h2>
								{/* <FormattedMessage
									id="myFeed"
									defaultMessage={`Suggestions`}
								/> */}
                                Suggestions
							</h2>
						</div>) : (
							<div className={styles.para}>
								<h5>Add to your network</h5>
								<p >
									Connect & collaborate with more people from workspace 1.
								</p>
							</div>
						)}
						<div className={styles.suggestion}>
							{isLoading ? (
								<CircularProgress
									className={styles.loaderSpinner}
									style={{
										margin: "auto",
										color:
										theme === "dark"
											? "#d9def4"
											: "#0d0f22",
									}}
								/>
							) : friendSuggestion.length > 0 ? (
								friendSuggestion.map((suggestion) => (
									<div
										className={`${styles.card} ${
						theme === "dark"
						? styles.darksha
						: styles.lgsha
						}`}
									>
										<div className={styles.info}>
											<ChatUserReview id={suggestion?.userID}>
												<OnlineBadge
													invisible={false}
													last_active={
														suggestion?.last_active
													}
													online={suggestion.user?.online}
													ripple={true}
												>
													<Avatar
														style={{
															width: "2rem",
															height: "2rem",
														}}
														className="mr-1"
														src={suggestion.avatar}
														style={{
															background:
															theme === "dark"
																? "#d9def4"
																: "#0d0f22",
															color:
															theme === "dark"
																? "#0d0f22"
																: "#d9def4",
															border:
															theme === "dark"
																? "2px solid #d9def4"
																: "",
														}}
													>
														{suggestion.username[0].toUpperCase()}
													</Avatar>
												</OnlineBadge>
											</ChatUserReview>

											<Link
												to={`/profile/${suggestion.userID}`}
											>
												<p
													className={`${
						styles.suggestionName
						} ${
						theme === "dark"
						? styles.darkText
						: styles.lgText
						}`}
												>
													@{suggestion.username}
												</p>
											</Link>
										</div>
										<span
											onClick={() =>
												sendFriendRequest(suggestion.userID)
											}
											style={{ cursor: "pointer" }}
										>
											<img src={Plus} alt="" />
										</span>
									</div>
								))
							) : (
								delay()
							)}
						</div>
					</div>}
				</div>
			</div>
		</>

	);
};

export default Friends;
