import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { bdittoAPIv2 } from "../../../Utils/axiosConfig";
import { searchStatusURLV2 } from "../../../Utils/Constants";
// import SearchCard from "../../Extras/SearchCard/SearchCard";
import CardSkeleton from "../../Skeleton/CardSkeleton/CardSkeleton";
import Interests from "./InterestSidebar";
import styles from "./InterestsPage.module.css";
import { useHistory } from "react-router";
import { getParsedLocations } from "../../../Redux/selectors/locationSelector";
import { useSelector } from "react-redux";
import EmptyState from "./../../Extras/EmptyState/index";
import { IconButton } from "@material-ui/core";
import Chevron from "../../../Icons/Chevron";
// import Arrows from "../../../Icons/Arrows";
// import ArrowLeft from "../../../Icons/ArrowLeft";
import StatusCard from "../../Extras/StatusCard/StatusCard";
import { FormattedMessage } from "react-intl";

let FETCH_LIMIT = 16;
const InterestsPage = (props) => {
	const { keyword } = props.rootParams.params;
	let reset = useRef(false);
	const locations = useSelector(getParsedLocations);
	const theme = useSelector((state) => state.theme.theme);
	const groupDataFromStore = useSelector((state) => state.groups?.groupData);
	const [isEmpty, setIsEmpty] = useState(false);
	const [data, setData] = useState([]);
	const [stop, setStop] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	let offset = useRef(0);
	let end = useRef(false);
	const isDesktop = useMediaQuery({ query: "(min-device-width: 1224px)" });

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getInterestsSearch = useCallback(() => {
		if (isLoading || end.current) return;
		setIsLoading(true);
		const form = {
			search_phrase: keyword,
			location: locations,
			groupId: groupDataFromStore.id,
		};
		let URL = `${searchStatusURLV2}?limit=${FETCH_LIMIT}&offset=${offset.current}`;

		bdittoAPIv2
			.post(URL, form)
			.then((res) => {
				if (res.status !== 200) throw new Error();

				if (res.data.body) {
					setIsEmpty(true);
				} else if (res.data.result.length < 1) {
					setStop(true);
				} else {
					setIsEmpty(false);
					if (reset.current) {
						setData(res.data.result);
						reset.current = false;
					} else setData([...data, ...res.data.result]);
				}

				offset.current += FETCH_LIMIT;
				isLoading(false);
				if (!res.data.next) {
					setStop(true);
					end.current = true;
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	});
	const history = useHistory();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleScroll = useCallback((e) => {
		const bottom =
			e.target.scrollHeight - Math.round(e.target.scrollTop) <=
			e.target.clientHeight + 200;
		if (bottom) {
			!stop && !end.current && getInterestsSearch();
		}
	});
	useEffect(() => {
		setData([]);
		setStop(false);
		reset.current = true;
		offset.current = 0;
		end.current = false;
		getInterestsSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyword, locations]);

	const isMobile = useMediaQuery({ query: "(max-device-width: 600px)" });
	return (
		<div className={styles.wrapper}>
			<div className={styles.container} onScroll={handleScroll}>
				<div className={styles.left}>
					<div className={styles.heading}>
						{isMobile ? (
							<span>
								{" "}
								<IconButton
									size="small"
									variant="contained"
									style={{
										color: "#ff5d5d",
										marginLeft: "0.5rem",
									}}
									onClick={history.goBack}
								>
									<Chevron.leftDark viewBox="0 0 27 27" />
								</IconButton>
							</span>
						) : (
							<span>
								{" "}
								<IconButton
									size="small"
									variant="contained"
									style={{
										color:
											theme === "dark"
												? "#d9def4"
												: "#0d0f22",
									}}
									onClick={() => history.push("/")}
								>
									{/* <ArrowLeft
										color={
											theme === "dark"
												? "#d9def4"
												: "#0d0f22"
										}
										viewBox="0 0 27 27"
									/> */}
									<img
										style={{ width: "2rem" }}
										src="/Home/ArrowRight.png"
										alt=""
									/>
								</IconButton>
							</span>
						)}
						<div className={styles.headItem}>
							<h1
								className={`${
									theme === "dark" ? styles.darkl : styles.lgl
								}`}
							>
								&nbsp;{" "}
								<FormattedMessage
									id="latest"
									defaultMessage={keyword}
								/>
							</h1>
						</div>
					</div>
					<div
						className={
							theme === "dark" ? styles.cardsDk : styles.cardsLg
						}
					>
						{!isEmpty ? (
							data.map((item, index) => (
								<StatusCard
									status={item}
									key={index}
									width={isDesktop ? "22rem" : "80%"}
									height="13rem"
									className={styles.card}
								/>
							))
						) : (
							<>
								{" "}
								{!isLoading && (
									<EmptyState
										heading="No Results!"
										imageUrl="/vectors/empty-states/emptySearch.svg"
										subHeading="We cannot find what you’re looking for, maybe a little spelling mistake?"
									/>
								)}
							</>
						)}
						{isLoading && (
							<>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
								<CardSkeleton
									width={isDesktop ? "22rem" : "80%"}
								/>
							</>
						)}
					</div>
				</div>
				{!isMobile && <Interests />}
			</div>
		</div>
	);
};

export default InterestsPage;
