import { createSelector } from "./helper";
import { formatNumber } from "../../Utils/numbers";
export const getlocationsObject = (state) => state.location.locations;

export const getParsedLocations = createSelector(getlocationsObject, (locations) => {
  return Object.keys(locations).map((country) => ({
    country,
    city: locations[country],
  }));
});

const getCountriesFromLocations = (locations) => Object.keys(locations);

export const countriesSelector = createSelector(getlocationsObject, getCountriesFromLocations);

export const isLocalSelector = createSelector(countriesSelector, (countries) => countries.length > 0);
