export const gradients = {
	// LIGHTBLUE: "#BDF2A5",
	// YELLOW : "#F5D495",
	// RED :"#E42A5B",
	// ORANGE :"#E67773" ,

	LIGHTBLUE: "#BDF2A5",
	ORANGE: "#E67773",
	RED: "#E42A5B",
	GREEN: "#F5D495",
	SELECTED: "#00C6FF",
};

export const darkGradients = {
	LIGHTBLUE: "#BDF2A5",
	// YELLOW : "#F5D495",
	GREEN: "#F5D495",
	RED: "#E42A5B",
	ORANGE: "#E67773",
};
