import config from "../config";
import { getDecryptedToken } from "../Utils/Encryption";
import Log from "../Utils/logger";
import CustomSocket from "./CustomSocket";
import onNotification from "./onNotification";

const NOTIFICATION_SOCKET_URL = config.NOTIFICATION_SOCKET_URL;

const onConnect = () => {
	Log.debug("connected");
};

const onDisConnect = () => {
	Log.debug("disconnected");
};

const onError = (error) => {
	Log.debug(error);
};

const NotificationSocket = new CustomSocket(NOTIFICATION_SOCKET_URL);

export const connect = (props) => {
	const authToken = getDecryptedToken();
	NotificationSocket.connect(authToken, onNotification, props, {
		onConnect,
		onClose: onDisConnect,
		onError,
	});
};

export const disconnect = () => {
	NotificationSocket.disconnect();
};
