export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const DISPATCH_MESSAGE = "DISPATCH_MESSAGE";

export const UPDATE_DISPATCH_QUEUE = "UPDATE_DISPATCH_QUEUE";

export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";

export const MESSAGE_SENT = "MESSAGE_SENT";
export const CONVERSATION_READ = "CONVERSATION_READ";

export const DELETE_FROM_QUEUE = "DELETE_FROM_QUEUE";

export const SET_UNREAD_COUNT_MULTIPLE_CONVERSATIONS = "SET_UNREAD_COUNT_MULTIPLE_CONVERSATIONS";

export const CLEAR_UNREAD_COUNT = "CLEAR_UNREAD_COUNT";

export const INCREMENT_UNREAD_COUNT = "INCREMENT_UNREAD_COUNT";

export const SET_CHAT_LOADING = "SET_CHAT_LOADING";
export const MESSAGE_DELETE = "MESSAGE_DELETE";
