import React, { useEffect } from "react";
import { connect } from "react-redux";
import Radio from "@material-ui/core/Radio";
import { makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import { setTheme } from "../../../Redux/Actions/themeAction";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import styled from "styled-components"

import BackgroundDarkPath from "../../../assets/images/home-dark-theme.png";
import BackgroundLightPath from "../../../assets/images/home-light-theme.png";

import styles from "./Themes.module.css";

const Themes = ({ theme, setTheme }) => {
	const isMobile = window.screen.width < 768;
	useEffect(() => {
		const currentTheme = localStorage.getItem("theme");
		if (currentTheme) {
			setTheme(currentTheme);
		}
	}, []);

	const handleChange = (event) => {
		setTheme(event.target.value);
		localStorage.setItem("theme", event.target.value);
	};

	const useStyles = makeStyles({
		root: {
			color: "var(--heading-color2)",

			"& .MuiRadio-colorSecondary.Mui-checked": {
				color: "#00C6FF",
			},
		},

		button: {
			"&. .MuiIconButton-label": {
				color: "var(--secondary) !important",
			},
		},
	});
	const classes = useStyles();
	return (
		<div className={styles.container}>
			{!isMobile && <div className={styles.header}>
				<h2
					style={{ color: theme === "dark" ? "white" : "#0D0F22" }}
				>
					Themes
				</h2>
			</div>}
			<div>
				{!isMobile ? <FormControl className={classes.root}>
					<RadioGroup value={theme} onChange={handleChange}>
						<div className={styles.themeBoxContainer}>
							<div>
								<PreviewContainer
									url={BackgroundLightPath}
									className={`${styles.themeBox}  ${
										theme === "light"
											? styles.currentTheme
											: styles.disabledTheme
									}`}
									onClick= {
										( ) => handleChange({ target: { value: "light" }})
									}
								/>
								<FormControlLabel
									value="light"
									control={<Radio />}
									label="Light"
									style={{ color: theme === "dark" ? "white" : "#0D0F22" }}
								/>
							</div>
							<div className={classes.button}>
								<PreviewContainer
									url={BackgroundDarkPath}
									className={`${styles.themeBox}  ${
										theme === "dark"
											? styles.currentTheme
											: null
									}`}
									onClick= {
										( ) => handleChange({ target: { value: "dark" }})
									}
								/>
								<FormControlLabel
									value="dark"
									control={<Radio />}
									label="Dark"
									style={{ color: theme === "dark" ? "white" : "#0D0F22" }}
								/>
							</div>
						</div>
					</RadioGroup>
				</FormControl>
				:  <div className="mx-2">
					<FormControl className={classes.root}>
						<RadioGroup value={theme} onChange={handleChange}>
							<div className="row">
								<div className="col-12">
									<PreviewContainer
										url={BackgroundLightPath}
										className={`${styles.themeBox}  ${
											theme === "light"
												? styles.currentTheme
												: styles.disabledTheme
										}`}
										onClick= {
											( ) => handleChange({ target: { value: "light" }})
										}
									/>
									<FormControlLabel
										value="light"
										control={<Radio />}
										label="Light"
										style={{ color: theme === "dark" ? "white" : "#0D0F22" }}
									/>
								</div>
								<div className="col-12">
									<PreviewContainer
										url={BackgroundDarkPath}
										className={`${styles.themeBox}  ${
											theme === "dark"
										? styles.currentTheme
										: null
										}`}
										onClick= {
											( ) => handleChange({ target: { value: "dark" }})
										}
									/>
									<FormControlLabel
										value="dark"
										control={<Radio />}
										label="Dark"
										style={{ color: theme === "dark" ? "white" : "#0D0F22" }}
									/>
								</div>
							</div>
						</RadioGroup>
					</FormControl>
				</div>
				}
			</div>
		</div>
	);
};

const PreviewContainer = styled.div`
	background: url("${({ url }) => url}");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;

const mapStateToProps = (state) => ({
	theme: state.theme.theme,
});

export default connect(mapStateToProps, { setTheme })(Themes);
