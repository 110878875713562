import { NEW_MESSAGE, READ_MESSAGES } from "./actionTypes";

export const messageReceived = (payload) => (dispatch, getState) => {
  const { auth } = getState();
  dispatch({
    type: NEW_MESSAGE,
    payload,
    context: auth?.user_data,
  });
};

export const readMessages = (conversationID) => ({
  type: READ_MESSAGES,
  payload: { conversationID },
});
